import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useApi, { GIFT_THANK_YOU_REPLY } from '~hooks/useApi';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Dialog from '~components/Dialog';

import successReplyImg from '~src/assets/images/popups/thank-you-success.png';
import failedReplyImg from '~src/assets/images/popups/thank-you-failed.png';
import SendReplyTextBox from './components/send-reply-text-box';
import ButtonClosePopup from './components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  gridContainerHeight: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 130px)'
    }
  },

  closeButton: props => ({
    padding: props.popupState === 'INITIAL' ? '0 !important' : '12px',
    position: props.popupState === 'INITIAL' ? 'unset' : 'absolute',
    alignItems: 'flex-end',
    backgroundColor: '#FFF',

    '&:hover': {
      backgroundColor: '#FFF'
    }
  }),
  paper: {
    overflowY: 'visible',
    background: 'transparent',
    boxShadow: 'none',
    borderRadius: '4px',
    width: '100%',
    margin: '65px 12px',
    padding: '0',
    [theme.breakpoints.down('md')]: {
      margin: '35px 12px',
      padding: '0'
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: 'calc(100% - 32px) !important'
    }
  },
  containerPadding: {
    padding: '32px',
    [theme.breakpoints.down('md')]: {
      padding: '18px'
    }
  },
  predefinedResponseButton: {
    fontSize: '12px',
    marginTop: '4px',
    width: '100%',
    borderRadius: '1rem',
    border: '1px solid #BFBFBF',
    textTransform: 'none'
  },
  continueWatching: {
    backgroundColor: 'transparent',
    margin: '1rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline'
    },

    '&:active': {
      backgroundColor: 'unset'
    }
  },
  popupHeroImage: {
    width: '100%',
    height: 'auto'
  },
  progressButton: {
    backgroundColor: '#317DC9',
    color: 'white',

    '&:hover': {
      backgroundColor: '#317DC9'
    },

    '&:active': {
      backgroundColor: '#317DC9'
    }
  },
  boldedText: {
    fontWeight: 500
  }
}));

const GiftThankYouPopup = ({
  showTopCloseBtn,
  id = undefined,
  isPopupOpen,
  handlePopupClose,
  senderFullName,
  playlistId,
  playlistSlug,
  playlistTitle,
  author,
  thumbnail,
  contentType,
  giftToken
}) => {
  const [popupState, setPopupState] = useState('INITIAL');
  const classes = useStyles({ popupState });
  const [requestState, setRequestParams] = useApi();

  const [isOpen] = useState(true);

  const inputRef = useRef(null);
  const [isSendDisabled, setIsSendDisabled] = useState(true);

  const { session } = useUserContext();
  const { user } = session;
  const { meta_first_name: firstName, meta_last_name: lastName } = user;

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  useEffect(() => {
    const requestSuccess = !requestState.isLoading && requestState.data;

    if (requestSuccess) {
      setPopupState('SUCCESS');
    }

    if (requestState.isError) {
      setPopupState('FAILED');
    }

    if (requestState.isLoading) {
      setIsSendDisabled(true);
    }
  }, [requestState]);

  const handlePredefinedResponse = e => {
    const predefinedResponse = e.target.innerText;
    inputRef.current.value += `${predefinedResponse} `;
    setIsSendDisabled(false);
  };

  const handleReplyInputChange = e => {
    const isReplyInputEmpty = !e.target.value;
    setIsSendDisabled(isReplyInputEmpty);
  };

  const handleSendThankYouMessage = () => {
    const thankYouMessageDTO = {
      playlistId,
      playlistSlug,
      sender: `${firstName} ${lastName}`,
      message: inputRef.current.value,
      contentType: contentType.toLowerCase(),
      contentTitle: playlistTitle,
      contentSubtitle: author,
      contentThumbnail: thumbnail.url,
      giftToken
    };

    setRequestParams(GIFT_THANK_YOU_REPLY(thankYouMessageDTO));
  };

  const handleRetry = () => {
    setPopupState('INITIAL');
  };

  switch (popupState) {
    case 'INITIAL': {
      return (
        <Dialog
          isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
          id={id}
          keepMounted={false}
          backdrop="rgba(0, 0, 0, 0.7)"
          background="transparent"
          maxWidth="lg"
          PaperProps={{
            className: classes.paper
          }}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        >
          <Grid
            container
            direction="row"
            justify={isSmallDevice ? 'center' : 'flex-end'}
            alignItems={isSmallDevice ? 'center' : 'flex-end'}
            className={classes.gridContainerHeight}
          >
            <Grid item lg={3}>
              <Box bgcolor="#fff" p={3} borderRadius={2} width="380px">
                {showTopCloseBtn && (
                  <Box display="flex" width="auto" justifyContent="flex-end">
                    <ButtonClosePopup
                      className={classes.closeButton}
                      handleClose={handlePopupClose}
                      iconSize="1rem"
                    />
                  </Box>
                )}

                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  Tell <span style={{ color: '#317DC9' }}>{senderFullName}</span> what you think of
                  this gift...
                </Typography>

                <SendReplyTextBox
                  ref={inputRef}
                  id="message"
                  handleSendReply={handleSendThankYouMessage}
                  handleInputChange={handleReplyInputChange}
                  isSendDisabled={isSendDisabled}
                  placeholder="Share your thoughts..."
                />

                {/* Predefined messages */}
                <Grid
                  item
                  container
                  justify="space-around"
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  <Grid item xs={6} justify="center">
                    <Button
                      className={classes.predefinedResponseButton}
                      onClick={handlePredefinedResponse}
                    >
                      I loved this! ❤️
                    </Button>

                    <Button
                      className={classes.predefinedResponseButton}
                      onClick={handlePredefinedResponse}
                    >
                      Send me more! 😍
                    </Button>
                  </Grid>

                  <Grid item xs={6} justify="center">
                    <Button
                      className={classes.predefinedResponseButton}
                      onClick={handlePredefinedResponse}
                    >
                      Interesting! 🤔
                    </Button>

                    <Button
                      className={classes.predefinedResponseButton}
                      onClick={handlePredefinedResponse}
                    >
                      Thanks for sharing! 👍
                    </Button>
                  </Grid>

                  <Button
                    variant="text"
                    className={classes.continueWatching}
                    onClick={handlePopupClose}
                    disableRipple
                  >
                    Continue Watching
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      );
    }

    case 'SUCCESS': {
      return (
        <Dialog
          isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
          id={id}
          keepMounted={false}
          backdrop="rgba(0, 0, 0, 0.7)"
          background="transparent"
          maxWidth="lg"
          PaperProps={{
            className: classes.paper
          }}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        >
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <Grid item sm={4} md={3}>
              <Box bgcolor="#fff" borderRadius={2} maxWidth="380px">
                <Box display="flex" width="auto" justifyContent="flex-end">
                  {showTopCloseBtn && (
                    <ButtonClosePopup
                      className={classes.closeButton}
                      handleClose={handlePopupClose}
                      iconSize="1rem"
                      style={{ margin: '1rem' }}
                    />
                  )}
                  <img src={successReplyImg} alt="" className={classes.popupHeroImage} />
                </Box>
                {/* Content Box */}
                <Grid container direction="column" alignItems="center" spacing={3} p={3}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 600, marginTop: '0.5rem' }}
                      align="center"
                    >
                      Woohoo!
                    </Typography>
                  </Grid>

                  <Grid item style={{ padding: '0px' }}>
                    <Typography align="center" variant="body1" className={classes.boldedText}>
                      Your message was sent!
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography align="center" variant="body2">
                      Back to the action...
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      color="secondary"
                      className={classes.progressButton}
                      onClick={handlePopupClose}
                      disableRipple
                      style={{ minWidth: '200px' }}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      );
    }

    case 'FAILED': {
      return (
        <Dialog
          isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
          id={id}
          keepMounted={false}
          backdrop="rgba(0, 0, 0, 0.7)"
          background="transparent"
          maxWidth="lg"
          PaperProps={{
            className: classes.paper
          }}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        >
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <Grid item sm={4} md={3}>
              <Box bgcolor="#fff" borderRadius={2} maxWidth="380px">
                <Box display="flex" width="auto" justifyContent="flex-end">
                  {showTopCloseBtn && (
                    <ButtonClosePopup
                      className={classes.closeButton}
                      handleClose={handlePopupClose}
                      iconSize="1rem"
                      style={{ margin: '1rem' }}
                    />
                  )}
                  <img src={failedReplyImg} alt="" className={classes.popupHeroImage} />
                </Box>
                {/* Content Box */}
                <Grid container direction="column" alignItems="center" spacing={3} p={3}>
                  <Grid item>
                    <Typography variant="h6" style={{ fontWeight: 600 }} align="center">
                      Uh oh.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography align="center" variant="body1" className={classes.boldedText}>
                      Something weird happened...
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      color="secondary"
                      className={classes.progressButton}
                      onClick={handleRetry}
                      disableRipple
                    >
                      TRY AGAIN
                    </Button>
                  </Grid>
                </Grid>
                {/* End of Content Box */}
              </Box>
            </Grid>
          </Grid>

          {showTopCloseBtn && (
            <ButtonClosePopup
              className={classes.closeButton}
              handleClose={handlePopupClose}
              iconSize="1rem"
            />
          )}
        </Dialog>
      );
    }

    default:
      throw new Error(`Unrecognized GiftThankYouPopup State: ${popupState}`);
  }
};

GiftThankYouPopup.propTypes = {
  handlePopupClose: PropTypes.func.isRequired,
  showTopCloseBtn: PropTypes.bool,
  id: PropTypes.string,
  isPopupOpen: PropTypes.bool,

  playlistTitle: PropTypes.string.isRequired,
  senderFullName: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,

  playlistId: PropTypes.string.isRequired,
  playlistSlug: PropTypes.string.isRequired,

  contentType: PropTypes.string.isRequired,

  thumbnail: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,

  giftToken: PropTypes.string
};

GiftThankYouPopup.defaultProps = {
  showTopCloseBtn: false,
  id: undefined,
  isPopupOpen: null,
  giftToken: null
};

export default GiftThankYouPopup;
