export const arePathsEqual = (path1, path2) => {
  if (!path1 || !path2) {
    return false;
  }
  // Remove query parameters, if they exist
  const pathWithoutQuery1 = path1.split('?')[0];
  const pathWithoutQuery2 = path2.split('?')[0];

  // Remove trailing slashes
  const cleanPath1 = pathWithoutQuery1.replace(/\/+$/, '');
  const cleanPath2 = pathWithoutQuery2.replace(/\/+$/, '');

  // Compare the cleaned paths
  return cleanPath1 === cleanPath2;
};

/**
 * This function returns a valid url path replacing below placeholders
 *  - {members-site} : replaced with the members site domain
 * @param {*} url - path or a full url
 */
export const getPlaceholderFormattedUrl = url => {
  if (!url) {
    return url;
  }
  return url.replaceAll('{members-site}', process.env.GATSBY_MEMBERS_SITE_URL);
};

export const generateUrlFriendlyIdFromTitle = title =>
  (title || '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .trim()
    .split(' ')
    .filter(Boolean)
    .join('-');
