import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import RichText from '~components/RichText';
import TitleContainer from '../TitleContainer';

const useStyles = makeStyles({
  text: {
    color: '#000',
    fontSize: '18px',
    marginBottom: '20px',
    lineHeight: 1.75,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    '& > p': {
      lineHeight: 1.75,
      marginBlockStart: 0,
      marginBlockEnd: '0.35rem'
    }
  }
});

const CurrentPodcastDescription = ({ description }) => {
  const classes = useStyles();
  return (
    <TitleContainer marginY="50px" fontSize="24px" orderDirection="column" title="In This Episode">
      <RichText
        html={description}
        verticalSpacing={0}
        textAlign="left"
        externalClassName={classes.text}
      />
    </TitleContainer>
  );
};
CurrentPodcastDescription.propTypes = {
  description: PropTypes.string.isRequired
};

export default CurrentPodcastDescription;
