import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import Link from '~components/Link';
import unfurl from '~prismic/unfurl/job';

import Container from '~components/Container';
import RichText from '~components/RichText';

const useStyles = makeStyles(() => ({
  jobsContentSubTitle: {
    display: 'inline-block',
    marginTop: '30px',
    marginBottom: '20px',
    borderBottom: '2px solid #438dce'
  },
  jobsContent: {
    margin: 0,
    '& p, & ul li': {
      fontSize: '1rem',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      lineHeight: '1.5',
      letterSpacing: '0.00938em',
      marginBottom: '14px',
      marginBlockStart: 0
    },
    ' & ul li': {
      marginBottom: '4px'
    }
  },
  jobClosure: {
    marginTop: '28px',
    marginBottom: '2em'
  },
  backToAllJobs: {
    textDecoration: 'none'
  }
}));

const JobPost = ({ data }) => {
  const classes = useStyles();
  const jobPost = unfurl(data);

  return (
    <Page>
      <SEO />
      <Box mt={8} component={Container}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={8}>
            <Box pb={2}>
              <Link to="/jobs" className={classes.backToAllJobs}>
                <Box component="span">← Back to all jobs</Box>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box mb={2}>
              <Typography variant="h4" component="h2">
                <Box component="b">{jobPost.jobPosition}</Box>
              </Typography>
            </Box>
          </Grid>

          {jobPost.sections.map(section => (
            <Grid key={section.primary.title} item xs={12} sm={8}>
              <Typography variant="h6" component="h4" className={classes.jobsContentSubTitle}>
                {section.primary.title}
              </Typography>
              <RichText
                html={section.primary.section_content.html}
                externalClassName={classes.jobsContent}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Page>
  );
};

JobPost.propTypes = {
  data: PropTypes.shape({}).isRequired
};

export default withPrismicPreview(JobPost);

export const query = graphql`
  query ($uid: String!) {
    prismicJobPage(uid: { eq: $uid }) {
      _previewable
      uid
      type
      data {
        job_position
        department
        body {
          ... on PrismicJobPageDataBodySection {
            primary {
              title
              section_content {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`;
