import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Container from '~components/Container';
import RichText from '~components/RichText';

const useStyles = makeStyles({
  container: {
    color: props => props.text_block_text_color,
    '& a': {
      color: props => props.text_block_link_color
    }
  }
});

const TextBlockColsHTML = ({
  section_title_html,
  text_block_content_left,
  text_block_content_right,
  text_block_background_image,
  text_block_background_color,
  text_block_text_color,
  text_block_link_color,
  isCustomSpacingTextBlocks
}) => {
  const classes = useStyles({
    text_block_text_color,
    text_block_link_color
  });
  return (
    <Box
      component={Container}
      center
      background={
        text_block_background_image
          ? `url(${text_block_background_image.url})`
          : text_block_background_color
      }
      className={classes.container}
      pt={4}
      pb={4}
      style={{
        paddingTop: isCustomSpacingTextBlocks ? '100px' : '32px',
        paddingBottom: isCustomSpacingTextBlocks ? '100px' : '32px'
      }}
    >
      {section_title_html && (
        <RichText
          html={section_title_html}
          mode={
            isCustomSpacingTextBlocks ? 'holidayTextBlockSectionTitle' : 'textBlockSectionTitle'
          }
        />
      )}

      <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={4}>
        <Grid item xs={12} sm={6}>
          <RichText html={text_block_content_left.html} textAlign="justify" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <RichText html={text_block_content_right.html} textAlign="justify" />
        </Grid>
      </Grid>
    </Box>
  );
};

TextBlockColsHTML.propTypes = {
  section_title_html: PropTypes.string,
  text_block_content_left: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
  text_block_content_right: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
  text_block_background_image: PropTypes.shape({ url: PropTypes.string.isRequired }),
  text_block_background_color: PropTypes.string,
  text_block_text_color: PropTypes.string,
  text_block_link_color: PropTypes.string,
  isCustomSpacingTextBlocks: PropTypes.bool
};

TextBlockColsHTML.defaultProps = {
  section_title_html: '',
  text_block_background_image: null,
  text_block_background_color: '#fff',
  text_block_text_color: 'rgba(0, 0, 0, 0.87)',
  text_block_link_color: '#3273DD',
  isCustomSpacingTextBlocks: false
};

export default TextBlockColsHTML;
