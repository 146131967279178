import { extractKnownSlices, extractSeoData } from '~src/utils/unfurl-helpers';
import { get } from '../utils/get';

const extractHeroData = prismicData => {
  const hero = get(prismicData, 'prismicTeamPage.data', {});
  return {
    hero_background_image: hero.hero_background_image || {},
    hero_subtitle: hero.hero_subtitle || {},
    hero_title: hero.hero_title || {}
  };
};

export default prismicData => ({
  hero: extractHeroData(prismicData),
  seo: extractSeoData(prismicData, 'prismicTeamPage'),
  slices: extractKnownSlices(prismicData, 'prismicTeamPage.data.body')
});
