import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography, Button } from '@material-ui/core';
import Link from '~components/Link';
import { getResourceName } from '~components/resource-icon/ResourceIcon';
import useConversionFramework from '~hooks/useConversionFramework';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { planIdFromString } from '~utils/playlist-helpers';
import authorizeUser from '~utils/authorization-helper';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '35px',
    fontWeight: '600'
  },
  subtitle: {
    fontSize: '18px',
    width: '80%'
  },
  button: {
    backgroundColor: '#009EFF',
    borderRadius: '25.5px',
    padding: '10px 42px 10px 42px',
    fontSize: '14px',
    marginTop: '50px'
  },
  buttonText: {
    color: '#FFFFFF'
  },
  image: {
    maxWidth: '100%'
  }
}));

const FeaturedResourceCard = ({ resource }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isMarketingSite } = useConversionFramework();

  const materialDescription = resource.description && resource.description;
  const articleDescription = resource.description_text && resource.description_text.text;
  const holidayDescription = resource.slices && resource.slices[0]?.primary?.description;

  const descriptionToShow = materialDescription || articleDescription || holidayDescription;

  const materialType = resource.material_type || resource.typeName;

  const requiredSubscriptionLevel = planIdFromString(resource.required_subscription_plan);

  const { hasAccess, redirectPath, redirectProps } = authorizeUser(
    requiredSubscriptionLevel,
    session,
    false,
    isMarketingSite
  );

  const redirectState = !hasAccess && { ...redirectProps };

  const href = resource?.file?.url;

  const imagePreview = resource.preview_image?.url || resource.seo_image?.url;

  const viewResourceButtonText = {
    PrismicArticle: 'View',
    PrismicMaterial: 'View Guide',
    PrismicBlogPost: 'View Blog Post',
    'Holiday Guide': 'Print Now'
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <Grid item md={7} xs={10}>
        <h4>
          <Link
            to={hasAccess ? href || resource.url : redirectPath}
            target={href ? '_blank' : '_self'}
            {...redirectState}
          >
            {getResourceName(materialType)}
          </Link>
        </h4>
        <Typography className={classes.title}>{resource.title}</Typography>
        <Typography className={classes.subtitle}>
          {descriptionToShow?.text || descriptionToShow || ''}
        </Typography>
        <Button className={classes.button}>
          <Link
            className={classes.buttonText}
            to={hasAccess ? href || resource.url : redirectPath}
            target={href ? '_blank' : '_self'}
            {...redirectState}
          >
            {viewResourceButtonText[materialType] || 'View'}
          </Link>
        </Button>
      </Grid>
      <Grid container item md={4} xs={2} justifyContent="center">
        <img className={classes.image} src={imagePreview} alt="resource" />
      </Grid>
    </Grid>
  );
};

FeaturedResourceCard.propTypes = {
  resource: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    material_type: PropTypes.string,
    typeName: PropTypes.string,
    description_text: PropTypes.shape(),
    title_text: PropTypes.shape(),
    slices: PropTypes.arrayOf(PropTypes.shape()),
    url: PropTypes.string,
    required_subscription_plan: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    }),
    preview_image: PropTypes.shape(),
    seo_image: PropTypes.shape()
  })
};

FeaturedResourceCard.defaultProps = {
  resource: PropTypes.shape({
    title: null,
    description: null,
    material_type: null,
    typeName: null,
    description_text: null,
    title_text: null,
    slice: null,
    file: {}
  })
};

export default FeaturedResourceCard;
