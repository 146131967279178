import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        siteTitle
        siteDescription
        siteKeywords
        siteThemeColor
        siteSocialImageRaw
        twitterAccountId
        facebookAppId
      }
    }
  }
`;

const defaultStructuredDataObject = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Aleph Beta',
  legalName: 'Aleph Beta | Hoffberger Institute for Text Study, Inc.',
  alternateName: 'Learn Torah | Inspiring Videos, Guides, Courses | Aleph Beta',
  foundingDate: '2010',
  url: 'https://www.alephbeta.org',
  logo: 'https://images.prismic.io/aleph-beta/7bc75bf8-d14a-4146-9adb-40e5fa77e7ca_site_logo_raw.png'
};

const SearchEngineOptimization = memo(
  ({
    seo_title,
    seo_description,
    seo_keywords,
    seo_image,
    seo_noindex,
    disable_page_title_suffix,
    pageCanonicalUrl,
    site,
    structuredDataObject,
    hasDefaultStructuredDataObject
  }) => {
    const {
      siteUrl,
      siteTitle,
      siteDescription,
      siteKeywords,
      siteThemeColor,
      siteSocialImageRaw,
      twitterAccountId,
      facebookAppId
    } = site;
    const pageTitleSuffix = disable_page_title_suffix ? '' : ` | ${siteTitle}`;
    const pageTitleFull = seo_title ? `${seo_title}${pageTitleSuffix}` : siteTitle;
    const pageDescriptionFull = seo_description || siteDescription;
    const pageKeywordsFull = seo_keywords || siteKeywords;
    const pageImageFull = seo_image || `${siteUrl}/${siteSocialImageRaw}`;

    return (
      <Helmet>
        <html lang="en" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />

        {/* Indexing */}
        {seo_noindex && <meta name="robots" content="noindex" />}

        {/* Title */}
        <meta content={siteTitle} name="apple-mobile-web-app-title" />
        <meta content={pageTitleFull} property="og:title" />
        <meta content={pageTitleFull} name="twitter:title" />
        <title>{pageTitleFull}</title>
        <meta content={pageKeywordsFull} name="keywords" />
        <meta content={pageDescriptionFull} name="description" />
        <meta content={pageDescriptionFull} property="og:description" />
        <meta content={pageDescriptionFull} name="twitter:description" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="black-translucent" name="apple-mobile-web-app-status-bar-style" />
        <meta content={siteThemeColor} name="theme-color" />
        <meta content={siteTitle} name="application-name" />

        {/* Title, URL */}
        <meta content="website" property="og:type" />
        <meta content={siteTitle} property="og:site_name" />

        <meta content={facebookAppId} property="fb:app_id" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={`@${twitterAccountId}`} name="twitter:site" />
        <meta content={`@${twitterAccountId}`} name="twitter:creator" />
        <meta content={pageTitleFull} name="twitter:text:title" />
        {pageCanonicalUrl && <meta content={pageCanonicalUrl} property="og:url" />}
        {pageCanonicalUrl && <meta content={pageCanonicalUrl} name="twitter:url" />}
        {pageCanonicalUrl && <link rel="canonical" href={pageCanonicalUrl} />}

        {/* Images */}
        <meta content={pageImageFull} property="og:image" />
        <meta content="1024" property="og:image:width" />
        <meta content="512" property="og:image:height" />
        <meta content={pageImageFull} name="twitter:image" />
        <meta content="1024" name="twitter:image:width" />
        <meta content="512" name="twitter:image:height" />

        {/* JSON-LD */}
        {structuredDataObject && (
          <script defer type="application/ld+json">
            {JSON.stringify(structuredDataObject)}
          </script>
        )}

        {hasDefaultStructuredDataObject && (
          <script defer type="application/ld+json">
            {JSON.stringify(defaultStructuredDataObject)}
          </script>
        )}

        <meta name="facebook-domain-verification" content="3wwkdnxi0vpc0n5p7hfvo4afxmc7z9" />
      </Helmet>
    );
  }
);

SearchEngineOptimization.propTypes = {
  site: PropTypes.shape({
    siteUrl: PropTypes.string.isRequired,
    siteTitle: PropTypes.string.isRequired,
    siteDescription: PropTypes.string.isRequired,
    siteKeywords: PropTypes.string.isRequired,
    siteThemeColor: PropTypes.string.isRequired,
    siteSocialImageRaw: PropTypes.string.isRequired,
    twitterAccountId: PropTypes.string.isRequired,
    facebookAppId: PropTypes.string.isRequired
  }).isRequired,
  seo_title: PropTypes.string,
  seo_description: PropTypes.string,
  seo_keywords: PropTypes.string,
  seo_image: PropTypes.string,
  seo_noindex: PropTypes.bool,
  disable_page_title_suffix: PropTypes.bool,
  pageCanonicalUrl: PropTypes.string,
  structuredDataObject: PropTypes.shape({}),
  hasDefaultStructuredDataObject: PropTypes.bool
};

SearchEngineOptimization.defaultProps = {
  seo_title: null,
  seo_description: null,
  seo_keywords: null,
  seo_image: null,
  seo_noindex: false,
  disable_page_title_suffix: false,
  pageCanonicalUrl: null,
  structuredDataObject: null,
  hasDefaultStructuredDataObject: false
};

const withStaticQuery = props => (
  <StaticQuery
    query={query}
    render={graphqlData => (
      <SearchEngineOptimization site={graphqlData.site.siteMetadata} {...props} />
    )}
  />
);

export default withStaticQuery;
