import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import useCheckout from '~hooks/useCheckout';
import products, { PRODUCT_IDS } from '~utils/products';
import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    backgroundColor: props => props.buttonBackgroundColor,
    color: '#fff',
    minHeight: '59px',
    padding: '8px 46px',
    fontSize: '20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: props => props.buttonBackgroundColor,
      opacity: '0.9'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      minHeight: '45px',
      width: '100%',
      padding: '8px 15px'
    },
    [theme.breakpoints.only('xs')]: {
      minHeight: props => (props.buttonMobileHeight ? props.buttonMobileHeight : '45px')
    }
  }
}));

const ProducersCircleButton = ({
  title,
  buttonBackgroundColor,
  buttonSpacing,
  buttonMobileHeight,
  ...rest
}) => {
  const classes = useStyles({ buttonBackgroundColor, buttonMobileHeight });
  const { isMarketingSite } = useConversionFramework();
  const [, setCheckoutState] = useCheckout();

  const handleProducerClick = () => {
    const product = products.find(({ id }) => id === PRODUCT_IDS.PC);
    if (isMarketingSite) {
      redirectToMembersCheckout(product, 'year');
    } else {
      setCheckoutState({ product, billingCycle: 'year' });
      navigate('/checkout');
    }
  };

  return (
    <Box
      component={Button}
      variant="contained"
      my={buttonSpacing}
      size="large"
      className={classes.buttonStyle}
      onClick={handleProducerClick}
      {...rest}
    >
      {title}
    </Box>
  );
};

ProducersCircleButton.propTypes = {
  title: PropTypes.string.isRequired,
  buttonBackgroundColor: PropTypes.string,
  buttonSpacing: PropTypes.number,
  buttonMobileHeight: PropTypes.string
};

ProducersCircleButton.defaultProps = {
  buttonBackgroundColor: '#F2A271',
  buttonSpacing: 3,
  buttonMobileHeight: null
};

export default ProducersCircleButton;
