import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  inputField: {
    root: {
      padding: '0.5rem 0px',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white'
      },
      '&:focused': {
        backgroundColor: 'white'
      },
      '&:active': {
        backgroundColor: 'white'
      }
    }
  },
  sendButton: {
    backgroundColor: props => (props.isSendDisabled ? 'transparent' : '#317dc9'),
    color: props => (props.isSendDisabled ? 'inherit' : 'white'),
    margin: '8px'
  }
});

const SendReplyTextBox = React.forwardRef(
  ({ handleInputChange, isSendDisabled, handleSendReply, ...rest }, ref) => {
    const classes = useStyles({ isSendDisabled });

    return (
      <Box
        border={1}
        borderRadius={4}
        borderColor="#e2e2e1"
        textAlign="right"
        marginTop={2}
        marginBottom={1}
      >
        <Box p={1}>
          <TextField
            inputRef={ref}
            type="text"
            fullWidth
            multiline
            rows="3"
            margin="normal"
            className={classes.inputField}
            InputProps={{ disableUnderline: true }}
            onChange={handleInputChange}
            {...rest}
          />
        </Box>
        <Button onClick={handleSendReply} disabled={isSendDisabled} className={classes.sendButton}>
          Send
        </Button>
      </Box>
    );
  }
);

SendReplyTextBox.propTypes = {
  handleSendReply: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isSendDisabled: PropTypes.bool.isRequired
};

export default SendReplyTextBox;
