import React from 'react';
import PropTypes from 'prop-types';
import { Link as MuiLink } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';

export const isInternalLink = to => /^\/(?!\/)/.test(to);

export const isInternalFile = to => /\.[0-9a-z]+$/i.test(to);

const Link = React.forwardRef(({ children, to, ...rest }, ref) => {
  if (isInternalLink(to)) {
    if (isInternalFile(to)) {
      return (
        <MuiLink href={to} ref={ref} {...rest}>
          {children}
        </MuiLink>
      );
    }

    return (
      <MuiLink component={GatsbyLink} to={to} ref={ref} {...rest}>
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink href={to} ref={ref} {...rest}>
      {children}
    </MuiLink>
  );
});

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default Link;
