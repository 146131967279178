import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import Link from '~components/Link';

const useStyles = makeStyles({
  iconStyle: {
    marginRight: '10px'
  },
  titleStyle: {
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  }
});

const MaterialCard = ({
  searchTerm,
  listPosition,
  material: { url, title, relevance },
  classes
}) => (
  <div title={`Relevance score: ${relevance}`}>
    <Link
      to={url}
      underline="always"
      className={classes.titleStyle}
      state={{
        searchTerm,
        searchResultsListPosition: listPosition,
        clickLocation: 'title',
        searchResultType: 'material'
      }}
    >
      <PictureAsPdfIcon className={classes.iconStyle} /> {title}
    </Link>
  </div>
);
const MaterialPropTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  relevance: PropTypes.number
};

MaterialCard.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  listPosition: PropTypes.number.isRequired,
  material: PropTypes.shape(MaterialPropTypes).isRequired,
  classes: PropTypes.shape({ titleStyle: PropTypes.string, iconStyle: PropTypes.string }).isRequired
};

const Materials = ({ searchTerm, materials }) => {
  const classes = useStyles();
  return (
    <>
      {materials.map((material, index) => (
        <MaterialCard
          key={material.id}
          material={material}
          classes={classes}
          searchTerm={searchTerm}
          listPosition={index + 1}
        />
      ))}
    </>
  );
};
Materials.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape(MaterialPropTypes))
};
Materials.defaultProps = {
  materials: []
};
export default Materials;
