import React /* , { useEffect } */ from 'react';
import PropTypes from 'prop-types';
import { graphql /* navigate */ } from 'gatsby';

import { extractKnownSlices, extractSeoData } from '~utils/unfurl-helpers';
import PageWithSlices from '~src/layout/PageWithSlices';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Holiday = ({ data }) => {
  const slices = extractKnownSlices(data, 'prismicHolidayRedesign.data.body');
  const seo = extractSeoData(data, 'prismicHolidayRedesign');

  // useEffect(() => {
  //   const browser = typeof window !== 'undefined' && window;

  //   const isTb = browser && browser?.location?.pathname === '/tisha-bav';

  //   if (isTb) {
  //     navigate('/');
  //   }
  // }, []);

  return <PageWithSlices seo={seo} slices={slices} />;
};
Holiday.propTypes = {
  data: PropTypes.shape().isRequired
};
export default withPrismicPreview(Holiday);

export const query = graphql`
  query ($uid: String!) {
    prismicHolidayRedesign(uid: { eq: $uid }) {
      _previewable
      uid
      type
      data {
        title
        body {
          ... on PrismicHolidayRedesignDataBodyOffer {
            id
            slice_type
            primary {
              offer_title
              offer_name
              offer_description {
                html
              }
              coupon_code
              cta_text
              cta_url
              background_color
              text_color
              description_text_color
              cta_background_color
              cta_text_color
              subscription_level
            }
          }
          ... on PrismicHolidayRedesignDataBodyProducersCircleMembers {
            slice_type
            items {
              member_name
            }
          }
          ... on PrismicHolidayRedesignDataBodyExpansionPanel {
            slice_type
            primary {
              panel_title
              category
            }
            items {
              panel_materials {
                url
                document {
                  ... on PrismicMaterial {
                    data {
                      title
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyTeamSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              team_member_avatar {
                url
              }
              team_member_name {
                html
              }
              team_member_position {
                html
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyJobSection {
            slice_type
            primary {
              job_section_title {
                html
                text
              }
              job_section_content {
                html
              }
              job_section_background
              job_section_button_title
              job_section_button_color
              job_section_button_link {
                url
              }
              job_section_image {
                url
                alt
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyFaqSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyAudioFileSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            audio_files: items {
              audio_file_link {
                url
              }
              title
              description {
                html
              }
              transcript {
                html
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyAboutPageAboutUs {
            slice_type
            primary {
              section_title {
                html
                text
              }
              about_us_bg_color
            }
            sections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
              topic_image {
                url
                alt
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyAboutTestimonials {
            slice_type
            primary {
              testimonials_background_color
            }
            testimonials: items {
              avatar {
                alt
                url
              }
              title
              subtitle
              name
              subscription_plan
            }
          }
          ... on PrismicHolidayRedesignDataBodyAboutVideosPath {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              section_footnote {
                html
              }
              slider_background_color
            }
            slides: items {
              title {
                html
              }
              subtitle {
                html
              }
              first_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              second_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              third_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyAboutTeam {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            members: items {
              avatar {
                alt
                url
              }
              name {
                html
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyAboutFaq {
            slice_type
            primary {
              section_title {
                html
                text
              }
              faq_bg_color
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            faqSections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyAboutCta {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_content {
                html
              }
              section_image {
                alt
                url
              }
            }
            links: items {
              title {
                html
              }
              subtitle {
                html
              }
              to {
                url
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyPlaylists {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      cover_image {
                        url
                        alt
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyFaqHero {
            slice_type
            primary {
              hero_title {
                html
              }
              hero_subtitle {
                html
              }
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyHeroTextImage {
            slice_type
            primary {
              title
              subtitle
              link {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
              link_title
              text_color
              background_color
              foreground_image {
                url
                alt
              }
              background_image {
                alt
                url
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyResources {
            slice_type
            primary {
              section_title_rich_text {
                text
                html
              }
            }
            items {
              is_featured
              resource {
                uid
                url
                document {
                  __typename
                  ... on PrismicMaterial {
                    data {
                      title
                      description
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                      preview_image {
                        url
                      }
                    }
                  }
                  ... on PrismicParshat {
                    data {
                      title
                      slices {
                        ... on PrismicParshatDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicHolidayRedesign {
                    data {
                      title
                      body {
                        ... on PrismicHolidayRedesignDataBodyHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAdvanced {
                    data {
                      title
                      slices {
                        ... on PrismicAdvancedDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    data {
                      title
                      description_text: description {
                        text
                      }
                      parent_page {
                        uid
                      }
                      seo_image {
                        url
                      }
                    }
                  }

                  ... on PrismicBlogPost {
                    data {
                      title_text: title {
                        text
                        html
                      }
                      content {
                        text
                      }
                      seo_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyTextBlock {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content {
                html
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyTextBlockCols {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content_left {
                html
              }
              text_block_content_right {
                html
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyCustomHtml {
            slice_type
            primary {
              custom_html {
                html
                text
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyTeamPageFounder {
            slice_type
            primary {
              founder_section_title {
                html
                text
              }
              founder_name {
                html
              }
              founder_position {
                html
              }
              founder_text {
                html
              }
              founder_image {
                url
              }
              founder_content_title {
                html
              }
            }
            items {
              founder_publication_title
              founder_publication {
                url
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyHeroTextToc {
            slice_type
            primary {
              title
              overline_rich_text {
                html
                text
              }
              underline_rich_text {
                html
                text
              }
              description
              text_color
              hero_background_color
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyVideoList {
            slice_type
            primary {
              theme
              title_video_list {
                html
                text
              }
              event_date {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyPromotionalBox {
            slice_type
            primary {
              promotional_box_link
              promotional_box_text
              text_color
              promotional_box_background_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicHolidayRedesignDataBodyAuthorTestimonials {
            slice_type
            primary {
              author {
                document {
                  ... on PrismicAuthor {
                    data {
                      name
                      role
                      avatar {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              testimonial
            }
          }
          ... on PrismicHolidayRedesignDataBodyOneLineEmailAcquisition {
            slice_type
            primary {
              cta_text
              one_line_email_background_color
              cta_text_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicHolidayRedesignDataBodyHolidayHeroSplitScreen {
            slice_type
            primary {
              overline_text {
                html
                text
              }
              hero_title {
                html
                text
              }
              hero_image_split_direction
              hero_subtitle {
                html
                text
              }
              hero_background_image {
                url
                alt
              }
              hero_mobile_background_image {
                url
                alt
              }
              label_text {
                html
                text
              }
              label_text_color
              label_text_background_color
              subscription_level
              theme
              hero_featured_video {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyFeaturedPlaylist {
            slice_type
            primary {
              featured_playlist {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodySpotlightPlaylists {
            slice_type
            id
            primary {
              subscription_level
              background_color
              text_color
              spotlight_section_bottom_divider {
                url
                alt
              }
            }
            items {
              spotlight_title
              spotlight_playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                      }
                      cover_image {
                        url
                        alt
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidayRedesignDataBodyContinueWatching {
            slice_type
            primary {
              continue_watching_playlist
              theme
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
      }
    }
  }
`;
