import { useEffect, useState, useCallback } from 'react';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import * as ACTIONS from '~context/UserContext/actions/actions';
import useApi, { GIFT_PLAYLIST } from '~hooks/useApi';

export default () => {
  const { dispatch, session } = useUserContext();
  const { user, giftTokensLeft } = session;

  const [formState, setFormState] = useState({
    userFormData: {
      firstName: user.meta_first_name || '',
      lastName: user.meta_last_name || '',
      username: user.username,
      message: ''
    },
    receiverFormData: {
      firstName: '',
      lastName: '',
      email: ''
    }
  });

  const [state, makeRequest] = useApi();
  const { isLoading, isError, data } = state;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isError) {
      return;
    }
    if (data) {
      const { leftoverTokens } = data;

      dispatch({
        type: ACTIONS.USER_UPDATE,
        payload: { giftTokensLeft: leftoverTokens }
      });
    }
  }, [data, dispatch, isError, isLoading]);

  const sendGiftContent = useCallback(
    giftData => {
      makeRequest(GIFT_PLAYLIST(giftData));
    },
    [makeRequest]
  );

  const handleFormChange = useCallback(event => {
    const { id: inputId, name, value } = event.currentTarget;

    const formId = name ? 'receiverFormData' : 'userFormData';
    const inputFieldId = name || inputId;

    setFormState(prevState => ({
      ...prevState,
      [formId]: { ...prevState[formId], [inputFieldId]: value }
    }));
  }, []);

  return {
    state,
    sendGiftContent,
    user,
    giftTokensLeft,
    userFormData: formState.userFormData,
    receiverFormData: formState.receiverFormData,
    handleFormChange
  };
};
