/**
 * README
 * We're using `gatsby-plugin-facebook-pixel` to initialize FB's Pixel and track page views automatically.
 * This module's solely purpose is to easy the tracking of specific actions and it uses the pre-loaded `fbq` global variable.
 */
export const fbRegistrationEvent = ({ fbEventId }) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq(
      'trackCustom',
      'DR-CompleteRegistration',
      {
        value: 1.0,
        currency: 'USD',
        type: 'password'
      },
      { eventID: fbEventId }
    );
  }
};

export const fbSubscriptionEvent = ({ fbEventId, value = 9.0, planName }) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq(
      'trackCustom',
      'DR-CompleteSubscription',
      {
        content_type: 'product',
        content_category: 'Subscription',
        content_name: planName,
        value,
        currency: 'USD'
      },
      { eventID: fbEventId }
    );
  }
};

export const fbRecurringDonationEvent = ({ fbEventId, value = 9.0, planName }) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq(
      'trackCustom',
      'DR-CompleteRecurringDonation',
      {
        content_type: 'product',
        content_category: 'Recurring Donation',
        content_name: planName,
        value,
        currency: 'USD'
      },
      { eventID: fbEventId }
    );
  }
};

export const fbDonationEvent = ({ fbEventId, value }) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq(
      'track',
      'Purchase',
      {
        content_type: 'product',
        content_category: 'Donation',
        content_name: 'One-Time Donation',
        value,
        currency: 'USD'
      },
      { eventID: fbEventId }
    );
  }
};

export const fbBarMitzvahEvent = () => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq('track', 'DR-PurchaseBarMitzvahAccess', {
      value: 3.0,
      currency: 'USD'
    });
  }
};
