import { isNil } from 'lodash';
import { redirect } from '~utils/redirect-helper';

export const redirectToMembersCheckout = (product, period, additionalParams = {}) => {
  if (!product?.id) {
    return;
  }

  const url = new URL('express-checkout', process.env.GATSBY_MEMBERS_SITE_URL);
  const searchParams = { planId: product.id, period, ...additionalParams };
  Object.keys(searchParams).forEach(key => {
    if (!isNil(searchParams[key])) {
      url.searchParams.append(key, searchParams[key]);
    }
  });

  redirect(url.toString());
};

/**
 * Get the members site page url for a given path
 * Note that GATSBY_MEMBERS_SITE_URL should not have a slash at the end
 * @param {string} path - path to the resource. (should start with a slash "/")
 * @returns {string} fully qualified domain with the given path
 */
export const getPagePathInMembers = path => `${process.env.GATSBY_MEMBERS_SITE_URL}${path}`;

/**
 * Get the path to navigate
 * When the marketing site is on, return path is the full URL.
 * @param {*} path - path to the resource. (should start with a slash "/")
 * @returns {string} relative path or full url to navigate
 */
export const getNavigationPageLink = (path, isMarketingSite = false) =>
  isMarketingSite ? getPagePathInMembers(path) : path;

/**
 * Redirects to a path in the members website
 * @param {*} path - path to the resource. (should start with a slash "/")
 */
export const redirectToPageInMembers = path => {
  redirect(getPagePathInMembers(path));
};
