import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { trackCurationPlaylistSelected } from '~utils/analytics';
import { pushDataLayerEvent } from '~utils/data-layer';
import { LightenColor } from '~utils/playlist-helpers';
import CurationPageDrawerPlaylists from '~src/modules/curation-page/components/CurationPageDrawerPlaylists';

const id = 'banner_container';

const useStyles = makeStyles(theme => ({
  drawer: {
    overflowY: 'auto',
    scrollbarColor: '#C4C4C4 transparent',
    scrollbarWidth: 'thin',
    position: 'fixed',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
      zIndex: '1010'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '15px',
      backgroundColor: '#C4C4C4',
      zIndex: '1010'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px',
      zIndex: '1010'
    },

    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
      overflowY: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'unset'
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: 'unset',
      overflowY: 'auto'
    }
  },
  drawerTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    color: '#fff',
    display: 'flex',
    alignItems: 'center'
  },
  closeIcon: {
    fontSize: '25px',
    color: '#fff',
    cursor: 'pointer'
  },
  drawerHeader: {
    width: '50vw',
    zIndex: '1005',
    top: props => `${props.topSpacing}`,
    padding: '24px 32px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '24px',
      top: '0'
    }
  },
  drawerContent: {
    marginTop: '88px',
    [theme.breakpoints.down('md')]: {
      marginTop: '100px'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '90px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '110px'
    }
  }
}));

const DrawerContent = ({ drawerData, handleClose }) => {
  const [topSpacing, setTopSpacing] = useState(77);
  const classes = useStyles({ topSpacing });
  const inputRef = useRef();
  const bannerIsOpen = document.getElementById(id);

  const selectPlaylistEvent = event => {
    trackCurationPlaylistSelected(event.currentTarget.id);
    pushDataLayerEvent('curation_video_selected', { video_id: event.currentTarget.id });
  };

  useEffect(() => {
    if (bannerIsOpen) {
      setTopSpacing(prevState => prevState + 66);
    }
  }, [bannerIsOpen]);

  return (
    <Box
      ref={inputRef}
      bgcolor={drawerData && `${drawerData.color}`}
      maxWidth="100%"
      height="100%"
      zIndex="1000"
      className={classes.drawer}
      pb={10}
    >
      <Box
        display="flex"
        bgcolor={drawerData && `#${LightenColor(drawerData.color, -25)}`}
        position="fixed"
        className={classes.drawerHeader}
        px={4}
        py={3}
      >
        <Typography className={classes.drawerTitle}>{drawerData.title}</Typography>

        <span style={{ flex: 1 }} />

        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      </Box>

      <Box maxWidth="100%" px={3} className={classes.drawerContent}>
        {drawerData &&
          drawerData.playlists.length > 0 &&
          drawerData.playlists.map(playlist => (
            <CurationPageDrawerPlaylists
              selectEvent={selectPlaylistEvent}
              key={playlist.uid}
              {...playlist}
            />
          ))}
      </Box>
    </Box>
  );
};
DrawerContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  drawerData: PropTypes.shape({
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    playlists: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired
      })
    )
  })
};

DrawerContent.defaultProps = {
  drawerData: {}
};
export default DrawerContent;
