import React, { memo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';

const PlaylistCard = loadable(() => import('~components/playlist-card/PlaylistCard'));

// const PromotionalPlaylistCard = loadable(() =>
//   import('~components/playlist-card/PromotionalPlaylistCard')
// );
const RichText = loadable(() => import('~src/components/RichText'));
const Carousel = loadable(() => import('~components/Carousel'));

const Playlists = memo(({ section_title, playlists, progress, hasPromotionalCard }) => {
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  const modifiedPlaylists = [...playlists];

  if (!playlists.length) {
    return null;
  }

  if (hasPromotionalCard) {
    modifiedPlaylists.splice(3, 0, {});
  }

  return (
    <Box pt={4} pb={4} minHeight="370px">
      {section_title && (
        <Container>
          <RichText html={section_title} mode="playlistsSectionTitle" />
        </Container>
      )}
      <Carousel>
        {playlists &&
          modifiedPlaylists.map((playlist, index) => (
            <Box
              id={playlist.id || `promotional-pc-card-${index}`}
              key={playlist.id || `promotional-pc-card-${index}`}
              px={isSmallerThanMd ? 0 : 1}
            >
              {/* {hasPromotionalCard && index === 3 ? (
                <PromotionalPlaylistCard />
              ) : ( */}
              <PlaylistCard {...playlist} progress={progress[playlist.id]} />
              {/* )} */}
            </Box>
          ))}
      </Carousel>
    </Box>
  );
});

Playlists.propTypes = {
  section_title: PropTypes.string,
  playlists: PropTypes.arrayOf(PropTypes.shape(PlaylistCard.propTypes)).isRequired,
  progress: PropTypes.shape({}),
  hasPromotionalCard: PropTypes.bool
};

Playlists.defaultProps = {
  section_title: null,
  progress: {},
  hasPromotionalCard: false
};

export default Playlists;
