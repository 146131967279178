export const defaultImageSizes = img => {
  const mobile = img.replace('w=1920&h=1080', 'w=400&h=225');
  const tablet = img.replace('w=1920&h=1080', 'w=800&h=450');

  return {
    mobile,
    tablet,
    desktop: img
  };
};

export const customImageSize = (img, width, height) =>
  img.replace('w=1920&h=1080', `w=${width}&h=${height}`);
