import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Box from '@material-ui/core/Box';
import unfurl from '~prismic/unfurl/article';
import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import SliceToComponent from '~components/slices/slices';

const Article = ({ data }) => {
  const { seo, slices, bgColor, pageBreadcrumbs, trendingItems } = unfurl(data);

  const globalProps = useMemo(() => ({ trendingItems }), [trendingItems]);

  return (
    <Page backgroundColor={bgColor || '#F3F3F3'}>
      <SEO {...seo} />
      <Box position="relative">
        <SliceToComponent
          slices={slices}
          pageBreadcrumbs={pageBreadcrumbs}
          containerSize="730px"
          globalProps={globalProps}
        />
      </Box>
    </Page>
  );
};

Article.propTypes = {
  data: PropTypes.shape().isRequired
};

export default withPrismicPreview(Article);

export const query = graphql`
  query ($uid: String!) {
    prismicTrendingItems {
      data {
        title
        description {
          text
          html
        }
        cta_text
        cta_path
        trending_list {
          title
          item {
            uid
            url
            document {
              ... on PrismicPlaylist {
                type
                uid
                url
                data {
                  title
                  label_is_audio
                  cover_image {
                    alt
                    url
                  }
                  videos {
                    video {
                      id
                      document {
                        ... on PrismicVideo {
                          data {
                            length_in_seconds
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPodcastPlaylist {
                type
                uid
                url
                data {
                  podcast_title {
                    text
                  }
                  card_thumbnail {
                    alt
                    url
                  }
                  episodes {
                    ... on PrismicPodcastPlaylistDataEpisodesEpisode {
                      items {
                        podcast_episode {
                          document {
                            ... on PrismicPodcastEpisode {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicArticle(uid: { eq: $uid }) {
      _previewable
      uid
      last_publication_date(formatString: "MMMM D, YYYY")
      type
      data {
        title
        subtitle
        background_color
        parent_page {
          url
          document {
            ... on PrismicParshat {
              type
              data {
                title
              }
            }
            ... on PrismicHolidayRedesign {
              type
              data {
                title
              }
            }
          }
        }
        slices {
          ... on PrismicArticleDataSlicesHeroTextToc {
            slice_type
            primary {
              title
              overline_rich_text {
                html
                text
              }
              underline_rich_text {
                html
                text
              }
              description
              navigation_menu_title
              text_color
              hero_background_color
              hero_background_image {
                url
              }
            }
          }
        }
        body {
          ... on PrismicArticleDataBodyArticleHero {
            slice_type
            primary {
              hero_background_image {
                url
                alt
              }
              hero_bottom_shape_divider {
                url
                alt
              }
            }
          }
          ... on PrismicArticleDataBodyArticleContent {
            slice_type
            primary {
              parent_page_name
              parent_page_link
              article_title {
                text
              }
              article_subtitle {
                text
              }
              article_author
              article_date
              read_time
              background_color
            }
            items {
              text_block_title_rich_text {
                text
                html
              }
              text_block_content_rich_text {
                text
                html
              }
              content_image {
                url
                alt
              }
            }
          }
          ... on PrismicArticleDataBodyArticleContentBox {
            slice_type
            id
            primary {
              text_block_title_rich_text {
                text
                html
              }
              text_block_content_rich_text {
                text
                html
              }
              background_color
            }
          }
          ... on PrismicArticleDataBodyArticleImage {
            slice_type
            id
            primary {
              image {
                alt
                url
              }
              background_color
            }
          }
          ... on PrismicArticleDataBodyArticleEmailCapture {
            slice_type
            id
            primary {
              background_color
              main_title
              description_text {
                html
              }
              mailchimp_tag
            }
          }
          ... on PrismicArticleDataBodyArticleFaq {
            slice_type
            primary {
              section_title {
                text
                html
              }
              background_color
              bottom_shape_divider {
                url
                alt
              }
            }
            items {
              topic_title {
                text
                html
              }
              topic_content {
                text
                html
              }
            }
          }
          ... on PrismicArticleDataBodyAboutAlephBeta {
            slice_type
          }
          ... on PrismicArticleDataBodyCustomHtml {
            slice_type
            primary {
              custom_html {
                html
                text
              }
            }
          }
          ... on PrismicArticleDataBodyTextBlock {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content {
                html
              }
            }
          }
          ... on PrismicArticleDataBodyTextBlockCols {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content_left {
                html
              }
              text_block_content_right {
                html
              }
            }
          }
          ... on PrismicArticleDataBodyVideoGallery {
            slice_type
            primary {
              video_gallery_title
              video_gallery_description {
                text
                html
              }
            }
            items {
              playlist_url {
                uid
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodySpotlightPlaylists {
            slice_type
            id
            primary {
              subscription_level
              background_color
              text_color
              compact_margins
              spotlight_section_bottom_divider {
                url
                alt
              }
            }
            items {
              spotlight_title
              spotlight_playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                      }
                      cover_image {
                        url
                        alt
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyArticleResources {
            slice_type
            id
            primary {
              section_title {
                text
                html
              }
              background_color
            }
            items {
              custom_title
              custom_duration
              custom_description
              resource {
                uid
                url
                document {
                  __typename
                  ... on PrismicMaterial {
                    url
                    data {
                      title
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                      preview_image {
                        alt
                        url
                      }
                      seo_image {
                        alt
                        url
                      }
                      material_description: description
                    }
                  }
                  ... on PrismicParshat {
                    url
                    data {
                      title
                      seo_description
                      seo_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicHolidayRedesign {
                    url
                    data {
                      title
                      seo_description
                      seo_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicAdvanced {
                    url
                    data {
                      title
                      seo_description
                      seo_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      cover_image {
                        alt
                        url
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicArticle {
                    url
                    data {
                      title
                      description_text: description {
                        text
                      }
                      parent_page {
                        uid
                      }
                      seo_image {
                        url
                      }
                    }
                  }
                  ... on PrismicBlogPost {
                    url
                    data {
                      title_text: title {
                        text
                        html
                      }
                      content {
                        text
                      }
                      seo_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicArticleDataBodyArticleRelatedPages {
            slice_type
            id
            primary {
              section_title {
                text
                html
              }
              background_color
            }
            items {
              related_page_title
              related_page_url
            }
          }
          ... on PrismicArticleDataBodyArticleDiscussionPoints {
            slice_type
            id
            primary {
              discussion_title
            }
            items {
              discussion_point {
                text
                html
              }
            }
          }
          ... on PrismicArticleDataBodyArticleCard {
            slice_type
            id
            primary {
              card_title
              card_content {
                text
                html
              }
            }
          }
          ... on PrismicArticleDataBodyArticleInlineAd {
            id
            slice_type
            primary {
              ad_title {
                html
                text
              }
              ad_description {
                html
                text
              }
              ad_text_color
              ad_background_color
              background_image {
                alt
                url
              }
              cta_path
              cta_text
              cta_theme
            }
          }
          ... on PrismicArticleDataBodyTrendingList {
            id
            slice_type
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
        seo_canonical_url
        seo_noindex
      }
    }
  }
`;
