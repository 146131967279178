import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  extractKnownSlices,
  extractSeoData,
  extractCourseGlobalProps
} from '~utils/unfurl-helpers';
import PageWithSlices from '~src/layout/PageWithSlices';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Course = ({ data }) => {
  const slices = extractKnownSlices(data, 'prismicCourse.data.body');
  const globalProps = extractCourseGlobalProps(data);
  const seo = extractSeoData(data, 'prismicCourse');

  return (
    <PageWithSlices
      seo={seo}
      slices={slices}
      globalProps={globalProps}
      redirectLoggedInUsersToMembers
    />
  );
};

Course.propTypes = {
  data: PropTypes.shape().isRequired
};

export default withPrismicPreview(Course);

export const query = graphql`
  query ($uid: String!) {
    prismicCourse(uid: { eq: $uid }) {
      _previewable
      uid
      type
      data {
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
          alt
        }
        seo_canonical_url
        product_id
        is_subscriber_eligible
        is_producer_circle_eligible
        body {
          ... on PrismicCourseDataBodyCourseHero {
            slice_type
            id
            primary {
              title
              title_text_color
              background_color
              cta_text
              cta_background_color
              cta_text_color
              description {
                html
              }
              description_text_color
              eligible_cta_text
              eligible_cta_url
              image {
                alt
                url
              }
              top_label
              eligible_top_label
              top_label_background_color
              top_label_text_color
              bottom_shape_divider {
                alt
                url
              }
            }
          }
          ... on PrismicCourseDataBodyCourseIntroduction {
            slice_type
            id
            primary {
              text_color
              time
              title
              background_color
              cta_text
              cta_background_color
              cta_text_color
              date
              description {
                html
              }
              eligible_cta_text
              eligible_cta_url
              medium
              audience
              price
              price_description {
                html
              }
              price_wave_off_text
              quote {
                html
              }
              bottom_shape_divider {
                alt
                url
              }
            }
          }
          ... on PrismicCourseDataBodyLearningMethods {
            slice_type
            id
            primary {
              title
              text_color
              background_color
              navigation_color
              bottom_shape_divider {
                alt
                url
              }
            }
            items {
              title
              icon {
                alt
                url
              }
              short_explanation {
                text
              }
              animation_file {
                url
              }
              animation_type
              animation_still_frame
              call_to_action_text
              playlist {
                document {
                  __typename
                  ... on PrismicPlaylist {
                    id
                    url
                    data {
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              second_playlist {
                document {
                  __typename
                  ... on PrismicPlaylist {
                    id
                    url
                    data {
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              third_playlist {
                document {
                  __typename
                  ... on PrismicPlaylist {
                    id
                    url
                    data {
                      cover_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicCourseDataBodyCourseAuthor {
            slice_type
            id
            primary {
              title
              text_color
              avatar {
                alt
                url
              }
              background_color
              content_area_color
              description {
                html
              }
              bottom_shape_divider {
                url
                alt
              }
            }
          }
          ... on PrismicCourseDataBodyCoursePerks {
            slice_type
            id
            primary {
              title
              text_color
              background_color
              subtitle
              bottom_shape_divider {
                url
                alt
              }
            }
            items {
              perk_title
              perk_description {
                html
              }
              background_color
              text_color
              perk_icon {
                url
                alt
              }
            }
          }
          ... on PrismicCourseDataBodyCourseCta {
            slice_type
            id
            primary {
              title
              background_color
              cta_text
              eligible_cta_text
              eligible_cta_url
              cta_text_color
              cta_background_color
              bottom_shape_divider {
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`;
