import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';

const CHIP_BASE_STYLE = {
  height: '22px',
  borderRadius: 2,
  backgroundColor: '#ffe458',
  fontSize: '12px',
  fontWeight: 900,
  letterSpacing: 0.5
};

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: theme.spacing(),
    marginLeft: theme.spacing() / 2,
    '& > *': {
      marginLeft: theme.spacing() / 2
    }
  },
  new: {
    ...CHIP_BASE_STYLE,
    backgroundColor: '#ffe458',
    color: theme.palette.text.primary
  },
  premium: {
    ...CHIP_BASE_STYLE,

    backgroundColor: '#2c7abf',
    color: '#fff'
  },
  audio: {
    ...CHIP_BASE_STYLE,
    backgroundColor: '#64eebc',
    color: theme.palette.text.primary
  },
  producer: {
    ...CHIP_BASE_STYLE,
    backgroundColor: '#6a52c4',
    color: '#fff'
  }
}));

export const LabelOverlay = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.overlay} {...rest}>
      {children}
    </div>
  );
};

LabelOverlay.propTypes = {
  children: PropTypes.node.isRequired
};

export const LabelNew = () => {
  const classes = useStyles();
  return <Chip size="small" label="NEW" className={classes.new} />;
};

export const LabelPremium = () => {
  const classes = useStyles();
  return <Chip size="small" label="PREMIUM" className={classes.premium} />;
};

export const LabelAudio = () => {
  const classes = useStyles();
  return <Chip size="small" label="PODCAST" className={classes.audio} />;
};

export const LabelProducer = () => {
  const classes = useStyles();
  return <Chip size="small" label="PRODUCER" className={classes.producer} />;
};
