import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SupportIconImg from '~images/guides/parsha-guide-support-icon.jpg';

const StyledSectionSpacingDiv = styled.div`
  padding: 24px 0px;
`;

const StyledButtonSpacingDiv = styled.div`
  padding: 16px 0px;
`;

const StyledBorderBoxDiv = styled.div`
  padding: 40px 8px;
  border: 1px solid #f4f5f6;
  border-radius: 4px;
  text-align: center;
`;

const GuidesFAQ = () => (
  <StyledSectionSpacingDiv>
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12} md={8}>
        <Typography variant="h6" gutterBottom>
          There are a lot of divrei Torah. How are Guides different?
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          At Aleph Beta, we believe that the Torah is a guidebook that answers life’s biggest
          questions, offering profound insights about how we should live our lives. Moreover, we
          believe that Jewish tradition has always recognized the right of all readers, in every
          generation, to look at the text themselves and try to decide what they think it means.
          That means you. That’s why you are the most important author of this quest through the
          sources. We think that this guide offers a fun, stimulating and relevant path through the
          sources, but if you get wrapped up in a stimulating discussion and never make it past page
          2, we’ll consider that a success!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Is this guide for self-study or should I study with others?
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Either works! You can gather a small group of friends to explore it together, share it
          with a chevruta (learning partner), or go through it by yourself.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Do I need to prepare anything or can I just jump in?
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Just jump in! Even if you’re planning to use this for a group discussion, just open up to
          page 1 and get going. (If you read it in advance, it will spoil the fun!) The only thing
          you should do in advance is print out copies of the “Source Sheet” for the other
          participants, so everyone can follow along and engage with the sources.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <StyledBorderBoxDiv>
          <img src={SupportIconImg} alt="Alpeh Beta Support Icon" />
          <Typography variant="h6" align="center" gutterBottom>
            Still have questions?
          </Typography>
          <Typography variant="subtitle2" align="center" gutterBottom>
            No worries, we are here to help.
          </Typography>
          <StyledButtonSpacingDiv>
            <Button
              href="mailto:info@alephbeta.org?Subject=Aleph Beta Guides Question"
              target="_blank"
              variant="contained"
              color="primary"
            >
              Contact Us
            </Button>
          </StyledButtonSpacingDiv>
        </StyledBorderBoxDiv>
      </Grid>
    </Grid>
  </StyledSectionSpacingDiv>
);

export default GuidesFAQ;
