import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import unfurl from '~prismic/unfurl/podcast-episode-description';
import Container from '~components/Container';
import Page from '~layout/Page';
import PodcastListenButtons from '~src/modules/podcasts/components/PodcastListenButtons';
import PodcastListeningHero from '~src/modules/podcasts/components/PodcastListeningHero';
import Transcript from '~components/playlist/transcript';
import CurrentPodcastDescription from '~src/modules/podcasts/components/CurrentPodcastDescription';
import SpotifyPlayer from '~src/modules/podcasts/components/SpotifyPlayer';
import BuzzsproutPlayer from '~src/modules/podcasts/components/BuzzsproutPlayer';
import PodcastPreviousSeasons from '~src/modules/podcasts/components/PodcastPreviousSeasons';
import { PodcastButton } from '~src/modules/podcasts';
import HostContainer from '~src/modules/podcasts/components/HostContainer';
import Popups, { POPUPS_MAP } from '~src/layout/Popups';
import useApi from '~hooks/useApi/useApi';
import { PODCAST_START_EPISODE, PODCAST_CLOSE_PLAYER } from '~hooks/useApi';
import { makeAnalyticsPayloadForPodcastEpisodePageEpisode } from '~utils/analytics';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';

const IS_BUZZSPROUT_PODCAST_PLAYER_ENABLED = isFeatureEnabled(
  FEATURE_FLAGS.BUZZSPROUT_PODCAST_PLAYER
);

const PodcastEpisodeDescription = ({ data, pageContext }) => {
  const [playClicked, setPlayClicked] = useState(false);
  const [, makeRequest] = useApi();
  const { session } = useUserContext();
  const { isSubscriber } = session;

  const episodeData = unfurl(data);
  const {
    part,
    title,
    color,
    playButtonColor,
    playButtonTextColor,
    season,
    episode,
    spotifyUrl,
    playerEmbedCode,
    isSubscriberEpisode,
    providers,
    thumbnail,
    transcript,
    podcastUid,
    description,
    detailedDescription,
    podcastTitle,
    previousSeasons,
    hosts,
    seasonUid
  } = episodeData;

  const handleStartListening = () => {
    setPlayClicked(true);
    makeRequest(
      PODCAST_START_EPISODE({
        episode: makeAnalyticsPayloadForPodcastEpisodePageEpisode(episodeData)
      })
    );
  };

  const handleClose = () => {
    setPlayClicked(false);
    makeRequest(
      PODCAST_CLOSE_PLAYER({
        episode: makeAnalyticsPayloadForPodcastEpisodePageEpisode(episodeData)
      })
    );
  };

  const [currentPopupId, setCurrentPopupId] = useState(null);

  const handleInsufficientAccess = popupId => {
    setCurrentPopupId(popupId);
  };

  const handlePopupClose = () => {
    setCurrentPopupId(null);
  };

  const handleClosePopup = () => {
    setPlayClicked(false);
  };

  const transcriptTitle = episode ? `${podcastTitle} - Episode ${episode}` : podcastTitle;
  const seo = {
    seo_title: `${title} | ${podcastTitle} Podcast`,
    seo_description: description,
    disable_page_title_suffix: true
  };

  const showSubscribePopup = playClicked && isSubscriberEpisode && !isSubscriber;

  const showSpotifyPlayer = !IS_BUZZSPROUT_PODCAST_PLAYER_ENABLED
    ? playClicked && spotifyUrl // when Buzzsprout Player is not enabled, just check spotify url is set
    : playClicked && !playerEmbedCode && spotifyUrl; // when Buzzsprout Player is enabled, when there's no embed code, let's show the spotify player

  const showBuzzsproutPlayer =
    IS_BUZZSPROUT_PODCAST_PLAYER_ENABLED && playClicked && playerEmbedCode;

  return (
    <Page seo={seo} redirectLoggedInUsersToMembers>
      <PodcastListeningHero
        coverColor={color}
        playButtonColor={playButtonColor}
        playButtonTextColor={playButtonTextColor}
        podcastCover={thumbnail}
        title={title}
        description={description}
        podcastTitle={podcastTitle}
        playPodcast={handleStartListening}
        episode={episode}
        season={season}
        part={part}
        podcastUid={podcastUid}
        seasonUid={seasonUid}
      />
      <Container>
        <PodcastListenButtons providers={providers} />
        <PodcastPreviousSeasons previousSeasons={previousSeasons} />
        {hosts && hosts.length > 0 && <HostContainer hosts={hosts} />}
        <CurrentPodcastDescription description={detailedDescription} />
        <Transcript
          isAudioTranscript
          transcript={transcript}
          uid={pageContext?.episodeUid}
          title={transcriptTitle}
          CustomReadMoreBtn={PodcastButton}
          customReadMoreProps={{ color: '#000' }}
          hasUserSufficientAccess
          handleInsufficientAccess={handleInsufficientAccess}
        />
      </Container>

      {!showSubscribePopup && showSpotifyPlayer && (
        <SpotifyPlayer url={spotifyUrl} title={podcastTitle} handleClose={handleClose} />
      )}
      {!showSubscribePopup && showBuzzsproutPlayer && (
        <BuzzsproutPlayer embedCode={playerEmbedCode} handleClose={handleClose} />
      )}
      {showSubscribePopup && (
        <Popups
          which={POPUPS_MAP.UPGRADE_TO_PREMIUM_FOR_FEATURE}
          isPopupOpen
          handleClose={handleClosePopup}
        />
      )}

      <Popups
        which={currentPopupId}
        isPopupOpen={!!currentPopupId}
        handleClose={handlePopupClose}
      />
    </Page>
  );
};

PodcastEpisodeDescription.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({
    podcastUid: PropTypes.string.isRequired,
    episodeUid: PropTypes.string.isRequired
  }).isRequired
};

export default withPrismicPreview(PodcastEpisodeDescription);

export const query = graphql`
  query ($podcastUid: String!, $uid: String!) {
    allPrismicPodcastEpisode(
      filter: { data: { podcast_playlist: { uid: { eq: $podcastUid } } }, uid: { eq: $uid } }
    ) {
      nodes {
        _previewable
        uid
        prismicId
        type
        data {
          body {
            ... on PrismicPodcastEpisodeDataBodyProvidersSlice {
              id
              slice_type
              items {
                provider_id
                url {
                  url
                }
              }
            }
            ... on PrismicPodcastEpisodeDataBodyHost {
              id
              slice_type
              items {
                host {
                  document {
                    ... on PrismicAuthor {
                      id
                      data {
                        name
                        role
                        avatar {
                          url
                          alt
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          podcast_playlist {
            uid
            id
            document {
              ... on PrismicPodcastPlaylist {
                data {
                  hosts {
                    ... on PrismicPodcastPlaylistDataHostsHost {
                      items {
                        host {
                          document {
                            ... on PrismicAuthor {
                              data {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  podcast_title {
                    html
                    text
                    raw
                  }
                  episodes {
                    ... on PrismicPodcastPlaylistDataEpisodesEpisode {
                      primary {
                        series_uid
                      }
                      items {
                        podcast_episode {
                          uid
                        }
                      }
                    }
                  }
                  required_subscription_plan
                  providers {
                    ... on PrismicPodcastPlaylistDataProvidersProvidersSlice {
                      id
                      items {
                        provider_id
                        url {
                          url
                        }
                      }
                    }
                  }
                  previous_seasons {
                    cta_text
                    previous_playlist {
                      slug
                      uid
                      document {
                        ... on PrismicPlaylist {
                          uid
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          color
          play_button_color
          play_button_text_color
          audio_url {
            url
          }
          spotify_url {
            url
          }
          is_subscriber_episode
          player_embed_code
          cover_image {
            url
            alt
          }
          created_at
          description {
            text
            html
          }
          detailed_description {
            text
            html
          }
          episode
          length_in_seconds
          part
          season
          title
          transcript {
            html
          }
          wistia_stream_url {
            url
          }
        }
      }
    }
  }
`;
