import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import { Link } from 'gatsby';
import TopSearchesList from '~components/search/TopSearchesList';

import SearchDecoration from '~images/search/search-start-page-decoration.svg';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'flex-start'
  },
  tabsRoot: {
    marginBottom: '15px'
  },
  root: {
    padding: '0px !important',
    marginRight: '40px !important',
    minWidth: 'unset'
  },
  indicator: {
    height: '3px'
  },
  linksWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  link: {
    flex: '0 1 auto',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    fontSize: '18px',
    lineHeight: '21px',
    padding: '0.5em 2em',
    color: 'black',
    margin: '0.5em 16px 0.5em 0px',
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    textDecoration: 'none',
    '&:hover': {
      borderColor: '#458FCE',
      color: '#458FCE'
    }
  },
  decorationWrapper: {
    marginTop: '100px'
  },
  decorationBlock: {
    textAlign: 'center'
  },
  sectionPosition: {
    marginTop: '48px',
    width: '100%'
  },
  heading: {
    fontWeight: 'normal',
    fontSize: '30px',
    lineHeight: '35px'
  },
  paragraph: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px'
  },
  media: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  clearButton: {
    padding: 0,
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  topSearchesLink: {
    fontSize: '18px',
    lineHeight: '25px'
  },
  topSearchesLi: {
    padding: '10px 5px'
  }
});

const SearchesList = ({ searchTerms, classes }) => (
  <div className={classes.linksWrapper}>
    {searchTerms.map(term => (
      <Link key={term} className={classes.link} to={`/search?t=${term}`}>
        {term}
      </Link>
    ))}
  </div>
);
SearchesList.propTypes = {
  classes: PropTypes.shape({
    linksWrapper: PropTypes.string,
    link: PropTypes.string
  }).isRequired,
  searchTerms: PropTypes.arrayOf(PropTypes.string)
};
SearchesList.defaultProps = {
  searchTerms: []
};

const SearchStartingPage = ({
  recommendedSearchTerms,
  searchTerms,
  setRecentSearchesState,
  hasNotFoundSearchResults,
  searchTerm,
  isLoading,
  isAdmin,
  topSearchesData
}) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleTabSwitch = (event, newValue) => {
    setValue(newValue);
  };
  const clearRecentSearches = () => {
    setRecentSearchesState({ searchTerms: [] });
  };

  const hasRecentSearchResults = value === 1 && searchTerms && searchTerms.length > 0;

  if (isLoading) {
    return null;
  }

  return (
    <div className={classes.sectionPosition}>
      <>
        <Tabs
          value={value}
          onChange={handleTabSwitch}
          indicatorColor="primary"
          classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
        >
          <Tab
            label={isXs ? 'Recommended' : 'Recommended searches'}
            className={classes.root}
            classes={{ wrapper: classes.wrapper }}
          />
          <Tab
            label={isXs ? 'Recent' : 'Recent searches'}
            className={classes.root}
            classes={{ wrapper: classes.wrapper }}
          />
          {isAdmin && (
            <Tab
              label="Top 100 Searches"
              className={classes.root}
              classes={{ wrapper: classes.wrapper }}
            />
          )}
          {hasRecentSearchResults && (
            <Button
              color="primary"
              onClick={clearRecentSearches}
              disableFocusRipple
              className={classes.clearButton}
            >
              Clear All
            </Button>
          )}
        </Tabs>

        {value === 0 && <SearchesList classes={classes} searchTerms={recommendedSearchTerms} />}
        {value === 1 && <SearchesList classes={classes} searchTerms={searchTerms} />}
        {value === 2 && <TopSearchesList classes={classes} topSearchesData={topSearchesData} />}
      </>
      <Grid
        container
        className={classes.decorationWrapper}
        direction="column"
        justify="space-evenly"
        alignItems="stretch"
      >
        <Grid className={classes.decorationBlock} item xs={12}>
          <img src={SearchDecoration} alt="Aleph Beta Search" className={classes.media} />
        </Grid>
        <Grid className={classes.decorationBlock} item xs={12}>
          <Typography variant="h2" align="center" className={classes.heading} gutterBottom>
            {hasNotFoundSearchResults
              ? `Hmmm, we didn't find anything for ${searchTerm}`
              : ' What are you looking for?'}
          </Typography>
        </Grid>
        <Grid className={classes.decorationBlock} item xs={12}>
          <Typography variant="subtitle1" align="center" className={classes.paragraph}>
            {hasNotFoundSearchResults
              ? 'Please try a different search term or check out our suggestions above.'
              : ' We have a full library of videos and guides. Start searching to start learning.'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

SearchStartingPage.propTypes = {
  recommendedSearchTerms: PropTypes.arrayOf(PropTypes.string),
  searchTerms: PropTypes.arrayOf(PropTypes.string),
  setRecentSearchesState: PropTypes.func.isRequired,
  hasNotFoundSearchResults: PropTypes.bool,
  searchTerm: PropTypes.string,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  topSearchesData: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string,
      count: PropTypes.string
    })
  )
};
SearchStartingPage.defaultProps = {
  recommendedSearchTerms: [],
  searchTerms: [],
  searchTerm: null,
  isLoading: false,
  hasNotFoundSearchResults: false,
  topSearchesData: null,
  isAdmin: false
};

export default SearchStartingPage;
