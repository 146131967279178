import { get } from 'lodash';
import { extractSeoData } from '~src/utils/unfurl-helpers';

const extractCustomHtmlSlices = prismicData => {
  const customHtmlSlicesArray = get(prismicData, 'prismicBlogPost.data.body', []);

  if (customHtmlSlicesArray) {
    return customHtmlSlicesArray.map(section => ({
      html: get(section.primary, 'custom_html.text')
    }));
  }

  return null;
};

export default prismicData => ({
  ...get(prismicData, 'prismicBlogPost.data'),
  head: {
    uid: get(prismicData, 'prismicBlogPost.uid'),
    title: get(prismicData, 'prismicBlogPost.data.title'),
    overline: get(prismicData, 'prismicBlogPost.data.overline'),
    last_publication_date: get(prismicData, 'prismicBlogPost.last_publication_date'),
    author: get(prismicData, 'prismicBlogPost.data.author.document.data'),
    seo_image: get(prismicData, 'prismicBlogPost.data.seo_image')
  },
  html: get(prismicData, 'prismicBlogPost.data.content.html', ''),
  custom_html_slices: extractCustomHtmlSlices(prismicData),
  seo: extractSeoData(prismicData, 'prismicBlogPost')
});
