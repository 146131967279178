import { getNavigationPageLink } from '~utils/common-site-helper';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import { ANALYTIC_EVENT } from './analytics-tracking';
import { getPushToAppExperimentCtaData } from './experiment-helper';

const IS_FREE_TRIAL_ENABLED = isFeatureEnabled(FEATURE_FLAGS.FREE_TRIAL);

export const getRegisterButtonProps = (isLoggedIn, isMarketingSite = false, marketingUser = {}) => {
  let text = 'Try for free';
  let url = getNavigationPageLink('/checkout/register', isMarketingSite);
  let shouldTrackAnalytics = false;
  let analyticsEvent = '';
  if (!isMarketingSite && isLoggedIn) {
    text = 'Get unlimited access';
    url = '/subscribe';
  } else if (isMarketingSite && !IS_FREE_TRIAL_ENABLED && isLoggedIn) {
    text = 'Member Home';
    url = getNavigationPageLink('/', isMarketingSite);
  } else if (isMarketingSite && IS_FREE_TRIAL_ENABLED && marketingUser?.isSubscriber) {
    text = 'Member Home';
    url = getNavigationPageLink('/', isMarketingSite);
  } else if (
    isMarketingSite &&
    IS_FREE_TRIAL_ENABLED &&
    !marketingUser?.isSubscriber &&
    marketingUser?.usedFreeTrial
  ) {
    text = 'Get unlimited access';
    url = '/subscribe-now';
  } else if (isMarketingSite && IS_FREE_TRIAL_ENABLED && !marketingUser?.isSubscriber) {
    // marketing experiment variant
    const buttonData = getPushToAppExperimentCtaData();
    text = buttonData.text;
    url = buttonData.url;

    shouldTrackAnalytics = true;
    analyticsEvent = ANALYTIC_EVENT.FREE_TRIAL_BUTTON_CLICK;
  }
  return { text, url, shouldTrackAnalytics, analyticsEvent };
};

export const getSubscribeButtonProps = (
  isLoggedIn,
  isMarketingSite = false,
  marketingUser = {}
) => {
  let text = 'Try for free';
  let url = getNavigationPageLink('/checkout/register', isMarketingSite);
  let shouldTrackAnalytics = false;
  let analyticsEvent = '';

  if (!isMarketingSite && isLoggedIn) {
    text = 'Get unlimited access';
    url = '/subscribe';
  } else if (isMarketingSite && !IS_FREE_TRIAL_ENABLED && isLoggedIn) {
    text = 'Member Home';
    url = getNavigationPageLink('/', true);
  } else if (isMarketingSite && IS_FREE_TRIAL_ENABLED && marketingUser?.isSubscriber) {
    text = 'Member Home';
    url = getNavigationPageLink('/', true);
  } else if (
    isMarketingSite &&
    IS_FREE_TRIAL_ENABLED &&
    !marketingUser?.isSubscriber &&
    marketingUser?.usedFreeTrial
  ) {
    text = 'Get unlimited access';
    url = '/subscribe-now';
  } else if (isMarketingSite && IS_FREE_TRIAL_ENABLED && !marketingUser?.isSubscriber) {
    // marketing experiment variant
    const buttonData = getPushToAppExperimentCtaData();
    text = buttonData.text;
    url = buttonData.url;

    shouldTrackAnalytics = true;
    analyticsEvent = ANALYTIC_EVENT.FREE_TRIAL_BUTTON_CLICK;
  }
  return { text, url, shouldTrackAnalytics, analyticsEvent };
};
