import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import isBot from 'isbot';
import { isAndroid, isIOS } from 'react-device-detect';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import MobileAppBlockerPopup from './MobileAppBlockerPopup';

const POPUP_SHOWN_KEY = 'mobile_app_popup_shown';
const EXPIRE_IN_1_DAY = {
  expires: 1
};

const MobileAppBlocker = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { isUserStateLoading } = useUserContext().session;

  useEffect(() => {
    if (isUserStateLoading) {
      return; // exit
    }
    const { userAgent } = typeof window !== 'undefined' ? window.navigator : {};
    const isTargetAudience = (isIOS || isAndroid) && !isBot(userAgent);
    const notShownRecently = Cookies.get(POPUP_SHOWN_KEY) === undefined;

    if (isTargetAudience && notShownRecently) {
      setShowPopup(true);
      // Set flag to prevent showing popup again for 1 day
      Cookies.set(POPUP_SHOWN_KEY, 1, EXPIRE_IN_1_DAY);
    }
  }, [isUserStateLoading]);

  return showPopup && <MobileAppBlockerPopup />;
};

export default MobileAppBlocker;
