import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Dialog from '~components/Dialog';
import AbBlackLogo from '~images/alephbeta-black-logo.svg';
import DesktopBackgroundImage from '~images/popups/watch-time/rabbi-book-shelf.jpg';
import MobileBackgroundImage from '~images/popups/watch-time/rabbi-book-shelf-mobile.jpg';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import ClickableText from '~components/buttons/ClickableText/ClickableText';
import Link from '~components/Link';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import ButtonClosePopup from '../components/ButtonClosePopup';
import SupportHeartText from '../components/SupportHeartText';

const useStyles = makeStyles(theme => ({
  scrollBody: {
    '&:after': {
      height: 'unset !important'
    }
  },
  paper: {
    margin: 0,
    color: '#000000',
    height: '100%',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
      minHeight: '100vh'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      backgroundPosition: 'bottom center'
    }
  },
  logoImage: {
    marginLeft: '40px',
    marginTop: '18px',
    maxWidth: '200px'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    top: '47%',
    transform: 'translateY(-50%)',
    padding: '0px 7%',
    [theme.breakpoints.down('sm')]: {
      top: 'unset',
      transform: 'unset',
      alignItems: 'flex-start',
      marginTop: '20px',
      marginBottom: '20px'
    },
    [theme.breakpoints.down('xs')]: {
      top: 'unset',
      transform: 'unset',
      padding: '0px 20px',
      alignItems: 'center',
      marginTop: '80px'
    }
  },
  headingText: {
    fontFamily: 'Inter',
    fontSize: '52px',
    lineHeight: '114%',
    fontWeight: 700,
    marginBottom: '41px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      textAlign: 'center',
      lineHeight: '117%',
      marginBottom: '13px',
      order: 0
    }
  },
  bodyHighlightedText: {
    fontFamily: 'Inter',
    fontSize: '24px',
    lineHeight: '140%',
    fontWeight: 700,
    marginBottom: '64px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '14px',
      marginBottom: '13px',
      order: 1
    }
  },
  bodyText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '158%',
    fontWeight: 500,
    maxWidth: '484px',
    marginBottom: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '150%',
      fontWeight: 400,
      marginBottom: '29px',
      textAlign: 'center',
      order: 2
    }
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      gap: '8px',
      order: 3
    }
  },
  bottomLightText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '142%',
    textAlign: 'center',
    marginTop: '4px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '0px'
    }
  },
  continueWatchingTextBtn: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '142%',
    fontWeight: 700,
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  bottomBoldTextLink: {
    '& > *': {
      color: '#000000',
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '142%',
      fontWeight: 700,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px'
      }
    },
    '& > a:hover': {
      textDecoration: 'none'
    }
  },
  subscribeBtn: {
    textAlign: 'center',
    padding: '16px 102px',
    fontWeight: 600,
    marginBottom: '16px'
  },
  subscribeMobileBtn: {
    fontWeight: 600,
    padding: '9px 34px'
  }
}));

const PopupVariant2 = ({ showTopCloseBtn, id = undefined, handleClose, isOpen, isLoggedIn }) => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      isOpen={isOpen}
      id={id}
      backdrop="#A4D0EE"
      background={`url(${isMobileDevice ? MobileBackgroundImage : DesktopBackgroundImage})`}
      maxWidth="100vw"
      classes={{ paper: classes.paper, scrollBody: classes.scrollBody }}
      disableScrollLock
    >
      {!isMobileDevice && (
        <img src={AbBlackLogo} alt="Aleph Beta logo" className={classes.logoImage} />
      )}
      <div className={classes.bodyContainer}>
        <Typography className={classes.headingText}>
          Hey,
          <br />
          it’s Rabbi Fohrman. 
        </Typography>
        <Typography className={classes.bodyText}>
          That’s right, it’s me. I’m the disembodied voice behind this video. But I’m also the
          founder of this non-profit. We’re a team of people trying to help make the Torah come
          alive for folks around the world. Please join our efforts and become a subscriber. You’ll
          get full access to the thousands of videos and podcasts in our library.
        </Typography>
        <Typography className={classes.bodyHighlightedText}>
          You’ve watched 10 of your free 30 {!isMobileDevice && <br />}minutes this month.
        </Typography>
        <div className={classes.bottomContainer}>
          <RoundedLinkButton
            text={<SupportHeartText />}
            to="/subscribe"
            size={isMobileDevice ? 'mini' : 'large'}
            variant="black"
            externalClassName={isMobileDevice ? classes.subscribeMobileBtn : classes.subscribeBtn}
          />
          <Typography className={classes.bottomLightText}>
            Not yet?{' '}
            <ClickableText className={classes.continueWatchingTextBtn} onClick={handleClose}>
              Continue watching
            </ClickableText>
          </Typography>
          {!isLoggedIn && (
            <Typography className={classes.bottomLightText}>
              Already have an account?{' '}
              <span className={classes.bottomBoldTextLink}>
                <Link to={getNavigationPageLink('/login', isMarketingSite)}>Log in here</Link>
              </span>
            </Typography>
          )}
        </div>
      </div>
      {showTopCloseBtn && (
        <ButtonClosePopup
          color={isMobileDevice ? '#000000' : '#FFFFFF'}
          iconSize={isMobileDevice ? '20px' : '2rem'}
          top={isMobileDevice ? '10px' : '20px'}
          right={isMobileDevice ? '10px' : '20px'}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};

PopupVariant2.propTypes = {
  showTopCloseBtn: PropTypes.bool,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool
};

PopupVariant2.defaultProps = {
  showTopCloseBtn: false,
  id: undefined,
  isOpen: null,
  isLoggedIn: false
};

export default PopupVariant2;
