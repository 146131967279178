import { get } from 'lodash';

const extractProducerCircleMembers = prismicData => {
  const membersSlices = get(prismicData, 'prismicProducersCircle.data.body', []).filter(
    slice => slice.slice_type === 'producers_circle_members'
  );
  const members = membersSlices && membersSlices[0] ? membersSlices[0].items : [];
  return members || [];
};

const extractCourseCard = prismicData => {
  const slices = get(prismicData, 'prismicProducersCircle.data.body1', []).filter(
    slice => slice.slice_type === 'course_card'
  );

  return get(slices, '[0].primary', null);
};

export default prismicData => ({
  producersCircleMembers: extractProducerCircleMembers(prismicData),
  courseCard: extractCourseCard(prismicData)
});
