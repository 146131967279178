import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { useCtaRegistration } from '~hooks/useAuth';

import TextInput from '~components/form-inputs/TextInput';
import MessageBox from '~components/forms/MessageBox';
import Container from '~components/Container';

import { Button } from '@material-ui/core';
import shabbatGuide from './images/shabbat-guide.png';

const useStyles = makeStyles(theme => ({
  container: {
    width: '70%',
    margin: '70px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  title: {
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '44px',
    maxWidth: '360px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  input: {
    width: '230px',
    border: '1px solid #000000',
    borderRadius: '6px 0px 0px 6px',
    margin: '0px'
  },
  inputBase: {
    border: 'none',
    height: '100%'
  },
  submitButton: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '0px 6px 6px 0px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 'normal',
    display: 'inline',
    width: '170px',
    '&:hover': {
      backgroundColor: '#D3D3D3',
      color: '#000000'
    },
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
      padding: '0px'
    }
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      height: '200px'
    }
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    rowGap: '20px',
    columnGap: '120px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center'
    }
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px'
  },
  formContainer: {
    width: '100%'
  }
}));

const WeeklyTorahPortionOneLineSignUp = ({ isLoggedIn, isUserStateLoading }) => {
  const classes = useStyles();

  const { register, handleSubmit } = useForm();
  const [regState, registerCta] = useCtaRegistration();
  const { isLoading, isError, error } = regState;

  const onSubmit = ({ username }) => {
    // NOTE: MUST be one of the backend/src/models/RegistrationStrategy.ts
    registerCta({ username, strategy: 'one-line-sign-up' });
  };

  if (isLoggedIn || isUserStateLoading) {
    return null;
  }

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
        {isError && <MessageBox text={error} isError />}
        <div className={classes.grid}>
          <div className={classes.gridItem}>
            <Typography className={classes.title}>
              Sign up for weekly Parsha Emails straight to your inbox.
            </Typography>
            <div className={classes.inputContainer}>
              <TextInput
                label="Your Email"
                id="username-signup-form"
                name="username"
                type="email"
                inputRef={register}
                required
                margin="dense"
                disabled={isLoading}
                className={classes.input}
              />
              <Button type="submit" className={classes.submitButton}>
                Get weekly Parsha
              </Button>
            </div>
          </div>
          <div className={classes.gridItem}>
            <img src={shabbatGuide} alt="shabbat-guide" className={classes.image} />
          </div>
        </div>
      </form>
    </Container>
  );
};

WeeklyTorahPortionOneLineSignUp.propTypes = {
  isLoggedIn: PropTypes.bool,
  isUserStateLoading: PropTypes.bool.isRequired
};

WeeklyTorahPortionOneLineSignUp.defaultProps = {
  isLoggedIn: false
};

export default WeeklyTorahPortionOneLineSignUp;
