import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExitIcon from '@material-ui/icons/Clear';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import { setSpotifyPlayerOpen } from '~context/GlobalContext/actions';

const useStyles = makeStyles(theme => ({
  exitIcon: {
    display: props => (props.isExitShown ? 'flex' : 'none'),
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '2px 8px',
    cursor: 'pointer',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    top: '-10px',
    right: '16px',
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      display: 'flex !important'
    }
  },
  container: {
    zIndex: 999,
    paddingTop: 4,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF'
  },
  embedHolder: {
    display: 'flex',
    width: '100%',
    maxWidth: '1500px'
  }
}));
const BuzzsproutPlayer = ({ embedCode, handleClose }) => {
  const [isExitShown, setIsExitShown] = useState(false);
  const classes = useStyles({ isExitShown });
  const {
    dispatch,
    state: { intercomInitialized }
  } = useGlobalStore();

  useEffect(() => {
    if (intercomInitialized && window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: true
      });
    }

    return () => {
      if (intercomInitialized && window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: false
        });
      }
    };
  }, [intercomInitialized]);

  useEffect(() => {
    dispatch(setSpotifyPlayerOpen(true));

    return () => {
      dispatch(setSpotifyPlayerOpen(false));
    };
  }, [dispatch]);

  const toggleIsExitShown = () => {
    setIsExitShown(prevState => !prevState);
  };

  return (
    <div
      key="buzz-player-container"
      id="buzz-playerid"
      className={classes.container}
      onMouseEnter={toggleIsExitShown}
      onMouseLeave={toggleIsExitShown}
    >
      <Box
        key="button-container-close"
        id="container-close"
        className={classes.exitIcon}
        onClick={handleClose}
      >
        <div>Close</div>
        <ExitIcon />
      </Box>

      <div
        id="buzzsprout-embed-holder"
        className={classes.embedHolder}
        dangerouslySetInnerHTML={{ __html: embedCode }}
      />
    </div>
  );
};

BuzzsproutPlayer.propTypes = {
  embedCode: PropTypes.string.isRequired,
  handleClose: PropTypes.func
};

BuzzsproutPlayer.defaultProps = {
  handleClose: null
};

export default BuzzsproutPlayer;
