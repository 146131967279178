import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExitIcon from '@material-ui/icons/Clear';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import { setSpotifyPlayerOpen } from '~context/GlobalContext/actions';

const useStyles = makeStyles(theme => ({
  exitIcon: {
    display: props => (props.isExitShown ? 'flex' : 'none'),
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '2px 8px',
    cursor: 'pointer',
    position: 'absolute',
    backgroundColor: '#001040',
    top: '10px',
    right: '16px',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      display: 'flex !important'
    }
  }
}));
const SpotifyPlayer = ({ url, title, handleClose }) => {
  const [isExitShown, setIsExitShown] = useState(false);
  const classes = useStyles({ isExitShown });
  const { dispatch } = useGlobalStore();

  useEffect(() => {
    dispatch(setSpotifyPlayerOpen(true));
    const intercom =
      document.querySelector('.intercom-lightweight-app') ||
      document.getElementById('intercom-container');

    const parent = document.getElementById('spotify-embed-holder');

    const options = {
      uri: url,
      height: '250',
      title
    };

    const callback = controller => controller.play();

    // this check is solely added not to throw an error, if the spotify player is not ready at the time user clicks the play button
    if (window.SpotifyIframeApi) {
      window.SpotifyIframeApi.createController(parent, options, callback);
    }

    if (intercom) {
      intercom.style.display = 'none';
    }

    return () => {
      dispatch(setSpotifyPlayerOpen(false));
      if (intercom) {
        intercom.style.display = 'block';
      }
    };
  }, [url, title]);

  const toggleIsExitShown = () => {
    setIsExitShown(prevState => !prevState);
  };

  return (
    <Box
      key="sp-player-container"
      id="sp-playerid"
      zIndex={999}
      paddingTop={4}
      position="fixed"
      left={0}
      bottom={-32}
      width="100%"
      onMouseEnter={toggleIsExitShown}
      onMouseLeave={toggleIsExitShown}
    >
      <Box
        key="button-container-close"
        id="container-close"
        className={classes.exitIcon}
        onClick={handleClose}
      >
        <Box>Close</Box>
        <ExitIcon />
      </Box>

      <div id="spotify-embed-holder" />
    </Box>
  );
};

SpotifyPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func
};

SpotifyPlayer.defaultProps = {
  handleClose: null
};

export default SpotifyPlayer;
