import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';

const More = ({ title, children }) => (
  <Box component={Container} center size={33} pt={4} pb={4}>
    <Box mt={2} mb={4}>
      <Typography variant="h3" align="center" gutterBottom>
        More on {title}
      </Typography>
    </Box>

    <Grid container direction="row" justify="space-evenly" alignItems="flex-start" spacing={4}>
      {children &&
        React.Children.toArray(children).map(child => (
          <Grid item xs={12} sm={3} key={`holiday-card-key-${child.key}`}>
            {child}
          </Grid>
        ))}
    </Grid>
  </Box>
);

More.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default More;
