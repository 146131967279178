import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Link from '~components/Link';
import {
  LabelNew,
  LabelAudio,
  LabelPremium,
  LabelOverlay
} from '~components/playlist-card/PlaylistLabels';

import { formatDurationOfFirstVideoTishaBav, LightenColor } from '~utils/playlist-helpers';
import { lineFix } from '~utils/popup-helpers';

const VideoTitle = styled.h6`
  display: flex;
  font-size: 18px;
  align-items: center;
  font-weight: 500;
  margin: 0;
  line-height: 22px;
  letter-spacing: 0.00938em;
  margin-bottom: 6px;
  color: inherit;
`;

const StyledImage = styled.img`
  display: flex;
  max-width: 100%;
`;

const InfoText = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.00714em;
  display: flex;
  align-items: center;
  font-weight: normal;
  margin: 0;
  color: inherit;
  opacity: 1;
  @media only screen and (max-width: 322px) {
    display: block;
  }
`;

const StyledLabelsOverlay = styled(LabelOverlay)`
  position: unset;
  margin-left: -4px;
  & > * {
    margin-left: 4px;
  }
`;

const StyledExcerpt = styled.p`
  font-size: 12px !important;
  word-break: break-word;
  line-height: 16px !important;
  margin-block-end: 15px !important;
  margin-block-start: 6px !important;
  color: inherit;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledDotSeparator = styled.span`
  font-size: 0.6rem;
  margin-left: 5px;
  margin-right: 5px;
  color: inherit;
  @media only screen and (max-width: 322px) {
    top: 63px;
    left: 98px;
  }
`;

const CurationPageDrawerPlaylists = ({
  url,
  uid,
  title,
  color,
  description,
  cover_image,
  isNew,
  isAudio,
  isPremium,
  author,
  length_in_seconds,
  selectEvent
}) => (
  <Box width="100%" p={1} pr={2} bgcolor={`#${LightenColor(color, -10)}`} my={2} color="#fff">
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
      <Grid item sm={4} md={4}>
        <Link
          id={uid}
          to={url}
          state={{ searchResultVideoUid: uid }}
          underline="hover"
          onClick={selectEvent}
          style={{ width: '100%' }}
        >
          <StyledImage src={cover_image.url} alt={cover_image.alt} />
        </Link>
      </Grid>
      <Grid item sm={8} md={8}>
        <VideoTitle>
          <Link
            id={uid}
            to={url}
            state={{ searchResultVideoUid: uid }}
            underline="hover"
            color="inherit"
            onClick={selectEvent}
          >
            {lineFix(title)}
          </Link>
        </VideoTitle>

        <InfoText>
          {author} <StyledDotSeparator>&#x25cf;</StyledDotSeparator>
          {formatDurationOfFirstVideoTishaBav(length_in_seconds)} video
        </InfoText>

        <StyledExcerpt id={uid}>{description}</StyledExcerpt>

        <StyledLabelsOverlay>
          {isNew && <LabelNew />}
          {isAudio && <LabelAudio />}
          {isPremium && <LabelPremium />}
        </StyledLabelsOverlay>
      </Grid>
    </Grid>
  </Box>
);
CurationPageDrawerPlaylists.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  cover_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  description: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  isAudio: PropTypes.bool.isRequired,
  isPremium: PropTypes.bool.isRequired,
  author: PropTypes.string.isRequired,
  length_in_seconds: PropTypes.number.isRequired,
  selectEvent: PropTypes.func.isRequired
};

export default CurationPageDrawerPlaylists;
