import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import RichText from '~components/RichText';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color || 'inherit',
    position: 'relative'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '0px',
    paddingTop: '40px',
    paddingBottom: '60px'
  },
  avatar: {
    width: '137px',
    borderRadius: '999px',
    marginBottom: '33px'
  },
  title: {
    color: props => props.text_color,
    lineHeight: '116%',
    fontSize: '35px',
    fontWeight: '600',
    fontFamily: 'Inter',
    marginBottom: '24px',
    marginTop: 0,
    textAlign: 'center',
    maxWidth: '1030px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '118%'
    }
  },
  subtitle: {
    color: props => props.text_color,
    lineHeight: '115%',
    fontSize: '24px',
    fontWeight: '500',
    fontFamily: 'Inter',
    marginBottom: '44px',
    marginTop: 0,
    textAlign: 'center',
    maxWidth: '1030px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      marginBottom: '24px'
    }
  },
  perksContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '33px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      gap: '16px'
    }
  },
  perkBox: {
    width: 'calc((100% - 66px)/3)',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    borderRadius: '10px',
    padding: '42px 30px 42px 25px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc((100% - 32px)/2)'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '38px 22px'
    }
  },
  perkHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '27px'
  },
  perkTitle: {
    lineHeight: '130%',
    fontSize: '24px',
    fontWeight: '600',
    fontFamily: 'Inter',
    margin: 0
  },
  perkDescription: {
    '& > *': {
      fontSize: '16px',
      fontFamily: 'Inter',
      lineHeight: '164%',
      marginBlockStart: 0,
      marginBottom: '18px',
      color: 'inherit',
      '& > a': {
        fontWeight: '600',
        color: 'inherit',
        textDecoration: 'underline'
      }
    },
    '& > *:last-child': {
      marginBottom: '0px'
    }
  }
}));

const CoursePerks = ({
  title,
  subtitle,
  perks,
  background_color,
  text_color,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    background_color,
    text_color
  });

  return (
    <div className={classes.root}>
      <Container center>
        <div className={classes.contentArea}>
          <h2 className={classes.title}>{title}</h2>
          <h3 className={classes.subtitle}>{subtitle}</h3>
          <div className={classes.perksContainer}>
            {perks.map(perk => (
              <div
                key={perk.perk_title}
                className={classes.perkBox}
                style={{ backgroundColor: perk?.background_color, color: perk?.text_color }}
              >
                <div className={classes.perkHeading}>
                  <img
                    src={perk?.perk_icon?.url}
                    alt={perk?.perk_icon?.alt || perk?.perk_title}
                    className={classes.perkIcon}
                  />
                  <h4 className={classes.perkTitle}>{perk?.perk_title}</h4>
                </div>
                <RichText
                  html={perk?.perk_description?.html}
                  verticalSpacing={0}
                  externalClassName={classes.perkDescription}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

CoursePerks.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  text_color: PropTypes.string,
  background_color: PropTypes.string,
  perks: PropTypes.arrayOf(
    PropTypes.shape({
      perk_title: PropTypes.string,
      perk_description: PropTypes.shape({ html: PropTypes.string }),
      background_color: PropTypes.string,
      text_color: PropTypes.string,
      perk_icon: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string
      })
    })
  ),
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

CoursePerks.defaultProps = {
  subtitle: '',
  background_color: '#FFFFFF',
  text_color: '#000000',
  bottom_shape_divider: null,
  perks: []
};

export default CoursePerks;
