import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinkIcon from '@material-ui/icons/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReplyIcon from '@material-ui/icons/Reply';

import Popper from '~components/popper/Popper';

import request from '~utils/request';
import { SHARE_PLAYLIST } from '~src/hooks/useApi';
import PodcastButton from '../PodcastButton';

const useStyles = makeStyles(() => ({
  popperBody: {
    zIndex: 1300,
    padding: '22px',
    backgroundColor: 'white',
    border: '2px solid #e4e4e4',
    borderRadius: '5px'
  },

  shareButton: {
    paddingTop: '5px',
    paddingBottom: '5px',
    display: 'flex',
    justifyContent: 'flex-start',

    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  textStyles: {
    color: '#606060',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '18px'
  },

  socialBg: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    fontSize: '30px',
    padding: '4px',
    color: 'white'
  },

  fixButtonLineHeight: {
    lineHeight: '0 !important',
    display: 'flex',
    alignItems: 'center'
  },

  socialButton: {
    outline: '0',
    marginRight: '1rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7'
    }
  },

  shareIcon: {
    marginRight: '4px',
    marginBottom: '4px',
    transform: 'scaleX(-1)',
    MozTransformOrigin: 'scaleX(-1)',
    MozTransformStyle: 'scaleX(-1)',
    WebkitTransform: 'scaleX(-1)',
    msTransform: 'scaleX(-1)'
  },

  linkCopiedText: {
    color: '#419A4D',
    display: 'flex',
    alignItems: 'center'
  },

  checkIcon: {
    fontSize: '1.2rem',
    marginLeft: '5px'
  }
}));

const PodcastShareButton = ({ title, videoTitle, contentType }) => {
  const classes = useStyles();
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isUrlCopiedToClipboard, setUrlCopiedToClipboard] = useState(false);

  const browser = typeof window !== 'undefined' && window;

  const currentPageUrl = browser && browser?.location?.href;

  const showPopper = ({ currentTarget }) => {
    setPopperAnchor(currentTarget);
    setIsPopperOpen(true);
  };

  const hidePopper = () => {
    setPopperAnchor(null);
    setUrlCopiedToClipboard(false);
    setIsPopperOpen(false);
  };

  const copyUrlToClipboard = () => {
    window.navigator.clipboard.writeText(currentPageUrl);
    setUrlCopiedToClipboard(true);
  };

  const recordSharing = method => {
    request(SHARE_PLAYLIST({ method, url: currentPageUrl })).catch(() => {
      /* Explicitly do nothing */
    });
  };

  return (
    <>
      <PodcastButton
        btnVariant="transparent"
        disableRipple
        disableFocusRipple
        disableTouchRipple
        onClick={showPopper}
      >
        <ReplyIcon fontSize="small" className={classes.shareIcon} /> Share
      </PodcastButton>

      <Popper popperAnchorRef={popperAnchor} onClickAway={hidePopper} placement="bottom-end">
        <Box className={classes.popperBody}>
          {isPopperOpen && (
            <>
              <Typography
                component="h3"
                style={{ fontWeight: '700', fontSize: '18px' }}
                gutterBottom
              >
                Share
              </Typography>

              <Box
                className={classes.shareButton}
                onClick={() => {
                  copyUrlToClipboard();
                  recordSharing('clipboard');
                }}
              >
                <LinkIcon className={`${classes.socialButton} ${classes.socialBg}`} />
                {isUrlCopiedToClipboard ? (
                  <Typography component="h4" className={classes.linkCopiedText}>
                    Link Copied <CheckCircleIcon className={classes.checkIcon} />
                  </Typography>
                ) : (
                  <Typography component="h4" className={classes.textStyles}>
                    Copy Link
                  </Typography>
                )}
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <FacebookShareButton
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('facebook');
                  }}
                >
                  <FacebookIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Share on Facebook
                  </Typography>
                </FacebookShareButton>
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <TwitterShareButton
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('twitter');
                  }}
                >
                  <TwitterIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Share on Twitter
                  </Typography>
                </TwitterShareButton>
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <EmailShareButton
                  subject="I think you'd enjoy this..."
                  body={`Hey,\r\n\r\nI found this Aleph Beta ${contentType.toLowerCase()} really incredible and I wanted to share it with you! Here are the details...\r\n\r\n\r\n${title}\r\n\r\n${videoTitle}\r\n\r\n`}
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('email');
                  }}
                >
                  <EmailIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Email
                  </Typography>
                </EmailShareButton>
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <WhatsappShareButton
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('whatsapp');
                  }}
                >
                  <WhatsappIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Share on WhatsApp
                  </Typography>
                </WhatsappShareButton>
              </Box>
            </>
          )}
        </Box>
      </Popper>
    </>
  );
};

PodcastShareButton.propTypes = {
  title: PropTypes.string,
  contentType: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired
};

PodcastShareButton.defaultProps = {
  title: 'Check out Aleph Beta!'
};

export default PodcastShareButton;
