import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: '#000000',
    fontWeight: '600',
    fontSize: '14px',
    marginBottom: '6px'
  },
  input: {
    color: '#000000',
    fontSize: '16px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    borderRadius: 7,
    border: '1px solid #000000',
    '& input': {
      borderRadius: 7,
      padding: '13px 19px 13px 19px'
    }
  }
}));

const Input = ({ id, type, label, ...rest }) => {
  const classes = useStyles();
  return (
    <>
      {label && (
        <InputLabel htmlFor={id} className={classes.inputLabel}>
          {label}
        </InputLabel>
      )}
      <InputBase id={id} type={type} className={classes.input} {...rest} />
    </>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string
};

Input.defaultProps = {
  label: ''
};

export default Input;
