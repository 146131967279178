import Cookies from 'js-cookie';
import { startMixpanelExperiment } from '~utils/mixpanel-helper';
import { generateUrlFriendlyIdFromTitle } from '~utils/url-helper';
import { trackExperimentVariant } from '~utils/analytics';

const AUTH_DOMAIN = process.env.GATSBY_AUTH_DOMAIN;

export default class ExperimentTracking {
  /**
   * This function returns a variant from a new experiment or already existing experiment.
   * When called with a experiment name for the 1st time, it starts the experiment with the assigned variant.
   * @param {string} experimentName - Name of the experiment.
   * @param {string[]} variants - List of all possible variants
   * @param {boolean} isCrossDomain - Whether experiment is available in both the marketing and the members site
   * @returns {string} variant
   */
  static trackAndGetVariant(
    experimentName,
    variants,
    isCrossDomain = false,
    excludedVariantsFromAssigning = []
  ) {
    const experimentCookieKey = `exp_${generateUrlFriendlyIdFromTitle(experimentName)}`;

    const assignableVariants =
      excludedVariantsFromAssigning?.length > 0
        ? variants.filter(variant => !excludedVariantsFromAssigning.includes(variant))
        : variants;

    // When not rendered in a browser, let's not record the variant and just return the 1st variant
    if (typeof window === 'undefined') {
      return assignableVariants[0];
    }

    const cookieVariant = Cookies.get(experimentCookieKey);

    const isAlreadyAssigned = variants.includes(cookieVariant);
    let variant = cookieVariant;
    if (isAlreadyAssigned) {
      variant = cookieVariant;
    } else {
      // Generate a random index within the range of the variants length
      const randomVariantIndex = Math.floor(Math.random() * assignableVariants.length);
      // Return the item at the randomly generated index
      variant = assignableVariants[randomVariantIndex];

      const cookieOptions = { expires: 120 }; // expires in 4 months
      if (isCrossDomain) {
        cookieOptions.domain = AUTH_DOMAIN;
      }
      Cookies.set(experimentCookieKey, variant, cookieOptions);

      // mixpanel start the experiment
      startMixpanelExperiment(experimentName, variant);

      // track in analytics db
      trackExperimentVariant(experimentName, variant);
    }

    return variant;
  }

  static getVariant(experimentName, defaultToVariant = null) {
    const experimentCookieKey = `exp_${generateUrlFriendlyIdFromTitle(experimentName)}`;
    const cookieVariant = Cookies.get(experimentCookieKey);

    if (cookieVariant) {
      return cookieVariant;
    }

    return defaultToVariant;
  }
}
