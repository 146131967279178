import React from 'react';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

const BUTTON_VARIANTS = {
  PURPLE: 'purple',
  BLACK: 'black'
};

const getVariantStyles = variant => {
  switch (variant) {
    case BUTTON_VARIANTS.BLACK: {
      return {
        color: '#FFFFFF',
        backgroundColor: '#222222',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#222222'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#222222'
        }
      };
    }
    case BUTTON_VARIANTS.PURPLE:
    default: {
      return {
        color: '#FFFFFF',
        backgroundColor: '#251B6F',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#251B6F'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#251B6F'
        }
      };
    }
  }
};

const useStyles = makeStyles({
  buttonStyle: props => ({
    borderRadius: '40px',
    padding: '20px 52px',
    fontSize: '18px',
    textTransform: 'none',
    boxShadow: 'none',
    marginTop: props?.marginTop,
    marginBottom: props?.marginTop,
    ...getVariantStyles(props?.variant)
  })
});

const BecomePatronButton = ({ title, variant, marginTop, marginBottom }) => {
  const classes = useStyles({ variant, marginTop, marginBottom });

  return (
    <Button
      component={Scroll.Link}
      to="Patron Tiers Section"
      smooth
      duration={500}
      size="large"
      className={classes.buttonStyle}
    >
      {title}
    </Button>
  );
};

BecomePatronButton.propTypes = {
  title: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS))
};

BecomePatronButton.defaultProps = {
  title: 'Become a Patron',
  variant: BUTTON_VARIANTS.PURPLE,
  marginTop: '0px',
  marginBottom: '0px'
};

export default BecomePatronButton;
