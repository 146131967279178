import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import footerSprite from '~images/footer/footer-sprite-icons.png';
import footerSpritePodcast from '~images/footer/footer-sprite-podcast-icons.png';

import Link from '~components/Link';

const useStyles = makeStyles({
  image: {
    width: '134px',
    height: '46px',
    display: 'inline-block',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    outline: 'none',
    whiteSpace: 'nowrap'
  },
  appstore: {
    objectFit: 'none',
    objectPosition: '-545px -1px',
    width: '134px',
    height: '46px'
  },
  gplay: {
    objectFit: 'none',
    objectPosition: '-273px -1px',
    width: '134px',
    height: '46px'
  },
  itunes: {
    objectFit: 'none',
    objectPosition: '-1px -1px',
    width: '134px',
    height: '46px'
  }
});

export const AppStore = () => {
  const classes = useStyles();
  return (
    <Link
      to="https://itunes.apple.com/app/apple-store/id598939260?pt=118164377&ct=MobileWeb&mt=8"
      className={`${classes.image}`}
    >
      <img className={`${classes.appstore}`} src={footerSprite} alt="Available on the AppStore" />
    </Link>
  );
};

export const GooglePlay = () => {
  const classes = useStyles();
  return (
    <Link
      to="https://play.google.com/store/apps/details?id=org.alephbeta.android&hl=en"
      className={`${classes.image}`}
    >
      <img className={`${classes.gplay}`} src={footerSprite} alt="Get it on Google Play" />
    </Link>
  );
};

export const ApplePodcasts = () => {
  const classes = useStyles();
  return (
    <Link
      to="https://itunes.apple.com/us/podcast/parsha-lab-by-aleph-beta/id1331532911?mt=2"
      className={`${classes.image}`}
    >
      <img
        className={`${classes.itunes}`}
        src={footerSpritePodcast}
        alt="Listen on Apple Podcasts"
      />
    </Link>
  );
};
