import {
  trackMixpanelEvent,
  resetMixpanel,
  trackMixpanelEventWithCallback,
  trackMixpanelLinks,
  startSendingEventsToMixpanel
} from './mixpanel-helper';

export const ANALYTIC_EVENT = {
  VIDEO_START: 'Trailer Video Watch Start',
  VIDEO_END: 'Trailer Video Watch End',
  BANNER_CLICK: 'Banner Clicked',
  FREE_TRIAL_BUTTON_CLICK: 'Free Trial Button Clicked'
};

export default class AnalyticsTracking {
  static initUser(user) {
    // mixpanel
    startSendingEventsToMixpanel(user);
    return this;
  }

  static resetTracking() {
    // mixpanel
    resetMixpanel();
    return this;
  }

  static trackEvent(eventName, properties) {
    // mixpanel
    trackMixpanelEvent(eventName, properties);
    return this;
  }

  static trackEventWithCallback(eventName, properties, callback) {
    // mixpanel
    trackMixpanelEventWithCallback(eventName, properties, callback);
    return this;
  }

  static makeLinksTrackable(elementQuery, eventName, properties) {
    // mixpanel
    trackMixpanelLinks(elementQuery, eventName, properties);
    return this;
  }
}
