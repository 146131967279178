import React from 'react';
import { navigate } from 'gatsby';
import { Element } from 'react-scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ClickableText from '~components/buttons/ClickableText/ClickableText';
import useCheckout from '~hooks/useCheckout';
import products, { PRODUCT_IDS } from '~utils/products';
import Container from '~components/Container';
import Link from '~components/Link';
import { ProducersCircleButton } from '~modules/producers-circle/components/shared';
import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

import PcPriceImage from './images/pc-price.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F7F6F4'
  },
  container: {
    padding: '80px 17px',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      padding: '24px 17px',
      borderRadius: '0px'
    }
  },
  sectionSubtitle: {
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'Inter',
    lineHeight: '135%',
    paddingBottom: '8px',
    color: '#222222'
  },
  imageMedia: {
    maxWidth: '100%',
    display: 'flex',
    margin: '2em auto'
  },
  linkColor: {
    color: '#222222',
    fontWeight: '700'
  },
  buttonStyle: {
    borderRadius: '60px',
    padding: '24px 80px',
    marginBottom: '16px',
    fontSize: '26px',
    textTransform: 'none',
    boxShadow: 'none',
    color: '#FFFFFF',
    backgroundColor: '#3C3284',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#3C3284'
    },
    '&:active': {
      textDecoration: 'none',
      backgroundColor: '#3C3284'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      padding: '20px 52px'
    }
  },
  containerMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  monthlyPcLink: {
    textDecoration: 'underline',
    color: '#222222',
    fontWeight: '700',
    marginLeft: theme.spacing(),
    cursor: 'pointer'
  }
}));

const ProducersCirclePartnersSection = () => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const [, setCheckoutState] = useCheckout();

  const setMonthlyPcPlan = () => {
    const product = products.find(({ id }) => id === PRODUCT_IDS.PC);
    if (isMarketingSite) {
      redirectToMembersCheckout(product, 'month');
    } else {
      setCheckoutState({ product, billingCycle: 'month' });
      navigate('/checkout');
    }
  };

  return (
    <Element name="Partners Section" className={classes.root}>
      <Container maxWidth="md" center className={classes.container}>
        <img
          src={PcPriceImage}
          title="$1800/year"
          alt="Patrons Circle price"
          className={classes.imageMedia}
        />
        <ProducersCircleButton title="Become a Patron" className={classes.buttonStyle} />

        <Typography variant="h6" align="center" className={classes.sectionSubtitle}>
          Prefer to pay monthly?
          <ClickableText className={classes.monthlyPcLink} onClick={setMonthlyPcPlan}>
            Click here
          </ClickableText>
        </Typography>
        <Typography variant="h6" align="center" className={classes.sectionSubtitle}>
          Not quite ready to join Patrons Circle? Consider making a donation{' '}
          <Link to="/donate" underline="always" className={classes.linkColor}>
            here
          </Link>
        </Typography>
      </Container>
    </Element>
  );
};

export default ProducersCirclePartnersSection;
