import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useTestId } from '~utils/set-testid';
import PaypalIcon from '~images/checkout/paypal-icon.png';

const useStyles = makeStyles(theme => ({
  button: {
    background: '#fff'
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const ButtonSubmit = props => {
  const classes = useStyles();
  const paypalTestId = useTestId('paypal-form-submit-button');
  return (
    <Box py={1}>
      <Button
        type="button"
        variant="contained"
        size="large"
        fullWidth
        className={classes.button}
        {...paypalTestId}
        {...props}
      >
        <img src={PaypalIcon} alt="paypal" className={classes.icon} />
        Pay With PayPal
      </Button>
    </Box>
  );
};

export default ButtonSubmit;
