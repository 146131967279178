import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import gatsbyLinkResolver from '~prismic/utils/link-resolver';
import TopSearchesList from '~components/search/TopSearchesList';

import Playlists from './Playlists';

import Materials from './Materials';
import Videos from './Videos';
import PodcastEpisodes from './PodcastEpisodes';
import PodcastPlaylists from './PodcastPlaylists';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'flex-start'
  },
  tabsRoot: {
    marginBottom: '15px',
    maxWidth: '100%'
  },
  root: {
    padding: '0px !important',
    marginRight: '40px !important',
    minWidth: 'unset'
  },
  indicator: {
    height: '3px'
  },
  searchTermTextOverflow: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  topSearchesLink: {
    fontSize: '18px',
    lineHeight: '25px'
  },
  topSearchesLi: {
    padding: '10px 5px'
  }
});

const enrichWithUrl = doc => ({
  ...doc,
  originalUrl: doc.url,
  // All the links in the search results should be redirected to the members site
  // We cannot use raw URL objects because Gatsby `Link` component does not support them, only strings
  url: new URL(gatsbyLinkResolver(doc, null), process.env.GATSBY_MEMBERS_SITE_URL).toString()
});

const SEARCH_RESULT_TYPE = {
  VIDEO: 0,
  PLAYLIST: 1,
  MATERIAL: 2,
  PODCAST_EPISODE: 3,
  PODCAST_PLAYLIST: 4,
  TOP_SEARCH: 5
};

const SearchResults = ({ data, searchTerm, isLoading, isAdmin, topSearchesData }) => {
  const classes = useStyles();
  const [searchResultType, setSearchResultType] = React.useState(SEARCH_RESULT_TYPE.PLAYLIST);

  const handleTabSwitch = (event, resultType) => {
    setSearchResultType(resultType);
  };

  const itemsFound = data.result.length || 0;

  const compareByRelevance = (left, right) => right.relevance - left.relevance;
  const materials = data.result.filter(item => item.type === 'material');
  materials.sort(compareByRelevance);

  useEffect(() => {
    // Let's set the active tab to podcast playlists when there is at least a single result with the type podcast_playlist
    if (data.result && data.result.filter(item => item.type === 'podcast_playlist').length > 0) {
      setSearchResultType(SEARCH_RESULT_TYPE.PODCAST_PLAYLIST);
    }
  }, [data.result]);

  // HACK: we skip "Into the Verse" playlist videos, because client-side redirects don't work for some reason
  // and we cannot remove these videos from the search index yet - mobile apps require this playlist to exist.
  // We do the same with the "A Book Like No Other" and "Meaningful Judaism" playlist videos.
  // All these playlists are podcast playlists actually.

  const BLNO_PLAYLIST_ID = 'Y9O-xRAAACQALOff';
  const BLNO_SEASON_1_PLAYLIST_ID = 'Za7i3RAAACAAvnkZ';
  const BLNO_SEASON_3_PLAYLIST_ID = 'Ze2bYhAAACgA4ui7';
  const ITV_PLAYLIST_ID = 'YlBroxAAACAA-PEv';
  const MJ_PLAYLIST_ID = 'ZE4RMhEAAH-qpZy5';
  const playlistsToSkip = [
    BLNO_PLAYLIST_ID,
    BLNO_SEASON_1_PLAYLIST_ID,
    BLNO_SEASON_3_PLAYLIST_ID,
    ITV_PLAYLIST_ID,
    MJ_PLAYLIST_ID
  ];
  const videos = data.result
    .filter(item => item.type === 'video' && playlistsToSkip.includes(item.playlist_id) === false) // HACK
    .map(enrichWithUrl);
  videos.sort(compareByRelevance);
  const playlists = data.result
    .filter(item => item.type === 'playlist' && playlistsToSkip.includes(item.id) === false) // HACK
    .map(enrichWithUrl);
  playlists.sort(compareByRelevance);
  const podcastPlaylists = data.result
    .filter(item => item.type === 'podcast_playlist')
    .map(enrichWithUrl);
  podcastPlaylists.sort(compareByRelevance);
  const podcastEpisodes = data.result
    .filter(item => item.type === 'podcast_episode')
    .map(document => ({ ...document, data: { podcast_playlist: { uid: document.playlist_uid } } }))
    .map(enrichWithUrl);
  podcastEpisodes.sort(compareByRelevance);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <p className={classes.searchTermTextOverflow}>
        Found {itemsFound} results for {searchTerm}
      </p>
      <Tabs
        value={searchResultType}
        onChange={handleTabSwitch}
        variant="scrollable"
        indicatorColor="primary"
        classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
      >
        <Tab
          label={`Videos (${videos.length})`}
          className={classes.root}
          classes={{ wrapper: classes.wrapper }}
        />
        <Tab
          label={`Series (${playlists.length})`}
          className={classes.root}
          classes={{ wrapper: classes.wrapper }}
        />
        <Tab
          label={`Materials (${materials.length})`}
          className={classes.root}
          classes={{ wrapper: classes.wrapper }}
        />
        <Tab
          label={`Podcast Episodes (${podcastEpisodes.length})`}
          className={classes.root}
          classes={{ wrapper: classes.wrapper }}
        />
        <Tab
          label={`Podcast Playlists (${podcastPlaylists.length})`}
          className={classes.root}
          classes={{ wrapper: classes.wrapper }}
        />

        {isAdmin && (
          <Tab
            label="Top 100 Searches"
            className={classes.root}
            classes={{ wrapper: classes.wrapper }}
          />
        )}
      </Tabs>
      {searchResultType === SEARCH_RESULT_TYPE.VIDEO && (
        <Videos videos={videos} searchTerm={searchTerm} />
      )}
      {searchResultType === SEARCH_RESULT_TYPE.PLAYLIST && (
        <Playlists playlists={playlists} searchTerm={searchTerm} />
      )}
      {searchResultType === SEARCH_RESULT_TYPE.MATERIAL && (
        <Materials materials={materials} searchTerm={searchTerm} />
      )}
      {searchResultType === SEARCH_RESULT_TYPE.PODCAST_EPISODE && (
        <PodcastEpisodes episodes={podcastEpisodes} searchTerm={searchTerm} />
      )}
      {searchResultType === SEARCH_RESULT_TYPE.PODCAST_PLAYLIST && (
        <PodcastPlaylists playlists={podcastPlaylists} searchTerm={searchTerm} />
      )}
      {searchResultType === SEARCH_RESULT_TYPE.TOP_SEARCH && (
        <TopSearchesList
          topSearchesData={topSearchesData}
          classes={classes}
          setTabsValue={setSearchResultType}
        />
      )}
    </>
  );
};

SearchResults.propTypes = {
  data: PropTypes.shape({ result: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
  searchTerm: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  topSearchesData: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string,
      count: PropTypes.string
    })
  )
};
SearchResults.defaultProps = {
  isLoading: false,
  topSearchesData: null,
  isAdmin: false
};

export default SearchResults;
