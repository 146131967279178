import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PlayCircleIcon from '@material-ui/icons/PlayCircleFilledRounded';

const useStyles = makeStyles({
  iconStyle: {
    fontSize: '72px',
    marginRight: '14px',
    cursor: 'pointer'
  },
  buttonTitle: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500'
  },
  buttonContainer: {
    cursor: 'pointer',
    marginLeft: '-6px'
  }
});

const CurationDialogButton = ({ buttonText, handleClickOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <Box
      onClick={handleClickOpen}
      mt={4}
      display="inline-flex"
      alignItems="center"
      className={classes.buttonContainer}
      {...rest}
    >
      <PlayCircleIcon color="inherit" className={classes.iconStyle} />
      <Typography variant="subtitle1" className={classes.buttonTitle}>
        {buttonText}
      </Typography>
    </Box>
  );
};

CurationDialogButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClickOpen: PropTypes.func
};

CurationDialogButton.defaultProps = {
  handleClickOpen: null
};

export default CurationDialogButton;
