import React from 'react';
import PropTypes from 'prop-types';
import ClampLines from 'react-clamp-lines';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  excerpt: {
    '& p': {
      wordBreak: 'break-word'
    },
    '& button': {
      background: 'none',
      border: 0,
      color: theme.palette.text.primary,
      cursor: 'pointer',
      padding: theme.spacing(0, 0, 0, 0),
      fontSize: theme.typography.fontSize,
      '&:hover': {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      },
      '&:focus': {
        outline: 0
      }
    }
  }
}));

const Excerpt = ({ description, ...rest }) => {
  const classes = useStyles();
  return (
    <Typography
      component={ClampLines}
      id={Date.now().toString()}
      variant="subtitle1"
      text={description}
      lines="3"
      moreText="Read More"
      lessText="Read Less"
      innerElement="p"
      className={classes.excerpt}
      {...rest}
    />
  );
};

Excerpt.propTypes = {
  description: PropTypes.string.isRequired
};

export default Excerpt;
