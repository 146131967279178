/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { boolFlagFromString } from '~utils/playlist-helpers';
import Container from '~components/Container';
import { HeroTitle, HeroSubtitle } from '~components/Typography';
import {
  LabelOverlay,
  LabelNew,
  LabelPremium,
  LabelProducer,
  LabelAudio
} from '~components/playlist-card/PlaylistLabels';
import { defaultImageSizes } from '../../../utils/image-sizes';

const StyledHeroTitle = styled(HeroTitle)`
  color: ${props => props.text_color};
`;

const StyledHeroSubtitle = styled(HeroSubtitle)`
  color: ${props => props.text_color};
`;

const StyledButton = styled(Button)`
  background-color: ${props => `${props.backgroundColor} !important` || '#2c7abf !important'};
  color: ${props => `${props.textColor} !important` || '#000'};
  &:hover {
    background-color: ${props =>
      props.backgroundColor ? `${props.backgroundColor}c2 !important` : '#2c7abfc2 !important'};
  }
`;

const StyledLabelOverlay = styled(LabelOverlay)`
  margin-bottom: 4px;
  margin-left: unset;
  position: unset;
  & > * {
    margin-right: 8px;
  }
`;

const StyledHeroSpacingDiv = styled.div`
  padding: 16px 0px;
  @media (max-width: 960px) {
    padding: 24px 0px;
  }
`;

const StyledButtonSpacingDiv = styled.div`
  margin-top: 16px;
`;

const StyledGridContainer = styled(Grid)`
  flex-direction: row;
  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }
`;

const StyledGridImageItem = styled(Grid)`
  min-height: 300px;
  @media (max-width: 700px) {
    min-height: 200px;
    padding: 20px 0;
  }
`;

const HeroTextImage = ({
  title,
  subtitle,
  link,
  link_title,
  text_color,
  foreground_image,
  background_color,
  background_image,
  hero_button_background_color,
  hero_button_text_color,
  isHeroImageLink,
  labels
}) => {
  const { label_is_audio, label_is_new, label_is_premium, label_is_producer } = labels;
  const heroImageUrl = defaultImageSizes(foreground_image?.url);
  const [foregroundImage, setForegroundImage] = useState(heroImageUrl.desktop);
  const isAudio = boolFlagFromString(label_is_audio);
  const isNew = boolFlagFromString(label_is_new);
  const isPremium = boolFlagFromString(label_is_premium);
  const isProducer = boolFlagFromString(label_is_producer);

  useEffect(() => {
    if (foreground_image?.url.includes('w=1920&h=1080')) {
      if (window.innerWidth < 1280) {
        setForegroundImage(heroImageUrl.tablet);
      }
      if (window.innerWidth < 960) {
        setForegroundImage(heroImageUrl.mobile);
      }
    }
  }, [foreground_image?.url]);

  return (
    <Container
      center
      size={50}
      background={background_image?.url ? `url(${background_image.url})` : background_color}
    >
      <StyledHeroSpacingDiv>
        <StyledGridContainer container alignItems="center" justify="space-between">
          <Grid item xs={12} md={6} lg={6}>
            <StyledLabelOverlay>
              {isNew && <LabelNew />}
              {isAudio && <LabelAudio />}
              {isPremium && <LabelPremium />}
              {isProducer && <LabelProducer />}
            </StyledLabelOverlay>
            <StyledHeroTitle variant="h3" text_color={text_color}>
              {title}
            </StyledHeroTitle>

            {subtitle && (
              <StyledHeroSubtitle text_color={text_color}>{subtitle}</StyledHeroSubtitle>
            )}

            <StyledButtonSpacingDiv>
              <StyledButton
                component={Link}
                to={link.url}
                variant="contained"
                backgroundColor={hero_button_background_color}
                textColor={hero_button_text_color}
                size="large"
              >
                {link_title}
              </StyledButton>
            </StyledButtonSpacingDiv>
          </Grid>

          <StyledGridImageItem item xs={12} md={6} lg={6}>
            {isHeroImageLink && (
              <Link to={link.url} aria-label="Featured Playlist">
                <img
                  src={foregroundImage}
                  alt={foreground_image?.alt || ''}
                  style={{ display: 'flex', maxWidth: '100%' }}
                />
              </Link>
            )}

            {!isHeroImageLink && (
              <img
                src={foreground_image?.localFile?.url}
                alt={foreground_image?.alt || ''}
                style={{ display: 'flex', maxWidth: '100%' }}
              />
            )}
          </StyledGridImageItem>
        </StyledGridContainer>
      </StyledHeroSpacingDiv>
    </Container>
  );
};

HeroTextImage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  link: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
  link_title: PropTypes.string,
  text_color: PropTypes.string,
  foreground_image: PropTypes.shape({
    alt: PropTypes.string,
    fluid: PropTypes.string.isRequired
  }).isRequired,
  background_color: PropTypes.string,
  background_image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  labels: PropTypes.shape({
    label_is_audio: PropTypes.string,
    label_is_new: PropTypes.string,
    label_is_premium: PropTypes.string,
    label_is_producer: PropTypes.string
  }),
  isHeroImageLink: PropTypes.bool
};

HeroTextImage.defaultProps = {
  subtitle: null,
  text_color: '#ffffff',
  link_title: 'Watch Now',
  background_color: '#3787FB',
  background_image: null,
  isHeroImageLink: true,
  labels: {}
};

export default HeroTextImage;
