import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Container from '~components/Container';

const Summary = ({ children }) => (
  <Box component={Container} center size={50} pt={4} pb={4}>
    <Grid container direction="row" justify="space-between" alignItems="center" spacing={4}>
      {children &&
        children.map(child => (
          <Grid
            item
            xs={12}
            sm={3}
            key={child && child.props && `holiday-summary-key-${child.props.title}`}
          >
            {child}
          </Grid>
        ))}
    </Grid>
  </Box>
);

Summary.propTypes = {
  children: PropTypes.node.isRequired
};

export default Summary;
