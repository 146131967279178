import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { navigate } from 'gatsby';
import { lineFix } from '~utils/popup-helpers';

import { Container, FormControl, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '300px',
    margin: '0px 25px 0px 0px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #C5C5C5',
    borderRadius: '18px',
    boxShadow: '0px 4px 4px 0px #C5C5C5'
  }
});

const SeasonDropdown = ({ seasons, selectedSeasonUid }) => {
  const classes = useStyles();

  const handleChange = event => {
    navigate(`?season=${event.target.value}`);
  };

  return (
    <Container className={classes.root}>
      <FormControl fullWidth>
        <Select value={selectedSeasonUid} onChange={handleChange} disableUnderline>
          {seasons &&
            seasons.map(season => (
              <MenuItem key={season.uid} value={season.uid}>
                {lineFix(season.title)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Container>
  );
};

SeasonDropdown.propTypes = {
  seasons: PropTypes.arrayOf({
    title: PropTypes.string
  }).isRequired,
  selectedSeasonUid: PropTypes.string
};

SeasonDropdown.defaultProps = {
  selectedSeasonUid: ''
};

export default SeasonDropdown;
