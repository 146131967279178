import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DoneIcon from '@material-ui/icons/Done';
import { navigate } from 'gatsby';
import ClickableText from '~components/buttons/ClickableText';
import useCheckout from '~hooks/useCheckout';
import products from '~utils/products';
import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(() => ({
  root: props => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '292px',
    height: props.isFeatured ? '561px' : '520px',
    borderRadius: '10px',
    overflow: 'hidden',
    border: props.isFeatured ? `2px solid ${props.borderColor}` : `1px solid ${props.borderColor}`,
    backgroundColor: props.bgColor
  }),
  topContent: {
    width: '100%'
  },
  bottomContent: {
    display: 'flex',
    marginBottom: '23px'
  },
  packageName: {
    color: props => props.textColor,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '150%',
    marginTop: '40px',
    marginBottom: '23px'
  },
  priceText: {
    color: props => props.textColor,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '22px',
    fontWeight: '600',
    lineHeight: '150%',
    marginTop: '0px',
    marginBottom: '0px',
    '& > span': {
      fontSize: '20px',
      fontWeight: '400'
    }
  },
  featuredText: props => ({
    color: props.buttonTextColor,
    backgroundColor: props.buttonColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '41px',
    '& > p': {
      fontSize: '16px',
      lineHeight: '100%',
      textAlign: 'center',
      fontFamily: 'Inter'
    }
  }),
  heartIcon: {
    fontSize: '18px',
    lineHeight: '16px',
    color: props => props.buttonTextColor
  },
  doneIcon: {
    fontSize: '22px',
    lineHeight: '22px',
    color: '#47AF71'
  },
  perksContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '46px',
    marginLeft: '23px',
    maxWidth: '240px'
  },
  perk: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    '& > p': {
      fontSize: '14px',
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontWeight: '500',
      margin: '0px',
      color: props => props.textColor
    }
  },
  ctaButton: props => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    padding: '16px 36px',
    fontWeight: '600',
    fontFamily: 'Inter',
    borderRadius: '10px',
    width: 'fit-content',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    lineHeight: '16px',
    backgroundColor: props.buttonColor,
    color: props.buttonTextColor
  })
}));

const PricingCard = ({
  packageName,
  perks,
  productId,
  isYearly,
  buttonText,
  isFeatured,
  featuredText,
  textColor,
  borderColor,
  bgColor,
  buttonColor,
  buttonTextColor
}) => {
  const classes = useStyles({
    isFeatured,
    textColor,
    borderColor,
    bgColor,
    buttonColor,
    buttonTextColor
  });
  const { isMarketingSite } = useConversionFramework();
  const [, setCheckoutState] = useCheckout();
  const product = useMemo(() => products.find(({ id }) => id === productId), [productId]);
  const [price, period] = useMemo(() => {
    if (isYearly) {
      return [product?.yearlyPrice, ' /yr'];
    }
    return [product?.monthlyPrice, ' /month'];
  }, [product, isYearly]);

  const onCtaClick = useCallback(() => {
    const billingCycle = isYearly ? 'year' : 'month';
    if (product && isMarketingSite) {
      redirectToMembersCheckout(product, billingCycle);
    } else if (product) {
      setCheckoutState({ product, billingCycle });
      navigate('/checkout');
    }
  }, [setCheckoutState, product, isYearly, isMarketingSite]);

  return (
    <div className={classes.root}>
      <div className={classes.topContent}>
        {isFeatured && featuredText && (
          <div className={classes.featuredText}>
            <p>{featuredText}</p>
          </div>
        )}
        <h4 className={classes.packageName}>{packageName}</h4>
        <h3 className={classes.priceText}>
          ${price}
          <span>{period}</span>
        </h3>
        <div className={classes.perksContainer}>
          {perks.map(perk => (
            <div key={`perk_${perk}`} className={classes.perk}>
              <DoneIcon className={classes.doneIcon} />
              <p>{perk}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.bottomContent}>
        <ClickableText onClick={onCtaClick} className={classes.ctaButton}>
          <FavoriteIcon color="secondary" fontSize="small" className={classes.heartIcon} />
          {buttonText}
        </ClickableText>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  isFeatured: PropTypes.bool,
  featuredText: PropTypes.string,
  productId: PropTypes.number.isRequired,
  isYearly: PropTypes.bool,
  packageName: PropTypes.string.isRequired,
  perks: PropTypes.arrayOf(PropTypes.string).isRequired,
  buttonText: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string
};

PricingCard.defaultProps = {
  isFeatured: false,
  featuredText: '',
  isYearly: true,
  buttonText: 'Become a Patron',
  textColor: '#000000',
  borderColor: '#D1D9DD',
  bgColor: '#F9F5F0',
  buttonColor: '#3C3284',
  buttonTextColor: '#FFFFFF'
};

export default PricingCard;
