import React from 'react';
import PropTypes from 'prop-types';
import StripeCheckout from 'react-stripe-checkout';

import AlephBetaLogo from '~images/site_logo_raw.png';

const STRIPE_PUBLIC_KEY = process.env.GATSBY_STRIPE_PUBLIC_KEY;

const params = {
  stripeKey: STRIPE_PUBLIC_KEY,
  name: 'Aleph Beta',
  image: AlephBetaLogo,
  panelLabel: 'Donate',
  currency: 'USD',
  locale: 'auto',
  allowRememberMe: false,
  zipCode: false
};

const StripeExpressCheckoutButton = ({ children, username, amount, onSuccess, ...rest }) => {
  const amountInCents = amount * 100;

  return (
    <StripeCheckout
      email={username}
      amount={amountInCents}
      description={`Donation of $${amount}`}
      token={onSuccess}
      {...params}
      {...rest}
    >
      {children}
    </StripeCheckout>
  );
};

StripeExpressCheckoutButton.propTypes = {
  children: PropTypes.node.isRequired,
  username: PropTypes.string.isRequired,
  amount: PropTypes.number,
  onSuccess: PropTypes.func.isRequired
};

StripeExpressCheckoutButton.defaultProps = {
  amount: null
};

export default StripeExpressCheckoutButton;
