/* eslint-disable import/prefer-default-export, react/prop-types, no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { nanoid } from 'nanoid';
import { loadableReady } from '@loadable/component';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '~src/styles.css';
import linkResolver from '~src/prismic/utils/link-resolver';
import { UserContextProvider } from '~context/UserContext/UserSessionContext';
import { trackLandingPageView, trackPageView } from '~utils/analytics';
import {
  isPreviousPathOfInterest,
  setPreviousLocationData,
  persistMarketingSiteNonRedirectPath
} from '~utils/previous-path-cookie';
import componentResolver from '~src/prismic/utils/component-resolver';
import GlobalContextProvider from '~context/GlobalContext/GlobalContextProvider';
import GlobalHeaders from '~layout/GlobalHeaders';
import { MaybeDebugUI } from '~components/debug/MaybeDebugUI';
import { initializeMixpanel, trackMixpanelPageView } from '~utils/mixpanel-helper';
import App from './src/App';
import Theme from './src/theme';

let isFirstRouteUpdate = true;

export const wrapRootElement = ({ element }) => (
  <UserContextProvider>
    <GlobalHeaders />
    <GlobalContextProvider>
      <Theme>
        <PrismicPreviewProvider
          repositoryConfigs={[
            {
              repositoryName: 'aleph-beta',
              linkResolver,
              componentResolver
            }
          ]}
        >
          <App>{element}</App>
        </PrismicPreviewProvider>
      </Theme>
    </GlobalContextProvider>
  </UserContextProvider>
);

export const wrapPageElement = ({ element }) => <MaybeDebugUI>{element}</MaybeDebugUI>;

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
}

export const onClientEntry = () => {
  // Mixpanel
  initializeMixpanel();
  const fbEventId = nanoid();
  // Internal landing page view tracking
  trackLandingPageView({ fbEventId });
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && window.fbq && typeof window.fbq === `function`) {
    window.fbq('track', 'ViewContent', {}, { eventID: fbEventId });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Google Optimize activation
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'optimize.activate' });

  // track page view in mixpanel
  trackMixpanelPageView(!prevLocation);

  // Internal page view tracking
  if (!isFirstRouteUpdate) {
    const fbEventId = nanoid();
    trackPageView({ location, fbEventId });
    // Don't track while developing.
    if (process.env.NODE_ENV === `production` && window.fbq && typeof window.fbq === `function`) {
      window.fbq('track', 'ViewContent', {}, { eventID: fbEventId });
    }
  }
  isFirstRouteUpdate = false;

  // Global solution to store previously visited pages
  if (isPreviousPathOfInterest(location, prevLocation)) {
    const { pathname, state } = prevLocation;
    setPreviousLocationData({ pathname, state });
  }

  persistMarketingSiteNonRedirectPath(location?.pathname, location.state);

  /* Special hack copied from the `gatsby-plugin-google-tagmanager`.
   * We send special event to GTM after a delay in hope that Helmet would be able to finish its job in this time.
   * GTM will fire a `page_view` event in GA in response to this event.
   * We need this code in here because the delay in `gatsby-plugin-google-tagmanager` is 50ms which is not enough for our Helmet setup.
   */
  setTimeout(() => {
    window.dataLayer.push({
      event: 'page_view_delayed_by_300ms'
    });
  }, 300);
};
