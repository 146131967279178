import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import GiftCardIconActive from '~images/playlist/gift-button-active.png';
import GiftCardIconUnderPopper from '~images/playlist/gift-button-under-popper.png';
import GiftCardIconInactive from '~images/playlist/gift-button-inactive.png';
import ShareIconActive from '~images/playlist/share-button-active.png';
import ShareIconInactive from '~images/playlist/share-button-inactive.png';

import Popper from '~components/popper/Popper';

import request from '~utils/request';
import { SHARE_PLAYLIST } from '~src/hooks/useApi';

const useStyles = makeStyles(() => ({
  actionButton: {
    padding: 'unset',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  shareOutlineIcon: {
    margin: '4px',
    opacity: '0.7',
    width: '36px',
    height: '36px'
  },

  popperBody: {
    zIndex: 1300,
    padding: '22px',
    backgroundColor: 'white',
    border: '2px solid #e4e4e4',
    borderRadius: '5px'
  },

  shareButton: {
    paddingTop: '5px',
    paddingBottom: '5px',
    display: 'flex',
    justifyContent: 'flex-start',

    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  textStyles: {
    color: '#606060',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '18px'
  },

  socialBg: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    fontSize: '30px',
    padding: '4px',
    color: 'white'
  },

  fixButtonLineHeight: {
    lineHeight: '0 !important',
    display: 'flex',
    alignItems: 'center'
  },

  socialButton: {
    outline: '0',
    marginRight: '1rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7'
    }
  },

  linkCopiedText: {
    color: '#419A4D',
    display: 'flex',
    alignItems: 'center'
  },
  checkIcon: {
    fontSize: '1.2rem',
    marginLeft: '5px'
  }
}));

const VideoActionBar = ({
  title,
  videoTitle,
  contentType,
  showGiftPopup,
  giftAction: isGiftable,
  shareAction: isShareable
}) => {
  const classes = useStyles();
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [popperId, setPopperId] = useState();
  const [isUrlCopiedToClipboard, setUrlCopiedToClipboard] = useState(false);

  const { session } = useUserContext();
  const { isPrivilegedMember, giftTokensMonthlyLimit, giftTokensLeft } = session;

  const browser = typeof window !== 'undefined' && window;

  const currentPageUrl = browser && browser?.location?.href;

  const showPopper = event => {
    setPopperAnchor(event.currentTarget);
    setPopperId(event.currentTarget.id);
  };

  const hidePopper = () => {
    setPopperAnchor(null);
    setUrlCopiedToClipboard(false);
    setPopperId();
  };

  const copyUrlToClipboard = () => {
    window.navigator.clipboard.writeText(currentPageUrl);
    setUrlCopiedToClipboard(true);
  };

  const recordSharing = method =>
    isGiftable &&
    request(SHARE_PLAYLIST({ method, url: currentPageUrl })).catch(() => {
      /* Explicitly do nothing */
    });

  const handleGiftClick = () => {
    showGiftPopup();
  };

  const formattedContentType = useMemo(
    () => (!contentType.endsWith('s') ? `${contentType}s` : contentType),
    [contentType]
  );

  return (
    <>
      {isShareable && (
        <IconButton
          id="share"
          onClick={showPopper}
          className={classes.actionButton}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          <img
            src={popperAnchor?.id === 'share' ? ShareIconActive : ShareIconInactive}
            className={classes.shareOutlineIcon}
            alt="Share"
          />
        </IconButton>
      )}
      {isGiftable && (
        <IconButton
          id="gift"
          onMouseEnter={showPopper}
          onMouseLeave={hidePopper}
          onClick={handleGiftClick}
          className={classes.actionButton}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          <img
            src={popperAnchor?.id === 'gift' ? GiftCardIconActive : GiftCardIconInactive}
            className={classes.shareOutlineIcon}
            alt="Gift"
          />
        </IconButton>
      )}
      <Popper
        popperAnchorRef={popperAnchor}
        onClickAway={hidePopper}
        placement={popperId === 'gift' ? 'bottom' : 'bottom-end'}
      >
        <Box className={classes.popperBody}>
          {popperId && popperId === 'share' ? (
            <>
              <Typography
                component="h3"
                style={{ fontWeight: '700', fontSize: '18px' }}
                gutterBottom
              >
                Share
              </Typography>

              <Box
                className={classes.shareButton}
                onClick={() => {
                  copyUrlToClipboard();
                  recordSharing('clipboard');
                }}
              >
                <LinkIcon className={`${classes.socialButton} ${classes.socialBg}`} />
                {isUrlCopiedToClipboard ? (
                  <Typography component="h4" className={classes.linkCopiedText}>
                    Link Copied <CheckCircleIcon className={classes.checkIcon} />
                  </Typography>
                ) : (
                  <Typography component="h4" className={classes.textStyles}>
                    Copy Link
                  </Typography>
                )}
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <FacebookShareButton
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('facebook');
                  }}
                >
                  <FacebookIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Share on Facebook
                  </Typography>
                </FacebookShareButton>
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <TwitterShareButton
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('twitter');
                  }}
                >
                  <TwitterIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Share on Twitter
                  </Typography>
                </TwitterShareButton>
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <EmailShareButton
                  subject="I think you'd enjoy this..."
                  body={`Hey,\r\n\r\nI found this Aleph Beta ${contentType.toLowerCase()} really incredible and I wanted to share it with you! Here are the details...\r\n\r\n\r\n${title}\r\n\r\n${videoTitle}\r\n\r\n`}
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('email');
                  }}
                >
                  <EmailIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Email
                  </Typography>
                </EmailShareButton>
              </Box>

              <Divider />

              <Box className={classes.shareButton}>
                <WhatsappShareButton
                  url={currentPageUrl}
                  className={classes.fixButtonLineHeight}
                  beforeOnClick={() => {
                    recordSharing('whatsapp');
                  }}
                >
                  <WhatsappIcon className={classes.socialButton} size={32} round />
                  <Typography component="h4" className={classes.textStyles}>
                    Share on WhatsApp
                  </Typography>
                </WhatsappShareButton>
              </Box>

              {isGiftable && (
                <>
                  <Divider />

                  <Box className={classes.shareButton} onClick={handleGiftClick}>
                    <Box
                      component="div"
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="justify-start"
                    >
                      <img
                        src={GiftCardIconUnderPopper}
                        className={classes.socialButton}
                        width="32"
                        height="32"
                        alt="Gift"
                      />

                      <Box style={{ maxWidth: 210 }}>
                        <Typography component="h4" className={classes.textStyles}>
                          Gift This{' '}
                          <span style={{ textTransform: 'capitalize' }}>{contentType}</span>
                        </Typography>
                        <Typography component="p" style={{ fontSize: 12 }}>
                          Are you moved by this {contentType} and want to share that feeling with
                          others? As an Aleph Beta member, you can{' '}
                          <strong>
                            gift {giftTokensMonthlyLimit || 3} {formattedContentType}
                          </strong>{' '}
                          each month to a friend. Even if they&lsquo;re not an Aleph Beta
                          subscriber, they&lsquo;ll be able to watch without any blockers.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : (
            <Box style={{ maxWidth: 210 }}>
              <Grid container direction="row" alignItems="baseline" justify="space-between">
                <Grid item xs="auto">
                  <Typography
                    component="h3"
                    style={{
                      fontWeight: '700',
                      fontSize: '18px'
                    }}
                    gutterBottom
                  >
                    Gift This <span style={{ textTransform: 'capitalize' }}>{contentType}</span>{' '}
                  </Typography>
                </Grid>
                {isPrivilegedMember && (
                  <Grid item xs="auto">
                    <Typography
                      component="h3"
                      style={{
                        color: '#848484',
                        fontSize: '12px'
                      }}
                      gutterBottom
                    >
                      {giftTokensLeft}/{giftTokensMonthlyLimit}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography component="p" style={{ fontSize: 12 }}>
                    As an Aleph Beta member, you can{' '}
                    <strong>
                      gift {giftTokensMonthlyLimit} {formattedContentType}
                    </strong>{' '}
                    each month to a friend. Even if they&lsquo;re not an Aleph Beta member,
                    they&lsquo;ll be able to watch without any blockers.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Popper>
    </>
  );
};

VideoActionBar.propTypes = {
  title: PropTypes.string,
  contentType: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  showGiftPopup: PropTypes.func,
  shareAction: PropTypes.bool,
  giftAction: PropTypes.bool
};

VideoActionBar.defaultProps = {
  title: 'Check out Aleph Beta!',
  shareAction: true,
  showGiftPopup: () => {},
  giftAction: true
};

export default VideoActionBar;
