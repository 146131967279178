import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import RichText from '~components/RichText';
import Link from '~components/Link';
import PrimaryColorAnimatedPlayButton from '~components/PrimaryColorAnimatedButton';
import { lineFix } from '~utils/popup-helpers';

import { boolFlagFromString } from '~utils/playlist-helpers';
import {
  LabelNew,
  LabelAudio,
  LabelPremium,
  LabelProducer,
  LabelOverlay
} from '~components/playlist-card/PlaylistLabels';
import MaskImage from '~images/mask-image.png';
import LgTest from '~images/lg-mask-image.png';
import MdTest from '~images/md-mask-test.png';
import MobileMask from '~images/mobile-mask-image.png';

import Container from '~components/Container';

import HolidayHeroContainer from '~modules/holiday/components/HolidayHeroContainer';

const useStyles = makeStyles(theme => ({
  maskImage: props => ({
    display: 'flex',
    width: '54vw',
    position: 'absolute',
    left: '-4vw',
    top: 0,
    marginLeft: '-1px',
    marginTop: '-8px',
    height: props.maskHeight && props.maskHeight > 650 ? `${props.maskHeight}px` : '650px',
    [theme.breakpoints.up('lg')]: {
      width: '57vw'
    }
  })
}));

const BoldedSubtitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  text-align: left;
  margin: 0;
  margin-top: 20px;
  padding: 10px;
  display: inline-flex;
  border-radius: 3px;
  & > * {
    margin: 0;
  }
  @media (max-width: 992px) {
    padding-right: unset;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const StyledLabelsOverlay = styled(LabelOverlay)`
  position: unset;
  margin-bottom: 20px;
`;

const StyledMobileHeroImage = styled.img`
  display: flex;
  width: 100vw;
  margin-left: -24px;
  margin-bottom: -8px;
  @media (max-width: 600px) {
    margin-left: -16px;
  }
`;

const StyledDiv = styled.div`
  position: relative;
`;

const StyledGrid = styled(Grid)`
  min-height: 650px;
  @media (max-width: 959px) {
    min-height: unset;
  }
`;

const VerticalHolidayHero = ({
  hero_background_image,
  overline_text,
  hero_title,
  hero_subtitle,
  hero_featured_video,
  label_text,
  label_text_color,
  label_text_background_color
}) => {
  const [maskHeight, setMaskHeight] = useState();
  const classes = useStyles({ maskHeight });
  const { dispatch, session } = useUserContext();
  const { isUserStateLoading } = session;

  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const { url, document } = hero_featured_video;
  const { label_is_new, label_is_audio, label_is_premium, label_is_producer } =
    document && document.data;

  const isAudio = boolFlagFromString(label_is_audio);
  const isNew = boolFlagFromString(label_is_new);
  const isPremium = boolFlagFromString(label_is_premium);
  const isProducer = boolFlagFromString(label_is_producer);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setMaskHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const calcHeroImage = () => {
    if (isSmallerThanMd) {
      return null;
    }
    if (isMd) {
      return MdTest;
    }

    if (isXl) {
      return MaskImage;
    }
    return LgTest;
  };

  const isTishaBavHome = window && window.location.pathname === '/';

  useEffect(() => {
    if (isTishaBavHome && !isUserStateLoading) {
      dispatch({ type: 'USER_UPDATE', payload: { tishaBavHomeHeroFeaturedUrl: url } });
    }
  }, [dispatch, url, isUserStateLoading, isTishaBavHome]);

  return (
    <>
      {isSmallerThanMd && (
        <Container background={`url("${hero_background_image.url}")`}>
          <StyledDiv>
            <StyledMobileHeroImage src={MobileMask} alt="Mask" />
            <GatsbyLink to={url}>
              <PrimaryColorAnimatedPlayButton />
            </GatsbyLink>
          </StyledDiv>
        </Container>
      )}
      <HolidayHeroContainer background={!isSmallerThanMd && `url("${hero_background_image.url}")`}>
        {!isSmallerThanMd && (
          <StyledLink to={url}>
            <PrimaryColorAnimatedPlayButton />
          </StyledLink>
        )}

        <StyledGrid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          ref={measuredRef}
        >
          <Grid item xs={12} md={6} style={{ zIndex: 2 }}>
            <RichText
              html={overline_text.html}
              verticalSpacing={0}
              mode={isTishaBavHome ? 'blueText' : 'holidayHeroOverlineHeading'}
            />

            <RichText html={hero_title.html} mode="holidayHeroTitle" />
            <RichText html={hero_subtitle.html} mode="holidayHeroSubtitle" />
            <StyledLabelsOverlay>
              {isNew && <LabelNew />}
              {isAudio && <LabelAudio />}
              {isPremium && <LabelPremium />}
              {isProducer && <LabelProducer />}
            </StyledLabelsOverlay>
            {label_text && label_text.html && (
              <BoldedSubtitle
                style={{ color: label_text_color, backgroundColor: label_text_background_color }}
              >
                {lineFix(label_text.html)}
              </BoldedSubtitle>
            )}
          </Grid>
          {!isSmallerThanMd && (
            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
              <img src={calcHeroImage()} alt="Mask" className={classes.maskImage} />
            </Grid>
          )}
        </StyledGrid>
      </HolidayHeroContainer>
    </>
  );
};
VerticalHolidayHero.propTypes = {
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  overline_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_featured_video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    document: PropTypes.shape({
      data: PropTypes.shape({
        label_is_new: PropTypes.string.isRequired,
        label_is_audio: PropTypes.string.isRequired,
        label_is_premium: PropTypes.string.isRequired,
        label_is_producer: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  label_text: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  label_text_color: PropTypes.string,
  label_text_background_color: PropTypes.string
};

VerticalHolidayHero.defaultProps = {
  label_text_color: '#000',
  label_text_background_color: '#fff'
};

export default VerticalHolidayHero;
