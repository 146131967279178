import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { getCardFromPlaylist } from '~utils/playlist-helpers';

const Container = loadable(() => import('~components/Container'));
const GiftAcknowledgeBanner = loadable(() => import('./gift-acknowledge-banner'));
const VideoSection = loadable(() => import('./marketing/VideoSection'));
const VideoDescription = loadable(() => import('./marketing/VideoDescription'));
const RelatedContentCarousel = loadable(() => import('~components/RelatedContentCarousel'));
const AboutBoxSlice = loadable(() => import('~components/AboutBoxSlice'));

const MarketingPlaylistLayout = ({
  isPlaying,
  seekToSecond,
  currentVideo,
  currentVideoIndex,
  numberOfVideos,
  handlers,
  popup,
  isPopupOpen,
  title,
  author,
  prismicId,
  thumbnail,
  isSingleVideo,
  uid,
  hasPromotionalVideo,
  promoVideoId,
  labels,
  senderFullName,
  isGiftPlaylist,
  giftToken,
  isGiftBannerOpen,
  suggestedPlaylists,
  trendingPlaylists,
  popularPlaylists,
  isSkipPromotionalButtonOpen,
  hasUserSufficientAccess
}) => {
  const {
    handleGiftThankYouClick,
    handleCloseGiftBanner,
    showGiftPopup,
    onReady,
    onStart,
    onProgress,
    onEnded,
    handleBlockingPopupClose,
    onPromotionalProgress,
    onPromotionEnded,
    toggleVideoPlayState,
    showContentBlockingPopup
  } = handlers;

  const relatedPlaylistCards = useMemo(
    () => (suggestedPlaylists || []).map(getCardFromPlaylist),
    [suggestedPlaylists]
  );
  const popularPlaylistCards = useMemo(
    () => (popularPlaylists || []).map(getCardFromPlaylist),
    [popularPlaylists]
  );
  const trendingPlaylistCards = useMemo(
    () => (trendingPlaylists || []).map(getCardFromPlaylist),
    [trendingPlaylists]
  );

  return (
    <Container center size={100} background="#0F0F0F">
      <VideoSection
        isPlaying={isPlaying}
        seekToSecond={seekToSecond}
        currentVideo={currentVideo}
        onReady={onReady}
        onStart={onStart}
        onProgress={onProgress}
        onEnded={onEnded}
        handleBlockingPopupClose={handleBlockingPopupClose}
        onPromotionalProgress={onPromotionalProgress}
        onPromotionEnded={onPromotionEnded}
        toggleVideoPlayState={toggleVideoPlayState}
        popup={popup}
        isPopupOpen={isPopupOpen}
        title={title}
        author={author}
        prismicId={prismicId}
        thumbnail={thumbnail}
        isSingleVideo={isSingleVideo}
        uid={uid}
        hasPromotionalVideo={hasPromotionalVideo}
        promoVideoId={promoVideoId}
        labels={labels}
        senderFullName={senderFullName}
        giftToken={giftToken}
        isSkipPromotionalButtonOpen={isSkipPromotionalButtonOpen}
      />
      <VideoDescription
        currentVideo={currentVideo}
        currentVideoIndex={currentVideoIndex}
        numberOfVideos={numberOfVideos}
        title={title}
        author={author}
        isSingleVideo={isSingleVideo}
        labels={labels}
        showGiftPopup={showGiftPopup}
        hasUserSufficientAccess={hasUserSufficientAccess}
        handleInsufficientAccess={showContentBlockingPopup}
      />
      {relatedPlaylistCards.length > 0 && (
        <RelatedContentCarousel playlists={relatedPlaylistCards} title="Related Content" />
      )}
      {popularPlaylistCards.length > 0 && (
        <RelatedContentCarousel playlists={popularPlaylistCards} title="Popular on Aleph Beta" />
      )}
      {trendingPlaylistCards.length > 0 && (
        <RelatedContentCarousel playlists={trendingPlaylistCards} title="Trending Now" />
      )}

      <AboutBoxSlice />
      <GiftAcknowledgeBanner
        handleGiftThankYouClick={handleGiftThankYouClick}
        handleCloseGiftBanner={handleCloseGiftBanner}
        senderFullName={senderFullName}
        isGiftPlaylist={isGiftPlaylist}
        isGiftBannerOpen={isGiftBannerOpen}
      />
    </Container>
  );
};

MarketingPlaylistLayout.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  seekToSecond: PropTypes.number,
  currentVideoIndex: PropTypes.number,
  numberOfVideos: PropTypes.number,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    trailer_wistia_url: PropTypes.shape({ url: PropTypes.string.isRequired }),
    wistia_url: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  handlers: PropTypes.shape({
    onReady: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
    onProgress: PropTypes.func.isRequired,
    onEnded: PropTypes.func.isRequired,
    handleBlockingPopupClose: PropTypes.func.isRequired,
    showContentBlockingPopup: PropTypes.func.isRequired,
    setCurrentVideo: PropTypes.func.isRequired,
    onPromotionalProgress: PropTypes.func.isRequired,
    onPromotionEnded: PropTypes.func.isRequired,
    showGiftPopup: PropTypes.func.isRequired,
    handleGiftThankYouClick: PropTypes.func,
    handleCloseGiftBanner: PropTypes.func.isRequired,
    toggleVideoPlayState: PropTypes.func.isRequired
  }).isRequired,
  popup: PropTypes.number,
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  nextPlaylist: PropTypes.shape({}),
  prismicId: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  suggestedPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  trendingPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  popularPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  hasPromotionalVideo: PropTypes.bool.isRequired,
  promoVideoId: PropTypes.string,
  thumbnail: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string }).isRequired,
  isSingleVideo: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  senderFullName: PropTypes.string,
  isGiftPlaylist: PropTypes.bool,
  giftToken: PropTypes.string,
  isGiftBannerOpen: PropTypes.bool.isRequired,
  isSkipPromotionalButtonOpen: PropTypes.bool.isRequired,
  hasUserSufficientAccess: PropTypes.bool.isRequired
};
MarketingPlaylistLayout.defaultProps = {
  currentVideoIndex: 0,
  numberOfVideos: 1,
  seekToSecond: undefined,
  nextPlaylist: null,
  suggestedPlaylists: [],
  trendingPlaylists: [],
  popularPlaylists: [],
  isPopupOpen: false,
  isGiftPlaylist: false,
  popup: undefined,
  promoVideoId: null,
  senderFullName: null,
  giftToken: null
};

export default MarketingPlaylistLayout;
