import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import unfurl from '~prismic/unfurl/search';
import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import SearchUI from '~containers/search/SearchUI';

const SearchPage = ({ data, location }) => {
  const { recommendedSearchTerms } = unfurl(data);
  return (
    <Page>
      <SEO />
      <SearchUI location={location} recommendedSearchTerms={recommendedSearchTerms} />
    </Page>
  );
};

SearchPage.propTypes = {
  data: PropTypes.shape({
    recommended_search_terms: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default withPrismicPreview(SearchPage);

export const query = graphql`
  {
    prismicSearchTerms {
      _previewable
      data {
        recommended_search_terms
      }
    }
  }
`;
