import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import RichText from '~components/RichText';
import RoundedLinkIconButton from '~components/buttons/RoundedLinkIconButton';

import HanukkahHeroContainer from '~modules/holiday/components/HanukkahHero/HanukkahHeroContainer';
import HeroBackgroundImage from '~images/holidays/hanukkah_hero.jpg';
import HanukkahHeroMobile from './HanukkahHeroMobile';

const useStyles = makeStyles(theme => ({
  body: props => ({
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: props.themeColor,
    color: props.themeSecondaryColor
  }),
  gridStyle: {
    zIndex: 2,
    marginBlockEnd: '40px'
  },
  eventName: props => ({
    marginBlockStart: '150px',
    marginBlockEnd: '30px',
    color: props.themeSecondaryColor,
    opacity: 0.6,
    fontSize: '16px',
    '& > *': {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Inter',
      color: props.themeSecondaryColor,
      margin: 0
    },
    [theme.breakpoints.only('xs')]: {
      marginBlockStart: '75px'
    }
  }),
  heroTitle: props => ({
    marginBlockStart: 0,
    marginBlockEnd: 11,
    '& > *': {
      fontFamily: 'Inter',
      fontWeight: 700,
      color: props.themeSecondaryColor,
      fontSize: '48px',
      lineHeight: '130%',
      margin: 0,
      textAlign: 'left'
    },
    '& > * > strong': {
      color: '#FEC000'
    }
  }),
  heroSubtitle: props => ({
    marginBlockStart: 0,
    marginBlockEnd: 39,
    '& > *': {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 500,
      color: props.themeSecondaryColor,
      margin: 0,
      paddingRight: '66px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        paddingRight: 'unset',
        marginBottom: '35px'
      }
    }
  })
}));

const StyledGrid = styled(Grid)`
  min-height: 650px;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 959px) {
    min-height: unset;
    margin-top: 120px;
  }
`;

const THEME_OPTION = {
  HANUKKAH: 'Hanukkah'
};

const THEME_PRIMARY_COLOR = {
  [THEME_OPTION.HANUKKAH]: '#020928'
};

const THEME_SECONDARY_COLOR = {
  [THEME_OPTION.HANUKKAH]: '#FFFFFF'
};

const HanukkahHero = props => {
  const {
    theme: themeOption,
    overline_text,
    hero_title,
    hero_subtitle,
    hero_featured_video
  } = props;
  const [maskHeight, setMaskHeight] = useState();
  const classes = useStyles({
    maskHeight,
    themeColor: THEME_PRIMARY_COLOR[themeOption],
    themeSecondaryColor: THEME_SECONDARY_COLOR[themeOption]
  });
  const { dispatch, session } = useUserContext();
  const { isUserStateLoading } = session;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { url } = hero_featured_video;

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setMaskHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const isTishaBavHome = window && window.location.pathname === '/';
  useEffect(() => {
    if (isTishaBavHome && !isUserStateLoading) {
      dispatch({ type: 'USER_UPDATE', payload: { tishaBavHomeHeroFeaturedUrl: url } });
    }
  }, [dispatch, url, isUserStateLoading, isTishaBavHome]);

  const buttonVariant = themeOption === THEME_OPTION.HANUKKAH ? 'light' : 'dark';

  if (isMobile) {
    return <HanukkahHeroMobile {...props} />;
  }

  return (
    <div className={classes.body}>
      <HanukkahHeroContainer background={`url("${HeroBackgroundImage}")`} zIndex={0}>
        <StyledGrid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          ref={measuredRef}
        >
          <Grid item xs={10} md={6} className={classes.gridStyle}>
            <RichText
              html={overline_text.html}
              verticalSpacing={0}
              externalClassName={classes.eventName}
            />

            <RichText html={hero_title.html} externalClassName={classes.heroTitle} />
            <RichText html={hero_subtitle.html} externalClassName={classes.heroSubtitle} />
            <RoundedLinkIconButton
              text="Watch Now"
              to={url}
              icon={
                <PlayArrowIcon fill={THEME_PRIMARY_COLOR[themeOption]} height={17} width={17} />
              }
              variant={buttonVariant}
            />
          </Grid>
        </StyledGrid>
      </HanukkahHeroContainer>
    </div>
  );
};
HanukkahHero.propTypes = {
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  hero_mobile_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }),
  overline_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_featured_video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    document: PropTypes.shape({
      data: PropTypes.shape({
        label_is_new: PropTypes.string.isRequired,
        label_is_audio: PropTypes.string.isRequired,
        label_is_premium: PropTypes.string.isRequired,
        label_is_producer: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  theme: PropTypes.oneOf(Object.values(THEME_OPTION))
};

HanukkahHero.defaultProps = {
  theme: THEME_OPTION.HANUKKAH,
  hero_mobile_background_image: ''
};

export default HanukkahHero;
