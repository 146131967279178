export default (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        error: null,
        requestConfig: action.requestConfig
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
        requestConfig: action.requestConfig
      };
    default:
      throw new Error('Unknown action type inside of useApi#apiReducer!');
  }
};
