import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

const getBackground = backgroundColor => backgroundColor || '#E52059';

const useStyles = makeStyles({
  transparent: {
    backgroundColor: 'transparent',
    color: ({ color }) => color || 'white',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: ({ color }) => color || 'white'
  },
  colored: {
    backgroundColor: ({ backgroundColor }) => backgroundColor || '#E52059',
    color: ({ color }) => color || 'white'
  },
  general: {
    boxShadow: 'unset',
    minWidth: '200px',
    transition: 'all .2s ease-in-out',
    maxHeight: '42px',
    '&:hover': {
      transform: 'scale(1.05)',
      background: ({ btnVariant, backgroundColor }) =>
        btnVariant === 'transparent' ? 'transparent' : getBackground(backgroundColor)
    }
  }
});

const PodcastButton = ({ btnVariant, color, backgroundColor, children, onClick, ...rest }) => {
  const classes = useStyles({
    color,
    backgroundColor,
    btnVariant
  });

  return (
    <Fab
      onClick={onClick}
      variant="extended"
      justifycontent="center"
      {...rest}
      className={cn(classes[btnVariant], classes.general, rest.className || '')}
    >
      {children}
    </Fab>
  );
};

PodcastButton.propTypes = {
  btnVariant: PropTypes.oneOf(['colored', 'transparent']),
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

PodcastButton.defaultProps = {
  btnVariant: 'transparent',
  color: '',
  backgroundColor: ''
};

export default PodcastButton;
