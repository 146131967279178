import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TestimonialCarousel from '~components/TestimonialCarousel';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '140px',
    paddingBottom: '90px',
    gap: 60,
    [theme.breakpoints.down('xs')]: {
      gap: 15,
      paddingTop: '45px',
      paddingBottom: '75px'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: '#F7F6F4'
  },
  sectionHeading: {
    fontWeight: '600',
    fontSize: '44px',
    fontFamily: 'Inter',
    color: '#1D1927',
    paddingBottom: theme.spacing(),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px'
    }
  },
  shapeDivider: {
    display: 'flex',
    marginTop: '-5px'
  }
}));

const testimonials = [
  {
    testimonial:
      'We just love Aleph Beta. My wife is a Bible researcher and came across Aleph Beta as a reference in another podcast. It is some of the most brilliant content that we have ever heard...',
    author: 'Andrew Martin',
    designation: 'Aleph Beta user'
  },
  {
    testimonial: 'I learn so much from the videos, lectures and classes.',
    user: 'Yehudit Shier Weisberg',
    designation: 'Aleph Beta user'
  },
  {
    testimonial:
      'Aleph Beta has given me opportunities to see Torah in new ways and helped me better understand what is asked of me from a loving and merciful G-d who daily cares for His creation as from the day He spoke it into being.',
    user: 'E. Guerrero',
    designation: 'Aleph Beta user'
  },
  {
    testimonial:
      'I think that Aleph Beta makes a unique and critical contribution to Jewish education.',
    user: 'Glenn Schoenfeld',
    designation: 'Aleph Beta user'
  },
  {
    testimonial: `I am regularly blown away by the richness of connections you find in Torah  - truly inspiring.`,
    user: 'David Elias',
    designation: 'Aleph Beta user'
  },
  {
    testimonial:
      'Aleph Beta is at the forefront of making Torah scholarship accessible in an engaging, compelling way. Every interaction with Aleph Beta content makes me want to read the Bible, and I want to be a part of giving that feeling to as many people as possible! ',
    user: 'Clint Eubanks',
    designation: 'Aleph Beta user'
  }
];

const colors = [
  {
    backgroundColor: '#3C3284',
    color: '#FFFFFF'
  },
  {
    backgroundColor: '#FFFFFF',
    color: '#000000'
  },
  {
    backgroundColor: '#4C53B4',
    color: '#FFFFFF'
  },
  {
    backgroundColor: '#83D2F0',
    color: '#000000'
  }
];

const ProducersCircleTestimonials = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.section}>
        <div className={classes.container}>
          <Typography variant="h3" className={classes.sectionHeading}>
            Feedback from our Patrons
          </Typography>
          <Box component="div" width="100%">
            <TestimonialCarousel
              testimonials={testimonials}
              variants={colors}
              dotColor="#C5BCD8"
              activeDotColor="#3C3284"
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default ProducersCircleTestimonials;
