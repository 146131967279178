import { useUserContext } from '~context/UserContext/UserSessionContext';
import React from 'react';
import ReactJson from 'react-json-view';

export const UserContextDebugPanel = () => {
  const { session } = useUserContext();
  return (
    <ReactJson
      src={session}
      collapsed={1}
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 10e6,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        overflowY: 'scroll',
        maxHeight: '100vh'
      }}
    />
  );
};
