export const perksPageUrl = '/perks';
const CHANGE_LINK_TEXT = 'View other perks';

export const products = [
  {
    id: 71,
    name: 'DONATION',
    perkName: 'Magnet',
    perkTag: 'Perk - Magnet',
    isRecurring: false,
    monthlyPrice: 36,
    yearlyPrice: 36,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Aleph Beta Magnet',
          'A Tax Deductible Donation',
          '[!]Information to claim your perk will be sent in your confirmation email'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 72,
    name: 'DONATION',
    perkName: 'T-shirt',
    perkTag: 'Perk - T-shirt',
    isRecurring: false,
    monthlyPrice: 50,
    yearlyPrice: 50,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'New 2024 Aleph Beta t-shirt',
          'A Tax Deductible Donation',
          '[!]Information to claim your perk will be sent in your confirmation email'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 73,
    name: 'DONATION',
    perkName: 'Raffle',
    perkTag: 'Perk - Raffle',
    isRecurring: false,
    monthlyPrice: 100,
    yearlyPrice: 100,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          '1x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Your raffle ticket will automatically be entered into the draw after purchase'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 74,
    name: 'DONATION',
    perkName: 'Signed copy of ChatGPT Story + 1 Raffle Ticket',
    perkTag: 'Perk - Signed Copy',
    isRecurring: false,
    monthlyPrice: 180,
    yearlyPrice: 180,
    isDeliveryRequired: true,
    shippingCost: 20,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Short Story by Rabbi Fohrman and ChatGPT',
          '1 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Please provide us with your shipping information which we have provided in your confirmation email. Your raffle ticket will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 75,
    name: 'DONATION',
    perkName: '3x Raffle Tickets',
    perkTag: 'Perk - 3x Raffle',
    isRecurring: false,
    monthlyPrice: 180,
    yearlyPrice: 180,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          `3 x Raffle Tickets`,
          'A Tax Deductible Donation',
          '[!]Your raffle tickets will automatically be entered into the draw after purchase'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 76,
    name: 'DONATION',
    perkName: 'Framed Original canvas + 1 Raffle Ticket',
    perkTag: 'Perk - Framed Canvas',
    isRecurring: false,
    monthlyPrice: 250,
    yearlyPrice: 250,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Framed Original Canvas',
          '1 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Information to claim your perk will be sent in your confirmation email. Your raffle ticket will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 77,
    name: 'DONATION',
    perkName: 'Merch Bundle + 4 Raffle Tickets',
    perkTag: 'Perk - Merch Bundle',
    isRecurring: false,
    monthlyPrice: 360,
    yearlyPrice: 360,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Aleph Beta Merch Bundle',
          '4 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Information to claim your perk will be sent in your confirmation email. Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 78,
    name: 'DONATION',
    perkName: '6 Original Edition Aleph Beta Mugs',
    perkTag: 'Perk - 6 Mugs',
    isRecurring: false,
    monthlyPrice: 360,
    yearlyPrice: 360,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          '6 original edition Aleph Beta mugs',
          'A Tax Deductible Donation',
          '[!]Information to claim your perk will be sent in your confirmation email.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 79,
    name: 'DONATION',
    perkName: 'Pre-Publication Manuscript of Numbers, Signed copy + 5 Raffle Tickets',
    perkTag: 'Perk - Signed Manuscript',
    isRecurring: false,
    monthlyPrice: 500,
    yearlyPrice: 500,
    isDeliveryRequired: true,
    shippingCost: 20,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Pre-Publication Manuscript of Numbers, signed copy',
          '5 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Please provide us with your shipping information which we have provided in your confirmation email. Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 80,
    name: 'DONATION',
    perkName: 'Coffee Table Guide + 5 Raffle Tickets',
    perkTag: 'Perk - Coffee Guide',
    isRecurring: false,
    monthlyPrice: 500,
    yearlyPrice: 500,
    isDeliveryRequired: true,
    shippingCost: 48,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Coffee Table Parsha Guide',
          '5 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Please provide us with your shipping information which we have provided in your confirmation email. Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 81,
    name: 'DONATION',
    perkName: 'Ticket to Special Live Event: “Hollywood and the Rabbis” + 10 Raffle Tickets',
    perkTag: 'Perk - Event Ticket',
    isRecurring: false,
    monthlyPrice: 1000,
    yearlyPrice: 1000,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Ticket to Special Live Event: “Hollywood and the Rabbis”',
          '10 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 82,
    name: 'DONATION',
    perkName: `Listed as a supporter in acknowledgements of Rabbi Fohrman's next book (Bamidbar) + 15 Raffle Tickets`,
    perkTag: 'Perk - Acknowledgement',
    isRecurring: false,
    monthlyPrice: 1800,
    yearlyPrice: 1800,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          `Listed as a supporter in acknowledgements of Rabbi Fohrman's next book (Bamidbar)`,
          '15 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Please provide us with your acknowledgements details which we have provided in your confirmation email. Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 83,
    name: 'DONATION',
    perkName: 'Signed Early Manuscript of: The Beast That Crouches at the Door + 15 Raffle',
    perkTag: 'Perk - Early Manuscript',
    isRecurring: false,
    monthlyPrice: 3600,
    yearlyPrice: 3600,
    isDeliveryRequired: true,
    shippingCost: 20,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Signed Early Manuscript of: The Beast That Crouches at the Door',
          '15 x Raffle Ticket',
          'A Tax Deductible Donation',
          '[!]Please provide us with your shipping information which we have provided in your confirmation email. Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 84,
    name: 'DONATION',
    perkName: 'Framed and Mounted Actual Wacom Tablet',
    perkTag: 'Perk - Framed Tablet',
    isRecurring: false,
    monthlyPrice: 5000,
    yearlyPrice: 5000,
    isDeliveryRequired: true,
    shippingCost: 20,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Framed and Mounted Actual Wacom Tablet',
          '15 x Raffle Tickets',
          'A Tax Deductible Donation',
          '[!]Please provide us with your shipping information which we have provided in your confirmation email. Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 85,
    name: 'DONATION',
    perkName: 'Bar and Bat Mitzvah Learning with Rabbi Fohrman + 15 Raffle Tickets',
    perkTag: 'Perk - Learning Sessions',
    isRecurring: false,
    monthlyPrice: 5000,
    yearlyPrice: 5000,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Bar and Bat Mitzvah Learning with Rabbi Fohrman',
          '15 x Raffle Ticket ',
          'A Tax Deductible Donation',
          '[!]Your raffle tickets will automatically be entered into the draw after purchase.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  },
  {
    id: 86,
    name: 'DONATION',
    perkName: 'Fly to Israel and Tour the Old City with Rabbi Fohrman and his Favorite Tour Guide',
    perkTag: 'Perk - Israel Tour',
    isRecurring: false,
    monthlyPrice: 15000,
    yearlyPrice: 15000,
    lowerTierId: null,
    productType: 'Order',
    purchaseButtonTitle: 'Process Donation',
    changeLink: perksPageUrl,
    changeLinkText: CHANGE_LINK_TEXT,
    features: [
      {
        list: [
          'Fly to Israel and Tour the Old City with Rabbi Fohrman and his Favorite Tour Guide',
          'A Tax Deductible Donation',
          '[!]A Member of our team will get in touch with you after purchasing this once in a lifetime opportunity.'
        ]
      }
    ],
    planStyles: {
      color: '#438dce'
    },
    footnote: 'one-time payment'
  }
];
