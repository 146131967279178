import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import TuneIcon from '@material-ui/icons/Tune';

const useStyles = makeStyles({
  iconPosition: {
    display: 'flex',
    alignItems: 'center'
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'unset',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    }
  },
  filterIcon: {
    textTransform: 'none',
    padding: '0',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    opacity: 0.87
  },
  popper: {
    zIndex: 1100
  }
});

const CurationPageSortMenu = ({ handleSortClick }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSortMenuHover = event => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  return (
    <Box onMouseEnter={handleSortMenuHover} onMouseLeave={handleSortMenuClose}>
      <Button color="default" onClick={handleSortMenuHover} className={classes.filterIcon}>
        <TuneIcon />
      </Button>

      <Popper
        open={isMenuOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
        className={classes.popper}
      >
        <Paper>
          <MenuList disablePadding>
            <MenuItem id="name" onClick={handleSortClick} className={classes.iconPosition}>
              Alphabetical
            </MenuItem>

            <MenuItem id="duration" onClick={handleSortClick} className={classes.iconPosition}>
              Duration
            </MenuItem>

            <MenuItem id="default" onClick={handleSortClick} className={classes.iconPosition}>
              Default
            </MenuItem>
          </MenuList>
        </Paper>
      </Popper>
    </Box>
  );
};

CurationPageSortMenu.propTypes = {
  handleSortClick: PropTypes.func
};

CurationPageSortMenu.defaultProps = {
  handleSortClick: null
};

export default CurationPageSortMenu;
