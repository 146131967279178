import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

const StyledMuiContainer = styled(MuiContainer)`
  display: flex !important;
  flex-direction: column;
  min-height: 760px;

  ${props =>
    props.background &&
    `&:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background: ${props.background};
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: top;
      background-position-x: right;
    }

    :after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 300px;
      background: linear-gradient(180deg, rgb(2, 8, 40, 0), rgb(2, 8, 40));
   }

    `}
`;

const HanukkahHeroContainer = ({ children, background, zIndex, ...rest }) => (
  <StyledMuiContainer background={background} zIndex={zIndex} {...rest}>
    {children}
  </StyledMuiContainer>
);

HanukkahHeroContainer.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  zIndex: PropTypes.number
};

HanukkahHeroContainer.defaultProps = {
  background: '',
  zIndex: -2
};

export default HanukkahHeroContainer;
