import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CustomAnimatedButton from '~components/CustomAnimatedButton';

const useStyles = makeStyles(theme => ({
  playBtn: {
    position: 'absolute',
    left: '49%',
    top: '36%',
    zIndex: '10',
    [theme.breakpoints.down('sm')]: {
      left: 'calc(50% - 41px)',
      bottom: '-35px',
      top: 'unset'
    }
  }
}));

const PlayButton = ({ backgroundColor, iconColor }) => {
  const classes = useStyles();

  return (
    <div className={classes.playBtn}>
      <CustomAnimatedButton backgroundColor={backgroundColor} iconColor={iconColor} />
    </div>
  );
};
PlayButton.propTypes = {
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string
};

PlayButton.defaultProps = {
  backgroundColor: '#FFFFFF',
  iconColor: '#000000'
};

export default PlayButton;
