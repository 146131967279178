import React from 'react';
import PropTypes from 'prop-types';
import PrimaryVariantButton from './variants/PrimaryVariantButton';
import SecondaryVariantButton from './variants/SecondaryVariantButton';

const VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

const AppleSignInButton = ({ variant, children }) => {
  const apiURL = process.env.GATSBY_API_URL;
  const signInUrl = `${apiURL}/appstore/sign-in`;

  return variant === VARIANTS.SECONDARY ? (
    <SecondaryVariantButton signInUrl={signInUrl}>{children}</SecondaryVariantButton>
  ) : (
    <PrimaryVariantButton signInUrl={signInUrl} />
  );
};

AppleSignInButton.propTypes = {
  variant: PropTypes.oneOf([VARIANTS.PRIMARY, VARIANTS.SECONDARY]),
  children: PropTypes.node
};

AppleSignInButton.defaultProps = {
  variant: VARIANTS.PRIMARY,
  children: null
};

export default AppleSignInButton;
