import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '~components/Dialog';
import { useTestId } from '~utils/set-testid';
import ButtonClosePopup from './components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  pdfButtonStyle: {
    backgroundColor: '#278ae7',
    color: '#fff',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '12px 42px',
    marginBottom: theme.spacing(3),
    borderRadius: '3em',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  imageMediaStyle: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  divStyle: {
    textAlign: 'center'
  },
  subtitleStyle: {
    width: '40%',
    marginLeft: '30%',
    marginBottom: '2rem',
    fontSize: '18px'
  }
}));

const LargePDFPopup = ({
  id,
  popupData,
  showTopCloseBtn,
  isPopupOpen,
  setPopupId,
  handlePopupClose
}) => {
  const classes = useStyles();
  const { image, backdrop, title, subtitle, buttonProps } = popupData;
  const testid = useTestId(`too-large-pdf-popup-${id}`);

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    if (handlePopupClose) {
      handlePopupClose();
      return;
    }
    if (setPopupId) {
      setPopupId(0);
    }
    setIsOpen(false);
  };
  if (id !== 'pdf_too_large_for_download') {
    return null;
  }

  return (
    <Dialog
      isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
      backdrop={backdrop}
      background="transparent"
      maxWidth="md"
      {...testid}
      textAlign="center"
      alignItems="center"
    >
      <img src={image.src} alt={image.alt} className={classes.imageMediaStyle} />
      <div className={classes.divStyle}>
        <h1>{title}</h1>
        <p className={classes.subtitleStyle}>{subtitle}</p>
        <Button
          component={GatsbyLink}
          variant="contained"
          className={classes.pdfButtonStyle}
          to={buttonProps.href}
        >
          {buttonProps.title}
        </Button>
      </div>
      {showTopCloseBtn && <ButtonClosePopup handleClose={handleClose} />}
    </Dialog>
  );
};
LargePDFPopup.propTypes = {
  id: PropTypes.string,
  popupData: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    }).isRequired,
    backdrop: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonProps: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  showTopCloseBtn: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  setPopupId: PropTypes.func
};

LargePDFPopup.defaultProps = {
  id: '',
  showTopCloseBtn: false,
  setPopupId: null,
  isPopupOpen: null,
  handlePopupClose: null
};

export default LargePDFPopup;
