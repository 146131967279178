/**
 * README
 * We're using `gatsby-plugin-google-analytics` to initialize GA and track page views automatically.
 * This module's solely purpose is to easy the tracking of specific actions and it uses the pre-loaded `ga` global variable.
 */
export const irRegistrationEvent = ({ email, fname, mobile }) => {
  if (typeof window !== 'undefined' && window.ir) {
    window.ir('track', {
      event: 'registration',
      orderID: email,
      email,
      fname,
      mobile
    });
  }
};

export const irSaleEvent = ({ orderID, email, fname, mobile, order_custom_val, purchaseValue }) => {
  if (typeof window !== 'undefined' && window.ir) {
    window.ir('track', {
      event: 'sale',
      orderID,
      email,
      fname,
      mobile,
      order_custom_val,
      purchaseValue
    });
  }
};
