/**
 * Data fetching with React Hooks
 *
 * https://www.robinwieruch.de/react-hooks-fetch-data/
 *
 * Instructions
 *
 * There are two ways to use API hooks. Either immediately fetch data or trigger fetch manually, i.e. skip initial fetch.
 *
 * // `initialReqParams !== 'undefined'`  = will make immediate request
 * const [{ isLoading, isError, data: fetchedData }] = useApi({
 *   url: 'https://jsonplaceholder.typicode.com/posts'
 * });
 *
 * // `initialReqParams === 'undefined'` = will make request when `makeRequest` gets invoked with `initialReqParams` parameter
 * const [{ isLoading, isError, data: fetchedData }, makeRequest] = useApi();
 * makeRequest({url: 'https://jsonplaceholder.typicode.com/posts'});
 *
 */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useReducer } from 'react';
import { get } from 'lodash';

import request, { logError } from '~utils/request';

import dataFetchReducer from './apiReducer';

export default (initialReqParams, initialData) => {
  const [reqParams, setReqParams] = useState(initialReqParams);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
    error: null,
    requestConfig: null
  });

  useEffect(() => {
    let didCancel = false;

    if (reqParams) {
      (async () => {
        dispatch({ type: 'FETCH_INIT' });

        try {
          const result = await request(reqParams);

          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: result.data, requestConfig: result.config });
          }
        } catch (error) {
          logError(error);
          const errorMessage = get(error, 'response.data.message', String(error));
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE', error: errorMessage, requestConfig: error.config });
          }
        }
      })();
    }

    return function cleanup() {
      didCancel = true;
    };
  }, [reqParams]);

  return [state, setReqParams];
};
