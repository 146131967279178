import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import classNames from 'classnames';
import MediaPlayer from '~components/media-player';
import ButtonClosePopup from '~components/popups/components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    maxHeight: '100%'
  },
  root: {
    zIndex: '2000 !important',
    '@media (orientation: landscape)': {
      padding: '10px 70px'
    }
  },
  curationVideo: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      width: '100%'
    }
  }
}));

const CurationHeroDialog = ({ isOpen, handleClose, videoUrl, ...rest }) => {
  const classes = useStyles();
  const paperRootClass = classNames(classes.paper, classes.curationVideo);
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      classes={{ root: classes.root }}
      BackdropProps={{
        className: classes.backdrop
      }}
      PaperProps={{
        classes: {
          root: paperRootClass
        }
      }}
      {...rest}
    >
      <DialogContent className={classes.curationVideo}>
        <MediaPlayer url={videoUrl} playing />
        <ButtonClosePopup handleClose={handleClose} color="#fff" />
      </DialogContent>
    </Dialog>
  );
};

CurationHeroDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
  isOpen: PropTypes.bool
};

CurationHeroDialog.defaultProps = {
  isOpen: false
};

export default CurationHeroDialog;
