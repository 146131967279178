import { isIOS } from 'react-device-detect';
/* eslint-disable no-console */
import MediaPlayerBg from '~images/playlist/playlist_media_player_background.gif';

const enableLogging = true; // set to false to stop default console logs
const isDevelopment = process.env.NODE_ENV === 'development' && enableLogging;

// Supported options https://wistia.com/support/developers/embed-options#options_list
// Add resumable disable
export default {
  width: '100%',
  height: '100%',
  style: {
    background: `url(${MediaPlayerBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  config: {
    wistia: {
      options: {
        seo: false,
        videoFoam: true,
        playlistLinks: 'auto',
        playerColor: '#282828',
        resumable: false,
        fullscreenButton: !isIOS, // disabled for iphone, as it doesn't allow to exit fullscreen from code
        plugin: {
          'captions-v1': {
            onByDefault: false, // it looks like without explicit `false` which is default
            language: 'eng' // and a language explicitly declared Wistia player ignores captions settings.
          },
          'display-total-video-length': {
            src: '/wistia-plugin-display-total-video-length.js'
          }
        }
      }
    }
  },
  onReady: () => isDevelopment && console.log('media-player: onReady event fired'),
  onPlay: () => isDevelopment && console.log('media-player: onPlay event fired'),
  onProgress: () => isDevelopment && console.log('media-player: onProgress event fired'),
  onEnded: () => isDevelopment && console.log('media-player: onEnded event fired')
};
