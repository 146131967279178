export function makeFullName(user = {}) {
  const meta_first_name = user.meta_first_name || '';
  const meta_last_name = user.meta_last_name || '';

  if (
    !meta_first_name ||
    !meta_last_name ||
    typeof meta_first_name !== 'string' ||
    typeof meta_last_name !== 'string'
  ) {
    return null;
  }

  const raw = `${meta_first_name.trim()} ${meta_last_name.trim()}`;

  return raw.trim() || null;
}

export const isUserEducator = (user = {}) => Number(user.access_level) === 3;

export const isUserProducer = (user = {}) => Number(user.access_level) === 4;

export const isUserPrivilegedMember = (user = {}) => Number(user.access_level) > 1;

export const isUserBarMitzvah = (user = {}) =>
  !!(
    user.username &&
    (user.has_bar_mitzvah_access || (Number(user.access_level) > 1 && !user.has_failed_charges))
  );

export const isUserCompletedBarMitzvahOnboarding = (user = {}) =>
  !!(user.username && user.has_bar_mitzvah_onboarding);

export const isUserCompletedOnboarding = (user = {}) => !!(user.username && user.has_onboarding);
