import React from 'react';
import PropTypes from 'prop-types';
import { FacebookIcon } from 'react-share';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  buttonBackground: {
    backgroundColor: '#3b5998'
  }
}));

const PrimaryVariantButton = ({ onClick, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      className={classes.buttonBackground}
      {...rest}
    >
      <Box pr={1}>
        <FacebookIcon size={30} />
      </Box>
      {children}
    </Button>
  );
};

PrimaryVariantButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default PrimaryVariantButton;
