/**
 * Responsive Media Player component to show video sourced from URL
 *
 * Responsiveness is achieved by the trick shown here: https://github.com/CookPete/react-player/issues/145#issuecomment-275463885
 * It relies on the padding-top on the wrapper around the player, which is calculated specifically according to the expected aspect ratio of the video.
 * We setup this trick using `withStyles` HOC.
 *
 * By default video watching is time tracked. If you want to disable time tracking
 * you should override the onPlay, onReady, onProgress callbacks when using the component.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import localStorage from 'store2';

import withStyles from '@material-ui/core/styles/withStyles';
import isBot from 'isbot';
import playerConfig from './config';
import promotionalPlayerConfig from './promotional_config';

class MediaPlayer extends Component {
  // Just once, seek to the given second, if given one.
  componentDidMount() {
    const { seekToSecond } = this.props;
    if (seekToSecond) {
      this.player.seekTo(seekToSecond);
    }
  }

  componentDidUpdate() {
    const { playing } = this.props;

    // let's pause the video forcefully when playing is false
    /**
     * Ideally passing `playing` prop as false to react player should pause the video.
     * But react player has this bug (https://github.com/cookpete/react-player/issues/85)
     * where it sometimes doesn't stop with playing prop set to false.
     *
     * We've a use case where beyond some seconds, video shouldn't play.
     * It works perfectly but when seeked from the controls,
     * it doesn't stop the video although the playing prop is set to false.
     *
     * So here, we are using the internal player to pause the video.
     */
    const internalPlayer = this.player ? this.player.getInternalPlayer() : null;
    if (!playing && internalPlayer && internalPlayer.state() === 'playing') {
      internalPlayer.pause();
    }
  }

  // Have to define ref because `seekTo` is a method on the player, not a prop.
  ref = player => {
    this.player = player;
  };

  onVideoReady = () => {
    const { onReady } = this.props;
    const internalPlayer = this.player.getInternalPlayer();

    internalPlayer.bind('playbackratechange', playback => {
      localStorage.set('playback', playback);
    });

    if (onReady) {
      onReady();
    }
  };

  render() {
    const { url, classes, isPromotionalVideo, ...rest } = this.props;
    const { userAgent } = typeof window !== 'undefined' ? window.navigator : {};
    delete rest.seekToSecond; // otherwise it will be assigned to the HTML element which is unclean and React will warn us.

    const config = isPromotionalVideo ? promotionalPlayerConfig : playerConfig;
    const playback = localStorage.get('playback');

    return (
      <div className={classes.playerWrapper}>
        <ReactPlayer
          ref={this.ref}
          url={url}
          className={classes.player}
          {...config}
          {...rest}
          onReady={this.onVideoReady}
          light={isBot(userAgent)}
          playbackRate={playback || 1}
          pip={false}
        />
      </div>
    );
  }
}
MediaPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  seekToSecond: PropTypes.number,
  isPromotionalVideo: PropTypes.bool,
  playing: PropTypes.bool.isRequired,
  onReady: PropTypes.func,
  // Classes have to be given to us by the `withStyles` magic
  classes: PropTypes.shape({ playerWrapper: PropTypes.string, player: PropTypes.string }).isRequired
};
MediaPlayer.defaultProps = {
  seekToSecond: 0,
  isPromotionalVideo: false,
  onReady: null
};

export default withStyles({
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0
    /* width: 100% and height: 100% are set in the `config` props element because react-player have dedicated props for player dimensions. */
  }
})(MediaPlayer);
