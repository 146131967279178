import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  carousel: {
    width: '100vw',
    '& .slick-list': {
      position: 'unset',
      padding: '0px 0px 30px !important',
      '& > div': {
        marginLeft: 0
      }
    },
    '& .slick-track': {
      display: 'flex !important',
      alignItems: 'flex-end',
      gap: 15,
      position: 'unset',
      margin: 'auto',
      '& > :first-child': {
        marginLeft: '-4px'
      }
    },
    '& .slick-slider': {
      position: 'unset'
    },
    '& .slick-slide': {
      height: 'inherit !important',
      width: 'fit-content !important',
      '& > :first-child': {
        height: '100%'
      }
    },
    '& .slick-dots': {
      bottom: '0px',
      '& > li': {
        margin: 0
      },
      '& > li button:before': {
        color: '#3C3284 !important',
        fontSize: 10,
        opacity: 0.3
      },
      '& > li.slick-active button:before': {
        color: '#3C3284 !important',
        opacity: 1
      }
    }
  }
});

const BaseSlider = React.forwardRef(({ children }, ref) => {
  const classes = useStyles();

  const carouselSettings = {
    className: classes.carousel,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    infinite: false,
    arrows: false,
    dots: true,
    initialSlide: 1,
    variableWidth: true
  };

  return (
    <Slider ref={ref} {...carouselSettings}>
      {children}
    </Slider>
  );
});

BaseSlider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BaseSlider;
