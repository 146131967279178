export default [
  "Asara B'Tevet",
  "Beha'alotecha",
  "Eitz hada'at",
  "Go'el hadam",
  "Ha'azinu",
  "Kibbud Av V'em",
  "Lot's Wife",
  "Ma'amad Har Sinai",
  "Ma'aser",
  "Re'eh",
  "Tisha B'Av",
  "Tu B'shvat",
  "Tzara'at",
  "V'ahavta L'reacha",
  "V'zot Habracha",
  "Va'etchanan",
  '10 commandments',
  '10 makkot',
  '10 plagues',
  '17th of Tammuz',
  'Aaron',
  'Abel',
  'Abimelech',
  'Abraham',
  'Acharei Mot',
  'Achashverosh',
  'Achav',
  'Achaz',
  'Adam',
  'Ahab',
  'Aharon',
  'Ahavah',
  'Akeidat Yitzchak',
  'Amalek',
  'Antiochus',
  'Arayot',
  'Arba Minim',
  'Aseret haDibrot',
  'Asher',
  'Aveilut',
  'Avihu',
  'Avimelech',
  'Avodah Zarah',
  'Avraham',
  'Balaam',
  'Balak',
  'Bamidbar',
  'Bechukotai',
  'Behar',
  'Benjamin',
  'Bereishit',
  'Beshalach',
  'Bikkurim',
  'Bikur Cholim',
  'Bilaam',
  'Binding of Isaac',
  'Binyamin',
  'Birkat Hamazon',
  'Birkat Kohanim',
  'Blasphemer',
  'Blessings',
  'Blood avenger',
  'Bnot Tzelofchad',
  'Bo',
  'Boaz',
  'Brachot',
  'Brit Bein Habeitarim',
  'Brit Milah',
  'Cain',
  'Caleb',
  'Chag',
  'Chalamot',
  'Challah',
  'Cham',
  'Chana',
  'Chanukah',
  'Chanukat Hamishkan',
  'Charity',
  'Chava',
  'Chayei Sarah',
  'Cheit HaEgel',
  'Chizkiah',
  'Chukat',
  'City of Refuge',
  'Complainers',
  'Dan',
  'Daniel',
  'Daughters of Tzelofchad',
  'David',
  'Deuteronomy',
  'Devarim',
  'Dina',
  'Divrei Hayamim',
  'Dreams',
  'Eccliastes',
  'Efraim',
  'Egypt',
  'Egyptian Oppression',
  'Eicha',
  'Eikev',
  'Elazar',
  'Eli',
  'Elifaz',
  'Elijah',
  'Elimelech',
  'Elisha ben Abuyah',
  'Eliyahu',
  'Elkanah',
  'Emor',
  'Emunah',
  'Ephraim',
  'Er',
  'Esau',
  'Eser makkot',
  'Esther',
  'Etrog',
  'Eve',
  'Exodus',
  'Ezekiel',
  'Ezra',
  'Faith',
  'Fast of 10 Tevet',
  'Flood',
  'Gad',
  'Gan Eden',
  'Garden of Eden',
  'Genesis',
  'Gid hanashe',
  'Gideon',
  'Gilad',
  'Gilead',
  'God',
  'Golden Calf',
  'Grace after meals',
  'Hagar',
  'Haman',
  'Hanukkah',
  'Happiness',
  'Haran',
  'Hashem',
  'Hevel',
  'Holiday',
  'Holiness',
  'Honoring Parents',
  'Hope',
  'I Chronicles',
  'I Kings',
  'I Samuel',
  'II Chronicles',
  'II Kings',
  'II Samuel',
  'Idol worship',
  'Impurity',
  'Inauguration of the Tabernacle',
  'Ir miklat',
  'Isaac',
  'Isaiah',
  'Isaiah',
  'Ishmael',
  'Issachar',
  'Itamar',
  'Ithamar',
  'Iyov',
  'Jacob',
  'Jair',
  'Japheth',
  'Jeremiah',
  'Jethro',
  'Job',
  'Jonah',
  'Jonathan',
  'Joseph',
  'Joshua',
  'Jubilee Year',
  'Judah',
  'Judges',
  'Kalev',
  'Kashrut',
  'Kayin',
  'Kedoshim',
  'Kedusha',
  'Kerem',
  'Ki Tavo',
  'Ki Teitzei',
  'Ki Tisa',
  'Kilyon',
  'Kohelet',
  'Korach',
  'Korban Pesach',
  'Korbanot',
  'Kosher',
  'Kriat Yam Suf',
  'Laban',
  'Lag BaOmer',
  'Lamentations',
  'Lashon Hara',
  'Leah',
  'Lech Lecha',
  'Levi',
  'Leviim',
  'Levirate marriage',
  'Leviticus',
  'Lot',
  'Love your neighbor',
  'Love',
  'Mabul',
  'Machlon',
  'Manasseh',
  'Manna',
  'Marriage',
  'Masei',
  'Mashiach',
  'Matot',
  'Mechirat Yosef',
  'Mei Merivah',
  'Mekalel',
  'Melachim',
  'Menashe',
  'Meraglim',
  'Messiah',
  'Metzora',
  'Micah',
  'Micha',
  'Migdal Bavel',
  'Miketz',
  'Mikvah',
  'Milchemet Amalek',
  'Miriam',
  'Mishkan',
  'Mishlei',
  'Mishpatim',
  'Mitonenim',
  'Mitzrayim',
  'Mitzvot',
  'Mordechai',
  'Moses',
  'Moshe',
  'Mount Sinai',
  'Mourning',
  'Mt. Sinai',
  'Nachor',
  'Nadav',
  'Naftali',
  'Nahor',
  'Naphtali',
  'Naso',
  'Nazir',
  'Nazirite',
  'Nechemia',
  'Nehemiah',
  'Nekama',
  'Nitzavim',
  'Noach',
  'Noah',
  'Numbers',
  'Obadiah',
  'Omri',
  'Onan',
  'Ovadiah',
  'Para Adumah',
  'Paroh',
  'Pascal lamb',
  'Passover',
  'Pekudei',
  'Peninnah',
  'Peretz',
  'Pesach Sheni',
  'Pesach',
  'Pharaoh',
  'Phineas',
  'Pidyon Haben',
  'Pinchas',
  'Potiphar',
  'Prayer',
  'Priestly Blessing',
  'Proverbs',
  'Psalms',
  'Purim',
  'Purity',
  'Rachel',
  'Red Heifer',
  'Reuben',
  'Revelation at Sinai',
  'Revenge',
  'Rivka',
  'Rosh Hashanah',
  'Rus',
  'Ruth',
  'Ruth',
  'Sabbath',
  'Sacrifices',
  'Samuel',
  'Sancherev',
  'Sarah',
  'Saul',
  'Sea of Reeds',
  'Second passover',
  'Selling Joseph',
  'Shabbat',
  'Shaul',
  'Shavuot',
  'Shechem',
  'Sheilah',
  'Shelach',
  'Shem',
  'Shema',
  'Shemini',
  'Shemittah',
  'Shemot',
  'Shibud Mitzrayim',
  'Shiluach Hakan',
  'Shimon',
  'Shir Hashirim',
  'Shlomo',
  'Shmuel',
  'Shoftim',
  'Simcha',
  'Simon',
  'Sinai',
  'Solomon',
  'Song of Songs',
  'Song of the sea',
  'Sotah',
  'Spies',
  'Splitting of the sea',
  'Sukkot',
  'Tabernacle',
  'Tahara',
  'Tamar',
  'Tefillah',
  'Tefillin',
  'Tehillim',
  'Ten Commandments',
  'Ten plagues',
  'Terumah',
  'Teshuva',
  'Tetzaveh',
  'The Fast of 17 Tammuz',
  'The High Holidays',
  'Tikvah',
  'Tithe',
  'Toldot',
  'Tower of Babel',
  'Tree of Knowledge',
  'Trees',
  'Trei Asar',
  'Tumah',
  'Tzaraat',
  'Tzaria',
  'Tzav',
  'Tzedakah',
  'Tzelaphchad',
  'Tzipora',
  'Tzitzit',
  'Tzlophchad',
  'Tzom Gedaliah',
  'Vaera',
  'Vashti',
  'Vayakhel',
  'Vayechi',
  'Vayelech',
  'Vayera',
  'Vayeshev',
  'Vayetze',
  'Vayigash',
  'Vayikra',
  'Vayishlach',
  'Vineyard',
  'Visiting the sick',
  'Waters of strife',
  'Yaakov',
  'Yafet',
  'Yair',
  'Yam Suf',
  'Yamim Noraim',
  'Yechezkel',
  'Yehoshua',
  'Yehuda',
  'Yeshaya',
  'Yibum',
  'Yirmiyahu',
  'Yishmael',
  'Yissachar',
  'Yitro',
  'Yitzchak',
  'Yitziat Mitzrayim',
  'Yocheved',
  'Yom Kippur',
  'Yonah',
  'Yonatan',
  'Yosef',
  'Yotam',
  'Yovel',
  'Zebulun',
  'Zeresh',
  'Zevulun'
];
