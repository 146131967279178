import request from '~utils/request';
import {
  ANALYTICS_LANDING_PAGEVIEW,
  ANALYTICS_PAGEVIEW,
  ANALYTICS_TRANSCRIPT_EXPANSION,
  ANALYTICS_MATERIALS_DOWNLOAD,
  ANALYTICS_CANCELLATION_INTENT,
  ANALYTICS_PRODUCERS_CIRCLE_EVENT_CLICK,
  ANALYTICS_PRODUCERS_CIRCLE_MATERIAl_DOWNLOAD,
  ANALYTICS_CURATION_PAGE_SEARCH_TERM,
  ANALYTICS_CURATION_PAGE_INTRO_VIDEO,
  ANALYTICS_CURATION_PAGE_FILTER,
  ANALYTICS_CURATION_CARD_SELECT,
  ANALYTICS_CURATION_CARD_SELECT_VIDEO,
  ANALYTICS_EXPERIMENT_VARIANT
} from '~hooks/useApi';

/*
 * NOTE: This method is being called on the first render ONLY. At this point we don't have `location` from Reach Router yet.
 */
export const trackLandingPageView = ({ fbEventId }) => {
  if (typeof window !== 'undefined') {
    const { pathname: page, search } = window.location;
    const { referrer } = document;

    const params = ANALYTICS_LANDING_PAGEVIEW({ fbEventId, page, referrer, search });
    request(params);
  }
};

/* NOTE: this method is being called on client-side navigation ONLY.
 * It is not being called on a first render of the page in browser!
 * `location` is assumed to be an object from Reach Router.
 * It is NOT exactly the same as `window.location`!
 */
export const trackPageView = ({ location, fbEventId }) => {
  if (typeof window !== 'undefined') {
    const { pathname: page, search, state: context } = location || window.location;
    const { referrer } = document;

    const params = ANALYTICS_PAGEVIEW({ fbEventId, page, referrer, search, context });
    request(params);
  }
};

export const trackFullTranscript = videoUid => {
  const params = ANALYTICS_TRANSCRIPT_EXPANSION({ video_slug: videoUid });
  request(params);
};

export const trackMaterialsDownload = (uid, filename, material_type) => {
  const params = ANALYTICS_MATERIALS_DOWNLOAD({ slug: uid, filename, type: material_type });
  request(params);
};

export const trackCancellationIntent = decision => {
  const params = ANALYTICS_CANCELLATION_INTENT({ decision });
  request(params);
};

export const producersCircleEventClick = (eventLink, eventTitle, eventDate) => {
  const params = ANALYTICS_PRODUCERS_CIRCLE_EVENT_CLICK({
    event_link: eventLink,
    event_title: eventTitle,
    event_date: eventDate
  });
  return request(params);
};

export const producersCircleMaterialClick = (materialLink, materialTitle) => {
  const params = ANALYTICS_PRODUCERS_CIRCLE_MATERIAl_DOWNLOAD({
    material_link: materialLink,
    material_title: materialTitle
  });
  return request(params);
};

export const trackCurationIntroButton = () => {
  if (typeof window !== 'undefined') {
    const { pathname: page } = window.location;
    const params = ANALYTICS_CURATION_PAGE_INTRO_VIDEO({
      slug: page
    });

    request(params);
  }
};

export const trackCurationPageSearchTerm = searchTerm => {
  if (typeof window !== 'undefined') {
    const { pathname: slug } = window.location;
    const params = ANALYTICS_CURATION_PAGE_SEARCH_TERM({
      slug,
      searchTerm
    });

    request(params);
  }
};

export const trackCurationSortingMethod = sortingMethod => {
  if (typeof window !== 'undefined') {
    const { pathname: slug } = window.location;
    const params = ANALYTICS_CURATION_PAGE_FILTER({
      slug,
      sortingMethod
    });

    request(params);
  }
};

export const trackCurationCardSelect = card => {
  if (typeof window !== 'undefined') {
    const { pathname: slug } = window.location;
    const params = ANALYTICS_CURATION_CARD_SELECT({
      slug,
      card
    });

    request(params);
  }
};

export const trackCurationPlaylistSelected = videoUid => {
  if (typeof window !== 'undefined') {
    const { pathname: slug } = window.location;
    const params = ANALYTICS_CURATION_CARD_SELECT_VIDEO({
      slug,
      videoUid
    });

    request(params);
  }
};

export const trackExperimentVariant = (experimentId, variant) => {
  const params = ANALYTICS_EXPERIMENT_VARIANT({
    experimentId,
    variant
  });

  request(params);
};

/**
 * @see frontend/src/prismic/unfurl/podcasts.js
 * @param episodeFullData Data taken from the unfurl of the podcast page
 * @return {{}} Data which can be safely sent to the backend in an analytics event
 */
export const makeAnalyticsPayloadForPodcastPageEpisode = episodeFullData => ({
  uid: episodeFullData.uid,
  part: episodeFullData.part,
  title: episodeFullData.title,
  season: episodeFullData.season,
  episode: episodeFullData.episode,
  prismicId: episodeFullData.prismicId,
  podcastUid: episodeFullData.podcastUid,
  spotifyUrl: episodeFullData.spotifyUrl, // it was super hard to resist the urge to call this field `spotifyLink` to make the perfect ladder in the field names' length
  podcastTitle: episodeFullData.podcastTitle
});

/**
 * This method is deliberately made with a separate name, because formally it takes data from the data of a different Prismic document
 * but is deliberately made to return the data in the same format to simplify analytics tracking
 * At the moment of writing this code an unfurl of a podcast episode and an unfurl of a single episode inside a whole podcast emit data in almost the same format with the same field names.
 *
 * @see frontend/src/prismic/unfurl/podcast-episode-description.js
 * @param episodeFullData Data taken from the unfurl of the podcast episode page
 * @return {{}} Data which can be safely sent to the backend in an analytics event
 */
export const makeAnalyticsPayloadForPodcastEpisodePageEpisode =
  makeAnalyticsPayloadForPodcastPageEpisode;
