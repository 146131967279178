import React from 'react';
import PropTypes from 'prop-types';
import { VIEWS } from './constants';
import InitialView from './steps/InitialView';
import LoginView from './steps/LoginView';
import CreateAccountView from './steps/CreateAccountView';
import AccountCreatedView from './steps/AccountCreatedView';
import ResetPasswordView from './steps/ResetPasswordView';
import ResetPasswordLinkSentView from './steps/ResetPasswordLinkSentView';

const ViewLayout = ({ view, setView, username, setUsername, handleClose }) => {
  switch (view) {
    case VIEWS.INITIAL_VIEW:
      return <InitialView changeView={setView} username={username} changeUsername={setUsername} />;
    case VIEWS.EMAIL_LOGIN_VIEW:
      return <LoginView changeView={setView} username={username} onSuccess={handleClose} />;
    case VIEWS.CREATE_ACCOUNT_VIEW:
      return <CreateAccountView changeView={setView} username={username} />;
    case VIEWS.CREATE_ACCOUNT_SUCCESS_VIEW:
      return <AccountCreatedView onClose={handleClose} />;
    case VIEWS.RESET_PASSWORD_VIEW:
      return (
        <ResetPasswordView changeView={setView} username={username} changeUsername={setUsername} />
      );
    case VIEWS.RESET_PASSWORD_LINK_SENT_VIEW:
      return <ResetPasswordLinkSentView changeView={setView} username={username} />;
    default:
      return null;
  }
};

ViewLayout.propTypes = {
  view: PropTypes.oneOf([
    VIEWS.INITIAL_VIEW,
    VIEWS.EMAIL_LOGIN_VIEW,
    VIEWS.RESET_PASSWORD_VIEW,
    VIEWS.RESET_PASSWORD_LINK_SENT_VIEW,
    VIEWS.CREATE_ACCOUNT_VIEW,
    VIEWS.CREATE_ACCOUNT_SUCCESS_VIEW
  ]).isRequired,
  setView: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ViewLayout;
