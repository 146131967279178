import React from 'react';
import useConversionFramework from '~hooks/useConversionFramework';
import Link from './Link';

const OwnWebsitePromoLink = () => {
  const { isMarketingSite } = useConversionFramework();
  const websiteSubDomain = isMarketingSite ? 'members' : 'www';
  const websiteVisibleName = `${websiteSubDomain}.alephbeta.org`;
  const websiteUrl = `https://${websiteVisibleName}/`;

  return <Link to={websiteUrl}>{websiteVisibleName}</Link>;
};

OwnWebsitePromoLink.propTypes = {};

export default OwnWebsitePromoLink;
