import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import he from 'he';
import clsx from '~styles/clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import RichText from '~components/RichText';
import usePrevious from '~hooks/usePrevious';

const useStyles = makeStyles(theme => ({
  expansionButtonStyle: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  content: {
    textAlign: props => props.textAlign,
    ...theme.typography.subtitle1,
    '& > div': {
      '& > h1': {
        ...theme.typography.h2,
        fontSize: '3.2rem',
        textAlign: 'center',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockEnd: '-15px'
      },
      '& > h2': {
        fontSize: '2.5rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockStart: '1em',
        marginBlockEnd: '0.5em',
        letterSpacing: '0em'
      },
      '& > h3': {
        fontSize: '2rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockStart: '0.7em',
        marginBlockEnd: '0.4em',
        letterSpacing: '0em'
      },
      '& > h4': {
        fontSize: '1.7rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockStart: '0.7em',
        marginBlockEnd: '0.35em',
        letterSpacing: '0em'
      },
      '& > h5': {
        ...theme.typography.h5,
        marginBlockStart: '0.6em',
        marginBlockEnd: '0.3em'
      },
      '& > h6': {
        ...theme.typography.h6,
        marginBlockStart: '0.6em',
        marginBlockEnd: '0.3em'
      },
      '& > p': {
        ...theme.typography.subtitle1
      },
      '& > ol, ul': {
        ...theme.typography.subtitle1
      },
      '& img': {
        maxWidth: '100%',
        display: 'flex',
        margin: '0 auto'
      },
      '& > p > .align-left': {
        textAlign: 'left',
        display: 'block'
      },
      '& > p > .align-right': {
        textAlign: 'right',
        display: 'block'
      },
      '& > pre': {
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
      },
      '& > pre > iframe': {
        [theme.breakpoints.only('xs')]: {
          maxWidth: '100%',
          maxHeight: '100%'
        }
      },
      '& > p > .align-center': {
        textAlign: 'center',
        display: 'block'
      },
      '& > a': {
        color: '#438dce',
        textDecoration: 'none'
      },

      '& > * > .h1': {
        ...theme.typography.h2,
        fontSize: '3.2rem',
        textAlign: 'center',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockEnd: '-15px'
      },
      '& > * > .h2': {
        fontSize: '2.5rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockStart: '1em',
        marginBlockEnd: '0.5em',
        letterSpacing: '0em'
      },
      '& > * > .h3': {
        fontSize: '2rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockStart: '0.7em',
        marginBlockEnd: '0.4em',
        letterSpacing: '0em'
      },
      '& > * > .h4': {
        fontSize: '1.7rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.04',
        marginBlockStart: '0.7em',
        marginBlockEnd: '0.35em',
        letterSpacing: '0em'
      },
      '& > * > .h5': {
        ...theme.typography.h5,
        marginBlockStart: '0.6em',
        marginBlockEnd: '0.3em'
      },
      '& > * > .h6': {
        ...theme.typography.h6,
        marginBlockStart: '0.6em',
        marginBlockEnd: '0.3em'
      }
    }
  }
}));

const HtmlExcerpt = ({
  html,
  checkAccessAndTrackClick,
  videoUid,
  session,
  hasUserSufficientAccess,
  handleInsufficientAccess,
  showExpansionButton,
  CustomReadMoreBtn,
  customReadMoreProps,
  additionalClassName,
  ...rest
}) => {
  const classes = useStyles();
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [isTextClamped, setIsTextClamped] = useState(false);
  const decodedHtml = he.decode(html);

  const prevHtml = usePrevious(html);

  useEffect(() => {
    if (isTextExpanded && prevHtml !== html) {
      setIsTextExpanded(false);
    }
  }, [prevHtml, html, isTextExpanded]);

  const handleTextExpansion = () => {
    if (checkAccessAndTrackClick && !isTextExpanded) {
      if (!hasUserSufficientAccess) {
        handleInsufficientAccess();
        return;
      }
      checkAccessAndTrackClick(videoUid, session, setIsTextExpanded);
    } else {
      setIsTextExpanded(prevIsTextExpanded => !prevIsTextExpanded);
    }
  };

  const handleReflow = rleState => {
    const { clamped } = rleState;
    setIsTextClamped(clamped);
  };

  return (
    <>
      {!isTextExpanded && (
        <Box my={2}>
          <HTMLEllipsis
            unsafeHTML={decodedHtml}
            component={Box}
            maxLine={3}
            onReflow={handleReflow}
            ellipsis="..."
            basedOn="words"
            className={clsx(classes.content, additionalClassName)}
          />
        </Box>
      )}

      {isTextExpanded && <RichText html={decodedHtml} {...rest} />}

      {isTextClamped &&
        showExpansionButton &&
        (CustomReadMoreBtn ? (
          <CustomReadMoreBtn onClick={handleTextExpansion} {...customReadMoreProps}>
            {isTextExpanded ? 'Read Less' : 'Read More'}
          </CustomReadMoreBtn>
        ) : (
          <Typography
            variant="subtitle2"
            className={classes.expansionButtonStyle}
            onClick={handleTextExpansion}
          >
            {isTextExpanded ? 'Read Less' : 'Read More'}
          </Typography>
        ))}
    </>
  );
};

HtmlExcerpt.propTypes = {
  html: PropTypes.string.isRequired,
  videoUid: PropTypes.string,
  session: PropTypes.shape({}),
  checkAccessAndTrackClick: PropTypes.func,
  hasUserSufficientAccess: PropTypes.bool,
  handleInsufficientAccess: PropTypes.func,
  showExpansionButton: PropTypes.bool,
  CustomReadMoreBtn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.exact(undefined)
  ]),
  customReadMoreProps: PropTypes.shape({}),
  additionalClassName: PropTypes.string
};

HtmlExcerpt.defaultProps = {
  checkAccessAndTrackClick: null,
  videoUid: null,
  hasUserSufficientAccess: false,
  handleInsufficientAccess: null,
  session: {},
  showExpansionButton: true,
  CustomReadMoreBtn: undefined,
  customReadMoreProps: {},
  additionalClassName: null
};

export default HtmlExcerpt;
