import PropTypes from 'prop-types';
import React, { useReducer, useMemo } from 'react';
import globalReducer from './reducer';

const initialState = {
  intercomInitialized: false,
  intercomMessageOpen: false,
  isSpotifyPlayerOpen: false,
  isBottomBannerHidden: false,
  isMarqueeBannerHidden: false,
  isPlaylistPageSubscribeBannerVisible: true
};

const GlobalContext = React.createContext({
  state: initialState,
  dispatch: () => {}
});

export const useGlobalStore = () => React.useContext(GlobalContext);

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const value = useMemo(
    () => ({
      state,
      dispatch
    }),
    [state]
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GlobalContextProvider;
