import { get } from 'lodash';
import { extractSeoData } from '~src/utils/unfurl-helpers';

const extractMaterialsForCategory = (prismicData, category) =>
  get(prismicData, 'prismicGuides.data.body', [])
    .filter(({ primary }) => primary.category === category)
    .map(({ primary, items }) => ({
      ...primary,
      materials: items.map(({ panel_materials }) => ({
        title: get(panel_materials, 'document.data.title'),
        material_type: get(panel_materials, 'document.data.material_type'),
        required_subscription_plan: get(
          panel_materials,
          'document.data.required_subscription_plan'
        ),
        file: get(panel_materials, 'document.data.file')
      }))
    }));

export default prismicData => ({
  parshaMaterials: extractMaterialsForCategory(prismicData, 'Parsha'),
  holidayMaterials: extractMaterialsForCategory(prismicData, 'Holiday'),
  seo: extractSeoData(prismicData, 'prismicGuides')
});
