import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Select from './Select';

const useStyles = makeStyles(theme => ({
  titleSection: {
    marginBottom: theme.spacing(6)
  }
}));

const Events = ({ children, title, allCategories, defaultCategory, categoryName }) => {
  const classes = useStyles();
  const [category, setCategory] = useState(defaultCategory);
  let filteredChildrenByCategory = children;

  if (category !== 'All') {
    filteredChildrenByCategory = children.filter(child => child.props.category === category);
  }

  return (
    <Box component={Container} size={50} pt={4} pb={4}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
        className={classes.titleSection}
      >
        <Grid item xs={12} md>
          <Typography variant="h3">{title}</Typography>
        </Grid>

        {allCategories.lenght > 0 && (
          <Grid item xs={12} md={4}>
            <Select
              category={category}
              setCategory={setCategory}
              categories={allCategories}
              categoryName={categoryName}
            />
          </Grid>
        )}
      </Grid>

      <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={4}>
        {filteredChildrenByCategory &&
          filteredChildrenByCategory.map(child => (
            <Grid item xs={12} sm={6} md={4} key={`event-card-key-${child.key}`}>
              {child}
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

Events.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  allCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultCategory: PropTypes.string.isRequired,
  categoryName: PropTypes.string
};

Events.defaultProps = {
  categoryName: 'Category'
};

export default Events;
