import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Box from '@material-ui/core/Box';
import PrimaryVariantButton from './variants/PrimaryVariantButton';
import SecondaryVariantButton from './variants/SecondaryVariantButton';

const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID; // eslint-disable-line

const VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

const FacebookButtonSubmit = ({ children, callback, variant, ...rest }) => (
  <Box py={1}>
    <FacebookLogin
      version="5.0"
      appId={FACEBOOK_APP_ID}
      callback={callback}
      render={renderProps =>
        variant === VARIANTS.SECONDARY ? (
          <SecondaryVariantButton onClick={renderProps.onClick} {...rest}>
            {children}
          </SecondaryVariantButton>
        ) : (
          <PrimaryVariantButton onClick={renderProps.onClick} {...rest}>
            {children}
          </PrimaryVariantButton>
        )
      }
    />
  </Box>
);

FacebookButtonSubmit.propTypes = {
  children: PropTypes.node.isRequired,
  callback: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([VARIANTS.PRIMARY, VARIANTS.SECONDARY])
};

FacebookButtonSubmit.defaultProps = {
  variant: VARIANTS.PRIMARY
};

export default FacebookButtonSubmit;
