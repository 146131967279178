import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { useTestId } from '~utils/set-testid';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #b5b5b5i'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  passwordCursor: {
    cursor: 'pointer',
    userSelect: 'none'
  },
  focused: {}
}));

const PasswordInput = ({ form, ...rest }) => {
  const classes = useStyles();
  const testid = useTestId(`${form}-password-input`);
  const [isVisible, setIsVisible] = useState(false);

  const handleClickShowPassword = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <TextField
      label="Password"
      type={isVisible ? 'text' : 'password'}
      name="password"
      autoComplete="password"
      fullWidth
      required
      margin="normal"
      variant="filled"
      inputProps={{
        minLength: 6,
        ...testid
      }}
      // eslint-disable-next-line
      InputProps={{
        classes: { root: classes.root, focused: classes.focused },
        disableUnderline: true,
        endAdornment: (
          <InputAdornment onClick={handleClickShowPassword}>
            <Typography variant="body2" className={classes.passwordCursor}>
              {isVisible ? 'Hide Password' : 'Show Password'}
            </Typography>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  );
};

PasswordInput.propTypes = {
  form: PropTypes.string.isRequired
};

export default PasswordInput;
