import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Box from '@material-ui/core/Box';
import { Title } from '~src/components/Typography/Typography';

const useStyles = makeStyles({
  title: ({ color, fontSize }) => ({
    color,
    fontSize,
    fontWeight: '600',
    marginBottom: '40px'
  })
});

const TitleContainer = ({ orderDirection, children, title, fontSize, color, ...rest }) => {
  const classes = useStyles({ fontSize, color });
  return (
    <Box {...rest}>
      <Title className={classes.title} gutterBottom>
        {title}
      </Title>
      <Box
        display="flex"
        flexDirection={orderDirection}
        justifyContent={orderDirection === 'column' ? 'center' : 'flex-start'}
        alignItems={orderDirection !== 'column' ? 'center' : 'flex-start'}
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
};

TitleContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  orderDirection: PropTypes.oneOf(['column', 'row']),
  color: PropTypes.string,
  fontSize: PropTypes.string
};

TitleContainer.defaultProps = {
  children: null,
  orderDirection: 'column',
  color: '#000000',
  fontSize: '34px'
};

export default TitleContainer;
