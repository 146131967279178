import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import RichText from '~src/components/RichText';
import AlephBetaLogo from './images/aleph-beta-podcast-logo.png';

const useStyles = makeStyles({
  image: {
    maxWidth: '100%',
    display: 'flex',
    height: 'auto'
  }
});

const PodcastDescription = ({ podcastSubtitle, sponsorship }) => {
  const classes = useStyles();

  return (
    <Box paddingTop="20px" textAlign="center" width="100%">
      <RichText html={sponsorship} mode="podcastSubtitle" />
      <Box display="inline-block" margin="12px 0">
        <img src={AlephBetaLogo} className={classes.image} alt="Alelph Beta logo" height="60px" />
      </Box>
      <RichText html={podcastSubtitle} mode="podcastSubtitle" />
    </Box>
  );
};

export default PodcastDescription;

PodcastDescription.propTypes = {
  podcastSubtitle: PropTypes.string.isRequired,
  sponsorship: PropTypes.string.isRequired
};
