import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';

const getVariantStyles = variant => {
  switch (variant) {
    case 'dark':
      return {
        color: '#FFFFFF',
        backgroundColor: '#272727',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#161616'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#000000'
        },
        boxShadow: '2px 2px 0px rgba(255, 255, 255, 0.25)'
      };
    case 'blue':
      return {
        color: '#FFFFFF',
        backgroundColor: '#278AE7',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#1080E9'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#0677E1'
        }
      };
    case 'light':
    default:
      return {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#F8F8F8'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#EFEFEF'
        },
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.25)'
      };
  }
};

const getButtonSizeStyles = size => {
  switch (size) {
    case 'small':
      return {
        fontSize: 13,
        lineHeight: '13px',
        fontWeight: 700,
        borderRadius: 20
      };
    case 'normal':
    default:
      return {
        fontSize: 18,
        lineHeight: '18px',
        fontWeight: 500,
        borderRadius: 40
      };
  }
};

const getSizeStyles = size => {
  switch (size) {
    case 'small':
      return {
        padding: '10px 23px 10px 16px'
      };
    case 'normal':
    default:
      return {
        padding: '13px 34px 13px 22px'
      };
  }
};

const useStyles = makeStyles(() => ({
  button: props => ({
    display: 'block',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    zIndex: 10,
    // size
    ...getButtonSizeStyles(props.size),
    // theme variant
    ...getVariantStyles(props.variant)
  }),
  textArea: props => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    ...getSizeStyles(props.size)
  })
}));

const RoundedLinkIconButton = ({ text, to, icon, variant, size }) => {
  const classes = useStyles({ variant, size });
  return (
    <Link to={to} className={classes.button}>
      <div className={classes.textArea}>
        {icon}
        {text}
      </div>
    </Link>
  );
};

RoundedLinkIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['normal', 'small'])
};

RoundedLinkIconButton.defaultProps = {
  variant: 'light',
  size: 'normal'
};

export default RoundedLinkIconButton;
