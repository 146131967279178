import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RichText from '~components/RichText';
import QuoteIcon from './quote.inline.svg';

const getVariantRootStyles = variant => {
  if (variant) {
    return variant;
  }
  return {
    backgroundColor: '#397BB9',
    color: '#FFFFFF'
  };
};

const getQuoteStyles = variant => {
  if (variant?.color) {
    return {
      '& path': {
        fill: variant?.color
      }
    };
  }
  return {
    '& path': {
      fill: '#FFFFFF'
    }
  };
};

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 28,
    padding: '35px 60px 35px 60px',
    height: '570px',
    width: '418px',
    transform: `rotate(${props.rotation}deg)`,
    ...getVariantRootStyles(props.variant),
    [theme.breakpoints.down('xs')]: {
      padding: '35px 30px 30px 30px',
      height: '376px',
      width: '274px'
    }
  }),
  testimonial: {
    height: '100%',
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  author: {
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  designation: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    }
  },
  quoteIcon: props => ({
    ...getQuoteStyles(props.variant),
    [theme.breakpoints.down('xs')]: {
      width: 40
    }
  })
}));

const TestimonialCard = props => {
  const { testimonial, author, designation, variant, rotation } = props;
  const classes = useStyles({ variant, rotation });

  return (
    <Box component="div" className={classes.root}>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <QuoteIcon className={classes.quoteIcon} />
        <Box component="div" display="flex" flexDirection="column" justifyContent="center">
          <RichText html={testimonial} externalClassName={classes.testimonial} />
        </Box>
        <Box component="div" display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="body1" component="p" className={classes.author}>
            {author}
          </Typography>
          <Typography variant="body1" component="p" className={classes.designation}>
            {designation}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

TestimonialCard.propTypes = {
  testimonial: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  designation: PropTypes.string,
  variant: PropTypes.PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string
  }),
  rotation: PropTypes.number
};

TestimonialCard.defaultProps = {
  designation: '',
  variant: null,
  rotation: 0
};

export default TestimonialCard;
