import createPersistedState from 'use-persisted-state';

const localStorageUserKey = 'recent-searches';
const useRecentSearches = createPersistedState(localStorageUserKey);

export default (searchTerms = []) => {
  const [recentSearchesState, setRecentSearchesState] = useRecentSearches({
    searchTerms
  });

  return [recentSearchesState, setRecentSearchesState];
};
