import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

const StyledMuiContainer = styled(MuiContainer)`
  position: relative;
  display: flex !important;
  flex-direction: column;

  ${props =>
    props.background &&
    `&:before {
      position: absolute;
      right: 50%;
      width: 54vw;
      height: 100%;
      margin-left: -50vw;
      margin-right: -50vw;
      z-index: ${props.zIndex};
      content: '';
      background: ${props.background};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }`}

  ${props =>
    props.overlay &&
    `&:after { position: absolute;
      right: 50%;
      width: 50vw;
      height: 100%;
      margin-left: -50vw;
      margin-right: -50vw;
      z-index: ${props.zIndex};
      content: '';
      background: ${props.overlay};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }`}
`;

const HolidayHeroContainer = ({ children, background, overlay, zIndex, ...rest }) => (
  <StyledMuiContainer background={background} overlay={overlay} zIndex={zIndex} {...rest}>
    {children}
  </StyledMuiContainer>
);

HolidayHeroContainer.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  overlay: PropTypes.string,
  zIndex: PropTypes.number
};

HolidayHeroContainer.defaultProps = {
  background: '',
  overlay: '',
  zIndex: -2
};

export default HolidayHeroContainer;
