import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const Select = ({ category, setCategory, categories, categoryName }) => (
  <FormControl fullWidth>
    <InputLabel htmlFor="category">{categoryName}</InputLabel>
    <MuiSelect
      value={category}
      onChange={event => setCategory(event.target.value)}
      inputProps={{
        id: 'category'
      }}
    >
      <MenuItem value="All">All</MenuItem>
      {categories &&
        categories.map(_category => (
          <MenuItem key={_category} value={_category}>
            {_category}
          </MenuItem>
        ))}
    </MuiSelect>
  </FormControl>
);

Select.propTypes = {
  category: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  categoryName: PropTypes.string.isRequired
};

export default Select;
