/* eslint-disable func-names */
// eslint-disable-line
import createHmac from 'create-hmac';

const INTERCOM_APP_ID = process.env.GATSBY_INTERCOM_APP_ID;

const INTERCOM_IDENTITY_VERIFICATION_SECRET =
  process.env.GATSBY_INTERCOM_IDENTITY_VERIFICATION_SECRET;

const createUserHash = ({ id }) => {
  if (id) {
    return createHmac('sha256', INTERCOM_IDENTITY_VERIFICATION_SECRET)
      .update(String(id))
      .digest('hex');
  }

  return null;
};

const createUserName = ({ meta_first_name, meta_last_name }) => {
  let res = '';

  if (meta_first_name) {
    res += `${meta_first_name}`;
  }

  if (meta_last_name) {
    res += ` ${meta_last_name}`;
  }

  return res;
};

const createUserPaymentGateway = ({
  payment_gateway_stripe_id,
  payment_gateway_appstore_id,
  payment_gateway_gplay_id,
  payment_gateway_paypal_id
}) => {
  if (payment_gateway_stripe_id) {
    return 'Stripe';
  }
  if (payment_gateway_paypal_id) {
    return 'PayPal';
  }
  if (payment_gateway_appstore_id) {
    return 'AppStore';
  }
  if (payment_gateway_gplay_id) {
    return 'GooglePlay';
  }

  return null;
};

const getIntercomSettings = user => {
  if (!user || !user.id) {
    return {};
  }

  const {
    id,
    username,
    is_newsletter_action,
    is_verified,
    meta_phone,
    meta_address,
    meta_city,
    meta_country,
    meta_zip,
    subscription_plan_id,
    subscription_plan_period,
    subscription_joined_at,
    has_failed_charges,
    access_level,
    created_at
  } = user;
  return {
    user_id: id,
    email: username,
    receive_newsletters: is_newsletter_action,
    is_verified,
    name: createUserName(user),
    phone: meta_phone,
    address: meta_address,
    city: meta_city,
    country: meta_country,
    zip_code: meta_zip,
    subscription_plan: subscription_plan_id,
    subscription_period: subscription_plan_period,
    subscription_payment_gateway: createUserPaymentGateway(user),
    subscribed: subscription_joined_at,
    access_level,
    failed_charge: has_failed_charges,
    created_at,
    user_hash: createUserHash(user)
  };
};

/**
 * Cycle Intercom, to drop any user tracking cookies.
 * Used in logout flow.
 */
export function restartIntercom() {
  window.Intercom('shutdown');
  window.Intercom('boot', { app_id: INTERCOM_APP_ID });
}

/**
 * Update the current Intercom session with the actual user data.
 * @param {User} user Data stored about user in the `useUserContext` hook, field `session.user`.
 *                    If this data is empty (developer mistake), don't call `update` at all.
 */
export function updateIntercomUser(user) {
  const userData = getIntercomSettings(user);
  if (!userData || !userData.user_id) {
    return;
  }
  window.Intercom('update', { ...userData });
}
