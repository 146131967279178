import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import unfurl from '~prismic/unfurl/guides';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import Container from '~components/Container';
import HeroTextImage from '~components/slices/hero-text-image';
import { GuidesFAQ, GuidesParshaGuides, GuidesHolidayGuides } from '~components/guides';

import GuidesHeroImg from '~images/guides/parsha-guide-header.png';

const GUIDES_HERO = {
  title: 'Aleph Beta Guides',
  subtitle: 'Beautiful, printable Torah & Holiday Guides',
  link: {
    url: '/guides/achrei-mot-study-guide'
  },
  link_title: 'View Sample Guide',
  foreground_image: {
    url: `${GuidesHeroImg}`,
    alt: 'alt'
  },
  text_color: '#000000',
  background_color: '#f5f5f5',
  isHeroImageLink: false
};

const Guides = ({ data }) => {
  const { parshaMaterials, holidayMaterials, seo } = unfurl(data);

  return (
    <Page>
      <SEO {...seo} />

      <Container>
        <HeroTextImage {...GUIDES_HERO} />

        <GuidesFAQ />

        <GuidesParshaGuides materials={parshaMaterials} />

        <GuidesHolidayGuides materials={holidayMaterials} />
      </Container>
    </Page>
  );
};

export default withPrismicPreview(Guides);

Guides.propTypes = {
  data: PropTypes.shape().isRequired
};

export const query = graphql`
  {
    prismicGuides {
      _previewable
      data {
        body {
          ... on PrismicGuidesDataBodyExpansionPanel {
            primary {
              panel_title
              category
            }
            items {
              panel_materials {
                url
                document {
                  ... on PrismicMaterial {
                    data {
                      title
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
      }
    }
  }
`;
