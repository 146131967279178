import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Link from '~components/Link';

import holidayGuidesIcon from '~images/holidays/holidays-guides-icon.png';
import holidayNewsletterIcon from '~images/holidays/holidays-newsletter-icon.png';
import holidayBooksIcon from '~images/holidays/holidays-books-icon.png';

const useStyles = makeStyles(theme => ({
  image: {
    marginRight: theme.spacing(2)
  }
}));

const Resources = () => {
  const classes = useStyles();
  return (
    <Box component={Container} center pt={8} pb={8}>
      <Box mt={4} mb={8} textAlign="center">
        <Typography variant="h3">Jewish Holidays Resources</Typography>
      </Box>

      <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box mb={4} display="flex" justifyItems="center" alignItems="center">
            <img src={holidayGuidesIcon} alt="holiday guides" className={classes.image} />
            <Typography variant="h4">Holiday Guides</Typography>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            These full color printable PDFs offer you an exciting guided path through the Torah
            sources related to various Jewish holidays. If you get wrapped up in a stimulating
            discussion and never make it to page 2, that would be considered a great success
          </Typography>
          <Box pt={1}>
            <Link component={GatsbyLink} to="/subscribe" variant="subtitle1" color="primary">
              Start learning with the guides
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={4} display="flex" justifyItems="center" alignItems="center">
            <img
              src={holidayNewsletterIcon}
              alt="aleph beta newsletter"
              className={classes.image}
            />
            <Typography variant="h4">Aleph Beta Newsletter</Typography>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            Enhance your experience of the Jewish holidays with amazing videos delivered straight to
            your inbox.
          </Typography>
          <Box pt={1}>
            <Link component={GatsbyLink} to="/subscribe" variant="subtitle1" color="primary">
              Sign me up!
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box mb={4} display="flex" justifyItems="center" alignItems="center">
            <img src={holidayBooksIcon} alt="books" className={classes.image} />
            <Typography variant="h4">Books</Typography>
          </Box>
          <Typography variant="subtitle1" gutterBottom>
            Read Rabbi Fohrman’s best-selling books, and embark on a thrilling journey through the
            Jewish holidays as you’ve never seen them before.
          </Typography>
          <Box pt={1}>
            <Link component={GatsbyLink} to="/subscribe" variant="subtitle1" color="primary">
              Check them out
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Resources;
