import React from 'react';
import PropTypes from 'prop-types';

import { Element } from 'react-scroll';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { trackCurationPageSearchTerm } from '~utils/analytics';
import { pushDataLayerEvent } from '~utils/data-layer';

import Container from '~components/Container';
import CurationPageStoryCard from './components/CurationPageStoryCard';
import CurationPageSortMenu from './components/CurationPageSortMenu';

const useStyles = makeStyles(theme => ({
  gridTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px'
  },
  searchInputAdornment: props => ({
    visibility: props.searchQuery ? 'visible' : 'hidden'
  }),
  scrollElement: {
    width: '100%'
  },
  gridSpacing: {
    marginTop: '65px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '42px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '32px'
    }
  }
}));

const CurationPageStoriesGrid = ({
  title,
  cards,
  handleClick,
  isDrawerOpen,
  setSearchQuery,
  searchQuery,
  selectedPlaylist,
  handleSortClick
}) => {
  const classes = useStyles({ searchQuery, isDrawerOpen });

  const searchInputOnBlur = event => {
    if (event.currentTarget.value) {
      trackCurationPageSearchTerm(event.currentTarget.value);
      pushDataLayerEvent('curation_page_search_on_blur', { searchTerm: event.currentTarget.value });
    }
  };

  const handleChange = event => setSearchQuery(event.target.value);

  const clearSearchInput = () => setSearchQuery('');

  return (
    <Container className={classes.gridSpacing} size={isDrawerOpen ? 100 : 0} maxWidth="lg">
      <Element name="storyGridElement" className={classes.scrollElement}>
        <Box display="flex" width="100%" mb={3} alignItems="flex-end">
          <Typography variant="subtitle1" className={classes.gridTitle}>
            {title}
          </Typography>
          <span style={{ flex: 1 }} />
          <Hidden xsDown>
            <Box display="flex" alignItems="flex-end">
              <SearchIcon style={{ marginRight: '5px' }} />
              <FormControl>
                <InputLabel htmlFor="searchInput">Search</InputLabel>
                <Input
                  id="searchInput"
                  value={searchQuery}
                  type="text"
                  onBlur={searchInputOnBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end" className={classes.searchInputAdornment}>
                      <IconButton size="small" onClick={clearSearchInput}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Hidden>

          <CurationPageSortMenu handleSortClick={handleSortClick} />
        </Box>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          {cards &&
            cards.map(card => (
              <Grid
                key={card.card_title}
                item
                xs={6}
                md={isDrawerOpen ? 4 : 2}
                lg={isDrawerOpen ? 6 : 3}
              >
                <CurationPageStoryCard
                  handleClick={handleClick}
                  selectedPlaylist={selectedPlaylist}
                  {...card}
                />
              </Grid>
            ))}
        </Grid>
      </Element>
    </Container>
  );
};

CurationPageStoriesGrid.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool,
  setSearchQuery: PropTypes.func.isRequired,
  selectedPlaylist: PropTypes.string,
  handleSortClick: PropTypes.func.isRequired,
  searchQuery: PropTypes.string
};

CurationPageStoriesGrid.defaultProps = {
  selectedPlaylist: null,
  searchQuery: null,
  isDrawerOpen: null
};

export default CurationPageStoriesGrid;
