import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { useTheme, Grid, Typography, useMediaQuery } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Box from '@material-ui/core/Box';
import NavigateBefore from '@material-ui/icons/ChevronLeft';

import { Text } from '~src/components/Typography/Typography';
import Container from '~components/Container';
import RichText from '~components/RichText';
import PodcastButton from '../PodcastButton';
import PodcastShareButton from '../PodcastShareButton';

const useStyles = makeStyles(theme => ({
  title: {
    color: '#ffffff',
    fontWeight: 500
  },
  spacingY: {
    margin: `${theme.spacing(6)}px 0`
  },
  spacingX: {
    margin: `0 ${theme.spacing(1)}px`
  },
  reverse: {
    transform: 'rotate(180deg)'
  },
  backBtn: {
    color: '#fff',
    borderWidth: '3px',
    borderColor: '#fff',
    borderStyle: 'solid',
    borderRadius: '50%',
    fontSize: '48px',
    cursor: 'pointer'
  }
}));

const PodcastListeningHero = ({
  part,
  title,
  season,
  episode,
  coverColor,
  playButtonColor,
  playButtonTextColor,
  podcastUid,
  description,
  playPodcast,
  podcastTitle,
  podcastCover,
  seasonUid
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const navigateToSeries = () => navigate(`/podcasts/${podcastUid}?season=${seasonUid}`);

  return (
    <Container center="horizontal" background={coverColor}>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-around"
        space={2}
        className={classes.spacingY}
      >
        <Grid item container xs={12} md={5}>
          <Grid item container direction="row" justifyContent="center" spacing={2}>
            {isMdUp && (
              <Grid item>
                <NavigateBefore className={classes.backBtn} onClick={navigateToSeries} />
              </Grid>
            )}
            <Grid item>
              {podcastCover && <img src={podcastCover.url} alt={podcastCover.alt} height={200} />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={7} justifyContent={isMdUp ? 'flex-start' : 'center'}>
          <Box
            padding="1rem 0"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Text>
              {podcastTitle} | Season {season} | Episode {episode} {part && `| Part ${part}`}
            </Text>
            <Typography variant="h4" className={classes.title} gutterBottom>
              {title}
            </Typography>
            <RichText html={description} textColor="#fff" verticalSpacing={0} mode="paragraph" />
          </Box>
          <Grid item container spacing={2} justifyContent={isMdUp ? 'flex-start' : 'center'}>
            <Grid item>
              <PodcastButton
                btnVariant="colored"
                backgroundColor={playButtonColor}
                color={playButtonTextColor}
                disableFocusRipple
                disableRipple
                onClick={playPodcast}
              >
                <PlayArrowIcon
                  htmlColor={playButtonTextColor}
                  height={14}
                  width={14}
                  className={classes.playIcon}
                />
                START LISTENING
              </PodcastButton>
            </Grid>

            <Grid item>
              <PodcastButton
                btnVariant="transparent"
                disableFocusRipple
                disableRipple
                onClick={navigateToSeries}
              >
                SEE SERIES
              </PodcastButton>
            </Grid>

            <Grid item>
              <PodcastShareButton contentType="Podcast" title={podcastTitle} videoTitle={title} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

PodcastListeningHero.propTypes = {
  coverColor: PropTypes.string.isRequired,
  playButtonColor: PropTypes.string,
  playButtonTextColor: PropTypes.string,
  podcastCover: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired,
  part: PropTypes.number,
  description: PropTypes.string.isRequired,
  episode: PropTypes.number,
  season: PropTypes.number,
  playPodcast: PropTypes.func.isRequired,
  podcastTitle: PropTypes.string.isRequired,
  podcastUid: PropTypes.string.isRequired,
  seasonUid: PropTypes.string.isRequired
};

PodcastListeningHero.defaultProps = {
  playButtonTextColor: '#FFFFFF',
  playButtonColor: '',
  part: null,
  episode: null,
  season: null
};

export default PodcastListeningHero;
