import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton';
import PodcastListenImage from './images/podcast-listen-image.png';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '19px',
    backgroundColor: '#DDEBFF',
    width: '100%',
    padding: '33px 62px 33px 109px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '60px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '33px 62px'
    }
  },
  contentArea: {
    marginTop: '28px',
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  },
  buttonArea: {
    marginTop: '49px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '12px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: '30px'
    }
  },
  image: {
    maxHeight: '280px',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginLeft: '26px'
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '190px'
    }
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 40,
    lineHeight: '40px',
    color: '#000000',
    marginTop: 0,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      textAlign: 'center'
    }
  },
  description: {
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#000000',
    marginTop: 0,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  ctaButton: {
    backgroundColor: '#1775FB',
    padding: '20px 35px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 28px'
    }
  },
  ctaText: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: 7,
    alignItems: 'center'
  },
  iconStyle: {
    display: 'none',
    width: '20px'
  }
}));

const PodcastPreviousSeasons = ({ previousSeasons }) => {
  const classes = useStyles();

  if (!previousSeasons || previousSeasons.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentArea}>
        <h3 className={classes.title}>Like what you’re hearing?</h3>
        <p className={classes.description}>
          Unlock more episodes of this podcast as a Premium Member
        </p>
        <div className={classes.buttonArea}>
          {previousSeasons.map(season => (
            <RoundedLinkButton
              key={season.uid}
              text={
                <div className={classes.ctaText}>
                  <PlayCircleOutlineIcon className={classes.iconStyle} />
                  {season.ctaText}
                </div>
              }
              to={season.url}
              size="small"
              variant="blue"
              externalClassName={classes.ctaButton}
            />
          ))}
        </div>
      </div>

      <img src={PodcastListenImage} className={classes.image} alt="Listen Previous Season" />
    </div>
  );
};

export default PodcastPreviousSeasons;

PodcastPreviousSeasons.propTypes = {
  previousSeasons: PropTypes.arrayOf(
    PropTypes.shape({
      ctaText: PropTypes.string,
      url: PropTypes.string,
      uid: PropTypes.string,
      slug: PropTypes.string
    })
  ).isRequired
};
