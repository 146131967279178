import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  checkboxStyle: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eeebe5'
    }
  }
}));

const OnboardingCheckbox = ({ title, ...rest }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const handleCheckboxClick = () => {
    setChecked(!checked);
  };

  return (
    <Box
      bgcolor="#FFF"
      p={2}
      width="100%"
      display="flex"
      alignItems="center"
      onClick={handleCheckboxClick}
      className={classes.checkboxStyle}
    >
      <Checkbox checked={checked} value={checked ? title : false} color="primary" {...rest} />
      <Typography variant="subtitle1" component="p">
        {title}
      </Typography>
    </Box>
  );
};

OnboardingCheckbox.propTypes = {
  title: PropTypes.string.isRequired
};

export default OnboardingCheckbox;
