import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { getCardFromPlaylist } from '~utils/playlist-helpers';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import { setPlaylistPageSubscribeBannerVisibility } from '~context/GlobalContext/actions';
import { AB_EXPERIMENTS } from '~utils/experiment-helper';

const Container = loadable(() => import('~components/Container'));
const VideoHero = loadable(() => import('./marketing/VideoHero'));
const WatchFreeEmailRegister = loadable(() => import('./marketing/WatchFreeEmailRegister'));
const RelatedContentCarousel = loadable(() => import('~components/RelatedContentCarousel'));
const AboutBoxSlice = loadable(() => import('~components/AboutBoxSlice'));

const MarketingHeroPlaylistLayout = ({
  heroCoverImage,
  isPlaying,
  playlistUid,
  currentVideo,
  currentVideoIndex,
  numberOfVideos,
  handlers,
  popup,
  isPopupOpen,
  title,
  author,
  prismicId,
  thumbnail,
  isSingleVideo,
  uid,
  labels,
  suggestedPlaylists,
  trendingPlaylists,
  popularPlaylists,
  isFreeContent
}) => {
  const {
    showGiftPopup,
    onReady,
    onStart,
    onProgress,
    onEnded,
    handleBlockingPopupClose,
    toggleVideoPlayState,
    showContentBlockingPopup
  } = handlers;

  const { dispatch } = useGlobalStore();

  const relatedPlaylistCards = useMemo(
    () => (suggestedPlaylists || []).map(getCardFromPlaylist),
    [suggestedPlaylists]
  );
  const popularPlaylistCards = useMemo(
    () => (popularPlaylists || []).map(getCardFromPlaylist),
    [popularPlaylists]
  );
  const trendingPlaylistCards = useMemo(
    () => (trendingPlaylists || []).map(getCardFromPlaylist),
    [trendingPlaylists]
  );

  // Global banner remove experiment
  const heroVariant = AB_EXPERIMENTS.VIDEO_PLAYLIST.VARIANTS.PROMINENT_PLAY_BUTTON; // Winning variant of Marketing Playlist Experiment
  const isAboveFoldVariant = heroVariant === AB_EXPERIMENTS.VIDEO_PLAYLIST.VARIANTS.NO_BANNER;

  useEffect(() => {
    // when the variant is set to above fold, let's hide the playlist page top banner
    dispatch(setPlaylistPageSubscribeBannerVisibility(!isAboveFoldVariant));
  }, [isAboveFoldVariant]);

  return (
    <>
      <VideoHero
        heroCoverImage={heroCoverImage}
        isPlaying={isPlaying}
        currentVideo={currentVideo}
        onReady={onReady}
        onStart={onStart}
        onProgress={onProgress}
        onEnded={onEnded}
        toggleVideoPlayState={toggleVideoPlayState}
        popup={popup}
        isPopupOpen={isPopupOpen}
        title={title}
        author={author}
        prismicId={prismicId}
        thumbnail={thumbnail}
        isSingleVideo={isSingleVideo}
        uid={uid}
        labels={labels}
        currentVideoIndex={currentVideoIndex}
        numberOfVideos={numberOfVideos}
        showGiftPopup={showGiftPopup}
        handleInsufficientAccess={showContentBlockingPopup}
        handleBlockingPopupClose={handleBlockingPopupClose}
        compactMargins={isAboveFoldVariant}
        variant={heroVariant}
        isFreeContent={isFreeContent}
      />
      <Container center size={100} background="#0F0F0F">
        <WatchFreeEmailRegister playlistUid={playlistUid} compactMargins={isAboveFoldVariant} />

        {relatedPlaylistCards.length > 0 && (
          <RelatedContentCarousel playlists={relatedPlaylistCards} title="Related Content" />
        )}
        {popularPlaylistCards.length > 0 && (
          <RelatedContentCarousel playlists={popularPlaylistCards} title="Popular on Aleph Beta" />
        )}
        {trendingPlaylistCards.length > 0 && (
          <RelatedContentCarousel playlists={trendingPlaylistCards} title="Trending Now" />
        )}

        <AboutBoxSlice />
      </Container>
    </>
  );
};

MarketingHeroPlaylistLayout.propTypes = {
  heroCoverImage: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  isPlaying: PropTypes.bool.isRequired,
  currentVideoIndex: PropTypes.number,
  numberOfVideos: PropTypes.number,
  playlistUid: PropTypes.string.isRequired,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    trailer_wistia_url: PropTypes.shape({ url: PropTypes.string }),
    wistia_url: PropTypes.shape({ url: PropTypes.string }),
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  handlers: PropTypes.shape({
    onReady: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
    onProgress: PropTypes.func.isRequired,
    onEnded: PropTypes.func.isRequired,
    handleBlockingPopupClose: PropTypes.func.isRequired,
    showContentBlockingPopup: PropTypes.func.isRequired,
    setCurrentVideo: PropTypes.func.isRequired,
    showGiftPopup: PropTypes.func.isRequired,
    toggleVideoPlayState: PropTypes.func.isRequired
  }).isRequired,
  popup: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  prismicId: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  suggestedPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  trendingPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  popularPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  thumbnail: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string }).isRequired,
  isSingleVideo: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  isFreeContent: PropTypes.bool
};
MarketingHeroPlaylistLayout.defaultProps = {
  currentVideoIndex: 0,
  numberOfVideos: 1,
  suggestedPlaylists: [],
  trendingPlaylists: [],
  popularPlaylists: [],
  isPopupOpen: false,
  popup: undefined,
  isFreeContent: false
};

export default MarketingHeroPlaylistLayout;
