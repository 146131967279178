import { ACTION_TYPES } from './actions';

export default (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_INTERCOM_INITIALIZED:
      return {
        ...state,
        intercomInitialized: action.payload
      };
    case ACTION_TYPES.SET_MESSAGE_OPEN:
      return {
        ...state,
        intercomMessageOpen: action.payload
      };
    case ACTION_TYPES.SET_SPOTIFY_PLAYER_OPEN:
      return {
        ...state,
        isSpotifyPlayerOpen: action.payload
      };
    case ACTION_TYPES.SET_MARQUEE_BANNER_HIDDEN:
      return {
        ...state,
        isMarqueeBannerHidden: action.payload
      };
    case ACTION_TYPES.SET_BOTTOM_BANNER_HIDDEN:
      return {
        ...state,
        isBottomBannerHidden: action.payload
      };
    case ACTION_TYPES.SET_PLAYLIST_PAGE_SUBSCRIBE_BANNER_VISIBILITY:
      return {
        ...state,
        isPlaylistPageSubscribeBannerVisible: action.payload
      };
    default:
      return state;
  }
};
