import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import TextDivider from '~components/Divider/TextDivider';
import Box from '@material-ui/core/Box';
import Link from '~components/Link';
import { FacebookButtonSubmit, AppleSignInButton } from '~components/form-buttons';
import { Input } from '~components/form-inputs-v2';
import { useCheckUsernameExists, useFacebookLogin } from '~hooks/useAuth';
import { VIEWS } from '../constants';
import { useSharedStyles } from '../styles';

const useStyles = makeStyles(() => ({
  termsText: {
    fontSize: '14px',
    textAlign: 'center'
  },
  termsLink: {
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'inherit'
  }
}));

const InitialView = ({ changeView, username, changeUsername }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [userExistsState, checkUsernameExists] = useCheckUsernameExists();
  const { register, handleSubmit } = useForm();
  const [fbState, loginWithFacebook] = useFacebookLogin();
  const [requested, setRequested] = useState(false);

  const isLoading = userExistsState?.isLoading || fbState?.isLoading;

  useEffect(() => {
    if (userExistsState?.data?.exists) {
      changeView(VIEWS.EMAIL_LOGIN_VIEW);
    } else if (
      requested &&
      !userExistsState?.isError &&
      userExistsState?.data &&
      !userExistsState.data.exists
    ) {
      changeView(VIEWS.CREATE_ACCOUNT_VIEW);
    }
  }, [changeView, userExistsState, requested]);

  const onSubmit = formData => {
    setRequested(true);
    const requestBody = { username: formData?.email || username };
    changeUsername(requestBody.username);
    checkUsernameExists(requestBody);
  };

  return (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid className={sharedClasses.root}>
            <Grid item xs={12} className={sharedClasses.gapXs}>
              <Typography component="h5" className={sharedClasses.title}>
                Thank you for learning with Aleph Beta!
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapLg}>
              <Typography component="h6" className={sharedClasses.subtitle}>
                Create your free account to unlock 30 minutes of content a month, or log in
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Input
                label="Email Address"
                id="email-address"
                type="email"
                name="email"
                defaultValue={username}
                inputRef={register}
                required
              />
              {userExistsState?.error && (
                <Typography component="h6" className={sharedClasses.errorText}>
                  {String(userExistsState?.error)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapMd}>
              <ButtonSubmit
                id="guest-login-popup-continue-btn"
                className={sharedClasses.submitButton}
                disableElevation
                disabled={isLoading}
              >
                Continue
              </ButtonSubmit>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapMd}>
              <TextDivider>or</TextDivider>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Typography component="h6" className={classes.termsText}>
                By continuing you agree to the updated{' '}
                <a href="/terms" target="_blank" className={classes.termsLink}>
                  Terms of Sale, Terms of Service
                </a>{' '}
                and{' '}
                <a href="/privacy" target="_blank" className={classes.termsLink}>
                  Privacy Policy
                </a>
                .
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapXl}>
              <FacebookButtonSubmit
                callback={loginWithFacebook}
                disabled={isLoading}
                variant="secondary"
              >
                Continue with Facebook
              </FacebookButtonSubmit>
              <AppleSignInButton variant="secondary">Continue with Apple</AppleSignInButton>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h6" className={sharedClasses.joinPremiumDescription}>
                Unlimited Access to over 1,000 Torah podcasts and videos.
              </Typography>
              <Box
                component="h6"
                fontSize="16px"
                textAlign="center"
                marginTop="6px"
                marginBottom="0px"
              >
                <Link to="/subscribe">Join Premium</Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

InitialView.propTypes = {
  changeView: PropTypes.func.isRequired,
  username: PropTypes.string,
  changeUsername: PropTypes.func.isRequired
};

InitialView.defaultProps = {
  username: ''
};

export default InitialView;
