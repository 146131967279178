import React from 'react';
import PropTypes from 'prop-types';

import { MaterialsExpansionPanel } from '~components/playlist';

const GuidesMaterialsExpansionPanel = ({ materials }) => (
  <div style={{ width: '100%' }}>
    {materials &&
      materials.map((material, index) => (
        <MaterialsExpansionPanel
          title={material.panel_title}
          materials={material.materials}
          key={material.panel_title}
          defaultExpanded={index === 0 ? true : null}
        />
      ))}
  </div>
);

GuidesMaterialsExpansionPanel.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      panel_title: PropTypes.string.isRequired,
      materials: MaterialsExpansionPanel.PropTypes
    })
  ).isRequired
};

export default GuidesMaterialsExpansionPanel;
