import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import useApi, { SOFT_SALE_CLOSE } from '~hooks/useApi';

import Container from '~components/Container';

import HeartSvg from '~images/popups/heart.svg';

const useStyles = makeStyles(theme => ({
  banner: {
    bottom: 0,
    left: 'auto',
    right: 0,
    position: 'fixed',
    zIndex: 1090,
    background: 'linear-gradient(89.97deg, #FFFDD4 0.03%, #6CEAC4 98.31%)',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute'
    }
  },
  heading: {
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 700
  },
  buttonStyle: {
    backgroundColor: '#122119',
    color: '#fff',
    textTransform: 'none',
    fontSize: '22px',
    lineHeight: '23px',
    padding: '12px 41px',
    '&:hover': {
      backgroundColor: '#000'
    },
    '& img': {
      marginRight: '10px'
    }
  },
  iconStyle: {
    fontSize: '20px',
    color: theme.palette.secondary.main,
    marginRight: '12px'
  },
  iconButtonStyle: {
    backgroundColor: '#0000004D',
    '&:hover': {
      backgroundColor: 'grey'
    }
  },
  closeIconStyle: {
    fontSize: '1rem',
    color: '#fff'
  }
}));

const GuestUserSoftSaleFirstPopup = ({ which, ...rest }) => {
  const classes = useStyles();
  const { setPopupId, id = undefined } = rest;
  const [isPopupHidden, setIsPopupHidden] = useState(false);
  const [, makeRequest] = useApi();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleClose = () => {
    const params = SOFT_SALE_CLOSE({ which });
    makeRequest(params);

    if (setPopupId) {
      setPopupId(0);
    }

    setIsPopupHidden(true);
  };

  if (isPopupHidden) {
    return null;
  }
  return (
    <Box width="100%" pt={1} pb={3} className={classes.banner} id={id}>
      <Container center>
        <Box
          width="100%"
          px={isXs ? 0 : 3}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton
            aria-label="close"
            className={classes.iconButtonStyle}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon className={classes.closeIconStyle} />
          </IconButton>
        </Box>
        <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md="auto">
            <Typography variant="h6" className={classes.heading} align="center">
              You’ve watched 5 of your 10 free minutes.
            </Typography>
          </Grid>

          <Grid item xs={12} md="auto" textAlign="center">
            <Button
              component={GatsbyLink}
              variant="contained"
              className={classes.buttonStyle}
              to="/subscribe"
            >
              <img src={HeartSvg} alt="heart icon" />
              Get Unlimited Access
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

GuestUserSoftSaleFirstPopup.propTypes = {
  which: PropTypes.number.isRequired,
  setPopupId: PropTypes.func,
  id: PropTypes.string
};

GuestUserSoftSaleFirstPopup.defaultProps = {
  setPopupId: null,
  id: undefined
};

export default GuestUserSoftSaleFirstPopup;
