import React from 'react';
import PropTypes from 'prop-types';
import { extractHoursAndMinutes, localeDate } from '~utils/dates';
import cn from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { Text, HeroSubtitle, Title } from '~src/components/Typography/Typography';

const useStyles = makeStyles(() => ({
  wrapper: {
    transition: 'background-color 300ms ease',
    cursor: 'pointer',
    margin: '14px 0',

    '&:hover': {
      backgroundColor: '#E6E6E6'
    }
  },

  seasonEpisode: {
    display: 'inline-flex'
  },
  playButton: {
    marginTop: '32px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#212121',
    boxShadow: 'unset',
    '&:hover': {
      background: 'transparent'
    },
    '& > span > span': {
      marginBottom: '0px'
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textColor: {
    color: '#000000'
  },
  description: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem'
  },
  date: {
    fontSize: '18px'
  },
  bold: {
    fontWeight: '600'
  },
  series: {
    fontWeight: '500'
  },
  dotStyle: {
    fontSize: '6px',
    margin: '0 6px'
  }
}));

const formattedDate = createdAt =>
  createdAt
    ? localeDate(createdAt, { month: 'long', day: 'numeric', year: 'numeric' }).toUpperCase()
    : 'no date';

const EpisodeCard = ({
  part,
  title,
  season,
  episode,
  duration,
  createdAt,
  description,
  selectedSeasonUid,
  handleEpisode,
  handlePlayEpisode
}) => {
  const classes = useStyles();

  const handleListen = event => {
    event.stopPropagation();
    handlePlayEpisode(title, selectedSeasonUid);
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      textAlign="left"
      bgcolor="#ffffff"
      padding="32px 34px"
      onClick={handleEpisode(title)}
      className={classes.wrapper}
    >
      <Text className={cn(classes.date, classes.textColor)}>{formattedDate(createdAt)}</Text>
      <HeroSubtitle className={cn(classes.textColor, classes.series)}>
        Season {season} | Episode {episode} {part && `| Part ${part}`}
      </HeroSubtitle>
      <Title className={cn(classes.textColor, classes.bold)}>{title}</Title>
      <Text className={cn(classes.description, classes.textColor)}>{description}</Text>
      <Fab
        size="medium"
        disableFocusRipple
        disableRipple
        variant="extended"
        className={classes.playButton}
        onClick={handleListen}
      >
        <PlayArrowIcon fill="#212121" height={12} width={12} />
        <Text component="span" className={classes.textColor}>
          LISTEN
        </Text>
        <Text component="span" className={classes.dotStyle}>
          &#x25cf;
        </Text>
        {duration && (
          <Text component="span" className={classes.textColor}>
            {extractHoursAndMinutes(duration)}
          </Text>
        )}
      </Fab>
    </Box>
  );
};
EpisodeCard.propTypes = {
  part: PropTypes.number,
  season: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  episode: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  selectedSeasonUid: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleEpisode: PropTypes.func.isRequired,
  handlePlayEpisode: PropTypes.func.isRequired
};

EpisodeCard.defaultProps = {
  part: null
};

export default EpisodeCard;
