import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  .switches-container {
    width: 16rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: ${props => props.bgColor};
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
  }

  .switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
  }

  .switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: ${props => props.textColor};
  }

  .switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .switch {
    border-radius: 3rem;
    background: ${props => props.activeColor};
    height: 100%;
  }

  .switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: ${props => props.textColor};
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
  }

  .switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
  }

  .switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(100%);
  }

  .switches-container input:nth-of-type(1):checked ~ .switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
  }

  .switches-container input:nth-of-type(2):checked ~ .switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
  }
`;

const STATES = {
  CHECKED: 'CHECKED',
  UNCHECKED: 'UNCHECKED'
};

const Toggle = ({
  name,
  checkedText,
  uncheckedText,
  isChecked,
  onChange,
  textColor,
  activeColor,
  bgColor
}) => {
  const onToggleChange = event => {
    onChange(event.target.value === STATES.CHECKED, name);
  };

  return (
    <ToggleContainer textColor={textColor} activeColor={activeColor} bgColor={bgColor}>
      <div className="switches-container">
        <input
          type="radio"
          id={`${name}_checked`}
          name={name}
          value={STATES.CHECKED}
          checked={isChecked}
          onChange={onToggleChange}
        />
        <input
          type="radio"
          id={`${name}_unchecked`}
          name={name}
          value={STATES.UNCHECKED}
          checked={!isChecked}
          onChange={onToggleChange}
        />
        <label htmlFor={`${name}_checked`}>{checkedText}</label>
        <label htmlFor={`${name}_unchecked`}>{uncheckedText}</label>
        <div className="switch-wrapper">
          <div className="switch">
            <div>{checkedText}</div>
            <div>{uncheckedText}</div>
          </div>
        </div>
      </div>
    </ToggleContainer>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  checkedText: PropTypes.string.isRequired,
  uncheckedText: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  textColor: PropTypes.string,
  activeColor: PropTypes.string,
  bgColor: PropTypes.string
};

Toggle.defaultProps = {
  textColor: '#909090',
  activeColor: '#FFFFFF',
  bgColor: '#EEEEEE'
};

export default Toggle;
