import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

const StyledMuiContainer = styled(MuiContainer)`
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: ${props =>
    props.$center === 'horizontal' || props.$center === true ? 'center' : 'baseline'};
  justify-content: ${props =>
    props.$center === 'vertical' || props.$center === true ? 'center' : 'unset'};
  min-height: ${props => (props.size ? `${props.size}vh` : 'unset')};
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth} !important;`}

  ${props =>
    props.background &&
    `&:before {
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      width: 100vw;
      height: 100%;
      margin-left: -50vw;
      margin-right: -50vw;
      z-index: ${props.zIndex};
      content: '';
      background: ${props.background};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center ${props.background_position};
    }`}

  ${props =>
    props.overlay &&
    `&:after { position: absolute;
      left: 50%;
      right: 50%;
      width: 100vw;
      height: 100%;
      margin-left: -50vw;
      margin-right: -50vw;
      z-index: ${props.zIndex};
      content: '';
      background: ${props.overlay};
    }`}
`;

const Container = ({
  children,
  size,
  center,
  background,
  background_position,
  overlay,
  maxWidth,
  ...rest
}) => {
  /**
   * Note, MUI currently doesn't support custom max widths (supported values = 'xs', 'sm', 'md', 'lg', 'xl', false).
   * Therefore,  we are using `$maxWidth` instead of `maxWidth` to style custom max widths.
   */
  const maxWidthProp = useMemo(() => {
    if (!maxWidth) {
      return {};
    }
    return ['xs', 'sm', 'md', 'lg', 'xl', false].includes(maxWidth)
      ? { maxWidth }
      : { $maxWidth: maxWidth };
  }, [maxWidth]);

  return (
    // NOTE: we are using `$center` instead of `center` below.
    // This is the new "transient" properties introduced to styled-components v5.1
    // It fixes the error message about boolean values passed down to non-boolean attribute "center"
    // @see https://styled-components.com/docs/api#transient-props
    // @see https://github.com/styled-components/styled-components/issues/1198
    <StyledMuiContainer
      size={size}
      $center={center}
      background={background}
      overlay={overlay}
      background_position={background_position}
      {...maxWidthProp}
      {...rest}
    >
      {children}
    </StyledMuiContainer>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  center: PropTypes.oneOf(['vertical', 'horizontal', true, false]),
  background: PropTypes.string,
  overlay: PropTypes.string,
  background_position: PropTypes.string,
  maxWidth: PropTypes.string,
  zIndex: PropTypes.number
};

Container.defaultProps = {
  children: null,
  size: 0,
  center: false,
  background: '',
  overlay: '',
  background_position: 'center',
  maxWidth: false,
  zIndex: -2
};

export default Container;
