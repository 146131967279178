import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import Divider from '~components/Divider';
import Link from '~components/Link';
import HtmlExcerpt from '~components/HtmlExcerpt';
import { LabelNew, LabelAudio, LabelPremium, LabelOverlay } from '../playlist-card/PlaylistLabels';
import HighlightExpansionPanel from './SearchHighlightExpansionPanel';

const useStyles = makeStyles({
  playlistTitle: {
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  media: {
    display: 'flex',
    maxWidth: '100%'
  },
  linkStyle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    marginRight: '20px'
  },
  iconStyle: {
    fontSize: '20px',
    marginRight: '6px'
  }
});

const PlaylistCard = ({
  searchTerm,
  listPosition,
  playlist: {
    url,
    title,
    description,
    cover_image_url,
    relevance,
    is_new,
    is_audio,
    is_premium,
    highlight,
    id,
    videos_count
  },
  classes
}) => {
  const { session } = useUserContext();
  const { isAdmin, isUserStateLoading } = session;

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item md={3}>
          <Link
            to={url}
            underline="hover"
            color="textPrimary"
            style={{ width: '100%' }}
            state={{
              searchTerm,
              searchResultsListPosition: listPosition,
              clickLocation: 'thumbnail',
              searchResultType: 'playlist'
            }}
          >
            <img src={cover_image_url} alt="" className={classes.media} />
          </Link>
        </Grid>
        <Grid item md={8}>
          <Typography title={`Relevance score: ${relevance}`} className={classes.playlistTitle}>
            <Link
              to={url}
              underline="hover"
              color="textPrimary"
              state={{
                searchTerm,
                searchResultsListPosition: listPosition,
                clickLocation: 'title',
                searchResultType: 'playlist'
              }}
            >
              {title}
            </Link>
          </Typography>
          <LabelOverlay style={{ position: 'unset', marginLeft: '-4px' }}>
            {is_new && <LabelNew />}
            {is_audio && <LabelAudio />}
            {is_premium && <LabelPremium />}
          </LabelOverlay>
          <p>
            {videos_count} episode{videos_count > 1 ? 's' : ''}
          </p>
          {description && <HtmlExcerpt html={description} />}
          {isAdmin && !isUserStateLoading && (
            <>
              <Grid item container direction="row">
                <Grid item>
                  <Link
                    to={`https://aleph-beta.prismic.io/documents~k=playlist&b=working&c=published&l=en-us/${id}/`}
                    className={classes.linkStyle}
                    target="_blank"
                  >
                    <PlaylistIcon className={classes.iconStyle} /> Edit Playlist document
                  </Link>
                </Grid>
              </Grid>
              {highlight && (
                <Grid item>
                  <HighlightExpansionPanel highlights={highlight} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
const PlaylistPropTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  cover_image_url: PropTypes.string,
  description: PropTypes.string,
  relevance: PropTypes.number,
  is_new: PropTypes.bool,
  is_audio: PropTypes.bool,
  is_premium: PropTypes.bool,
  highlight: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
};
PlaylistCard.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  listPosition: PropTypes.number.isRequired,
  playlist: PropTypes.shape(PlaylistPropTypes),
  classes: PropTypes.shape({
    media: PropTypes.string,
    playlistTitle: PropTypes.string,
    linkStyle: PropTypes.string,
    iconStyle: PropTypes.string
  })
};
PlaylistCard.defaultProps = {
  playlist: {},
  classes: {}
};

const Playlists = ({ searchTerm, playlists }) => {
  const classes = useStyles();

  return (
    <>
      {playlists.map((playlist, index) => (
        <PlaylistCard
          key={`${playlist.id} ${playlist.title}`}
          playlist={playlist}
          classes={classes}
          searchTerm={searchTerm}
          listPosition={index + 1}
        />
      ))}
    </>
  );
};

Playlists.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  playlists: PropTypes.arrayOf(PropTypes.shape(PlaylistPropTypes))
};
Playlists.defaultProps = {
  playlists: []
};

export default Playlists;
