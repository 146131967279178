import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import ResourceIcon from '~components/resource-icon/ResourceIcon';
import { planIdFromString } from '~utils/playlist-helpers';
import authorizeUser from '~utils/authorization-helper';
import Link from '~components/Link';

import { pushDataLayerEvent } from '~utils/data-layer';
import { get } from '~prismic/utils/get';
import useConversionFramework from '~hooks/useConversionFramework';

const StyledSubscriptionLevelTypography = styled(Typography)`
  font-style: italic;
  font-weight: 500;
`;

const StyledResourceIcon = styled(ResourceIcon)`
  margin-right: 8px;
`;

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  @media (max-width: 1279.95px) {
    display: unset;
  }
`;

const MaterialsAccordion = ({ title, materials, ...rest }) => {
  const { session } = useUserContext();
  const { isMarketingSite } = useConversionFramework();

  const calculateGuideUrl = material => {
    const requiredSubscriptionLevel = planIdFromString(material.required_subscription_plan);
    const { hasAccess, redirectPath } = authorizeUser(
      requiredSubscriptionLevel,
      session,
      false,
      isMarketingSite
    );
    if (hasAccess) {
      return get(material, 'file.url', '');
    }
    return redirectPath;
  };

  const calculateGuideState = material => {
    const requiredSubscriptionLevel = planIdFromString(material.required_subscription_plan);
    const { hasAccess, redirectProps } = authorizeUser(
      requiredSubscriptionLevel,
      session,
      false,
      isMarketingSite
    );
    if (hasAccess) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return redirectProps.state;
  };

  const calculateGuideTarget = material => {
    const requiredSubscriptionLevel = planIdFromString(material.required_subscription_plan);
    const { hasAccess } = authorizeUser(requiredSubscriptionLevel, session, false, isMarketingSite);
    if (hasAccess) {
      return '_blank';
    }
    return '_self';
  };

  const isFreeGuide = subscriptionPlan => {
    if (subscriptionPlan === 'Free') {
      return true;
    }
    return false;
  };

  return (
    <Accordion {...rest}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" component="h5">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
          {materials &&
            materials.map((material, index) => (
              <Grid item key={`${material.title} - ${index + 1}`} xs={12} md={6}>
                <StyledTypography variant="subtitle1" gutterBottom>
                  <StyledResourceIcon
                    material_type={material.material_type}
                    size="small"
                    style={{ marginRight: '8px' }}
                  />
                  {material.material_type}
                </StyledTypography>
                <Link
                  onClick={() =>
                    pushDataLayerEvent('material_download_click', {
                      materialUrl: material.url,
                      materialTitle: material.title,
                      materialType: material.material_type
                    })
                  }
                  to={calculateGuideUrl(material)}
                  state={calculateGuideState(material)}
                  target={calculateGuideTarget(material)}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    {material.title}
                  </Typography>
                </Link>

                {!isFreeGuide(material.required_subscription_plan) && (
                  <StyledSubscriptionLevelTypography variant="subtitle1">
                    ({material.required_subscription_plan} only!)
                  </StyledSubscriptionLevelTypography>
                )}

                {isFreeGuide(material.required_subscription_plan) && (
                  <StyledSubscriptionLevelTypography variant="subtitle1">
                    ({material.required_subscription_plan} guide!)
                  </StyledSubscriptionLevelTypography>
                )}
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

MaterialsAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string,
      material_type: PropTypes.string,
      required_subscription_plan: PropTypes.number
    })
  ).isRequired
};

export default MaterialsAccordion;
