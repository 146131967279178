import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import VideoIcon from '@material-ui/icons/QueuePlayNext';
import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import Divider from '~components/Divider';
import Link from '~components/Link';
import HtmlExcerpt from '~components/HtmlExcerpt';
import { LabelNew, LabelAudio, LabelPremium, LabelOverlay } from '../playlist-card/PlaylistLabels';

import HighlightExpansionPanel from './SearchHighlightExpansionPanel';

const useStyles = makeStyles({
  playlistTitle: {
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  videoPlaylistSubtitle: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '15px'
  },
  playlistSmallThumbnail: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '20%',
    height: '20%'
  },
  media: {
    display: 'flex',
    maxWidth: '100%'
  },
  linkStyle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    marginRight: '20px'
  },
  iconStyle: {
    fontSize: '20px',
    marginRight: '6px'
  }
});

const VideoCard = ({
  searchTerm,
  listPosition,
  video: {
    uid,
    url,
    title,
    description,
    cover_image_url,
    relevance,
    is_new,
    is_audio,
    is_premium,
    playlist_id,
    highlight,
    id,
    playlist_title,
    position_in_playlist,
    playlist_length
  },
  classes
}) => {
  const { session } = useUserContext();
  const { isAdmin, isUserStateLoading } = session;

  let playlistDescription;
  if (!playlist_title) {
    playlistDescription = `not assigned to a playlist. Tell us about it!`;
  } else if (playlist_length > 1) {
    playlistDescription = `↳ part ${position_in_playlist} of ${playlist_length} of the series "${playlist_title}"`;
  }

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item md={3} style={{ position: 'relative' }}>
          <Link
            to={url}
            state={{
              index: position_in_playlist - 1,
              searchTerm,
              searchResultVideoUid: uid,
              searchResultsListPosition: listPosition,
              clickLocation: 'thumbnail',
              searchResultType: 'video'
            }}
            underline="hover"
            color="textPrimary"
            style={{ width: '100%' }}
          >
            <img src={cover_image_url} alt="" className={`${classes.media} lazyload`} />
          </Link>
        </Grid>
        <Grid item md={8}>
          <Typography title={`Relevance score: ${relevance}`} className={classes.playlistTitle}>
            <Link
              to={url}
              state={{
                searchTerm,
                searchResultVideoUid: uid,
                searchResultsListPosition: listPosition,
                clickLocation: 'title',
                searchResultType: 'video'
              }}
              underline="hover"
              color="textPrimary"
            >
              {title}
            </Link>
          </Typography>
          <Typography className={classes.videoPlaylistSubtitle}>{playlistDescription}</Typography>
          <LabelOverlay style={{ position: 'unset', marginLeft: '-4px' }}>
            {is_new && <LabelNew />}
            {is_audio && <LabelAudio />}
            {is_premium && <LabelPremium />}
          </LabelOverlay>

          {description && <HtmlExcerpt html={description} />}
          {isAdmin && !isUserStateLoading && (
            <>
              <Grid item container direction="row">
                <Grid item>
                  <Link
                    to={`https://aleph-beta.prismic.io/documents~k=video&b=working&c=published&l=en-us/${id}/`}
                    className={classes.linkStyle}
                    target="_blank"
                  >
                    <VideoIcon className={classes.iconStyle} /> Edit Video document
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    to={`https://aleph-beta.prismic.io/documents~k=playlist&b=working&c=published&l=en-us/${playlist_id}/`}
                    className={classes.linkStyle}
                    target="_blank"
                  >
                    <PlaylistIcon className={classes.iconStyle} /> Edit Playlist document
                  </Link>
                </Grid>
              </Grid>
              {highlight && (
                <Grid item>
                  <HighlightExpansionPanel highlights={highlight} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
const VideoPropTypes = {
  uid: PropTypes.string.isRequired,
  url: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  cover_image_url: PropTypes.string,
  description: PropTypes.string,
  relevance: PropTypes.number,
  is_new: PropTypes.bool,
  is_audio: PropTypes.bool,
  is_premium: PropTypes.bool,
  playlist_id: PropTypes.string,
  highlight: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
};
VideoCard.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  listPosition: PropTypes.number.isRequired,
  video: PropTypes.shape(VideoPropTypes),
  classes: PropTypes.shape({
    media: PropTypes.string,
    playlistTitle: PropTypes.string,
    linkStyle: PropTypes.string,
    iconStyle: PropTypes.string,
    playlistSmallThumbnail: PropTypes.string,
    videoPlaylistSubtitle: PropTypes.string
  })
};
VideoCard.defaultProps = {
  video: {},
  classes: {}
};

const Videos = ({ searchTerm, videos }) => {
  const classes = useStyles();

  return (
    <>
      {videos.map((video, index) => (
        <VideoCard
          key={`${video.id} ${video.title}`}
          video={video}
          classes={classes}
          listPosition={index + 1}
          searchTerm={searchTerm}
        />
      ))}
    </>
  );
};

Videos.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(PropTypes.shape(VideoPropTypes))
};
Videos.defaultProps = {
  videos: []
};

export default Videos;
