import { get } from 'lodash';
import { extractSeoData, getDuration } from '~src/utils/unfurl-helpers';

const RESOURCE_TYPENAME = {
  PLAYLIST: 'PrismicPlaylist',
  PODCAST_PLAYLIST: 'PrismicPodcastPlaylist',
  PODCAST_EPISODE: 'PrismicPodcastEpisode',
  VIDEO: 'PrismicVideo',
  MATERIAL: 'PrismicMaterial'
};

export const extractResource = carouselItem => {
  const resource = get(carouselItem, 'resource');
  const type = get(resource, 'document.__typename');

  const explicitSubtitle = carouselItem?.author || '';
  const explicitImage = get(carouselItem, 'card_image.url', '');
  const explicitDuration = carouselItem?.duration || '';

  switch (type) {
    case RESOURCE_TYPENAME.PLAYLIST: {
      const durationInSeconds = get(resource, 'document.data.videos', []).reduce(
        (acc, video) => acc + get(video, 'video.document.data.length_in_seconds', 0),
        0
      );
      return {
        title: get(resource, 'document.data.title'),
        subtitle: explicitSubtitle || get(resource, 'document.data.author.document.data.name'),
        image: explicitImage || get(resource, 'document.data.cover_image.url'),
        duration: getDuration(explicitDuration, durationInSeconds)
      };
    }
    case RESOURCE_TYPENAME.PODCAST_PLAYLIST: {
      const durationInSeconds = get(resource, 'document.data.episodes', []).reduce(
        (acc, episode) =>
          acc +
          get(episode, 'items', []).reduce(
            (subAcc, item) =>
              subAcc + get(item, 'podcast_episode.document.data.length_in_seconds', 0),
            0
          ),
        0
      );
      return {
        title: get(resource, 'document.data.podcast_title.text'),
        subtitle:
          explicitSubtitle ||
          get(resource, 'document.data.hosts[0].items', [])
            .map(author => get(author, 'host.document.data.name'))
            .join(' and '),
        image: explicitImage || get(resource, 'document.data.podcast_thumbnail.url'),
        duration: getDuration(explicitDuration, durationInSeconds)
      };
    }
    case RESOURCE_TYPENAME.PODCAST_EPISODE: {
      const durationInSeconds = get(resource, 'document.data.length_in_seconds', 0);
      const duration = getDuration(explicitDuration, durationInSeconds);
      return {
        title: get(resource, 'document.data.title'),
        subtitle: explicitSubtitle, // you can only define explicit author as no author in a video
        image: explicitImage || get(resource, 'document.data.cover_image.url'),
        duration: explicitDuration || duration
      };
    }
    case RESOURCE_TYPENAME.MATERIAL:
      return {
        title: get(resource, 'document.data.title'),
        link: get(resource, 'document.data.file.url'),
        subtitle: explicitSubtitle,
        image: explicitImage,
        duration: explicitDuration
      };
    default:
      return {
        subtitle: explicitSubtitle,
        image: explicitImage,
        duration: explicitDuration
      };
  }
};

export default prismicData => ({
  seo: extractSeoData(prismicData, 'prismicLandingPage'),
  slices: get(prismicData, 'prismicLandingPage.data.body2', [])
});
