import React, { memo } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingSpinner = memo(({ ...rest }) => (
  <Box textAlign="center" p={4} {...rest}>
    <CircularProgress />
  </Box>
));

export default LoadingSpinner;
