import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles(theme => {
  const color = theme.palette.background.paper; // Feel free to customise this like they do in Tooltip
  return {
    popper: {
      zIndex: 2000,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: 'calc(-1em + 8px)',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%'
        }
      },
      '&[x-placement*="bottom-end"] $arrow': {
        top: 0,
        left: 0,
        marginTop: 'calc(-1em + 8px)',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%'
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: 'calc(-1em + 8px)',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0'
        }
      },
      '&[x-placement*="top-end"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: 'calc(-1em + 8px)',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0'
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: 'calc(-1em + 2px)',
        height: '1em',
        width: '1em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%'
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: 'calc(-1em + 2px)',
        height: '1em',
        width: '1em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0'
        }
      }
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
    arrow: {
      overflow: 'hidden',
      position: 'absolute',
      width: 'calc(1em + 2px)',
      height: '1em' /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        border: '2px solid #e4e4e4',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)'
      }
    },
    childrenContainer: {
      marginTop: '6px',
      marginBottom: '6px'
    }
  };
});

const CustomPopper = ({ popperAnchorRef, onClickAway, children, ...rest }) => {
  const classes = useStyles();
  const isPopperOpened = Boolean(popperAnchorRef);
  const id = isPopperOpened ? 'share-popper' : undefined;
  const [arrowRef, setArrowRef] = useState();

  return (
    <Popper
      id={id}
      anchorEl={popperAnchorRef}
      placement="bottom-end"
      open={isPopperOpened}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window'
        },
        arrow: {
          enabled: true,
          element: arrowRef
        }
      }}
      className={classes.popper}
      {...rest}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={classes.childrenContainer}>
          <span className={classes.arrow} ref={setArrowRef} />
          {children}
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

CustomPopper.propTypes = {
  popperAnchorRef: PropTypes.shape(),
  onClickAway: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

CustomPopper.defaultProps = {
  popperAnchorRef: null
};

export default CustomPopper;
