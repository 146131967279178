module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"aleph-beta","accessToken":"MC5YU2RPZ3hJQUFDRUFCQ1p3.ZDTvv71ADG45L3bvv70gE--_ve-_vRZsHu-_vVNAAO-_vSzvv70zUO-_ve-_ve-_vQ9b77-9","promptForAccessToken":true,"apiEndpoint":"https://aleph-beta.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#438dce"},
    },{
      plugin: require('../plugins/alephbeta-gatsby-plugin-intercom/gatsby-browser.js'),
      options: {"plugins":[],"appId":"mdteixyo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true,"trackingId":"UA-27508035-1","defer":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aleph Beta","short_name":"Aleph Beta","start_url":"/","theme_color":"#438dce","background_color":"#fff","display":"minimal-ui","icon":"/home/circleci/project/frontend/src/assets/images/site_logo_raw.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a1a542de8674c61058ac3ecc075f447"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":false,"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N2NRGHP","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","activeEnvironment":"production"}},"gtmAuth":"6if9-NziLavQTXwagEmQgw","gtmPreview":"env-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
