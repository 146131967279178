/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import loadable from '@loadable/component';
import unfurl from '~prismic/unfurl/patrons-circle';
import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';

const ProducersCircleHero = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleHero
});

const ProducersCirclePartnersSection = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCirclePartnersSection
});
const ProducersCircleMeetTheProducersSection = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleMeetTheProducersSection
});
const ProducersCircleCta = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleCta
});
// const ProducersCircleFloatingButton = loadable(() => import('~modules/producers-circle'), {
//   resolveComponent: components => components.ProducersCircleFloatingButton
// });

const ProducersCircleTestimonials = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleTestimonials
});

const ProducersCircleImpactSection = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleImpactSection
});

const ProducersCircleTiers = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleTiers
});

const ProducersCircleCourse = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleCourse
});

const ProducersCircleBenefits = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleBenefits
});

const ProducersCircleIntro = loadable(() => import('~modules/producers-circle'), {
  resolveComponent: components => components.ProducersCircleIntro
});

const seo = {
  seo_title: 'Support Aleph Beta’s Mission | Jewish Non-Profit Organization',
  seo_description:
    'Aleph Beta seeks to renew the romance of the Jewish People with its founding document. Join our mission to help students fall in love with Torah every day.',
  seo_keywords: 'jewish charity, jewish non profit organization, donate to jewish charities'
};

const ProducersCirclePage = ({ data }) => {
  const { producersCircleMembers, courseCard } = unfurl(data);

  return (
    <Page>
      <SEO {...seo} />
      {/* Hero */}
      <ProducersCircleHero />

      {/* Course */}
      {courseCard && <ProducersCircleCourse {...courseCard} />}

      {/* Course */}
      <ProducersCircleIntro />

      {/* Perks */}
      <ProducersCircleBenefits />

      {/* Impact Section */}
      <ProducersCircleImpactSection />

      {/* Tiers */}
      <ProducersCircleTiers />

      {/* Carousel of testimonials */}
      <ProducersCircleTestimonials />

      {/* Meet the producers sections */}
      <ProducersCircleMeetTheProducersSection producersCircleMembers={producersCircleMembers} />

      {/* Partner with us section */}
      <ProducersCirclePartnersSection />

      {/* CTA */}
      <ProducersCircleCta />

      {/* FAB */}
      {/* <ProducersCircleFloatingButton isVisible={isButtonVisible} /> */}
    </Page>
  );
};

export const query = graphql`
  {
    prismicProducersCircle {
      _previewable
      data {
        body {
          ... on PrismicProducersCircleDataBodyProducersCircleMembers {
            slice_type
            items {
              member_name
            }
          }
        }
        body1 {
          ... on PrismicProducersCircleDataBody1CourseCard {
            slice_type
            primary {
              label
              title {
                html
              }
              time_period
              registration_label
              cta_url
              cta_text
              date_period
              description {
                html
              }
              thumbnail {
                url
                alt
              }
              subtitle {
                html
              }
              quote {
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(ProducersCirclePage);
