import { get } from '~prismic/utils/get';
import {
  getWatchDurationInMinutesFormatted,
  boolFlagFromString,
  getPlaylistCardReadableVideoDuration,
  getPlaylistType,
  getPlaylistMetaText,
  planIdFromString,
  getPlaylistFirstVideoDuration,
  getPlaylistCurrentVideoText
} from './playlist-helpers';
import { generateUrlFriendlyIdFromTitle } from './url-helper';
import { RESOURCE_MEDIUMS, RESOURCE_TYPES } from './resource-helpers';

export const extractFounderData = slice => ({
  ...slice.primary,
  founder_publications: get(slice, 'items', [])
    .filter(section => !!section)
    .map(section => ({
      title: section.founder_publication_title,
      link: section.founder_publication
    }))
});

export const extractPlaylists = slice =>
  slice.items
    .filter(item => !!item.playlist && !!item.playlist.document)
    .map(item => {
      if (item.playlist.type === 'podcast_playlist') {
        const episodeCount = get(item.playlist, 'document.data.episodes[0].items', []).length;
        const authors = get(item.playlist, 'document.data.hosts[0].items', []).map(author =>
          get(author, 'host.document.data.name')
        );
        return {
          ...item.playlist.document.data,
          id: item.playlist.id,
          uid: item.playlist.uid,
          url: item.playlist.url,
          videos: [],
          author: authors
            .slice(0, 2) // only 2 authors max (https://app.asana.com/0/1203861615628012/1203938149194487)
            .join(' | '),
          title: get(item.playlist, 'document.data.podcast_title.text'),
          label_is_audio: 'Yes',
          custom_duration: episodeCount > 0 ? `${episodeCount} episodes` : '',
          cover_image: get(
            item.playlist,
            'document.data.card_thumbnail',
            get(item.playlist, 'document.data.podcast_thumbnail', null)
          )
        };
      }
      if (item.playlist.type === 'podcast_episode') {
        const seconds = get(item.playlist, 'document.data.length_in_seconds', 0);
        const duration = getPlaylistCardReadableVideoDuration(seconds);
        const podcastPlaylistUid = get(
          item.playlist,
          'document.data.podcast_playlist.document.uid',
          ''
        );
        const uid = get(item.playlist, 'uid', '');

        return {
          ...item.playlist.document.data,
          id: item.playlist.id,
          uid,
          url: `/${podcastPlaylistUid}/${uid}`,
          videos: [],
          author: get(
            item.playlist,
            'document.data.podcast_playlist.document.data.hosts[0].items',
            []
          )
            .map(author => get(author, 'host.document.data.name'))
            .slice(0, 2) // only 2 authors max (https://app.asana.com/0/1203861615628012/1203938149194487)
            .join(' | '),
          title: get(item.playlist, 'document.data.title', ''),
          label_is_audio: 'Yes',
          custom_duration: duration,
          cover_image: get(
            item.playlist,
            'document.data.podcast_playlist.document.data.card_thumbnail',
            get(
              item.playlist,
              'document.data.podcast_playlist.document.data.podcast_thumbnail',
              null
            )
          )
        };
      }
      return {
        ...item.playlist.document.data,
        id: item.playlist.id,
        uid: item.playlist.uid,
        url: item.playlist.url,
        videos: get(item.playlist, 'document.data.videos', []).map(video =>
          get(video, 'video.document.data')
        ),
        author: get(item.playlist, 'document.data.author.document.data.name')
      };
    });

export const extractFeaturedPlaylist = slice => {
  const featuredPlaylist = {
    url: slice.primary.featured_playlist.url,
    uid: slice.primary.featured_playlist.document.uid,
    ...slice.primary.featured_playlist.document.data
  };

  return featuredPlaylist;
};

export const extractResources = slice =>
  slice.items.map(item => {
    // FIXME: Until we fix the link-resolver so it'll make proper URLs for articles we'll have to use manual hack
    let url = item.resource.url === '/' ? item.resource.url + item.resource.uid : item.resource.url;
    // eslint-disable-next-line no-underscore-dangle
    if (item.resource?.document?.__typename === 'PrismicArticle') {
      // NOTE: this URL creation schema must be in sync with how we create Article pages in Gatsby! See `gatsby-node.js`.
      url = `/${item.resource?.document?.data?.parent_page?.uid}/${item.resource.uid}`;
    }
    return {
      uid: item.resource.uid,
      url,
      is_featured: item.is_featured,
      typeName: get(item, 'resource.document.__typename', null),
      ...get(item, 'resource.document.data', {
        description: '',
        title: item.resource.url,
        material_type: 'Link'
      })
    };
  });

export const extractAuthorTestimonials = slice => {
  const authorTestimonials = {
    testimonial: slice.primary.testimonial,
    avatar: get(slice.primary, 'author.document.data.avatar'),
    name: get(slice.primary, 'author.document.data.name'),
    role: get(slice.primary, 'author.document.data.role')
  };
  return authorTestimonials;
};

export const extractVideoGalleryPlaylists = slice => {
  const playlistSections = {
    section_title: slice.primary.video_gallery_title,
    section_description: slice.primary.video_gallery_description,
    videos: slice.items
      .filter(item => !!item.playlist_url && !!item.playlist_url.document)
      .map(item => ({
        ...item.playlist_url.document.data,
        uid: item.playlist_url.uid,
        url: item.playlist_url.url
      }))
  };

  return playlistSections;
};

export const makeLinkTargetNameFromTitle = title => String(title).replace(/ +/g, '');

export const extractVideoList = slice =>
  slice.items
    .filter(item => !!item.playlist && !!item.playlist.document)
    .map(item => ({
      ...item.playlist.document.data,
      id: item.playlist.id,
      uid: item.playlist.uid,
      url: item.playlist.url,
      videos: get(item.playlist, 'document.data.videos', []).map(video =>
        get(video, 'video.document.data')
      ),
      author: get(item.playlist, 'document.data.author.document.data.name')
    }));

const SLICE_TYPES_TO_INCLUDE_IN_TABLE_OF_CONTENTS = {
  playlists: 'primary.section_title_rich_text.text',
  resources: 'primary.section_title_rich_text.text',
  text_block: 'primary.text_block_title_rich_text.text',
  text_block_cols: 'primary.text_block_title_rich_text.text'
};

export const makeNavlinks = slices =>
  slices.reduce((navlinks, slice) => {
    const query = SLICE_TYPES_TO_INCLUDE_IN_TABLE_OF_CONTENTS[slice.slice_type];
    if (query) {
      const title = get(slice, query, null);
      if (title) {
        return [...navlinks, title];
      }
    }
    return navlinks;
  }, []);

const extractArticleContentToc = contentItems => {
  if (!contentItems) {
    return [];
  }

  return contentItems.map(item => {
    const title = get(item, 'text_block_title_rich_text.text', '');
    return { title, url: `#${generateUrlFriendlyIdFromTitle(title)}` };
  });
};
const SLICE_TITLE_QUERY_IN_TOC = {
  article_faq: 'primary.section_title.text',
  article_content_box: 'primary.text_block_title_rich_text.text',
  article_resources: 'primary.section_title.text',
  article_related_pages: 'primary.section_title.text'
};

export const extractArticleToc = slices =>
  (slices || [])
    .reduce((res, slice) => {
      const sliceType = slice.slice_type;
      switch (sliceType) {
        case 'about_aleph_beta':
          return [...res, { title: 'About Aleph Beta', url: '#about' }];
        case 'article_faq':
        case 'article_content_box':
        case 'article_resources':
        case 'article_related_pages': {
          const title = get(slice, SLICE_TITLE_QUERY_IN_TOC[sliceType], '');
          return [
            ...res,
            {
              title,
              url: `#${generateUrlFriendlyIdFromTitle(title)}`
            }
          ];
        }
        case 'article_content': {
          const articleContentTitles = extractArticleContentToc(slice?.items);
          return [...res, ...articleContentTitles];
        }
        default:
          return res;
      }
    }, [])
    .filter(tocItem => !!tocItem.title);

/**
 * Get the `about_hero` slice data and produce something acceptable by `AboutHero` slice component.
 *
 * @param {*} sliceData Contents of the `<page>.<data>.<body>.find(slice => slice.slice_type === 'about_hero')`
 * @returns {} Object which could be destructured into props of the `AboutHero` component.
 */
export const extractAboutHeroData = sliceData => {
  if (!sliceData) {
    return {};
  }

  return {
    hero_background_image: get(sliceData, 'primary.hero_background_image'),
    hero_head_image: get(sliceData, 'primary.hero_head_image'),
    hero_text: get(sliceData, 'primary.hero_text'),
    underline_text: get(sliceData, 'primary.underline_text'),
    hero_featured_video: {
      url: get(sliceData, 'primary.hero_featured_video.url', '')
    }
  };
};

export const extractHeroTextImageData = sliceData => {
  const {
    title,
    subtitle,
    link,
    link_title,
    foreground_image,
    background_image,
    hero_button_background_color,
    hero_button_text_color,
    text_color,
    background_color
  } = sliceData.primary;

  return {
    title,
    subtitle,
    link,
    link_title,
    foreground_image,
    background_image,
    hero_button_background_color,
    hero_button_text_color,
    background_color,
    text_color,
    labels: get(link, 'document.data')
  };
};

export const extractAboutPageAboutUsData = aboutSlice => {
  const aboutData = aboutSlice && {
    section_title: aboutSlice.primary.section_title,
    about_us_bg_color: aboutSlice.primary.about_us_bg_color,
    sections: aboutSlice.sections
  };
  return aboutData || {};
};

export const extractAboutPageTestimonialsData = testimonialsSlice => {
  const testimonialsData = testimonialsSlice && {
    testimonials_background_color:
      get(testimonialsSlice, 'primary.testimonials_background_color', null) || undefined,
    testimonials: testimonialsSlice.testimonials
  };
  return testimonialsData || {};
};

export const extractAboutPageVideoPathData = videoPathSlice => {
  const videosPathData = videoPathSlice && {
    section_title: videoPathSlice.primary.section_title,
    section_subtitle: videoPathSlice.primary.section_subtitle,
    section_footnote: videoPathSlice.primary.section_footnote,
    slider_background_color: videoPathSlice.primary.slider_background_color,
    slides: videoPathSlice?.slides?.map(slide => ({
      title: slide.title,
      content: slide.subtitle,
      videos: [
        {
          url: slide.first_video.url,
          cover_image: slide.first_video.document.data.cover_image,
          title: slide.first_video.document.data.title
        },
        {
          url: slide.second_video.url,
          cover_image: slide.second_video.document.data.cover_image,
          title: slide.second_video.document.data.title
        },
        {
          url: slide.third_video.url,
          cover_image: slide.third_video.document.data.cover_image,
          title: slide.third_video.document.data.title
        }
      ]
    }))
  };
  return videosPathData || {};
};

export const extractAboutPageTeamData = teamSlice => {
  const teamData = teamSlice && {
    ...teamSlice.primary,
    members: [...(teamSlice.members || [])]
  };
  return teamData || {};
};

export const extractAboutPageFaqData = faqSlice => {
  const faqData = faqSlice && {
    ...faqSlice.primary,
    faqSections: [...(faqSlice.faqSections || [])]
  };
  return faqData || {};
};

export const extractAboutPageCtaData = ctaSlice => {
  const ctaData = ctaSlice && {
    ...ctaSlice.primary,
    links: [...(ctaSlice.links || [])]
  };
  return ctaData || {};
};

export const extractFaqSectionsData = faqSectionSlices => {
  if (!faqSectionSlices) {
    return [];
  }

  const sections = faqSectionSlices.map(section => ({
    section_title: section.primary.section_title,
    sections: section.items
  }));

  return sections;
};

export const extractAudioSectionData = audioSectionSlice => ({
  ...audioSectionSlice.primary,
  audio_files: [...audioSectionSlice.audio_files]
});

export const extractTeamSection = teamSectionSlice => ({
  ...teamSectionSlice.primary,
  members: teamSectionSlice.items.map(member => ({
    avatar: get(member, 'team_member.document.data.avatar', ''),
    name: get(member, 'team_member.document.data.name', ''),
    position: get(member, 'team_member.document.data.position', ''),
    description: get(member, 'team_member.document.data.description', '')
  }))
});

export const extractMaterialsForExpansionPanel = ({ primary, items }) => ({
  ...primary,
  materials: items.map(({ panel_materials }) => ({
    title: get(panel_materials, 'document.data.title'),
    material_type: get(panel_materials, 'document.data.material_type'),
    required_subscription_plan: get(panel_materials, 'document.data.required_subscription_plan'),
    file: get(panel_materials, 'document.data.file')
  }))
});

export const extractAbLabsIntroData = sliceData => ({
  content: get(sliceData, 'primary.content'),
  logo: get(sliceData, 'primary.logo'),
  section_image: get(sliceData, 'primary.section_image')
});

export const getDuration = (customDuration, originalDurationInSeconds) => {
  if (customDuration === '-') {
    return '';
  }
  return customDuration || getWatchDurationInMinutesFormatted(originalDurationInSeconds);
};

export const extractSpotlightPlaylists = sliceData => ({
  key: get(sliceData, 'id', ''),
  subscription_level: get(sliceData, 'primary.subscription_level', ''),
  background_color: get(sliceData, 'primary.background_color', ''),
  text_color: get(sliceData, 'primary.text_color', ''),
  bottom_shape_divider: get(sliceData, 'primary.spotlight_section_bottom_divider', ''),
  compact_margins: get(sliceData, 'primary.compact_margins', false),
  spotlights: get(sliceData, 'items', []).map(item => ({
    id: item.spotlight_playlist.id,
    uid: item.spotlight_playlist.uid,
    url: item.spotlight_playlist.url,
    title: get(item, 'spotlight_title', ''),
    playlist_title: get(item.spotlight_playlist, 'document.data.title', ''),
    playlist_description: get(item.spotlight_playlist, 'document.data.description.text', ''),
    cover_image: get(item.spotlight_playlist, 'document.data.cover_image', null),
    first_video_duration: get(
      item.spotlight_playlist,
      'document.data.videos[0].video.document.data.length_in_seconds',
      0
    ),
    total_videos: get(item.spotlight_playlist, 'document.data.videos', []).length,
    is_audio: get(item.spotlight_playlist, 'document.data.label_is_audio', '') === 'Yes',
    author: get(item.spotlight_playlist, 'document.data.author.document.data.name', '')
  }))
});

export const extractOfferSectionData = offerSlice => ({
  ...offerSlice.primary,
  id: [...offerSlice.id]
});

export const extractCourseGlobalProps = data => ({
  product_id: Number(get(data, 'prismicCourse.data.product_id', '')),
  is_subscriber_eligible: get(data, 'prismicCourse.data.is_subscriber_eligible', false),
  is_producer_circle_eligible: get(data, 'prismicCourse.data.is_producer_circle_eligible', false)
});

export const extractLearningMethods = data => {
  const items = get(data, 'items', []);

  const cards = items.map(item => ({
    title: get(item, 'title', ''),
    icon: get(item, 'icon', null),
    shortExplanation: get(item, 'short_explanation.text', ''),
    animationFileUrl: get(item, 'animation_file.url', ''),
    animationType: get(item, 'animation_type', ''),
    animationStillFrame: get(item, 'animation_still_frame', ''),
    playlist: {
      id: get(item, 'playlist.document.id', ''),
      url: get(item, 'playlist.document.url', ''),
      cardImage: get(item, 'playlist.document.data.cover_image', null)
    },
    secondaryPlaylist: {
      id: get(item, 'second_playlist.document.id', ''),
      url: get(item, 'second_playlist.document.url', ''),
      cardImage: get(item, 'second_playlist.document.data.cover_image', null)
    },
    ternaryPlaylist: {
      id: get(item, 'third_playlist.document.id', ''),
      url: get(item, 'third_playlist.document.url', ''),
      cardImage: get(item, 'third_playlist.document.data.cover_image', null)
    },
    ctaText: get(item, 'call_to_action_text', '')
  }));

  return {
    key: get(data, 'id', ''),
    title: get(data, 'primary.title', ''),
    background_color: get(data, 'primary.background_color', ''),
    text_color: get(data, 'primary.text_color', ''),
    navigation_color: get(data, 'primary.navigation_color', ''),
    bottom_shape_divider: get(data, 'primary.bottom_shape_divider', null),
    cards
  };
};

export const extractWaysToLearnData = prismicData => ({
  title: get(prismicData, 'primary.section_title', ''),
  methods: get(prismicData, 'items', []).map(data => ({
    title: get(data, 'title', ''),
    description: get(data, 'description.text', ''),
    thumbnail: get(data, 'thumbnail', null)
  })),
  bottom_shape_divider: get(prismicData, 'primary.bottom_shape_divider', null)
});

export const extractIntroductionData = slice => ({
  title: get(slice, 'primary.rich_intro_title', null),
  videoCover: get(slice, 'primary.intro_video_image', null),
  videoUrl: get(slice, 'primary.introduction_video1.document.data.wistia_url.url', ''),
  authors: get(slice, 'items', []).map(item => ({
    title: get(item, 'author_title', null),
    description: get(item, 'author_description', null),
    avatar: get(item, 'author_avatar', null)
  }))
});

export const extractSpotlight = spotlightData => ({
  id: get(spotlightData, 'id', ''),
  uid: get(spotlightData, 'document.uid', ''),
  url: get(spotlightData, 'url', ''),
  playlist_title: get(spotlightData, 'document.data.title', ''),
  playlist_description: get(spotlightData, 'document.data.description.text', ''),
  cover_image: get(spotlightData, 'document.data.cover_image', null),
  first_video_duration: get(
    spotlightData,
    'document.data.videos[0].video.document.data.length_in_seconds',
    0
  ),
  total_videos: get(spotlightData, 'document.data.videos', []).length,
  is_audio: get(spotlightData, 'document.data.label_is_audio', '') === 'Yes',
  author: get(spotlightData, 'document.data.author.document.data.name', '')
});

export const extractShowcaseLibrary = prismicData => ({
  title: get(prismicData, 'primary.title', ''),
  mobile_title: get(prismicData, 'primary.title', ''),
  subtitle: get(prismicData, 'primary.subtitle', null),
  mobile_subtitle: get(prismicData, 'primary.mobile_subtitle', null),
  cta_text: get(prismicData, 'primary.cta_text', ''),
  cta_path: get(prismicData, 'primary.cta_path', ''),
  library_sections: get(prismicData, 'primary.library_sections.document.data.body', [])
    .filter(section => section?.slice_type === 'library_section')
    .map(data => ({
      title: get(data, 'primary.title', ''),
      see_more_path: get(data, 'primary.see_more_path', ''),
      background_thumbnail: get(data, 'primary.background_thumbnail.url', ''),
      mobile_cap: get(data, 'primary.mobile_cap', 4),
      items: get(data, 'items', []).map(itemData => ({
        custom_resource_title: get(itemData, 'custom_resource_title', ''),
        custom_path: get(itemData, 'custom_path', ''),
        resource_title:
          get(itemData, 'resource.document.data.title', '') ||
          get(itemData, 'resource.document.data.podcast_title.text', ''),
        resource_path: get(itemData, 'resource.document.url', '')
      }))
    })),
  bottom_shape_divider: get(prismicData, 'primary.bottom_shape_divider', null)
});

const getTrendingItemTitle = trendingItem => {
  const type = get(trendingItem, 'item.document.type', '');
  const explicitTitle = get(trendingItem, 'title', '');
  if (explicitTitle) {
    return explicitTitle;
  }

  switch (type) {
    case 'podcast_playlist': {
      return get(trendingItem, 'item.document.data.podcast_title.text', '');
    }
    case 'playlist': {
      return get(trendingItem, 'item.document.data.title', '');
    }
    default: {
      return '';
    }
  }
};

const getCoverImage = resourceDocument => {
  const type = get(resourceDocument, 'type', '');
  switch (type) {
    case 'podcast_playlist': {
      return get(resourceDocument, 'data.card_thumbnail', null);
    }
    case 'playlist': {
      return get(resourceDocument, 'data.cover_image', null);
    }
    default: {
      return null;
    }
  }
};
const getMediaCount = item => {
  const type = get(item, 'item.document.type', '');
  switch (type) {
    case 'podcast_playlist': {
      return get(item, 'item.document.data.episodes[0].items', []).length;
    }
    case 'playlist': {
      return get(item, 'item.document.data.videos', []).length;
    }
    default: {
      return '';
    }
  }
};
const getMediaIsAudio = item => {
  const type = get(item, 'item.document.type', '');
  switch (type) {
    case 'podcast_playlist': {
      return true;
    }
    case 'playlist': {
      return boolFlagFromString(get(item, 'item.document.data.label_is_audio', 'false'));
    }
    default: {
      return false;
    }
  }
};
const getFirstMediaDuration = item => {
  const type = get(item, 'item.document.type', '');
  switch (type) {
    case 'podcast_playlist': {
      const episodes = get(item, 'item.document.data.episodes[0].items', []);
      const durationInSeconds = get(
        episodes.length > 0 ? episodes[episodes.length - 1] : {}, // get the 1st episode (episodes[0] is the last episode)
        'podcast_episode.document.data.length_in_seconds',
        0
      );
      return getPlaylistCardReadableVideoDuration(durationInSeconds);
    }
    case 'playlist': {
      return getPlaylistCardReadableVideoDuration(
        get(item, 'item.document.data.videos[0].video.document.data.length_in_seconds', 0)
      );
    }
    default: {
      return '';
    }
  }
};

export const extractTrendingItems = trendingList =>
  (trendingList || []).map(item => ({
    title: getTrendingItemTitle(item),
    category: get(item, 'category', ''),
    uid: get(item, 'item.uid', ''),
    url: get(item, 'item.url', ''),
    coverImage: getCoverImage(get(item, 'item.document', null)),
    mediaCount: getMediaCount(item),
    firstMediaDuration: getFirstMediaDuration(item),
    isAudio: getMediaIsAudio(item)
  }));

export const extractTestimonials = items =>
  (items || []).map(item => ({
    testimonial: get(item, 'testimonial.html'),
    author: get(item, 'author'),
    designation: get(item, 'designation')
  }));

export const extractLandingFaqs = items =>
  (items || []).map(faq => ({
    title: get(faq, 'title', ''),
    description: get(faq, 'description.html')
  }));

export const extractCardFromMaterial = materialResource => {
  const previewImage = get(materialResource, 'document.data.preview_image', null);
  const seoImage = get(materialResource, 'document.data.seo_image', null);
  const planId = planIdFromString(
    get(materialResource, 'document.data.required_subscription_plan', null)
  );
  return {
    uid: get(materialResource, 'uid', ''),
    url: get(materialResource, 'document.data.file.url', ''),
    isSubscriberResource: planId > 0,
    urlTarget: '_blank',
    title: get(materialResource, 'document.data.title', ''),
    type: RESOURCE_TYPES.PRINTABLE_GUIDE,
    resourceMedium: get(
      materialResource,
      'document.data.resource_medium',
      RESOURCE_MEDIUMS.PRINTABLE_GUIDE
    ),
    metaText: 'Printable Guide',
    description: get(materialResource, 'document.data.material_description', ''),
    image: previewImage?.url ? previewImage : seoImage
  };
};

export const extractCardFromArticle = articleResource => {
  const parentPageUid = get(articleResource, 'document.data.parent_page.uid', '');
  const currentPageUid = get(articleResource, 'uid', '');
  const url = parentPageUid ? `/${parentPageUid}/${currentPageUid}` : `/${currentPageUid}`;
  const readTime = get(articleResource, 'document.data.read_time', 0);

  return {
    uid: currentPageUid,
    url,
    title: get(articleResource, 'document.data.title', ''),
    type: RESOURCE_TYPES.ARTICLE,
    resourceMedium: get(
      articleResource,
      'document.data.resource_medium',
      RESOURCE_MEDIUMS.PRINTABLE_GUIDE
    ),
    duration: readTime ? `${readTime} min` : '',
    metaText: RESOURCE_TYPES.ARTICLE,
    description: get(articleResource, 'document.data.description_text.text', ''),
    image: get(articleResource, 'document.data.seo_image', null)
  };
};

export const extractCardFromGenericPage = pageResource => ({
  uid: get(pageResource, 'uid', ''),
  url: get(pageResource, 'url', ''),
  title: get(pageResource, 'document.data.title', ''),
  type: RESOURCE_TYPES.ARTICLE,
  resourceMedium: get(
    pageResource,
    'document.data.resource_medium',
    RESOURCE_MEDIUMS.EDUCATOR_GUIDE
  ),
  metaText: RESOURCE_TYPES.ARTICLE,
  description: get(pageResource, 'document.data.seo_description', ''),
  image: get(pageResource, 'document.data.seo_image', null)
});

export const extractCardFromParshatPage = pageResource => {
  const heroSlice = get(pageResource, 'document.data.slices', []).find(
    slice => slice?.slice_type === 'hero_text-toc'
  );

  return {
    uid: get(pageResource, 'uid', ''),
    url: get(pageResource, 'url', ''),
    title: get(heroSlice, 'primary.title', '') || get(pageResource, 'document.data.title', ''),
    type: RESOURCE_TYPES.ARTICLE,
    resourceMedium: get(
      pageResource,
      'document.data.resource_medium',
      RESOURCE_MEDIUMS.EDUCATOR_GUIDE
    ),
    metaText: RESOURCE_TYPES.ARTICLE,
    description:
      get(heroSlice, 'primary.description', '') ||
      get(pageResource, 'document.data.seo_description', ''),
    image: get(pageResource, 'document.data.seo_image', null)
  };
};

export const extractCardFromPlaylist = playlistResource => {
  const resourceType = getPlaylistType(
    get(playlistResource, 'document.data.videos.length', 1),
    get(playlistResource, 'document.data.label_is_audio', '') === 'Yes'
  );
  const videos = get(playlistResource, 'document.data.videos', []);
  const coverImage = get(playlistResource, 'document.data.cover_image', null);
  const coverImageWithoutText = get(
    playlistResource,
    'document.data.cover_image_without_text',
    null
  );

  return {
    uid: get(playlistResource, 'uid', ''),
    url: get(playlistResource, 'url', ''),
    title: get(playlistResource, 'document.data.title', ''),
    type: resourceType,
    resourceMedium: get(
      playlistResource,
      'document.data.resource_medium',
      RESOURCE_MEDIUMS.VIDEO_SERIES
    ),
    duration: getPlaylistFirstVideoDuration(videos),
    episodeCountText: getPlaylistCurrentVideoText(1, videos?.length),
    metaText: getPlaylistMetaText(get(playlistResource, 'document.data.videos', []), resourceType),
    description: get(playlistResource, 'document.data.description.text', ''),
    image: coverImageWithoutText?.url ? coverImageWithoutText : coverImage
  };
};

export const extractCardFromPodcastPlaylist = podcastPlaylistResource => {
  const episodeCount = get(podcastPlaylistResource, 'document.data.episodes', []).reduce(
    (acc, episode) => acc + get(episode, 'items', []).length,
    0
  );
  const metaText = episodeCount === 1 ? `1 episode` : `${episodeCount} episodes`;

  return {
    uid: get(podcastPlaylistResource, 'uid', ''),
    url: get(podcastPlaylistResource, 'url', ''),
    title: get(podcastPlaylistResource, 'document.data.podcast_title.text', ''),
    type: RESOURCE_TYPES.PODCAST,
    resourceMedium: get(
      podcastPlaylistResource,
      'document.data.resource_medium',
      RESOURCE_MEDIUMS.PODCAST
    ),
    episodeCountText: metaText,
    metaText,
    description: get(podcastPlaylistResource, 'document.data.podcast_description.text', ''),
    image: get(podcastPlaylistResource, 'document.data.card_thumbnail', null)
  };
};

export const extractCardFromPodcastEpisode = podcastEpisodeResource => {
  const seconds = get(podcastEpisodeResource, 'document.data.length_in_seconds', 0);
  const duration = getPlaylistCardReadableVideoDuration(seconds);
  const metaText = `Podcast Episode • ${duration}`;
  const podcastPlaylistUid = get(
    podcastEpisodeResource,
    'document.data.podcast_playlist.document.uid',
    ''
  );
  const uid = get(podcastEpisodeResource, 'uid', '');

  return {
    uid,
    url: `/${podcastPlaylistUid}/${uid}`,
    title: get(podcastEpisodeResource, 'document.data.title', ''),
    type: RESOURCE_TYPES.PODCAST,
    resourceMedium: get(
      podcastEpisodeResource,
      'document.data.resource_medium',
      RESOURCE_MEDIUMS.PODCAST
    ),
    duration,
    metaText,
    description: get(podcastEpisodeResource, 'document.data.description.text', ''),
    image: get(
      podcastEpisodeResource,
      'document.data.podcast_playlist.document.data.card_thumbnail',
      null
    )
  };
};

export const extractArticleResourceCards = items =>
  (items || []).map(item => {
    const typeName = get(item, 'resource.document.__typename', '');
    let resource = null;
    switch (typeName) {
      case 'PrismicMaterial': {
        resource = extractCardFromMaterial(item.resource);
        break;
      }
      case 'PrismicArticle': {
        resource = extractCardFromArticle(item.resource);
        break;
      }
      case 'PrismicPlaylist': {
        resource = extractCardFromPlaylist(item.resource);
        break;
      }
      case 'PrismicHolidayRedesign':
      case 'PrismicParshat':
      case 'PrismicAdvanced': {
        resource = extractCardFromGenericPage(item.resource);
        break;
      }
      default: {
        break;
      }
    }

    return {
      ...item,
      resource
    };
  });

/**
 * Query for a location inside `pageData` and get the data for all the slices known to `Slices` component.
 *
 * Currently it's just a convenience wrapper to keep the abstraction level the same as `extractSeoData`
 *
 * @param {*} pageData Data from Prismic inside which to search for slices
 * @param {*} query A string acceptable by `lodash/get` which tells it where to search inside `pageData`.
 * @returns {KnownSliceData[]} Array of objects each one is a valid data for one of the slices known to `Slices` component.
 */
export const extractKnownSlices = (pageData, query) => get(pageData, query, []);

/**
 * Gets standard SEO fields for `SEO` component which we introduce to all of our page types in Prismic.
 *
 * Example call for the home page: `extractSeoData(pageData, 'prismicHome')`.
 * `prismicHome` is the query which we normally use to get data for the Home page.
 *
 * @param {*} pageData Full page data from the query (starting from root).
 * @param {String} pagePrismicTypeName Name of the root field in the GraphQL query.
 * @returns {} Props acceptable by the SEO component.
 */
export const extractSeoData = (pageData, pagePrismicTypeQuery) => ({
  seo_title: get(pageData, `${pagePrismicTypeQuery}.data.seo_title`, ''),
  seo_description: get(pageData, `${pagePrismicTypeQuery}.data.seo_description`, ''),
  seo_keywords: get(pageData, `${pagePrismicTypeQuery}.data.seo_keywords`, ''),
  seo_image: get(pageData, `${pagePrismicTypeQuery}.data.seo_image.url`, ''),
  seo_noindex: get(pageData, `${pagePrismicTypeQuery}.data.seo_noindex`, false),
  pageCanonicalUrl: get(pageData, `${pagePrismicTypeQuery}.data.seo_canonical_url`, '')
});

export const extractMinimalVideoList = (items = []) =>
  items.map(item => ({
    url: item.content_link.url,
    image: item.video_image.url,
    title: item.video_title.text
  }));
