import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Typography } from '@material-ui/core';
import { getPlaylistTypeIcon, getPlaylistType } from '~utils/playlist-helpers';

const useStyles = makeStyles(() => ({
  description: {
    width: '100%',
    padding: '14px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 8,
    backgroundColor: '#1E3F63',
    borderRadius: '0px 0px 12px 12px'
  },
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '18px',
    color: 'rgba(255, 255, 255, 0.85)',
    textAlign: 'center'
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'center'
  },
  videoInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 20
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    marginBottom: '20px'
  },
  infoText: {
    fontFamily: 'Inter',
    fontSize: 12,
    color: 'rgba(255,255,255, 0.54)',
    marginBottom: 0
  },
  author: {
    fontFamily: 'Inter',
    margin: 'auto',
    fontSize: 12,
    textAlign: 'center',
    color: 'rgba(255,255,255, 0.54)',
    marginTop: '4px'
  },
  resourceInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gridGap: '8px',
    justifyContent: 'center',
    marginTop: '16px'
  }
}));

const MobileDescription = ({ title, description, author, videoCount, isAudio, duration }) => {
  const classes = useStyles();

  const resourceType = getPlaylistType(videoCount, isAudio);
  const part = videoCount > 1 ? `Part 1 of ${videoCount}` : '';
  const typeIcon = getPlaylistTypeIcon(resourceType);

  return (
    <>
      <div className={classes.description}>
        <Box display="flex" flexDirection="column" gridRowGap="4px">
          <Typography component="h3" className={classes.titleText}>
            {title}
          </Typography>
          <Typography component="p" className={classes.descriptionText}>
            {description}
          </Typography>
        </Box>
        <div className={classes.videoInfo}>
          <div className={classes.cardFooter}>
            <div>
              {(resourceType || part || duration) && (
                <span className={classes.resourceInfo}>
                  <img src={typeIcon} alt="icon" />
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="p"
                    className={classes.infoText}
                  >
                    {[resourceType, part, duration].filter(Boolean).join(' • ')}
                  </Typography>
                </span>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.author}
              >
                {author}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MobileDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  videoCount: PropTypes.number.isRequired,
  isAudio: PropTypes.bool,
  duration: PropTypes.string
};

MobileDescription.defaultProps = {
  description: '',
  author: '',
  isAudio: false,
  duration: ''
};

export default MobileDescription;
