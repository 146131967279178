import isBot from 'isbot';
import { POPUPS_MAP } from '~src/layout/Popups';
import {
  getInsufficientPlanRedirectProps,
  getFailedChargesRedirectProps,
  ACCESS_LEVELS
} from '~utils/required-subscription-plan';
import { getNavigationPageLink } from '~utils/common-site-helper';

const isAccessLevelSufficient = (
  isUserAccessLevelSufficient,
  minAccessLevel,
  accessBlockingPopupId
) => {
  if (!isUserAccessLevelSufficient) {
    return {
      hasAccess: false,
      redirectPath: '/subscribe',
      redirectProps: getInsufficientPlanRedirectProps(minAccessLevel),
      accessBlockingPopupId
    };
  }

  return {
    hasAccess: true
  };
};

export default function authorizeUser(minAccessLevel, session, isAccount, isMarketingSite = false) {
  const { userAgent } = typeof window !== 'undefined' ? window.navigator : {};
  const {
    isLoggedIn,
    isSubscriber,
    isPrivilegedMember,
    isEducator,
    isProducer,
    isBarMitzvah,
    isAdmin,
    user: { has_failed_charges } = {}
  } = session;

  const isSuper = isAdmin || isBot(userAgent);
  if (isSuper) {
    return { hasAccess: true };
  }

  if (has_failed_charges && !isAccount) {
    return {
      hasAccess: false,
      redirectPath: '/account',
      redirectProps: getFailedChargesRedirectProps()
    };
  }

  switch (minAccessLevel) {
    case ACCESS_LEVELS.LOGGED_IN:
      return {
        hasAccess: isLoggedIn,
        redirectPath: getNavigationPageLink('/login', isMarketingSite)
      };
    case ACCESS_LEVELS.FREE_USER:
    case ACCESS_LEVELS.UNRESTRICTED:
      return { hasAccess: true };
    case ACCESS_LEVELS.BASIC_PLAN:
      return isAccessLevelSufficient(isSubscriber, minAccessLevel);
    case ACCESS_LEVELS.PREMIUM_PLAN:
      return isAccessLevelSufficient(
        isPrivilegedMember,
        minAccessLevel,
        POPUPS_MAP.PREMIUM_CONTENT_BLOCKER
      );
    case ACCESS_LEVELS.EDUCATOR_PLAN:
      return isAccessLevelSufficient(isEducator || isProducer, minAccessLevel);
    case ACCESS_LEVELS.PRODUCER_PLAN:
      return isAccessLevelSufficient(
        isProducer,
        minAccessLevel,
        POPUPS_MAP.PRODUCER_CIRCLE_CONTENT_BLOCKER
      );
    case ACCESS_LEVELS.BARBATMITZVAH:
      return {
        hasAccess: isBarMitzvah,
        redirectPath: '/bar-bat-mitzvah'
      };
    case ACCESS_LEVELS.ADMIN:
      return {
        hasAccess: isAdmin,
        redirectPath: '/'
      };
    default:
      return true; // allow by default
  }
}

export const getAccessBlockingPopupId = accessLevel => {
  switch (accessLevel) {
    case ACCESS_LEVELS.PRODUCER_PLAN:
      return POPUPS_MAP.PRODUCER_CIRCLE_CONTENT_BLOCKER;
    default:
      return POPUPS_MAP.PREMIUM_CONTENT_BLOCKER;
  }
};
