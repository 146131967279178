import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import Dialog from '~components/Dialog';
import { lineFix } from '~utils/popup-helpers';

import AbLogoImage from '~images/popups/ab-logo.png';
import { useTestId } from '~utils/set-testid';
import ButtonClosePopup from './components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '44px',
    fontWeight: 500,
    lineHeight: '52px',
    marginBottom: '20px',
    letterSpacing: '-1px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '34px',
      lineHeight: '40px',
      textAlign: 'center'
    }
  },
  buttonStyle: {
    backgroundColor: '#122119',
    color: '#fff',
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '28px',
    padding: '16px 46px',
    marginBottom: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#000'
    },
    [theme.breakpoints.down('md')]: {
      padding: '16px 20px'
    }
  },
  logoImage: {
    display: 'flex',
    maxWidth: '100%',
    paddingBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  imageMedia: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  closeText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    display: 'block',
    opacity: 0.87,
    textAlign: 'center'
  },
  paragraphStyle: {
    textAlign: 'left',
    fontSize: '18px',
    lineHeight: '24px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  mobileImagePosition: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto'
    }
  }
}));

const GenericAccessBlockingPopup = ({
  id,
  popupData,
  showTopCloseBtn,
  isPopupOpen,
  setPopupId,
  handlePopupClose
}) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isLoggedIn } = session;
  const { image, backdrop, title, subtitle, buttonProps, ctaLinkProps } = popupData;
  const testid = useTestId(`generic-access-blocking-popup-${id}`);

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    if (handlePopupClose) {
      handlePopupClose();
      return;
    }
    if (setPopupId) {
      setPopupId(0);
    }

    setIsOpen(false);
  };
  return (
    <Dialog
      isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
      backdrop={backdrop}
      background="transparent"
      maxWidth="md"
      {...testid}
    >
      <Grid
        container
        direction="row-reverse"
        justify="space-between"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={10} sm={6} className={classes.mobileImagePosition}>
          <img src={image.src} alt={image.alt} className={classes.imageMedia} />
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={AbLogoImage} alt="Aleph Beta logo" className={classes.logoImage} />
          <Typography className={classes.heading}>{lineFix(title)}</Typography>
          <Typography variant="subtitle1" className={classes.paragraphStyle}>
            {subtitle}
          </Typography>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={12}>
              <Button
                component={GatsbyLink}
                variant="contained"
                className={classes.buttonStyle}
                to={buttonProps.href}
                size="large"
                fullWidth
              >
                {buttonProps.title}
              </Button>
            </Grid>
          </Grid>
          {!isLoggedIn && ctaLinkProps && (
            <Typography component={GatsbyLink} to={ctaLinkProps.href} className={classes.closeText}>
              {ctaLinkProps.title}
            </Typography>
          )}
        </Grid>
      </Grid>

      {showTopCloseBtn && <ButtonClosePopup handleClose={handleClose} />}
    </Dialog>
  );
};

GenericAccessBlockingPopup.propTypes = {
  id: PropTypes.string,
  popupData: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    }).isRequired,
    backdrop: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonProps: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired,
    ctaLinkProps: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  showTopCloseBtn: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  setPopupId: PropTypes.func
};

GenericAccessBlockingPopup.defaultProps = {
  id: '',
  showTopCloseBtn: false,
  setPopupId: null,
  isPopupOpen: null,
  handlePopupClose: null
};

export default GenericAccessBlockingPopup;
