import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';

import TitleContainer from '../TitleContainer';
import HostCard from './HostCard';

const HostContainer = ({ hosts }) => {
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TitleContainer width="100%" marginBottom="50px" title="Hosts" orderDirection="row">
      <Grid container spacing={isSmallerThanMd ? 2 : 8}>
        {hosts &&
          hosts.map(host => (
            <Grid key={host.name} xs={12} sm={6} lg={5} xl={5} item>
              <HostCard {...host} />
            </Grid>
          ))}
      </Grid>
    </TitleContainer>
  );
};

HostContainer.propTypes = {
  hosts: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
      name: PropTypes.string,
      role: PropTypes.string
    })
  ).isRequired
};

export default HostContainer;
