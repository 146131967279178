import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/styles';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import Link from '~components/Link';
import rightArrow from './images/right-arrow.png';
import leftArrow from './images/left-arrow.png';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F6F6F6'
  },
  navbar: {
    marginTop: '12px'
  },
  parshaName: {
    fontSize: '12px',
    fontWeight: '500',
    paddingBottom: '3px',
    borderBottom: '2px solid transparent',
    color: '#717071',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: '600',
      borderBottom: '2px solid black',
      color: '#000000',
      textDecoration: 'none'
    }
  },
  currentParsha: {
    fontWeight: '600',
    borderBottom: '2px solid black',
    color: '#000000'
  },
  arrows: {
    height: '20px',
    width: '20px'
  },
  arrowButtons: {
    height: '17px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const WeeklyTorahPortionParshaNavbar = ({ parshaList, current }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getLengthofVisibleList = () => {
    if (isMobileScreen) {
      return 4;
    }
    if (isSmallerScreen) {
      return 9;
    }
    return 13;
  };

  const currentParshaIndex = parshaList.findIndex(p => p.title === current);
  const [startIndex, setStartIndex] = useState(
    (currentParshaIndex - 1 + parshaList.length) % parshaList.length
  );

  const handleArrowClick = direction => {
    const arrayLength = parshaList.length;
    const newIndex = (startIndex + direction + arrayLength) % arrayLength;
    setStartIndex(newIndex);
  };

  const visibleParshaList = Array.from({ length: getLengthofVisibleList() }, (_, index) => {
    const parshaIndex = (startIndex + index) % parshaList.length;
    return {
      ...parshaList[parshaIndex],
      isCurrent: parshaIndex === currentParshaIndex
    };
  });

  return (
    <Grid container row="column" justifyContent="center" className={classes.root}>
      <Grid item container className={classes.navbar} direction="row" justifyContent="space-evenly">
        <Button className={classes.arrowButtons} onClick={() => handleArrowClick(-1)} disableRipple>
          <img className={classes.arrows} src={leftArrow} alt="arrow-left" />
        </Button>
        {visibleParshaList.map(parsha => (
          <Link
            to={parsha.url}
            key={parsha.url}
            className={`${classes.parshaName} ${parsha.isCurrent ? classes.currentParsha : ''}`}
          >
            {parsha.title}
          </Link>
        ))}
        <Button className={classes.arrowButtons} onClick={() => handleArrowClick(1)} disableRipple>
          <img className={classes.arrows} src={rightArrow} alt="arrow-right" />
        </Button>
      </Grid>
    </Grid>
  );
};

WeeklyTorahPortionParshaNavbar.propTypes = {
  parshaList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  current: PropTypes.string.isRequired
};

export default WeeklyTorahPortionParshaNavbar;
