import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const TopSearchesList = ({ topSearchesData, setTabsValue, classes }) => {
  const handleClick = () => {
    if (setTabsValue) {
      setTabsValue(0);
    }
  };
  return (
    <div>
      <ol>
        {topSearchesData.map(searchInfo => (
          <li key={searchInfo.term} className={classes.topSearchesLi}>
            <Link
              to={`/search?t=${searchInfo.term}`}
              onClick={handleClick}
              className={classes.topSearchesLink}
            >
              &ldquo;{searchInfo.term}&ldquo; -{' '}
              <strong>
                {searchInfo.count} {searchInfo.count > 1 ? 'searches' : 'search'}
              </strong>
            </Link>
          </li>
        ))}
      </ol>
    </div>
  );
};
TopSearchesList.propTypes = {
  classes: PropTypes.shape({
    topSearchesLink: PropTypes.string,
    topSearchesLi: PropTypes.string
  }).isRequired,
  topSearchesData: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string,
      count: PropTypes.number
    })
  ).isRequired,
  setTabsValue: PropTypes.func
};

TopSearchesList.defaultProps = {
  setTabsValue: null
};

export default TopSearchesList;
