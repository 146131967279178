import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import HearthIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  banner: {
    bottom: 0,
    left: 'auto',
    right: 0,
    position: 'fixed',
    zIndex: 1300,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute'
    }
  },
  heading: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 500
  },
  buttonStyle: {
    backgroundColor: '#122119',
    color: '#fff',
    textTransform: 'none',
    fontSize: '18px',
    lineHeight: '21px',
    padding: '12px 41px',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  iconStyle: {
    fontSize: '20px',
    color: theme.palette.secondary.main,
    marginRight: '12px'
  },
  iconButtonStyle: {
    backgroundColor: '#0000004D',
    '&:hover': {
      backgroundColor: 'grey'
    }
  },
  closeIconStyle: {
    fontSize: '1rem',
    color: '#fff'
  }
}));

const RegisteredUserSoftSaleSecondPopup = ({ which, isPopupOpen, handlePopupClose, ...rest }) => {
  const classes = useStyles();
  const { id = undefined } = rest;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleClose = () => {
    if (handlePopupClose) {
      handlePopupClose();
    }
  };

  if (!isPopupOpen) {
    return null;
  }
  return (
    <Box
      bgcolor="#88FAC3"
      width="100%"
      pt={1}
      pb={3}
      className={classes.banner}
      id={id}
      borderTop="1px solid #000000"
    >
      <Container center>
        <Box
          width="100%"
          px={isXs ? 0 : 3}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton
            aria-label="close"
            className={classes.iconButtonStyle}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon className={classes.closeIconStyle} />
          </IconButton>
        </Box>
        <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md="auto">
            <Typography variant="h6" className={classes.heading} align="center">
              Support Us By Subscribing
            </Typography>
          </Grid>

          <Box component={Grid} item xs={12} md="auto" textAlign="center">
            <Button
              component={GatsbyLink}
              variant="contained"
              className={classes.buttonStyle}
              to="/subscribe"
            >
              <HearthIcon className={classes.iconStyle} />
              Get Started
            </Button>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

RegisteredUserSoftSaleSecondPopup.propTypes = {
  which: PropTypes.number.isRequired,
  setPopupId: PropTypes.func,
  id: PropTypes.string,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func
};

RegisteredUserSoftSaleSecondPopup.defaultProps = {
  setPopupId: null,
  id: undefined,
  isPopupOpen: null,
  handlePopupClose: null
};

export default RegisteredUserSoftSaleSecondPopup;
