import React from 'react';
import PropTypes from 'prop-types';
import { useCookie } from '@use-hook/use-cookie';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import RichText from '~components/RichText';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  banner: {
    zIndex: 1100,
    background: props =>
      props.banner_background_color ? `${props.banner_background_color}` : '#438dce',
    backgroundSize: 'cover',
    height: 'auto'
  },
  linkStyle: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  announcementStyle: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  iconFontSize: {
    color: props => (props.close_icon_color ? `${props.close_icon_color}` : '#fff'),
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem'
    }
  },
  buttonStyle: {
    padding: '12px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      marginRight: 'unset'
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: 'unset'
    }
  },
  bannerText: {
    color: props => (props.banner_text_color ? `${props.banner_text_color}` : '#fff'),
    fontSize: '16px',
    fontWeight: '500',
    paddingLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  button: {
    backgroundColor: props =>
      props.button_background_color ? `${props.button_background_color}` : '#438dce',
    padding: '9px 26px ',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    lineHeight: '19px',
    boxShadow: 'unset',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    color: props => (props.button_text_color ? props.button_text_color : '#fff'),
    '&:hover': {
      backgroundColor: props =>
        props.button_background_color ? `${props.button_background_color}` : '#438dce',
      opacity: '0.8',
      textDecoration: 'none'
    }
  },
  bannerContent: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));

const Banner = ({
  uid,
  banner_background_color,
  banner_text,
  banner_text_color,
  button_background_color,
  button_text_color,
  banner_is_announcement,
  button_title,
  close_icon_color,
  banner_link,
  daysToExpire,
  ...rest
}) => {
  const classes = useStyles({
    banner_background_color,
    banner_text_color,
    button_background_color,
    button_text_color,
    close_icon_color
  });

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [isBannerHidden, setIsBannerHidden] = useCookie(`hide-banner-${uid}`, false);

  const handleBannerClose = () => {
    setIsBannerHidden(true, { expires: daysToExpire });
  };

  if (isBannerHidden || !uid) {
    return null;
  }

  if (banner_is_announcement === 'Yes') {
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        id="banner_container"
        alignItems={isMobileDevice ? 'flex-start' : 'center'}
        className={classes.banner}
        py={isMobileDevice ? 2 : 0}
      >
        <Box
          className={classes.announcementStyle}
          display="flex"
          justifyContent="center"
          alignItems="center"
          {...rest}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={isMobileDevice ? 2 : 3}
          >
            <Grid item xs={10} md="auto">
              <Typography className={classes.bannerText} component="div">
                <RichText
                  html={banner_text.html}
                  verticalSpacing={0}
                  textAlign="left"
                  textColor={banner_text_color}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <IconButton
            aria-label="close"
            onClick={handleBannerClose}
            classes={{
              root: classes.buttonStyle
            }}
          >
            <Close className={classes.iconFontSize} />
          </IconButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      id="banner_container"
      display="flex"
      flexDirection="row"
      alignItems={isMobileDevice ? 'flex-start' : 'center'}
      className={classes.banner}
      py={isMobileDevice ? 2 : 0}
    >
      <Box
        component={Link}
        to={banner_link}
        state={{ banner_uid: uid }}
        className={classes.linkStyle}
        display="flex"
        justifyContent="center"
        alignItems="center"
        underline="none"
        {...rest}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={isMobileDevice ? 2 : 3}
          className={classes.bannerContent}
        >
          <Grid item xs={10} md="auto">
            <Typography className={classes.bannerText} component="div">
              <RichText
                html={banner_text.html}
                verticalSpacing={0}
                textAlign="left"
                textColor={banner_text_color}
              />
            </Typography>
          </Grid>
          <Grid item xs={10} sm={6} md="auto">
            <Button variant="contained" className={classes.button} fullWidth>
              {button_title}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <IconButton
          aria-label="close"
          onClick={handleBannerClose}
          classes={{
            root: classes.buttonStyle
          }}
        >
          <Close className={classes.iconFontSize} />
        </IconButton>
      </Box>
    </Box>
  );
};

Banner.propTypes = {
  uid: PropTypes.string.isRequired,
  banner_background_color: PropTypes.string.isRequired,
  banner_text: PropTypes.string.isRequired,
  banner_text_color: PropTypes.string,
  button_background_color: PropTypes.string,
  button_text_color: PropTypes.string,
  button_title: PropTypes.string,
  banner_link: PropTypes.string,
  close_icon_color: PropTypes.string,
  banner_is_announcement: PropTypes.string,
  daysToExpire: PropTypes.number
};

Banner.defaultProps = {
  daysToExpire: 14,
  banner_link: '',
  button_title: '',
  button_text_color: '',
  button_background_color: '',
  banner_is_announcement: '',
  close_icon_color: '',
  banner_text_color: ''
};

export default Banner;
