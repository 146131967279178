import { extractKnownSlices, extractSeoData } from '~utils/unfurl-helpers';
import { get } from '../utils/get';

export const extractHeroPlaylists = playlist => ({
  ...playlist.document.data,
  id: playlist.document.id,
  uid: playlist.uid,
  url: playlist.url
});

export default prismicData => ({
  hero: {
    title: get(prismicData, 'prismicTopic.data.hero_title'),
    subtitle: get(prismicData, 'prismicTopic.data.hero_subtitle'),
    featuredVideo: extractHeroPlaylists(get(prismicData, 'prismicTopic.data.hero_video', []))
  },

  seo: extractSeoData(prismicData, 'prismicTopic'),
  slices: extractKnownSlices(prismicData, 'prismicTopic.data.body')
});
