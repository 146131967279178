import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: '#f0f0f0'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

const UPDATE_INTERVAL = 100; // in milliseconds

const IS_BROWSER = typeof window !== 'undefined';

const AnimatedProgressBar = ({ seconds, startingPercentage, finishingPercentage }) => {
  const [progress, setProgress] = useState(startingPercentage);
  const [progressTimer, setProgressTimer] = useState(null);
  const progressIncrement = useMemo(() => {
    const remainingPercentage = finishingPercentage - startingPercentage;
    const frameCount = Math.ceil((seconds * 1000) / UPDATE_INTERVAL);
    return remainingPercentage / frameCount;
  }, [startingPercentage, finishingPercentage, seconds]);

  useEffect(() => {
    let timer = null;

    if (IS_BROWSER) {
      timer = setInterval(() => {
        setProgress(prevProgress => {
          const currentProgress = prevProgress + progressIncrement;
          return currentProgress > 100 ? 100 : currentProgress;
        });
      }, UPDATE_INTERVAL);

      setProgressTimer(timer);
    }

    return () => {
      if (timer !== null) {
        clearInterval(timer);
      }
    };
  }, [progressIncrement]);

  useEffect(() => {
    if (IS_BROWSER && progressTimer !== null && progress >= 100) {
      clearInterval(progressTimer);
    }
  }, [progress, progressTimer]);

  return <BorderLinearProgress variant="determinate" value={progress} />;
};

AnimatedProgressBar.propTypes = {
  seconds: PropTypes.number.isRequired,
  startingPercentage: PropTypes.number,
  finishingPercentage: PropTypes.number
};

AnimatedProgressBar.defaultProps = {
  startingPercentage: 0,
  finishingPercentage: 100
};

export default AnimatedProgressBar;
