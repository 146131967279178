import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Page from '~layout/Page';
import SEO from '~layout/SearchEngineOptimization';

import CurationPageLayoutContainer from '~src/modules/curation-page/components/CurationPageLayoutContainer';

import unfurl from '~prismic/unfurl/curation';

const CurationPage = ({ data }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(null);

  const { hero, grid, seo } = unfurl(data);

  return (
    <Page hideFooter={isDrawerOpen}>
      <SEO {...seo} />

      <CurationPageLayoutContainer
        hero={hero}
        grid={grid}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </Page>
  );
};

CurationPage.propTypes = {
  data: PropTypes.shape().isRequired
};

export default withPrismicPreview(CurationPage);

export const query = graphql`
  query ($uid: String!) {
    prismicCuration(uid: { eq: $uid }) {
      _previewable
      uid
      type
      data {
        grid_title {
          text
        }
        body {
          ... on PrismicCurationDataBodyCurationHero {
            slice_type
            primary {
              title {
                html
                text
              }
              description {
                html
                text
              }
              hero_background_color
              hero_background_image {
                url
              }
              text_color
              popup_button_text
              hero_popup_video_link {
                url
              }
            }
          }
        }
        grid {
          ... on PrismicCurationDataGridCurationCard {
            primary {
              card_title
              card_alt_title
              card_color
              inactive
              open_first_playlist_on_click
              card_icon {
                url
              }
            }
            items {
              playlists {
                document {
                  ... on PrismicPlaylist {
                    uid
                    url
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
      }
    }
  }
`;
