import { useMemo } from 'react';
import * as JsSearch from 'js-search';
import { useDebounce } from 'use-debounce';
import { sortCurationPageCards } from '~utils/playlist-helpers';

const search = new JsSearch.Search('card_title');

search.addIndex('card_title');

search.addIndex('playlists_search_title');
search.addIndex('playlists_search_description');
search.addIndex('playlists_search_author');

function useCardSearch(cards, query, sortType) {
  const [debouncedQuery] = useDebounce(query, 500);
  // FIXME: each rerender adds the same documents to the search
  search.addDocuments([...cards]);

  const cardResult = useMemo(() => {
    if (debouncedQuery) return sortCurationPageCards(sortType, search.search(debouncedQuery));

    return sortCurationPageCards(sortType, cards);
  }, [debouncedQuery, cards, sortType]);

  return cardResult;
}

export default useCardSearch;
