import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useGiftContent from '~hooks/useGiftContent';

import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Dialog from '~components/Dialog';
import { TextInput, EmailInput, TextAreaInput } from '~components/form-inputs';
import Link from '~components/Link';
import { lineFix } from '~utils/popup-helpers';
import LoadingSpinner from '~components/LoadingSpinner';
import MessageBox from '~components/forms/MessageBox';
import ThankYouImage from '~images/thank-you-gif.gif';
import ButtonClosePopup from './components/ButtonClosePopup';

const EXTRA_CONTENT = [
  {
    to: '/playlist/importance-of-shabbat',
    coverImage: {
      url: 'https://images.prismic.io/aleph-beta/1e9e313f0c6be82963828100cf821ff24b641d07_why-shabbat-so-important-jewish-holidays.jpg',
      alt: 'The Importance Of Shabbat In All Jewish Holidays'
    },
    title: 'The Importance Of Shabbat In All Jewish Holidays',
    author: 'Rabbi David Fohrman'
  },
  {
    to: '/playlist/what-are-brachot',
    coverImage: {
      url: 'https://images.prismic.io/aleph-beta/0f0e419fb8d39fdf5e14b58f195bd0ef4ad15465_what-are-brachot_1528996583432.jpg',
      alt: 'The Meaning Of Brachot?'
    },
    title: 'The Meaning Of Brachot?',
    author: 'Ami Silver'
  },
  {
    to: '/playlist/sabbath-day-significance',
    coverImage: {
      url: 'https://images.prismic.io/aleph-beta/b3b4d1686392d739d81c838273c86a9651870beb_significance-keeping-sabbath-day.jpg',
      alt: 'The Significance Of Keeping The Sabbath'
    },
    title: 'The Significance Of Keeping The Sabbath',
    author: 'Ami Silver'
  }
];

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '27px',
    fontWeight: 700,
    lineHeight: '31px',
    color: '#458FCE',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      lineHeight: '24px',
      fontWeight: 600,
      textAlign: 'center'
    }
  },
  imageMedia: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  paragraphStyle: {
    textAlign: 'center',
    marginBottom: '20px',
    lineHeight: 1.57,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: 1.4,
      marginBottom: '10px'
    }
  },
  input: {
    margin: 0,
    '& .MuiFormLabel-root': {
      color: '#BFBFBF',
      fontSize: '14px'
    },
    '& .MuiFilledInput-multiline': {
      padding: '0'
    },
    '& .MuiFilledInput-input': {
      padding: '18px 12px 10px'
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent !important'
    }
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18.75px',
    marginBottom: '20px'
  },
  contentCtaText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '30px'
  },
  playlistAuthor: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px'
  },
  spinnerPosition: {
    position: 'absolute',
    top: '320px',
    left: '44%'
  },
  closeButton: {
    position: 'absolute',
    top: '6px',
    right: '6px'
  },
  linkPreviewText: {
    padding: '25px',
    paddingLeft: '50px',
    [theme.breakpoints.down('md')]: {
      padding: '16px'
    }
  },
  paper: {
    overflowY: 'visible',
    background: '"#fff',
    boxShadow: 'none',
    borderRadius: '4px',
    width: '100%',
    margin: '65px 12px',
    padding: '0',
    [theme.breakpoints.down('md')]: {
      margin: '35px 12px',
      padding: '0'
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: 'calc(100% - 32px) !important'
    }
  },
  containerPadding: {
    padding: '32px',
    [theme.breakpoints.down('md')]: {
      padding: '18px'
    }
  }
}));

const GiftContentPopup = ({
  showTopCloseBtn,
  setPopupId,
  id = undefined,
  isPopupOpen,
  handlePopupClose,
  playlistId,
  playlistSlug,
  thumbnail,
  contentType,
  playlistTitle,
  videoTitle,
  author
}) => {
  const classes = useStyles();
  const {
    state,
    sendGiftContent,
    giftTokensLeft,
    userFormData,
    receiverFormData,
    handleFormChange
  } = useGiftContent();
  const { isLoading, isError, data, error } = state;

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    if (handlePopupClose) {
      handlePopupClose();
      return;
    }
    if (setPopupId) {
      setPopupId(0);
    }
    setIsOpen(false);
  };

  const handleSubmit = event => {
    event.preventDefault();

    sendGiftContent({
      playlistId,
      playlistSlug,
      message: userFormData.message,
      gifterName: `${userFormData.firstName} ${userFormData.lastName}`,
      gifteeEmail: receiverFormData.email,
      gifteeName: `${receiverFormData.firstName} ${receiverFormData.lastName}`,
      contentType: contentType.toLowerCase(),
      contentTitle: playlistTitle,
      contentSubtitle: author,
      giftContentThumbnail: thumbnail.url
    });
  };

  return (
    <Dialog
      isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
      id={id}
      keepMounted={false}
      backdrop="rgba(0, 0, 0, 0.7)"
      background="#fff"
      maxWidth="md"
      PaperProps={{
        className: classes.paper
      }}
    >
      <Box position="relative">
        {data && data.leftoverTokens ? (
          <Box className={classes.containerPadding}>
            <Typography
              className={classes.heading}
              align="center"
              style={{
                marginBottom: '20px',
                color: '#000'
              }}
            >
              {lineFix(`Thank you for sharing Aleph Beta!`)}
            </Typography>
            <Typography align="center" className={classes.subtitle}>
              You have <strong>{giftTokensLeft} gifts remaining</strong> to give this month
            </Typography>
            <img
              src={ThankYouImage}
              alt="Thank you for sharing Aleph Beta"
              className={classes.imageMedia}
            />
            <Typography className={classes.contentCtaText}>
              Discover more of what you love...
            </Typography>
            <Grid container direction="row" alignItems="center" justify="space-between" spacing={2}>
              {EXTRA_CONTENT &&
                EXTRA_CONTENT.map(playlist => (
                  <Grid item xs={12} sm={6} md={4}>
                    <Link to={playlist.to} style={{ color: 'unset' }}>
                      <img
                        src={playlist.coverImage.url}
                        alt={playlist.coverImage.alt}
                        className={classes.imageMedia}
                      />

                      <Box p={1} border="0.3px solid #A5A5A5" borderRadius="2px" minHeight="85px">
                        <Typography
                          align="left"
                          style={{
                            fontWeight: 700,
                            fontSize: '15px',
                            lineHeight: '18.75px',
                            marginBottom: '10px'
                          }}
                        >
                          {playlist.title}
                        </Typography>
                        <Typography className={classes.playlistAuthor}>
                          {playlist.author}
                        </Typography>
                      </Box>
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </Box>
        ) : (
          <>
            {isLoading && (
              <Box position="relative">
                <LoadingSpinner className={classes.spinnerPosition} />
              </Box>
            )}
            <form onSubmit={handleSubmit} style={{ filter: isLoading && `blur(4px)` }}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.containerPadding}
              >
                <Grid item xs={12}>
                  <Typography className={classes.heading} align="center" gutterBottom>
                    {lineFix(`Gift This ${contentType}`)}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.paragraphStyle} align="center">
                    If you found this piece moving or exciting, you can now share that feeling with
                    family and friends, even if they are not Aleph Beta subscribers. Because of your
                    gift, they will be able to watch without any blockers.
                  </Typography>
                  {isError && <MessageBox text={error} isError />}
                </Grid>
                <Box
                  component={Grid}
                  item
                  container
                  direction="row"
                  xs={12}
                  justify="flex-start"
                  p={0}
                  alignItems="center"
                  bgcolor="#F2F2F2"
                  width="100%"
                  borderRadius={2}
                >
                  <Grid item xs={12} md={5}>
                    <img src={thumbnail.url} alt={thumbnail.alt} className={classes.imageMedia} />
                  </Grid>
                  <Grid item xs={12} md={7} className={classes.linkPreviewText}>
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '23.44px',
                        marginBottom: '20px'
                      }}
                    >
                      {playlistTitle}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '18.75px',
                        marginBottom: '10px'
                      }}
                      gutterBottom
                    >
                      {videoTitle}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: 400, fontSize: '16px', lineHeight: '18.75px' }}
                    >
                      {author}
                    </Typography>
                  </Grid>
                </Box>

                <Grid item container direction="row" xs={12} md={6} spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18.75px',
                        marginTop: '15px'
                      }}
                      gutterBottom
                    >
                      Your Name
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextInput
                      id="firstName"
                      label="First Name"
                      value={userFormData.firstName}
                      autoComplete="given-name"
                      className={classes.input}
                      defaultValue={userFormData.firstName}
                      onChange={handleFormChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      id="lastName"
                      label="Last Name"
                      value={userFormData.lastName}
                      defaultValue={userFormData.lastName}
                      autoComplete="family-name"
                      className={classes.input}
                      onChange={handleFormChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EmailInput
                      name="username"
                      value={userFormData.username}
                      disabled={!!userFormData.username}
                      className={classes.input}
                      onChange={handleFormChange}
                      defaultValue={userFormData.username}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row" xs={12} md={6} spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18.75px',
                        marginTop: '15px'
                      }}
                      gutterBottom
                    >
                      Your Friends Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      label="First Name"
                      name="firstName"
                      autoComplete="given-name"
                      value={receiverFormData.firstName}
                      defaultValue={receiverFormData.firstName}
                      className={classes.input}
                      onChange={handleFormChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      value={receiverFormData.lastName}
                      defaultValue={receiverFormData.lastName}
                      className={classes.input}
                      onChange={handleFormChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EmailInput
                      name="email"
                      value={receiverFormData.email}
                      defaultValue={receiverFormData.email}
                      className={classes.input}
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18.75px',
                        marginTop: '15px'
                      }}
                      gutterBottom
                    >
                      Message
                    </Typography>
                  </Grid>
                  <TextAreaInput
                    id="message"
                    placeholder={`Your friend is 70% more likely to watch/listen & enjoy your gift if it comes with a personalized card.${'\r\n\r\n'}Take a moment to write a personal message explaining what excited you and why you'd like them to take part in your gift.`}
                    className={classes.input}
                    value={userFormData.message}
                    defaultValue={userFormData.message}
                    onChange={handleFormChange}
                    style={{ marginBottom: '8px' }}
                  />
                </Grid>
                <Grid item xs={12} md={2} style={{ marginLeft: 'auto' }}>
                  <ButtonSubmit>Send</ButtonSubmit>
                </Grid>
              </Grid>
            </form>
          </>
        )}
        {showTopCloseBtn && (
          <ButtonClosePopup handleClose={handleClose} className={classes.closeButton} />
        )}
      </Box>
    </Dialog>
  );
};

GiftContentPopup.propTypes = {
  playlistTitle: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  showTopCloseBtn: PropTypes.bool,
  setPopupId: PropTypes.func,
  id: PropTypes.string,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  playlistId: PropTypes.string.isRequired,
  playlistSlug: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  contentType: PropTypes.string.isRequired
};

GiftContentPopup.defaultProps = {
  showTopCloseBtn: false,
  setPopupId: null,
  id: undefined,
  isPopupOpen: null,
  handlePopupClose: null
};

export default GiftContentPopup;
