import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Link from '~components/Link';
import GuidesMaterialExpansionPanel from './GuidesMaterialsExpansionPanel';

const StyledSectionSpacingDiv = styled.div`
  padding: 24px 0px;
  width: 100%;
`;

const StyledSpacingDiv = styled.div`
  padding: 24px 0px;
`;

const StyledPremiumLabelDiv = styled.div`
  padding: 0px 8px;
  margin-bottom: 8px;
  color: #fff;
  border-radius: 4px;
  background-color: #438dce;
`;

const StyledLink = styled(Link)`
  font-weight: 600;
`;

const GuidesParshaGuides = ({ materials }) => (
  <StyledSectionSpacingDiv>
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="h4" component="h4" gutterBottom>
          Parsha Guides
        </Typography>
      </Grid>
      <Grid item>
        <StyledPremiumLabelDiv>
          <Typography variant="overline" color="inherit">
            Premium only
          </Typography>
        </StyledPremiumLabelDiv>
      </Grid>
    </Grid>

    <Typography variant="subtitle2" gutterBottom>
      These printable Parsha Guides, which are available only to our Premium subscribers, are a
      wonderful resource for generating lively discussion at your Shabbat table, between you and
      your <em>chevruta</em> (learning partner) or study group, or just for curling up on the couch
      to read by yourself. To become a Premium member and access these guides,{' '}
      <StyledLink to="/subscribe">subscribe today!</StyledLink>
    </Typography>
    <StyledSpacingDiv>
      <GuidesMaterialExpansionPanel materials={materials} />
    </StyledSpacingDiv>
  </StyledSectionSpacingDiv>
);

GuidesParshaGuides.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      panel_title: PropTypes.string.isRequired,
      materials: GuidesMaterialExpansionPanel.PropTypes
    })
  ).isRequired
};
export default GuidesParshaGuides;
