import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #b5b5b5i'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  focused: {}
}));

const TextAreaInput = forwardRef(({ label, name, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <TextField
      inputRef={ref}
      label={label}
      type="text"
      name={name}
      fullWidth
      multiline
      rows="5"
      margin="normal"
      variant="filled"
      InputProps={{ classes, disableUnderline: true }}
      {...rest}
    />
  );
});

TextAreaInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default TextAreaInput;
