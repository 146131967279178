import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RichText from '~components/RichText';
import RoundedLinkIconButton from '~components/buttons/RoundedLinkIconButton';

const useStyles = makeStyles(theme => ({
  body: props => ({
    backgroundColor: props.themeColor,
    color: props.themeSecondaryColor,
    width: '100%',
    minHeight: '430px',
    backgroundImage: `url(${props.backgroundUrl})`,
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-2px'
    }
  }),
  gridStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '6%',
    paddingRight: '6%',
    margin: 0,
    width: '100%'
  },
  gridItem: {
    padding: '0 !important'
  },
  playIconStyle: props => ({
    fill: props.themeColor,
    width: '1rem',
    height: '1rem',
    fontSize: '1.2rem'
  }),
  eventName: props => ({
    marginBlockStart: '68px',
    marginBlockEnd: '12px',
    color: props.themeSecondaryColor,
    opacity: 0.6,
    fontSize: '12px',
    '& > *': {
      fontSize: '12px',
      fontWeight: 600,
      fontFamily: 'Inter',
      color: props.themeSecondaryColor,
      margin: 0,
      textAlign: 'center'
    }
  }),
  heroTitle: props => ({
    marginBlockStart: 0,
    marginBlockEnd: 8,
    '& > *': {
      fontFamily: 'Inter',
      fontWeight: 600,
      lineHeight: '42px',
      fontSize: '35px',
      color: props.themeSecondaryColor,
      margin: 0,
      textAlign: 'center'
    }
  }),
  heroSubtitle: props => ({
    marginBlockStart: 0,
    marginBlockEnd: 21,
    '& > *': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      color: props.themeSecondaryColor,
      margin: 0,
      textAlign: 'center'
    }
  })
}));

const THEME_OPTION = {
  MODERN_LIGHT: 'Modern Light',
  MODERN_DARK: 'Modern Dark'
};

const THEME_PRIMARY_COLOR = {
  [THEME_OPTION.MODERN_LIGHT]: '#FFFFFF',
  [THEME_OPTION.MODERN_DARK]: '#020928'
};

const THEME_SECONDARY_COLOR = {
  [THEME_OPTION.MODERN_LIGHT]: '#000000',
  [THEME_OPTION.MODERN_DARK]: '#FFFFFF'
};

const ModernHolidayHeroMobile = ({
  theme: themeOption,
  hero_mobile_background_image,
  hero_background_image,
  overline_text,
  hero_title,
  hero_subtitle,
  hero_featured_video
}) => {
  const classes = useStyles({
    themeColor: THEME_PRIMARY_COLOR[themeOption],
    themeSecondaryColor: THEME_SECONDARY_COLOR[themeOption],
    backgroundUrl: hero_mobile_background_image?.url || hero_background_image.url
  });
  const { url } = hero_featured_video;
  const buttonVariant = themeOption === THEME_OPTION.MODERN_DARK ? 'light' : 'dark';

  return (
    <div className={classes.body}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        className={classes.gridStyle}
      >
        <Grid item xs={12} className={classes.gridItem}>
          <RichText
            html={overline_text.html}
            verticalSpacing={0}
            externalClassName={classes.eventName}
          />

          <RichText html={hero_title.html} externalClassName={classes.heroTitle} />
          <RichText html={hero_subtitle.html} externalClassName={classes.heroSubtitle} />
        </Grid>
        <RoundedLinkIconButton
          text="Watch Now"
          to={url}
          icon={<PlayArrowIcon className={classes.playIconStyle} />}
          variant={buttonVariant}
          size="small"
        />
      </Grid>
    </div>
  );
};
ModernHolidayHeroMobile.propTypes = {
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  hero_mobile_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }),
  overline_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_featured_video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    document: PropTypes.shape({
      data: PropTypes.shape({
        label_is_new: PropTypes.string.isRequired,
        label_is_audio: PropTypes.string.isRequired,
        label_is_premium: PropTypes.string.isRequired,
        label_is_producer: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  theme: PropTypes.oneOf(Object.values(THEME_OPTION))
};

ModernHolidayHeroMobile.defaultProps = {
  theme: THEME_OPTION.MODERN_DARK,
  hero_mobile_background_image: ''
};

export default ModernHolidayHeroMobile;
