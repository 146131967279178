import { get } from 'lodash';
import { extractKnownSlices, extractSeoData } from '~utils/unfurl-helpers';

const extractWeeklyTorahLinks = prismicData => {
  const root = get(prismicData, 'edges[0].node.data.body');

  const menuItem = root.find(
    item => item.primary.menu_title.toLowerCase() === 'weekly torah portion'
  );
  if (!menuItem) {
    return [];
  }
  const base =
    menuItem.items &&
    menuItem.items.length > 0 &&
    menuItem.items.map(nestedMenuItem => ({
      children:
        nestedMenuItem.menu_item_document.document.data.chapters &&
        nestedMenuItem.menu_item_document.document.data.chapters.length > 0 &&
        nestedMenuItem.menu_item_document.document.data.chapters.map(child => ({
          link: child.chapter.url,
          title: child.chapter.document.data.title,
          uid: child.chapter.document.data.title.toLowerCase()
        }))
    }));

  return base.flatMap(x => x.children);
};

export default prismicData => ({
  slices: extractKnownSlices(prismicData, 'prismicParshat.data.slices'),
  seo: extractSeoData(prismicData, 'prismicParshat'),
  /**
   * This thing here exists to support the "next parshat/previous parshat" buttons on the parshat page.
   * It uses the top navigation data for that.
   * Please note that to support that, the top navigation data is taken not from the global Page GraphQL query
   * Parshat page template have a copy of this GraphQL query inside.
   * If the PrismicWipNavigation changes for the global top navbar, we'll have to migrate the changes to the Parshat
   * page template query, too.
   */
  listOfParshas: extractWeeklyTorahLinks(prismicData.allPrismicWipNavigation)
});
