import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  bottomShapeDivider: {
    width: '100vw',
    backgroundColor: props => props.bgColor,
    position: props => props.position,
    bottom: 0,
    display: props => (props.flex ? 'flex' : 'initial'),
    [theme.breakpoints.down('md')]: {
      width: '120vw',
      marginLeft: '-40px',
      overflow: 'hidden',
      bottom: -1
    }
  }
}));

const BottomShapeDivider = ({ src, bgColor, position, className, flex }) => {
  const classes = useStyles({ bgColor, position, flex });

  const classNames = className
    ? `${classes.bottomShapeDivider} ${className}`
    : classes.bottomShapeDivider;
  return <img src={src} alt="section-divider" className={classNames} />;
};

BottomShapeDivider.propTypes = {
  src: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  flex: PropTypes.bool,
  position: PropTypes.oneOf(['relative', 'absolute'])
};

BottomShapeDivider.defaultProps = {
  bgColor: '#FFFFFF',
  position: 'relative',
  className: '',
  flex: false
};

export default BottomShapeDivider;
