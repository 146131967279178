import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import RichText from '~components/RichText';
import Container from '~components/Container';
import { lineFix } from '~utils/popup-helpers';
import { boolFlagFromString } from '~utils/playlist-helpers';
import NonSubscriberHeroMaskImage from '~images/lg-mask-free-image.png';
import {
  LabelNew,
  LabelAudio,
  LabelPremium,
  LabelProducer,
  LabelOverlay
} from '~components/playlist-card/PlaylistLabels';
import PrimaryColorAnimatedPlayButton from './components/PrimaryColorAnimatedButton';

const StyledContainer = styled(Container)`
  margin-top: 70px;
`;

const StyledDiv = styled.div`
  position: relative;
  width: 100%;
`;

const StyledHeroContainer = styled(Container)`
  height: 650px;

  @media (max-width: 1919px) {
    height: 560px;
  }
  @media (max-width: 1279px) {
    height: 500px;
  }
  @media (max-width: 992px) {
    height: 380px;
  }
  @media (max-width: 600px) {
    height: 190px;
  }
`;

const StyledLabelsOverlay = styled(LabelOverlay)`
  position: unset;
  margin-bottom: 20px;
`;
const HeroMaskImage = styled.img`
  position: absolute;
  bottom: -1px;
  display: flex;
  width: 100%;
`;

const BoldedSubtitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  text-align: left;
  margin: 0;
  margin-top: 20px;
  padding: 10px;
  display: inline-flex;
  border-radius: 3px;
  & > * {
    margin: 0;
  }
  @media (max-width: 992px) {
    padding-right: unset;
  }
`;
const HorizontalHolidayHero = ({
  hero_background_image,
  overline_text,
  hero_title,
  hero_subtitle,
  hero_featured_video,
  label_text,
  label_text_color,
  label_text_background_color
}) => {
  const { url, document } = hero_featured_video;
  const { label_is_new, label_is_audio, label_is_premium, label_is_producer } =
    document && document.data;

  const isAudio = boolFlagFromString(label_is_audio);
  const isNew = boolFlagFromString(label_is_new);
  const isPremium = boolFlagFromString(label_is_premium);
  const isProducer = boolFlagFromString(label_is_producer);

  return (
    <>
      <StyledDiv>
        <StyledHeroContainer
          background={`url("${hero_background_image.url}")`}
          background_position="top"
        >
          <GatsbyLink to={url}>
            <PrimaryColorAnimatedPlayButton />
          </GatsbyLink>
        </StyledHeroContainer>
        <HeroMaskImage src={NonSubscriberHeroMaskImage} alt="Mask" />
      </StyledDiv>

      <StyledContainer>
        <RichText
          html={overline_text.html}
          mode="verticalHolidayHeroOverlineHeading"
          verticalSpacing={0}
        />
        <StyledLabelsOverlay>
          {isNew && <LabelNew />}
          {isAudio && <LabelAudio />}
          {isPremium && <LabelPremium />}
          {isProducer && <LabelProducer />}
        </StyledLabelsOverlay>
        <RichText html={hero_title.html} mode="holidayHeroTitle" />
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} md={8}>
            <RichText html={hero_subtitle.html} mode="holidayHeroSubtitle" verticalSpacing={0} />

            {label_text && label_text.html && (
              <BoldedSubtitle
                style={{ color: label_text_color, backgroundColor: label_text_background_color }}
              >
                {lineFix(label_text.html)}
              </BoldedSubtitle>
            )}
          </Grid>
        </Grid>
      </StyledContainer>
    </>
  );
};
HorizontalHolidayHero.propTypes = {
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  overline_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_featured_video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    document: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({
          label_is_new: PropTypes.string.isRequired,
          label_is_audio: PropTypes.string.isRequired,
          label_is_premium: PropTypes.string.isRequired,
          label_is_producer: PropTypes.string.isRequired
        })
      })
    )
  }).isRequired,
  label_text: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  label_text_color: PropTypes.string,
  label_text_background_color: PropTypes.string
};

HorizontalHolidayHero.defaultProps = {
  label_text_color: '#000',
  label_text_background_color: '#fff'
};

export default HorizontalHolidayHero;
