import VideoSeriesIcon from '~images/icons/video-series-icon.svg';
import VideoIcon from '~images/icons/video-icon.svg';
import AudioIcon from '~images/icons/audio-icon.svg';
import GuideIcon from '~images/icons/default-guide-icon.svg';
import MicrophoneIcon from '~images/icons/microphone-icon.svg';
import MediumDeepDiveCourse from '~images/resource-mediums/resource-medium-deep-dive-course.inline.svg';
import MediumJourney from '~images/resource-mediums/resource-medium-journey.inline.svg';
import MediumPresentation from '~images/resource-mediums/resource-medium-presentation.inline.svg';
import MediumRecordedLecture from '~images/resource-mediums/resource-medium-recorded-lecture.inline.svg';
import MediumVideo from '~images/resource-mediums/resource-medium-video.inline.svg';
import MediumEducatorGuide from '~images/resource-mediums/resource-medium-educator-guide.inline.svg';
import MediumPodcast from '~images/resource-mediums/resource-medium-podcast.inline.svg';
import MediumPrintableGuide from '~images/resource-mediums/resource-medium-printable-guide.inline.svg';
import MediumVideoSeries from '~images/resource-mediums/resource-medium-video-series.inline.svg';

export const RESOURCE_TYPES = {
  AUDIO: 'Audio',
  VIDEO: 'Video',
  VIDEO_SERIES: 'Video series',
  GUIDE: 'Guide',
  ARTICLE: 'Article',
  PRINTABLE_GUIDE: 'Printable Guide',
  PODCAST: 'Podcast',
  PODCAST_EPISODE: 'Podcast Episode'
};

export const getResourceTypeIcon = resourceType => {
  switch (resourceType) {
    case RESOURCE_TYPES.PODCAST:
    case RESOURCE_TYPES.PODCAST_EPISODE:
      return MicrophoneIcon;
    case RESOURCE_TYPES.AUDIO:
      return AudioIcon;
    case RESOURCE_TYPES.GUIDE:
    case RESOURCE_TYPES.PRINTABLE_GUIDE:
    case RESOURCE_TYPES.ARTICLE:
      return GuideIcon;
    case RESOURCE_TYPES.VIDEO_SERIES:
      return VideoSeriesIcon;
    case RESOURCE_TYPES.VIDEO:
    default:
      return VideoIcon;
  }
};

export const RESOURCE_MEDIUMS = {
  EDUCATOR_GUIDE: 'Educator Guide',
  PRINTABLE_GUIDE: 'Printable Guide',
  DEEP_DIVE_COURSE: 'Deep Dive Course',
  PODCAST: 'Podcast',
  RECORDED_LECTURE: 'Recorded Lecture',
  RECORDED_LECTURE_SERIES: 'Recorded Lecture Series',
  VIDEO_SERIES: 'Video Series',
  ANIMATED_VIDEO_SERIES: 'Animated Video Series',
  VIDEO: 'Video',
  ANIMATED_VIDEO: 'Animated Video',
  PRESENTATION: 'Presentation',
  JOURNEY: 'Journey'
};

export const getResourceMedium = resourceMedium => {
  // map legacy resource mediums (video and video series)
  // to new resource mediums (animated video and animated video series)
  switch (resourceMedium) {
    case RESOURCE_MEDIUMS.VIDEO:
      return RESOURCE_MEDIUMS.ANIMATED_VIDEO;
    case RESOURCE_MEDIUMS.VIDEO_SERIES:
      return RESOURCE_MEDIUMS.ANIMATED_VIDEO_SERIES;
    default:
      return resourceMedium;
  }
};

export const getResourceMediumIcon = resourceMedium => {
  switch (resourceMedium) {
    case RESOURCE_MEDIUMS.DEEP_DIVE_COURSE:
      return MediumDeepDiveCourse;
    case RESOURCE_MEDIUMS.JOURNEY:
      return MediumJourney;
    case RESOURCE_MEDIUMS.PRESENTATION:
      return MediumPresentation;
    case RESOURCE_MEDIUMS.RECORDED_LECTURE:
    case RESOURCE_MEDIUMS.RECORDED_LECTURE_SERIES:
      return MediumRecordedLecture;
    case RESOURCE_MEDIUMS.EDUCATOR_GUIDE:
      return MediumEducatorGuide;
    case RESOURCE_MEDIUMS.PODCAST:
      return MediumPodcast;
    case RESOURCE_MEDIUMS.PRINTABLE_GUIDE:
      return MediumPrintableGuide;
    case RESOURCE_MEDIUMS.VIDEO_SERIES:
    case RESOURCE_MEDIUMS.ANIMATED_VIDEO_SERIES:
      return MediumVideoSeries;
    case RESOURCE_MEDIUMS.VIDEO:
    case RESOURCE_MEDIUMS.ANIMATED_VIDEO:
    default:
      return MediumVideo;
  }
};
