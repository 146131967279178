import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #b5b5b5i'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  input: props => ({
    padding: props.withoutLabel && '10px',
    width: props.withoutLabel ? '60px' : '100%'
  }),
  focused: {}
}));

const TextInput = ({ label, name, InputProps, withoutLabel, ...rest }) => {
  const classes = useStyles({ withoutLabel });

  return (
    <TextField
      label={label}
      type="text"
      name={name}
      fullWidth
      margin="normal"
      variant="filled"
      InputProps={{ classes, disableUnderline: true, ...InputProps }}
      {...rest}
    />
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  InputProps: PropTypes.shape({}),
  withoutLabel: PropTypes.bool
};

TextInput.defaultProps = {
  InputProps: {},
  withoutLabel: false
};

export default TextInput;
