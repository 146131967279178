/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Dialog from '~components/Dialog';
import ButtonClosePopup from './components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #b5b5b5i'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  formControl: {
    width: '100%'
  },
  bbm_onboarding_popup: {
    padding: '50px 20px 20px 20px'
  },
  bbmobp_title: {
    color: '#5a6175',
    fontSize: '30px',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '34px',
    paddingBottom: '10px'
  },
  bbmobp_subtitle: {
    color: 'rgba(90, 97, 117, 0.7)',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: '20px'
  }
}));

const BarBatMitzvahOnboardingPopup = props => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [radioValue, setRadioValue] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const eduList = [
    'Jewish Day School',
    'Community School',
    'Yeshiva',
    'Jewish Summer Camp',
    'Hebrew School',
    'Private Tutor',
    'Homeschool',
    'Other'
  ];

  const handleEduCheck = event => {
    const { value } = event.target;
    setCheckedValues(
      checkedValues.includes(value)
        ? checkedValues.filter(c => c !== value)
        : [...checkedValues, value]
    );
  };

  function handleRadioChange(event) {
    setRadioValue(event.target.value);
  }

  function handleDOBChange(date) {
    setSelectedDOB(date);
  }

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  const [selectName, setSelectName] = React.useState('');

  function handleChange(event) {
    setSelectName(event.target.value);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleRef = ref => {
    // WARN! This is where this fails!
    // For some reason the ref is sometimes `null` which messes up the `inputRef={register}` call
    if (ref) {
      register(ref.node);
    }
  };

  const parshaOptions = [
    {
      label: 'Bereshit',
      value: 'bereshit'
    },
    {
      label: 'Noach',
      value: 'noach'
    },
    {
      label: 'Lech-Lecha',
      value: 'lechlecha'
    },
    {
      label: 'Vayera',
      value: 'vayera'
    },
    {
      label: 'Chayei Sara',
      value: 'chayei-sara'
    },
    {
      label: 'Toldot',
      value: 'toldot'
    },
    {
      label: 'Vayetzei',
      value: 'vayetzei'
    },
    {
      label: 'Vayishlach',
      value: 'vayishlach'
    },
    {
      label: 'Vayeshev',
      value: 'vayeshev'
    },
    {
      label: 'Miketz',
      value: 'miketz'
    },
    {
      label: 'Vayigash',
      value: 'vayigash'
    },
    {
      label: 'Vayechi',
      value: 'vayechi'
    },
    {
      label: 'Shemot',
      value: 'shemot'
    },
    {
      label: 'Vaera',
      value: 'vaera'
    },
    {
      label: 'Bo',
      value: 'bo'
    },
    {
      label: 'Beshalach',
      value: 'beshalach'
    },
    {
      label: 'Yitro',
      value: 'yitro'
    },
    {
      label: 'Mishpatim',
      value: 'mishpatim'
    },
    {
      label: 'Terumah',
      value: 'terumah'
    },
    {
      label: 'Tetzaveh',
      value: 'tetzaveh'
    },
    {
      label: 'Ki Tisa',
      value: 'ki-tisa'
    },
    {
      label: 'Vayakhel',
      value: 'vayakhel'
    },
    {
      label: 'Pekudei',
      value: 'pekudei'
    },
    {
      label: 'Vayikra',
      value: 'vayikra'
    },
    {
      label: 'Tzav',
      value: 'tzav'
    },
    {
      label: 'Shmini',
      value: 'shmini'
    },
    {
      label: 'Tazria',
      value: 'tazria'
    },
    {
      label: 'Metzora',
      value: 'metzora'
    },
    {
      label: 'Achrei Mot',
      value: 'achrei-mot'
    },
    {
      label: 'Kedoshim',
      value: 'kedoshim'
    },
    {
      label: 'Emor',
      value: 'emor'
    },
    {
      label: 'Behar',
      value: 'behar'
    },
    {
      label: 'Bechukotai',
      value: 'bechukotai'
    },
    {
      label: 'Bamidbar',
      value: 'bamidbar'
    },
    {
      label: 'Nasso',
      value: 'nasso'
    },
    {
      label: "Beha'alotcha",
      value: 'behaalotcha'
    },
    {
      label: "Sh'lach",
      value: 'shlach'
    },
    {
      label: 'Korach',
      value: 'korach'
    },
    {
      label: 'Chukat',
      value: 'chukat'
    },
    {
      label: 'Balak',
      value: 'balak'
    },
    {
      label: 'Pinchas',
      value: 'pinchas'
    },
    {
      label: 'Matot',
      value: 'matot'
    },
    {
      label: 'Masei',
      value: 'masei'
    },
    {
      label: 'Devarim',
      value: 'devarim'
    },
    {
      label: 'Vaetchanan',
      value: 'vaetchanan'
    },
    {
      label: 'Eikev',
      value: 'eikev'
    },
    {
      label: "Re'eh",
      value: 'reeh'
    },
    {
      label: 'Shoftim',
      value: 'shoftim'
    },
    {
      label: 'Ki Teitzei',
      value: 'ki-teitzei'
    },
    {
      label: 'Ki Tavo',
      value: 'ki-tavo'
    },
    {
      label: 'Nitzavim',
      value: 'nitzavim'
    },
    {
      label: 'Vayeilech',
      value: 'vayeilech'
    },
    {
      label: "Ha'Azinu",
      value: 'haazinu'
    },
    {
      label: 'Vezot Haberakhah',
      value: 'vezot-haberakhah'
    }
  ];
  return (
    <Dialog isOpen={isOpen} maxWidth="md">
      <div className={classes.bbm_onboarding_popup}>
        <Typography component="h3" className={classes.bbmobp_title}>
          Welcome to the <br />
          Bar and Bat Mitzvah Prep Course!
        </Typography>
        <Typography className={classes.bbmobp_subtitle}>
          Before you begin, we want to learn more about you so that we can personalize your Bar and
          Bat Mitzvah experience.
        </Typography>
        <Divider />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={handleSubmit(props.handleSubmit)} autoComplete="off">
            <TextField
              label="Full Name"
              type="text"
              name="bm_fullname"
              autoComplete="name"
              fullWidth
              margin="normal"
              variant="outlined"
              inputRef={register}
              required
            />
            <KeyboardDatePicker
              disableToolbar
              // variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              // id="date-picker-inline"
              label="Date of Birth (Bar or Bat Mitzvah student)"
              name="bm_dob"
              value={selectedDOB}
              onChange={handleDOBChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              inputRef={register({
                required: true
              })}
              fullWidth
              required
            />
            <TextField
              label="Who are you taking this course with? (ex. mother, father, sibling, tutor, Rabbi)"
              type="text"
              name="bm_sidekick"
              autoComplete="name"
              fullWidth
              margin="normal"
              variant="outlined"
              inputRef={register}
              required
            />
            <KeyboardDatePicker
              disableToolbar
              // variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              // id="date-picker-inline"
              label="When will you be celebrating your Bar or Bat Mitzvah?"
              name="bm_date"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              inputRef={register({
                required: true
              })}
              fullWidth
              required
            />
            <FormControl
              variant="outlined"
              margin="normal"
              className={classes.formControl}
              required
            >
              <InputLabel htmlFor="select-test">What is your Parsha?</InputLabel>
              <Select
                value={selectName}
                onChange={handleChange}
                input={
                  <OutlinedInput name="bm_parsha" id="select-test" labelWidth={165} required />
                }
                name="bm_parsha"
                MenuProps={MenuProps}
                inputRef={handleRef}
              >
                {parshaOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              component="fieldset"
              margin="normal"
              className={classes.formControl}
              required
            >
              <FormLabel component="legend">How do you identify?</FormLabel>
              <RadioGroup
                aria-label="identify"
                name="bm_identify"
                className={classes.group}
                value={radioValue}
                onChange={handleRadioChange}
                row
              >
                <FormControlLabel
                  value="Ashkenazi"
                  control={<Radio color="primary" />}
                  label="Ashkenazi"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Conservative"
                  control={<Radio color="primary" />}
                  label="Conservative"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Modern Orthodox"
                  control={<Radio color="primary" />}
                  label="Modern Orthodox"
                  inputRef={register}
                />
                <FormControlLabel
                  value="non-denominational"
                  control={<Radio color="primary" />}
                  label="non-denominational"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Orthodox"
                  control={<Radio color="primary" />}
                  label="Orthodox"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Reconstructionist"
                  control={<Radio color="primary" />}
                  label="Reconstructionist"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Reform"
                  control={<Radio color="primary" />}
                  label="Reform"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Renewal"
                  control={<Radio color="primary" />}
                  label="Renewal"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Sephardic"
                  control={<Radio color="primary" />}
                  label="Sephardic"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Traditional"
                  control={<Radio color="primary" />}
                  label="Traditional"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Christian"
                  control={<Radio color="primary" />}
                  label="Christian"
                  inputRef={register}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio color="primary" />}
                  label="Other"
                  inputRef={register}
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              component="fieldset"
              margin="normal"
              className={classes.formControl}
              required
            >
              <FormLabel component="legend">
                What Jewish educational settings have you been in? (Check all that apply)
              </FormLabel>
              <FormGroup row>
                {eduList.map((value, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        color="primary"
                        checked={checkedValues.includes(value)}
                        onChange={e => handleEduCheck(e)}
                        value={value}
                        name={`bm_educational[${index}]`}
                        inputRef={register}
                      />
                    }
                    label={value}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Button
              variant="contained"
              size="large"
              color="primary"
              // className={classes.button}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </MuiPickersUtilsProvider>
      </div>
      <ButtonClosePopup
        handleClose={() => {
          setIsOpen(false);
          props.handleClose();
        }}
      />
    </Dialog>
  );
};

BarBatMitzvahOnboardingPopup.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default BarBatMitzvahOnboardingPopup;
