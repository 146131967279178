import {
  makeFullName,
  isUserEducator,
  isUserProducer,
  isUserPrivilegedMember,
  isUserCompletedBarMitzvahOnboarding,
  isUserCompletedOnboarding,
  isUserBarMitzvah
} from '~utils/user-helpers';
import * as ACTIONS from '~context/UserContext/actions/actions';
import { isUserAuthTokenAvailable } from '~utils/request';

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.USER_LOADING:
      return {
        ...state,
        isUserStateLoading: true
      };
    case ACTIONS.USER_READY: {
      return {
        ...state,
        user: action.payload,
        isUserStateLoading: false,
        isLoggedIn: !!action.payload.username,
        jwtAvailable: isUserAuthTokenAvailable(),
        isSubscriber: !!action.payload.subscription_plan_id,
        isEducator: isUserEducator(action.payload),
        isProducer: isUserProducer(action.payload),
        isPrivilegedMember: isUserPrivilegedMember(action.payload), // both premium and educators
        isAdmin: !!(action.payload.username && action.payload.is_admin),
        isDonator: !!action.payload.is_donator,
        isBarMitzvah: isUserBarMitzvah(action.payload),
        isBarMitzvahOnboarding: isUserCompletedBarMitzvahOnboarding(action.payload),
        isCompletedOnboarding: isUserCompletedOnboarding(action.payload),
        fullName: makeFullName(action.payload),
        giftTokensLeft: action.payload?.gift_tokens?.leftover_this_month,
        giftTokensMonthlyLimit: action.payload?.gift_tokens?.max_per_month
      };
    }
    case ACTIONS.USER_UPDATE:
      return {
        ...state,
        ...action.payload
      };
    case ACTIONS.USER_PLAYLIST_POSITION_UPDATE: {
      return {
        ...state,
        playlistPositions: {
          ...state.playlistPositions,
          [action.payload.playlistId]: {
            index: action.payload.videoIndex,
            seekToSecond: action.payload.position
          }
        }
      };
    }
    default:
      return state;
  }
};
