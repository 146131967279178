import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';

import Link from '~components/Link';
import PhonesImage from './images/phones-cropped.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6F6F6'
  },
  heading: {
    fontWeight: '600',
    fontSize: '48px',
    lineHeight: '54px',
    marginTop: '40px',
    marginBottom: '70px',
    maxWidth: '600px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '42px',
      lineHeight: '50px',
      padding: '0px 10px'
    }
  },
  image: {
    width: '100%'
  }
}));

const WeeklyTorahPortionAccessFromAnywhereSection = () => {
  const classes = useStyles();

  return (
    <Container center className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.heading}>Take AlephBeta on the go with our app</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="center" xs={12} spacing={2}>
          <Link
            to="https://play.google.com/store/apps/details?id=org.alephbeta.android&amp;hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <img src={PhonesImage} alt="app" className={classes.image} />
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WeeklyTorahPortionAccessFromAnywhereSection;
