import { get } from 'lodash';
import { extractSeoData, extractTrendingItems } from '~utils/unfurl-helpers';

export default prismicData => ({
  slices: get(prismicData, 'prismicArticle.data.body', []),
  pageBreadcrumbs: {
    currentTitle: get(prismicData, 'prismicArticle.data.title', ''),
    parentUrl: get(prismicData, 'prismicArticle.data.parent_page.url', ''),
    parentTitle: get(prismicData, 'prismicArticle.data.parent_page.document.data.title', ''),
    parentType: get(prismicData, 'prismicArticle.data.parent_page.document.type', '')
  },
  bgColor: get(prismicData, 'prismicArticle.data.background_color', '#F3F3F3'),
  trendingItems: {
    title: get(prismicData, 'prismicTrendingItems.data.title', ''),
    description: get(prismicData, 'prismicTrendingItems.data.description', null),
    ctaText: get(prismicData, 'prismicTrendingItems.data.cta_text', ''),
    ctaPath: get(prismicData, 'prismicTrendingItems.data.cta_path', ''),
    resources: extractTrendingItems(get(prismicData, 'prismicTrendingItems.data.trending_list', []))
  },
  seo: extractSeoData(prismicData, 'prismicArticle')
});
