import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import useApi, { SOFT_SALE_CLOSE } from '~hooks/useApi';

import Container from '~components/Container';
import DevicesImage from '~images/popups/softsale-two-image.png';

const useStyles = makeStyles(theme => ({
  banner: {
    bottom: 0,
    left: 'auto',
    right: 0,
    position: 'fixed',
    zIndex: 1090,
    paddingBottom: '0',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute'
    }
  },
  heading: {
    fontSize: '30px',
    fontWeight: 700,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      lineHeight: 1,
      fontWeight: 500,
      fontSize: '41px',
      textAlign: 'center'
    }
  },
  buttonStyle: {
    backgroundColor: '#122119',
    color: '#fff',
    textTransform: 'none',
    fontSize: '24px',
    lineHeight: '28px',
    padding: '11px 45px',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  iconStyle: {
    fontSize: '25px',
    color: theme.palette.secondary.main,
    marginRight: '12px'
  },
  iconButtonStyle: {
    backgroundColor: '#0000004D',
    '&:hover': {
      backgroundColor: 'grey'
    }
  },
  closeIconStyle: {
    fontSize: '1rem',
    color: '#fff'
  },
  imageMedia: {
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  buttonPosition: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: 12
    }
  }
}));

const GuestUserSoftSaleSecondPopup = ({ which, ...rest }) => {
  const classes = useStyles();
  const { setPopupId, id = undefined } = rest;
  const [isPopupHidden, setIsPopupHidden] = useState(false);
  const [, makeRequest] = useApi();

  const handleClose = () => {
    const params = SOFT_SALE_CLOSE({ which });
    makeRequest(params);

    if (setPopupId) {
      setPopupId(0);
    }

    setIsPopupHidden(true);
  };

  if (isPopupHidden) {
    return null;
  }
  return (
    <Box
      bgcolor="#88FAC3"
      width="100%"
      pt={1}
      pb={3}
      className={classes.banner}
      id={id}
      borderTop="1px solid #000000"
    >
      <Container center>
        <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton
            aria-label="close"
            className={classes.iconButtonStyle}
            onClick={handleClose}
            size="small"
          >
            <CloseIcon className={classes.closeIconStyle} />
          </IconButton>
        </Box>
        <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md="auto" lg={7}>
            <Typography variant="h6" className={classes.heading}>
              Become a member for full access
            </Typography>
            <Box className={classes.buttonPosition}>
              <Button
                component={GatsbyLink}
                variant="contained"
                className={classes.buttonStyle}
                to="/subscribe"
                size="large"
              >
                Create Account
              </Button>
            </Box>
          </Grid>

          <Box component={Grid} item xs={12} md="auto" lg={4} textAlign="center">
            <img
              src={DevicesImage}
              alt="Mobile Devices"
              className={`${classes.imageMedia} lazyload`}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

GuestUserSoftSaleSecondPopup.propTypes = {
  which: PropTypes.number.isRequired,
  setPopupId: PropTypes.func,
  id: PropTypes.string
};

GuestUserSoftSaleSecondPopup.defaultProps = {
  setPopupId: null,
  id: undefined
};

export default GuestUserSoftSaleSecondPopup;
