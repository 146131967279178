/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/StarRounded';

import Link from '~components/Link';
import Dialog from '~components/Dialog';
import ButtonClosePopup from './components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  bbm_sale_popup: {
    color: '#ffffff',
    '& ul': {
      fontSize: '14px',
      color: 'rgba(255, 255, 255, 0.7)',
      listStyle: 'disc',
      paddingLeft: '30px',
      '& li': {
        paddingBottom: '2px'
      }
    }
  },
  list_feature: {
    fontSize: '14px'
  },
  gift_wrapper: {
    paddingBottom: '30px'
  },
  gift_title: {
    color: '#ffffff',
    fontSize: '30px',
    textAlign: 'center'
  },
  gift_subtitle: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '18px',
    textAlign: 'center'
  },
  all_access_box: {
    padding: '24px',
    border: '#7cb0dc solid 1px',
    borderRadius: '4px',
    '&:hover': {
      border: '#ffffff solid 1px',
      borderRadius: '4px'
    }
  },
  most_popular: {
    color: '#ffdba3',
    fontSize: '15px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center'
  },
  star_icon: {
    marginRight: theme.spacing(0.5)
  },
  wistia_video_container: {
    width: 'auto',
    height: '330px',
    maxWidth: '300px',
    maxHeight: '330px'
  },
  plan_title_price: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  all_access_plan_name: {
    fontSize: '30px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: '4px'
  },
  all_access_plan_price: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  all_access_plan_desc: {
    fontSize: '16px',
    color: 'rgba(255, 255, 255, 0.7)',
    paddingBottom: '10px'
  },
  all_access_btn_wrapper: {
    textAlign: 'right'
  },
  all_access_btn: {
    height: '52px',
    padding: '0 50px',
    color: '#438dce',
    fontWeight: '500',
    fontSize: '18px',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#438dce',
      backgroundColor: '#ffffff',
      opacity: '0.9',
      textDecoration: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  basic_plan_wrapper: {
    padding: '20px 20px 0 20px'
  },
  basic_plan: {
    textAlign: 'center',
    padding: '16px',
    '& span': {
      fontSize: '18px',
      cursor: 'pointer',
      color: '#ffffff',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  basic_plan_price: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'right',
    padding: '16px 16px 0 16px'
  },
  prem_cost: {
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'right',
    fontSize: '12px',
    lineHeight: '14px',
    padding: '0 16px 0 0',
    '& a': {
      color: '#ffffff',
      textDecoration: 'underline',
      '& hover': {
        color: '#ffffff',
        textDecoration: 'none'
      }
    }
  },
  basic_plan_price_wrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '& p': {
        textAlign: 'center !important'
      }
    }
  }
}));

const BarBatMitzvahSalePopup = ({
  // popupState: { gift = false },
  gift,
  mailGift,
  goToCheckout,
  showTopCloseBtn,
  handleClose,
  ...rest
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const playerConfig = {
    width: '100%',
    height: '330px',
    style: {
      pointerEvents: 'none',
      width: '300px',
      height: '330px',
      position: 'relative'
    },
    config: {
      wistia: {
        options: {
          seo: false,
          videoFoam: true,
          playlistLinks: 'auto',
          playerColor: '#3688FF',
          controlsVisibleOnLoad: false
        }
      }
    }
  };
  return (
    <Dialog isOpen={isOpen} {...rest}>
      <div className={classes.bbm_sale_popup}>
        {gift && (
          <div className={classes.gift_wrapper}>
            <Typography component="h3" className={classes.gift_title}>
              Choose your gift
            </Typography>
            <Typography className={classes.gift_subtitle}>
              It&apos;s the thought that counts
            </Typography>
          </div>
        )}
        <div className={classes.all_access_box}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <div className={classes.wistia_video_container}>
                <ReactPlayer
                  url="https://clevertech.wistia.com/medias/ewwy41oxnh"
                  className={classes.player}
                  {...playerConfig}
                  playing
                  muted
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography className={classes.most_popular}>
                <StarIcon className={classes.star_icon} />
                MOST POPULAR
              </Typography>
              <div className={classes.plan_title_price}>
                <Typography component="h4" className={classes.all_access_plan_name}>
                  all-access plan
                </Typography>
                <span className={classes.all_access_plan_price}>$180</span>
              </div>
              <Typography className={classes.all_access_plan_desc}>
                Gain access to the Bar and Bat Mitzvah Prep Course (a $150 value!) plus a 1-Year
                recurring Premium Membership (a $180 value!) that opens the door to our extensive
                video and audio library. The ultimate Aleph Beta experience!
              </Typography>
              <ul>
                <li>
                  <Typography className={classes.list_feature}>
                    Bar and Bat Mitzvah Prep Course
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.list_feature}>
                    Unlimited access to over 300 Torah videos
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.list_feature}>Amazing Holiday courses</Typography>
                </li>
                <li>
                  <Typography className={classes.list_feature}>
                    Weekly Parsha podcasts and guides
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.list_feature}>
                    Access to the iOS and Android app
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.list_feature}>
                    Access to Rabbi Fohrman’s weekly webinar
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.list_feature}>And so much more!</Typography>
                </li>
              </ul>
              <div className={classes.all_access_btn_wrapper}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.all_access_btn}
                  onClick={() => goToCheckout({ isAllAccess: true, isGift: gift })}
                >
                  {`${gift ? 'GIVE' : 'GET'} ALL-ACCESS`}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.basic_plan_wrapper}>
          <div style={{ width: '100%' }}>
            <Box display="flex" flexWrap="wrap">
              <Box flexGrow={1}>
                <p className={classes.basic_plan}>
                  Or{' '}
                  <span onClick={() => goToCheckout({ isAllAccess: false, isGift: gift })}>
                    continue with Basic Bar and Bat Mitzvah course only
                  </span>
                </p>
              </Box>
              <Box className={classes.basic_plan_price_wrapper}>
                <Typography className={classes.basic_plan_price}>$150</Typography>
                <Typography className={classes.prem_cost}>
                  $20 for &apos;Yearly-Premium&apos; Members
                </Typography>
                <Typography className={classes.prem_cost}>
                  Want to be Yearly-Premium?
                  <br />
                  <Link to="/subscribe?utm_source=ab&utm_medium=landing-page&utm_campaign=bar-bat-mitzvah-pop-up-upgrade">
                    Upgrade here!
                  </Link>
                </Typography>
              </Box>
            </Box>
          </div>
        </div>
      </div>
      {showTopCloseBtn && (
        <ButtonClosePopup
          handleClose={() => {
            setIsOpen(false);
            handleClose();
          }}
        />
      )}
    </Dialog>
  );
};

BarBatMitzvahSalePopup.propTypes = {
  gift: PropTypes.bool,
  showTopCloseBtn: PropTypes.bool
};

BarBatMitzvahSalePopup.defaultProps = {
  gift: true,
  showTopCloseBtn: false
};

export default BarBatMitzvahSalePopup;
