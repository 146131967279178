import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  container: {
    background: '#fff'
  },
  name: {
    fontSize: '32px',
    fontWeight: '600',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    }
  },
  role: {
    fontSize: '24px',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  media: {
    borderRadius: '50%',
    width: '100%',
    height: 'auto'
  }
}));

const HostCard = ({ avatar: { url, alt }, name, role }) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={3}>
        <Box margin="20px 15px">
          <Avatar src={url} alt={alt} className={classes.media} />
        </Box>
      </Grid>
      <Grid item container direction="column" xs={9}>
        <Grid item>
          <Typography xs={6} className={classes.name}>
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography xs={6} className={classes.role}>
            {role}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
HostCard.propTypes = {
  avatar: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }).isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};

export default HostCard;
