import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ModernCarousel from '~components/ModernCarousel';
import ModernPlaylistCard from '~components/playlist-card/ModernPlaylistCard';

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingTop: '32px',
    paddingBottom: '16px',
    minHeight: '370px'
  }
});

const WeeklyTorahPortionMoreVideos = ({ playlists }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ModernCarousel>
        {playlists &&
          playlists.map((playlist, index) => (
            <ModernPlaylistCard
              key={playlist.id || `promotional-pc-card-${index}`}
              {...playlist}
              subtitleType="type-part-duration"
            />
          ))}
      </ModernCarousel>
    </div>
  );
};

WeeklyTorahPortionMoreVideos.propTypes = {
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      label_is_audio: PropTypes.string,
      cover_image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      }),
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          length_in_seconds: PropTypes.number.isRequired
        })
      )
    })
  ).isRequired
};

export default WeeklyTorahPortionMoreVideos;
