import React from 'react';
import ReactDOMServer from 'react-dom/server';

export const TorahPortionFaqs = [
  {
    title: 'What is the Torah?',
    description: ReactDOMServer.renderToString(
      <>
        <p>
          “Torah” can take on different meanings, depending on the context. Generally, Torah refers
          to the first books of the Bible, known as the Five Books of Moses or Pentateuch – Genesis,
          Exodus, Leviticus, Numbers and Deuteronomy – and often encompasses rabbinic commentaries
          as well (perushim).
        </p>
        <p>
          In Rabbinic literature, Torah is often used to refer to both the Five Books ( תורה שבכתב‎;
          &ldquo;Torah that is written&ldquo;) and Oral Torah (תורה שבעל פה, &ldquo;Torah that is
          spoken&ldquo;). The word Torah translates to &ldquo;instruction,&ldquo; and in its
          broadest sense, some people may even use it to refer to the full Tanakh or the whole body
          of Jewish teachings and law.
        </p>
        <p>
          The Torah makes up the first section of the Tanach – the Hebrew Bible, or what
          Christianity refers to as the Old Testament. Tanach is an acronym referring to its three
          parts: Torah, Nevi’im (Prophets) and Ketuvim (Writings).
        </p>
        <p>
          The words of the Torah are traditionally handwritten in Hebrew by a scribe (sofer) on a
          parchment scroll. These scrolls are read from in synagogue services, broken up into
          separate Torah portions to spread the readings over the course of a year. Public Torah
          readings are at the heart of Jewish communal life.
        </p>
      </>
    )
  },
  {
    title: 'What Are Torah Portions?',
    description: ReactDOMServer.renderToString(
      <>
        <p>
          The Five Books of Moses are divided into 54 portions (Parshiyot), linked to a specific
          week in a leap year. In non-leap years, with fewer weeks, some shorter Torah portion
          readings are combined into one week. Each weekly Torah portion takes its name from the
          first word or distinctive phrase of the passage. The Torah is divided into portions of two
          to six chapters each week, with added corresponding readings from the Prophets (Haftarah
          portions). The Torah reading cycle starts after the Feast of Tabernacles, with Genesis
          1:1, and finishes with the last verses of Deuteronomy around 12 months later. Jewish
          communities celebrate the annual completion of the Torah reading with a holiday known as
          Simchat Torah or “Rejoicing in the Law.”
        </p>
        <p>
          The Torah is divided into portions of two to six chapters each week, with added
          corresponding readings from the Prophets (Haftarah portions). The Torah reading cycle
          starts after the Feast of Tabernacles, with Genesis 1:1, and finishes with the last verses
          of Deuteronomy around 12 months later. Jewish communities celebrate the annual completion
          of the Torah reading with a holiday known as Simchat Torah or “Rejoicing in the Law.”
        </p>
      </>
    )
  },
  {
    title: 'Origin of the Weekly Torah Portion?',
    description: ReactDOMServer.renderToString(
      <p>
        After God saved the Israelites from captivity and restored the Jewish nation, Ezra the
        scribe wanted to ensure their people would not fall off the wagon again, as we read about in
        the Book of Nehemia, so he created a system to ensure we would read the text of the Torah
        each week at synagogue. Thousands of years later, Jewish communities all around the world
        still study the same portion of the Torah in unity.
      </p>
    )
  },
  {
    title: 'Torah Blessing and Aliyot?',
    description: ReactDOMServer.renderToString(
      <p>
        An aliyah, עליה, is the honor of being called to read a blessing over a segment of the
        Torah. In synagogue, members from the congregation are chosen to go up to the bimah (podium)
        and recite two blessings (one before the reading, and one after) to thank God for the Torah.
      </p>
    )
  },
  {
    title: 'What are Haftarah Portions?',
    description: ReactDOMServer.renderToString(
      <p>
        Haftarah portions – or Haftoroh in Ashkenazic, or “Concluding Portion” – are selections from
        the books of Nevi’im (Prophets) of the Hebrew Bible. They are also publicly read – rather,
        sung or chanted – in synagogue services, following the Torah reading each Sabbath, holidays
        and fast days. There is usually a thematic link to the weekly Parsha.
      </p>
    )
  },
  {
    title: 'Torah Reading Services & Ceremonies',
    description: ReactDOMServer.renderToString(
      <>
        <p>
          Jewish communities read the relevant Torah portion aloud in synagogues on Sabbaths, as
          part of the prayer service. The first section of the Torah portion is also read on Mondays
          and Thursday mornings, an origin that stems from older days, when rural people would go to
          town to visit the market on those days.
        </p>
        <p>
          On Saturday afternoons, Mondays, and Thursdays, the start of the next week’s portion is
          read. Special Torah portion readings are also associated with Jewish holidays, Rosh
          Chodesh and fast days. A Torah reading generally refers to the whole service, including
          the grand removing and replacing of the scrolls in the Torah Ark.
        </p>
      </>
    )
  }
];
