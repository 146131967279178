import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import unfurl from '~prismic/unfurl/advanced';

import PageWithSlices from '~src/layout/PageWithSlices';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Advanced = ({ data }) => {
  const { slices, seo } = unfurl(data);

  return <PageWithSlices slices={slices} seo={seo} />;
};

Advanced.propTypes = {
  data: PropTypes.shape().isRequired
};

export default withPrismicPreview(Advanced);

export const query = graphql`
  query ($uid: String!) {
    prismicAdvanced(uid: { eq: $uid }) {
      _previewable
      uid
      type
      data {
        title
        slices {
          ... on PrismicAdvancedDataSlicesProducersCircleMembers {
            slice_type
            items {
              member_name
            }
          }
          ... on PrismicAdvancedDataSlicesExpansionPanel {
            slice_type
            primary {
              panel_title
              category
            }
            items {
              panel_materials {
                url
                document {
                  ... on PrismicMaterial {
                    data {
                      title
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvancedDataSlicesTeamSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              team_member_avatar {
                url
              }
              team_member_name {
                html
              }
              team_member_position {
                html
              }
            }
          }
          ... on PrismicAdvancedDataSlicesJobSection {
            slice_type
            primary {
              job_section_title {
                html
                text
              }
              job_section_content {
                html
              }
              job_section_background
              job_section_button_title
              job_section_button_color
              job_section_button_link {
                url
              }
              job_section_image {
                url
                alt
              }
            }
          }
          ... on PrismicAdvancedDataSlicesFaqSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicAdvancedDataSlicesAudioFileSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            audio_files: items {
              audio_file_link {
                url
              }
              title
              description {
                html
              }
              transcript {
                html
              }
            }
          }
          ... on PrismicAdvancedDataSlicesPromotionalBox {
            slice_type
            primary {
              promotional_box_link
              promotional_box_text
              text_color
              promotional_box_background_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicAdvancedDataSlicesAuthorTestimonials {
            slice_type
            primary {
              author {
                document {
                  ... on PrismicAuthor {
                    data {
                      name
                      role
                      avatar {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              testimonial
            }
          }
          ... on PrismicAdvancedDataSlicesAboutPageAboutUs {
            slice_type
            primary {
              section_title {
                html
                text
              }
              about_us_bg_color
            }
            sections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
              topic_image {
                url
                alt
              }
            }
          }
          ... on PrismicAdvancedDataSlicesAboutTestimonials {
            slice_type
            primary {
              testimonials_background_color
            }
            testimonials: items {
              avatar {
                alt
                url
              }
              title
              subtitle
              name
              subscription_plan
            }
          }
          ... on PrismicAdvancedDataSlicesAboutVideosPath {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              section_footnote {
                html
              }
              slider_background_color
            }
            slides: items {
              title {
                html
              }
              subtitle {
                html
              }
              first_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              second_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              third_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvancedDataSlicesAboutTeam {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            members: items {
              avatar {
                alt
                url
              }
              name {
                html
              }
            }
          }
          ... on PrismicAdvancedDataSlicesAboutFaq {
            slice_type
            primary {
              section_title {
                html
                text
              }
              faq_bg_color
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            faqSections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicAdvancedDataSlicesAboutCta {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_content {
                html
              }
              section_image {
                alt
                url
              }
            }
            links: items {
              title {
                html
              }
              subtitle {
                html
              }
              to {
                url
              }
            }
          }
          ... on PrismicAdvancedDataSlicesVideoList {
            slice_type
            primary {
              title_video_list {
                html
                text
              }
              event_date {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvancedDataSlicesOneLineEmailAcquisition {
            slice_type
            primary {
              cta_text
              one_line_email_background_color
              cta_text_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicAdvancedDataSlicesFaqHero {
            slice_type
            primary {
              hero_title {
                html
              }
              hero_subtitle {
                html
              }
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicAdvancedDataSlicesAboutHero {
            slice_type
            primary {
              hero_text {
                html
              }
              underline_text {
                html
              }
              hero_background_image {
                url
                alt
              }
              hero_head_image {
                url
                alt
              }
              hero_featured_video {
                url
              }
            }
          }
          ... on PrismicAdvancedDataSlicesHolidayHeroSplitScreen {
            slice_type
            primary {
              overline_text {
                html
                text
              }
              hero_title {
                html
                text
              }
              hero_image_split_direction
              hero_subtitle {
                html
                text
              }
              hero_background_image {
                url
                alt
              }
              label_text {
                html
                text
              }
              label_text_color
              label_text_background_color
              subscription_level
              hero_featured_video {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvancedDataSlicesHeroTextImage {
            slice_type
            primary {
              title
              subtitle
              link {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
              link_title
              text_color
              background_color
              foreground_image {
                alt
                url
              }
              background_image {
                alt
                url
              }
            }
          }
          ... on PrismicAdvancedDataSlicesHeroTextToc {
            slice_type
            primary {
              title
              overline_rich_text {
                html
                text
              }
              underline_rich_text {
                html
                text
              }
              description
              text_color
              hero_background_color
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicAdvancedDataSlicesFeaturedPlaylist {
            slice_type
            primary {
              featured_playlist {
                url
                document {
                  ... on PrismicPlaylist {
                    id
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvancedDataSlicesPlaylists {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvancedDataSlicesResources {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              resource {
                url
                document {
                  __typename
                  ... on PrismicMaterial {
                    data {
                      title
                      description
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                  ... on PrismicParshat {
                    data {
                      title
                      slices {
                        ... on PrismicParshatDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicHolidayRedesign {
                    data {
                      title
                      body {
                        ... on PrismicHolidayRedesignDataBodyHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAdvanced {
                    data {
                      title
                      slices {
                        ... on PrismicAdvancedDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }

                  ... on PrismicBlogPost {
                    data {
                      title_text: title {
                        text
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAdvancedDataSlicesTextBlock {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content {
                html
              }
            }
          }
          ... on PrismicAdvancedDataSlicesTextBlockCols {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content_left {
                html
              }
              text_block_content_right {
                html
              }
            }
          }
          ... on PrismicAdvancedDataSlicesCustomHtml {
            slice_type
            primary {
              custom_html {
                html
                text
              }
            }
          }
          ... on PrismicAdvancedDataSlicesTeamPageFounder {
            slice_type
            primary {
              founder_section_title {
                html
                text
              }
              founder_name {
                html
              }
              founder_position {
                html
              }
              founder_text {
                html
              }
              founder_image {
                url
              }
              founder_content_title {
                html
              }
            }
            items {
              founder_publication_title
              founder_publication {
                url
              }
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
      }
    }
  }
`;
