import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import { Input } from '~components/form-inputs-v2';
import ClickableText from '~components/buttons/ClickableText/ClickableText';
import { useGetMagicLink } from '~hooks/useAuth';
import { VIEWS } from '../constants';
import { useSharedStyles } from '../styles';

const ResetPasswordView = ({ changeView, username, changeUsername }) => {
  const sharedClasses = useSharedStyles();

  const [magicLinkState, getMagicLink] = useGetMagicLink();
  const { register, handleSubmit } = useForm();

  const isLoading = magicLinkState?.isLoading;

  useEffect(() => {
    if (!magicLinkState?.isError && magicLinkState.data) {
      changeView(VIEWS.RESET_PASSWORD_LINK_SENT_VIEW);
    }
  }, [magicLinkState, changeView]);

  const onSubmit = formData => {
    // change the username if user has updated it.
    if (formData?.email) {
      changeUsername(formData?.email);
    }
    getMagicLink({ ...formData, username: formData?.email || username });
  };

  const handleBack = () => {
    changeView(VIEWS.EMAIL_LOGIN_VIEW);
  };

  return (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid className={sharedClasses.root}>
            <Grid item xs={12} className={sharedClasses.gapXs}>
              <Typography component="h5" className={sharedClasses.title}>
                Reset password
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapLg}>
              <Typography component="h6" className={sharedClasses.subtitle}>
                Enter the email you use for your AlephBeta account.
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Input
                label="Email Address"
                id="email-address"
                type="email"
                name="email"
                defaultValue={username}
                inputRef={register}
                required
              />
              {magicLinkState?.error && (
                <Typography component="h6" className={sharedClasses.errorText}>
                  {String(magicLinkState?.error)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapMd}>
              <ButtonSubmit
                id="guest-login-popup-pwd-reset-btn"
                className={sharedClasses.submitButton}
                disableElevation
                disabled={isLoading}
              >
                Continue
              </ButtonSubmit>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <ClickableText className={sharedClasses.secondaryTextLink} onClick={handleBack}>
                Go back to login
              </ClickableText>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

ResetPasswordView.propTypes = {
  username: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  changeUsername: PropTypes.func.isRequired
};

export default ResetPasswordView;
