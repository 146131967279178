import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import Link from '~components/Link';

import ApplePodcastImage from '~images/quarantine/apple-podcasts-white.svg';
import GooglePodcastImage from '~images/quarantine/google-podcasts.svg';
import SpotifyImage from '~images/quarantine/spotify.svg';
import RSSImage from '~images/quarantine/rss.svg';

const useStyles = makeStyles(theme => ({
  wrapper: props => ({
    boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.67)',
    transition: 'all .2s ease-in-out',
    backgroundColor: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '180px',
    gap: '10px',
    textDecoration: 'none !important',
    bgcolor: '#000000',
    padding: '4px 8px',
    borderRadius: '12px',
    border: props.applyBorder ? ' 2px solid #ffffff' : 'none',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)'
    },
    [theme.breakpoints.down('xs')]: {
      width: '170px'
    }
  }),
  smallSpan: {
    fontSize: '12px',
    fontWeight: '500',
    paddingLeft: '4px',
    color: '#ffffff',
    whiteSpace: 'nowrap'
  },
  bigSpan: {
    fontSize: '16px',
    fontWeight: '500',
    paddingRight: '4px',
    color: '#ffffff',
    whiteSpace: 'nowrap'
  },
  media: {
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '16px'
  }
}));

const ICONS_MAP = {
  Spotify: SpotifyImage,
  Apple: ApplePodcastImage,
  Google: GooglePodcastImage,
  'RSS Feed': RSSImage
};

const PodcastListenButton = ({ text, url, applyBorder }) => {
  const classes = useStyles({ applyBorder });
  return (
    <Link to={url} className={classes.wrapper}>
      <Typography component="span" className={classes.smallSpan}>
        Listen on
      </Typography>
      <img src={ICONS_MAP[text]} alt={text} className={classes.media} />
      <Typography component="span" className={classes.bigSpan}>
        {text}
      </Typography>
    </Link>
  );
};

PodcastListenButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  applyBorder: PropTypes.bool
};

PodcastListenButton.defaultProps = {
  applyBorder: 'false'
};

export default PodcastListenButton;
