import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Link from '~components/Link';
import Container from '~components/Container';
import RichText from '~components/RichText';

const useStyles = makeStyles({
  media: {
    display: 'flex',
    maxWidth: '100%'
  },
  containerPosition: {
    marginBottom: '60px'
  }
});

const RecommendedVideosGallery = ({ videos, section_title, section_description }) => {
  const classes = useStyles();

  return (
    <Container className={classes.containerPosition}>
      <Typography variant="h4" component="h2" align="left" gutterBottom>
        {section_title}
      </Typography>

      <RichText html={section_description.html} />

      <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
        {videos &&
          videos.map(video => (
            <Grid key={video.title} item xs={12} md={6}>
              <Link to={video.url}>
                <img
                  src={video?.cover_image?.url}
                  alt={video?.title}
                  className={`${classes.media} lazyload`}
                />
              </Link>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

RecommendedVideosGallery.propTypes = {
  section_description: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  }).isRequired,
  section_title: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    })
  ).isRequired
};

export default RecommendedVideosGallery;
