import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  btn: {
    width: '90%',
    margin: theme.spacing(),
    borderColor: '#ffffff',
    color: '#ffffff',
    '&:hover': {
      background: 'white',
      color: props => props.color
    }
  }
}));

const Button = ({ title, url, color }) => {
  const classes = useStyles({ color });
  return (
    <MuiButton component={Link} to={url} variant="outlined" className={classes.btn}>
      {title}
    </MuiButton>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default Button;
