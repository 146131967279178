import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDivider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    margin: props => theme.spacing(props.spacing, 0)
  }
}));

const Divider = ({ spacing, ...rest }) => {
  const classes = useStyles({ spacing });
  return <MuiDivider variant="middle" className={classes.divider} {...rest} />;
};

Divider.propTypes = {
  spacing: PropTypes.number
};

Divider.defaultProps = {
  spacing: 4
};

export default Divider;
