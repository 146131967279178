import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ClickableText from '~components/buttons/ClickableText/ClickableText';
import { VIEWS } from '../constants';
import { useSharedStyles } from '../styles';

const useStyles = makeStyles(() => ({
  contactUsLink: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'center'
  }
}));

const ResetPasswordLinkSentView = ({ changeView, username }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const handleBack = () => {
    changeView(VIEWS.EMAIL_LOGIN_VIEW);
  };

  return (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Grid item xs={12}>
        <Grid className={sharedClasses.root}>
          <Grid item xs={12} className={sharedClasses.gapXs}>
            <Typography component="h5" className={sharedClasses.title}>
              Check your email to reset password
            </Typography>
          </Grid>
          <Grid item xs={12} className={sharedClasses.gapSm}>
            <Typography component="h6" className={sharedClasses.subtitle}>
              A password reset email has been sent to your address at {username}. Please check your
              inbox to continue. See you in a minute!
            </Typography>
          </Grid>
          <Grid item xs={12} className={sharedClasses.gapLg}>
            <Typography component="h6" className={sharedClasses.subtitle}>
              If you don&apos;t get a link, check your spam folder. Still need help?
              <a href="/donate#contact" target="_blank" className={classes.contactUsLink}>
                Contact us.
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h6" className={sharedClasses.subtitle}>
              <ClickableText className={sharedClasses.secondaryTextLink} onClick={handleBack}>
                Go back to login
              </ClickableText>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ResetPasswordLinkSentView.propTypes = {
  username: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired
};

export default ResetPasswordLinkSentView;
