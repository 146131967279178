/**
 * Helper function to navigate to a external path
 * (This function is required as the navigate from reach/router cannot be used in cross-domain routing)
 * When preserveHistory argument is false or not passed,path where the redirection originate is
 * replaced by the redirected path.
 * @param {string} url - external url
 * @param {boolean} preserveHistory - whether to replace the history or not
 */
export const redirect = (url, preserveHistory = false) => {
  if (typeof window !== 'undefined') {
    if (preserveHistory) {
      window.location = url;
    }
    window.location.replace(url);
  }
};
