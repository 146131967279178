export const ACTION_TYPES = {
  SET_INTERCOM_INITIALIZED: 'SET_INTERCOM_INITIALIZED',
  SET_MESSAGE_OPEN: 'SET_MESSAGE_OPEN',
  SET_SPOTIFY_PLAYER_OPEN: 'SET_SPOTIFY_PLAYER_OPEN',
  SET_BOTTOM_BANNER_HIDDEN: 'SET_BOTTOM_BANNER_HIDDEN',
  SET_MARQUEE_BANNER_HIDDEN: 'SET_MARQUEE_BANNER_HIDDEN',
  SET_PLAYLIST_PAGE_SUBSCRIBE_BANNER_VISIBILITY: 'SET_PLAYLIST_PAGE_SUBSCRIBE_BANNER_VISIBILITY'
};

export const setIntercomInitialized = initialized => ({
  type: ACTION_TYPES.SET_INTERCOM_INITIALIZED,
  payload: initialized
});

export const setIntercomMessageOpen = isOpen => ({
  type: ACTION_TYPES.SET_MESSAGE_OPEN,
  payload: isOpen
});

export const setSpotifyPlayerOpen = isOpen => ({
  type: ACTION_TYPES.SET_SPOTIFY_PLAYER_OPEN,
  payload: isOpen
});

export const setBottomBannerHidden = isHidden => ({
  type: ACTION_TYPES.SET_BOTTOM_BANNER_HIDDEN,
  payload: isHidden
});

export const setMarqueeBannerHidden = isHidden => ({
  type: ACTION_TYPES.SET_MARQUEE_BANNER_HIDDEN,
  payload: isHidden
});

export const setPlaylistPageSubscribeBannerVisibility = isVisible => ({
  type: ACTION_TYPES.SET_PLAYLIST_PAGE_SUBSCRIBE_BANNER_VISIBILITY,
  payload: !!isVisible
});
