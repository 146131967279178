import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '~components/Container';
import RichText from '~components/RichText';

const useStyles = makeStyles(() => ({
  imageMedia: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0 auto'
  },
  buttonStyle: props => ({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    padding: '15px 45px',
    marginTop: '50px',
    boxShadow: 'unset',
    backgroundColor: props.job_section_button_color || '#458FCE',
    color: '#fff',
    '&:hover': {
      boxShadow: 'unset',
      backgroundColor: props.job_section_button_color || '#458FCE',
      opacity: '0.8'
    }
  })
}));

const JobsSection = ({
  job_section_background,
  job_section_title,
  job_section_content,
  job_section_image,
  job_section_button_title,
  job_section_button_color,
  job_section_button_link
}) => {
  const classes = useStyles({ job_section_button_color });
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Container center background={job_section_background}>
      <Box py={12}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={isXs ? 2 : 6}
        >
          <Grid item xs={12} md={7}>
            <RichText
              html={job_section_title && job_section_title.html}
              mode="teamPageJobSectionTitle"
              verticalSpacing={0}
            />
            <RichText
              html={job_section_content && job_section_content.html}
              mode="teamPageFounderText"
              verticalSpacing={0}
            />
            <Button
              variant="contained"
              href={job_section_button_link && job_section_button_link.url}
              className={classes.buttonStyle}
            >
              {job_section_button_title}
            </Button>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src={job_section_image && job_section_image.url}
              alt={job_section_image && job_section_image.alt}
              className={classes.imageMedia}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

JobsSection.propTypes = {
  job_section_background: PropTypes.string.isRequired,
  job_section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  job_section_content: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  job_section_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  job_section_button_link: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  job_section_button_title: PropTypes.string.isRequired,
  job_section_button_color: PropTypes.string.isRequired
};

export default JobsSection;
