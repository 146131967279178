import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import AddIcon from '@material-ui/icons/Add';
import RichText from '~components/RichText';
import { Box } from '@material-ui/core';
import { TorahPortionFaqs } from './Faq';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6F6F6'
  },
  container: {
    display: 'flex',
    width: '65%',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '100px',
    paddingBottom: '100px',
    gap: 64,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      gap: 30,
      paddingTop: '40px',
      paddingBottom: '60px'
    }
  },
  sectionTitle: {
    fontSize: '40px',
    fontWeight: '600',
    textAlign: 'center'
  },
  accordion: {
    borderRadius: '10px !important',
    boxShadow: '0px 2px 0px 0px #0000001A',
    padding: '8px 15px 8px 15px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px 5px'
    }
  },
  accordionExpanIcon: {
    color: '#000000',
    fontSize: 25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  description: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }
}));

const WeeklyTorahPortionOriginsOfParsha = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.sectionTitle}>Frequently Asked Questions</Typography>
        <Container maxWidth="md">
          <Box
            component="div"
            width="100%"
            display="flex"
            flexDirection="column"
            gridGap="13px"
            marginBottom="40px"
          >
            {TorahPortionFaqs.map((item, index) => (
              <Accordion key={item.title} className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<AddIcon className={classes.accordionExpanIcon} />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                >
                  <Typography className={classes.title}>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RichText html={item.description} externalClassName={classes.description} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default WeeklyTorahPortionOriginsOfParsha;
