import Cookies from 'js-cookie';
import { UserContextDebugPanel } from '~components/debug/UserContextDebugPanel';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Special wrapper to check whether we want to enable debug UI elements.
 * Made as a separate component to encapsulate all the logic related to checking cookies etc.
 *
 * @param children
 * @return {*|JSX.Element}
 * @constructor
 */
export const MaybeDebugUI = ({ children }) => {
  if (Cookies.get('isUserContextDebugPanelVisible')) {
    return (
      <>
        <UserContextDebugPanel />
        {children}
      </>
    );
  }
  return children;
};

MaybeDebugUI.propTypes = {
  children: PropTypes.node.isRequired
};
