import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Dialog from '~components/Dialog';
import AbBlackLogo from '~images/alephbeta-black-logo.svg';
import RabbiImage from '~images/popups/watch-time/rabbi-icon.png';
import ChatArrowVector from '~images/popups/watch-time/chat-arrow.svg';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import Link from '~components/Link';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import { useTestId } from '~utils/set-testid';
import ButtonClosePopup from '../components/ButtonClosePopup';
import SupportHeartText from '../components/SupportHeartText';

const useStyles = makeStyles(theme => ({
  scrollBody: {
    '&:after': {
      height: 'unset !important'
    }
  },
  paper: {
    margin: 0,
    color: '#000000',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: 'unset',
      height: 'unset'
    }
  },
  logoImage: {
    marginLeft: '40px',
    marginTop: '18px',
    marginBottom: '14px'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 7%',
    [theme.breakpoints.down('sm')]: {
      top: 'unset',
      transform: 'unset',
      padding: '0px 30px',
      alignItems: 'center',
      marginTop: '85px',
      marginBottom: '40px'
    }
  },
  headingText: {
    fontFamily: 'Inter',
    fontSize: '24px',
    lineHeight: '140%',
    fontWeight: 700,
    marginBottom: '45px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      textAlign: 'center',
      marginBottom: '11px'
    }
  },
  bodyText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    lineHeight: '158%',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginBottom: '51px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px',
      gap: '8px'
    }
  },
  messageArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    [theme.breakpoints.down('sm')]: {
      gap: '8px',
      flexDirection: 'column'
    }
  },
  messageBox: {
    borderRadius: '30px',
    maxWidth: '707px',
    backgroundColor: '#0CA8FF',
    padding: '23px 34px 21px 26px',
    width: 'fit-content',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '8px',
      padding: '10px 25px',
      order: 1
    }
  },
  chatArrow: {
    position: 'absolute',
    bottom: 0,
    left: '-20px'
  },
  emptyAvatar: {
    width: '90px',
    minWidth: '90px',
    alignSelf: 'end',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  avatar: {
    width: '90px',
    alignSelf: 'end',
    [theme.breakpoints.down('sm')]: {
      width: '45px',
      alignSelf: 'start'
    }
  },
  avatarWithTypingArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '18px',
    order: 2
  },
  typingArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center'
  },
  typingCircle: {
    width: '5px',
    height: '5px',
    borderRadius: '99px',
    backgroundColor: '#0CA8FF'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      gap: '8px'
    }
  },
  bottomLightText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '142%',
    textAlign: 'center',
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginTop: '0px'
    }
  },
  bottomBoldTextLink: {
    '& > *': {
      color: '#000000',
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '142%',
      fontWeight: 700,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    '& > a:hover': {
      textDecoration: 'none'
    }
  },
  subscribeBtn: {
    textAlign: 'center',
    padding: '16px 102px',
    marginBottom: '16px'
  },
  subscribeMobileBtn: {
    padding: '10px 34px'
  }
}));

const PopupVariant2 = ({ showTopCloseBtn, id = undefined, handleClose, isOpen, isLoggedIn }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMarketingSite } = useConversionFramework();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const testId = useTestId(`access-blocking-popup-${id}`);

  return (
    <Dialog
      isOpen={isOpen}
      id={id}
      backdrop="#DAEBF5"
      background="transparent"
      maxWidth="100vw"
      classes={{ paper: classes.paper, scrollBody: classes.scrollBody }}
      disableScrollLock
      {...testId}
    >
      {!isMobileDevice && (
        <img src={AbBlackLogo} alt="Aleph Beta logo" className={classes.logoImage} />
      )}
      <div className={classes.bodyContainer}>
        <Typography className={classes.headingText}>
          You’ve watched all of your free 30 free minutes this month.
        </Typography>
        <div className={classes.messageContainer}>
          <div className={classes.messageArea}>
            <div className={classes.emptyAvatar}>&nbsp;</div>
            <div className={classes.messageBox}>
              <Typography className={classes.bodyText}>
                I want to let you in on a little secret...
              </Typography>
            </div>
          </div>

          <div className={classes.messageArea}>
            <div className={classes.emptyAvatar}>&nbsp;</div>
            <div className={classes.messageBox}>
              <Typography className={classes.bodyText}>
                Hey, it’s Me Again, Rabbi Fohrman.&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
            </div>
          </div>

          <div className={classes.messageArea}>
            <div className={classes.emptyAvatar}>&nbsp;</div>
            <div className={classes.messageBox}>
              <Typography className={classes.bodyText}>
                I hate fundraising. Yup. It’s the worst. At Aleph Beta, we’ve opted to spend our
                time, almost exclusively, learning, writing, and creating phenomenal Torah
                presentations – the stuff you and your family get to enjoy.
              </Typography>
            </div>
          </div>

          <div className={classes.messageArea}>
            {isMobileDevice ? (
              <div className={classes.avatarWithTypingArea}>
                <img src={RabbiImage} alt="Rabbi Fohrman Avatar" className={classes.avatar} />
                <div className={classes.typingArea}>
                  <div className={classes.typingCircle} />
                  <div className={classes.typingCircle} />
                  <div className={classes.typingCircle} />
                </div>
              </div>
            ) : (
              <img src={RabbiImage} alt="Rabbi Fohrman Avatar" className={classes.avatar} />
            )}
            <div className={classes.messageBox}>
              <img src={ChatArrowVector} alt="arrow" className={classes.chatArrow} />
              <Typography className={classes.bodyText}>
                How do we get away with that? By asking for micro-donations – in the form of
                subscriptions – from our viewers. Please chip in and help us keep the lights on.
                You’ll get full access to the thousands of videos and audios that live on our site.
                And, you’ll be our friend 🙂
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <RoundedLinkButton
            text={<SupportHeartText />}
            to="/subscribe"
            size={isMobileDevice ? 'mini' : 'large'}
            variant="black"
            externalClassName={isMobileDevice ? classes.subscribeMobileBtn : classes.subscribeBtn}
          />
          {!isLoggedIn && (
            <Typography className={classes.bottomLightText}>
              Already have an account?{' '}
              <span className={classes.bottomBoldTextLink}>
                <Link to={getNavigationPageLink('/login', isMarketingSite)}>Log in here</Link>
              </span>
            </Typography>
          )}
        </div>
      </div>
      {showTopCloseBtn && (
        <ButtonClosePopup
          color="#000000"
          iconSize={isMobileDevice ? '20px' : '2rem'}
          top={isMobileDevice ? '10px' : '20px'}
          right={isMobileDevice ? '10px' : '20px'}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};

PopupVariant2.propTypes = {
  showTopCloseBtn: PropTypes.bool,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool
};

PopupVariant2.defaultProps = {
  showTopCloseBtn: false,
  id: undefined,
  isOpen: null,
  isLoggedIn: false
};

export default PopupVariant2;
