import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import queryString from 'query-string';
import loadable from '@loadable/component';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import unfurl from '~prismic/unfurl/playlist';
import * as ACTIONS from '~context/UserContext/actions/actions';

import { createStructuredDataObject, createVideoSeoObject } from '~utils/playlist-helpers';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import useConversionFramework from '~hooks/useConversionFramework';
import { redirectToPageInMembers } from '~utils/common-site-helper';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';

const Playlist = loadable(() => import('~containers/playlist'));
const Page = loadable(() => import('~layout/Page'));
const SEO = loadable(() => import('~src/layout/SearchEngineOptimization'));

/*
Page collects:
1. User
2. Playlist data
3. ID of the video to start with
4. Continue watching information (time to skip to in the video in 3)

Page passes the above info to the playlist page.
*/

const DEFAULT_PLAYLIST_ALLOWED_SECONDS = 90;

const PlaylistPage = props => {
  const { data, location, pageContext } = props;
  const { state } = location || {};
  const { isMarketingSite } = useConversionFramework();
  const { session, dispatch: dispatchUserAction } = useUserContext();
  const { isUserStateLoading } = session;

  //
  const {
    state: { isPlaylistPageSubscribeBannerVisible }
  } = useGlobalStore();

  const { search } = location; // query string from the url
  const parsedSearchQueryParams = search && queryString.parse(search);

  const { giftToken, gifter: senderFullName } = search && parsedSearchQueryParams;

  const playlist = unfurl(data);

  const {
    uid,
    prismicId: playlistId,
    author,
    seo,
    firstVideo,
    videos,
    promotionalVideos,
    labels,
    redirectUrl,
    membersSitePath
  } = playlist;

  const membersPath = useMemo(
    () =>
      (pageContext.videoUid && `/video/${uid}/${pageContext.videoUid}`) ||
      membersSitePath ||
      `/playlist/${uid}`,
    [pageContext, uid, membersSitePath]
  );

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    }
  }, [redirectUrl]);

  useEffect(() => {
    const giftQueryString = giftToken ? `?giftToken=${giftToken}&gifter=${senderFullName}` : '';
    /**
     * In marketing site, let's send the users to the members site when a gift token is available
     */
    const hasGiftQueryString = uid && giftQueryString;

    if (!isUserStateLoading && isMarketingSite && hasGiftQueryString) {
      redirectToPageInMembers(`${membersPath}${giftQueryString}`);
    }
  }, [uid, membersPath, isUserStateLoading, isMarketingSite, giftToken, senderFullName]);

  const { currentVideo, currentVideoIndex, seekToSecond, redirectToVideo } = useMemo(() => {
    // Let's check if url or search state has a video uid
    const searchResultVideoUid = pageContext.videoUid || state?.searchResultVideoUid;

    // Let's check if the uid actually present in the video list of this playlist
    const preSelectedVideoIndexFromSearch = searchResultVideoUid
      ? videos.findIndex(v => v.uid === searchResultVideoUid) // findIndex function returns -1 if it doesn't find such element in the array
      : -1;

    const preSelectedVideoIndex =
      preSelectedVideoIndexFromSearch >= 0 ? preSelectedVideoIndexFromSearch : -1;

    // Let's find the correct video index and video.
    const firstVideoIndex = videos.findIndex(v => v.uid === firstVideo.uid);
    let videoIndex = preSelectedVideoIndex >= 0 ? preSelectedVideoIndex : firstVideoIndex; // defaults to the 1st video of the playlist
    let seconds = 0;

    const maybePlaylistPosition =
      isMarketingSite || isMarketingSite === null ? null : session?.playlistPositions?.[playlistId];
    let shouldRedirectToVideo = false;
    /**
     * Let's check if user has a session for the selected playlist for continue watching
     */
    if (maybePlaylistPosition && maybePlaylistPosition?.index === videoIndex) {
      // continue watching video is same as the current video
      seconds = maybePlaylistPosition.seekToSecond;
    } else if (
      maybePlaylistPosition &&
      preSelectedVideoIndex === -1 &&
      maybePlaylistPosition?.index !== videoIndex
    ) {
      // continue watching video is not the current video and there's no video pre selected
      seconds = maybePlaylistPosition.seekToSecond;
      videoIndex = maybePlaylistPosition?.index;
      shouldRedirectToVideo = true;
    }

    return {
      currentVideo: videos[videoIndex],
      currentVideoIndex: videoIndex,
      seekToSecond: seconds,
      redirectToVideo: shouldRedirectToVideo
    };
  }, [
    firstVideo?.uid,
    playlistId,
    session?.playlistPositions,
    videos,
    state?.searchResultVideoUid,
    pageContext.videoUid,
    isMarketingSite
  ]);

  const allowedSeconds = DEFAULT_PLAYLIST_ALLOWED_SECONDS;

  useEffect(() => {
    if (redirectToVideo && currentVideo) {
      navigate(`/video/${uid}/${currentVideo.uid}`, { replace: true });
    }
  }, [redirectToVideo, currentVideo, uid]);

  /**
   * As the playlist page (/playlist/{playlist-uid}) and the 1st video page (/video/{playlist-uid}/{video-uid})
   * are identical right now, we should make the 1st video page's canonical url as the playlist page
   */
  const pageCanonicalUrl =
    firstVideo?.uid === currentVideo?.uid
      ? `${process.env.SITE_URL}/playlist/${uid}`
      : `${process.env.SITE_URL}/video/${uid}/${currentVideo.uid}`;

  const structuredDataObject = createStructuredDataObject(currentVideo, author, seo.seo_keywords);

  /**
   * playlist page should use the seo from the playlist while video pages should use the seo with video details updated
   */
  const updatedSeo = !pageContext.videoUid ? seo : createVideoSeoObject(seo, null, currentVideo);

  const hasPodcastSubscriptionLink =
    location.pathname.includes('alephbetaquarantined') ||
    location.pathname.includes('aleph-beta-quarantined-newest-episode') ||
    location.pathname.includes('into-the-verse');

  const onProgress = (videoIndex, seconds) => {
    dispatchUserAction({
      type: ACTIONS.USER_PLAYLIST_POSITION_UPDATE,
      payload: { playlistId, videoIndex, position: seconds }
    });
  };

  return (
    <Page
      redirectLoggedInUsersToMembers
      membersPath={membersPath}
      hideMarketingSecondaryHeader={!isPlaylistPageSubscribeBannerVisible}
    >
      <SEO
        {...updatedSeo}
        structuredDataObject={structuredDataObject}
        pageCanonicalUrl={pageCanonicalUrl}
      />

      <Playlist
        seekToSecond={seekToSecond}
        playlist={playlist}
        session={session}
        videoId={currentVideoIndex}
        hasPodcastSubscriptionLink={hasPodcastSubscriptionLink}
        onProgress={onProgress}
        promotionalVideos={promotionalVideos}
        labels={labels}
        giftToken={giftToken}
        senderFullName={senderFullName}
        allowedSeconds={allowedSeconds}
      />
    </Page>
  );
};

PlaylistPage.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    videoUid: PropTypes.string
  }).isRequired,
  location: PropTypes.shape()
};

PlaylistPage.defaultProps = {
  location: null
};

export default withPrismicPreview(PlaylistPage);

export const query = graphql`
  query ($uid: String!) {
    prismicPlaylist(uid: { eq: $uid }) {
      _previewable
      uid
      prismicId
      type
      data {
        label_is_new
        label_is_audio
        label_is_premium
        label_is_producer
        title
        required_subscription_plan
        allow_full_watch
        allowed_seconds
        members_site_playlist_path
        page_layout
        redirect_url {
          url
        }
        description {
          html
          text
        }
        cover_image {
          url
          alt
        }
        marketing_playlist_page_cover {
          url
          alt
        }
        author {
          document {
            ... on PrismicAuthor {
              data {
                name
                role
                avatar {
                  alt
                  url
                }
                biography {
                  html
                }
              }
            }
          }
        }
        videos {
          video {
            document {
              ... on PrismicVideo {
                uid
                prismicId
                first_publication_date
                data {
                  title
                  description {
                    html
                    text
                  }
                  cover_image {
                    url
                    alt
                  }
                  wistia_url {
                    url
                  }
                  trailer_wistia_url {
                    url
                  }
                  transcript {
                    html
                    text
                  }
                  length_in_seconds
                  allowed_seconds
                }
              }
            }
          }
        }
        next_playlist {
          url
          document {
            ... on PrismicPlaylist {
              uid
              data {
                title
                cover_image {
                  url
                  alt
                }
              }
            }
          }
        }
        suggested_playlists {
          playlist {
            url
            document {
              ... on PrismicPlaylist {
                uid
                data {
                  title
                  label_is_audio
                  author {
                    document {
                      ... on PrismicAuthor {
                        data {
                          name
                        }
                      }
                    }
                  }
                  videos {
                    video {
                      document {
                        ... on PrismicVideo {
                          uid
                          data {
                            length_in_seconds
                          }
                        }
                      }
                    }
                  }
                  cover_image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
        materials {
          material {
            url
            document {
              ... on PrismicMaterial {
                uid
                data {
                  title
                  material_type
                  required_subscription_plan
                  file {
                    url
                  }
                }
              }
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
      }
    }
    prismicPromotionalCtaVideos {
      data {
        body {
          ... on PrismicPromotionalCtaVideosDataBodyPromotionalVideo {
            primary {
              wistia_video_url
              video_access_level
              promotional_video_position
            }
          }
        }
      }
    }
    prismicPopularTrendingPlaylists {
      data {
        trending_list {
          playlist {
            url
            document {
              ... on PrismicPlaylist {
                uid
                data {
                  title
                  label_is_audio
                  author {
                    document {
                      ... on PrismicAuthor {
                        data {
                          name
                        }
                      }
                    }
                  }
                  videos {
                    video {
                      document {
                        ... on PrismicVideo {
                          uid
                          data {
                            length_in_seconds
                          }
                        }
                      }
                    }
                  }
                  cover_image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
        popular_list {
          playlist {
            url
            document {
              ... on PrismicPlaylist {
                uid
                data {
                  title
                  label_is_audio
                  author {
                    document {
                      ... on PrismicAuthor {
                        data {
                          name
                        }
                      }
                    }
                  }
                  videos {
                    video {
                      document {
                        ... on PrismicVideo {
                          uid
                          data {
                            length_in_seconds
                          }
                        }
                      }
                    }
                  }
                  cover_image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
