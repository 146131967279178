import { get } from 'lodash';

const convertRawPrismicNestedMenuItem = nestedMenuItem => {
  if (
    !nestedMenuItem ||
    !nestedMenuItem.menu_item_document ||
    nestedMenuItem.menu_item_document.isBroken
  ) {
    return null;
  }
  const itemDocument = nestedMenuItem.menu_item_document;
  switch (itemDocument.link_type) {
    case 'Document': {
      const result = {
        // NOTE: if any document type stores its title in something else than simple `itemDocument.document.data.title`,
        // we must introduce an `if` statement below to extract the title to `title` field of the result
        title: itemDocument.document?.data?.title || 'NO TITLE, TELL WEBSITE ADMINS',
        uid: itemDocument.uid || 'UNDEFINED_UID',
        link: itemDocument.url
      };
      // We know that books contain chapters so let's add them as children
      if (itemDocument.type === 'book') {
        result.children =
          itemDocument.document?.data.chapters &&
          itemDocument.document?.data.chapters.length > 0 &&
          itemDocument.document?.data.chapters.map(({ chapter }) => ({
            title: chapter.document?.data.title || 'NO PARSHAT TITLE',
            link: chapter.url,
            uid: chapter.document?.data.title.toLowerCase() // FIXME: this is some hack which I don't understand yet
          }));
      }
      // We know that Podcast Playlists have titles in a rich text format and stored in a different field, let's adapt
      if (itemDocument.type === 'podcast_playlist') {
        result.title = itemDocument.document.data.podcast_title.text;
      }

      return result;
    }
    case 'Web': {
      return {
        title: 'A link', // FIXME: TODO: what title to put into a link?
        uid: 'UNDEFINED_UID',
        link: itemDocument.url
      };
    }
    case 'Media': {
      return {
        title: 'A material', // FIXME: TODO: what title to put into a link?
        uid: 'UNDEFINED_UID',
        link: itemDocument.url
      };
    }
    default: {
      return {
        title: 'WRONG ITEM',
        uid: 'UNDEFINED_UID',
        link: ''
      };
    }
  }
};
const convertRawPrismicMenuItem = menuItem => ({
  title: menuItem.primary.menu_title,
  uid: menuItem.primary.uid || menuItem.primary.menu_title.toLowerCase(),
  link: menuItem.primary.menu_link,
  children:
    menuItem.items &&
    menuItem.items.length > 0 &&
    menuItem.items.map(convertRawPrismicNestedMenuItem).filter(Boolean)
});

const extractNavigationBarData = prismicData =>
  get(prismicData, 'edges[0].node.data.body', []).map(convertRawPrismicMenuItem);

const extractFooterLinks = prismicData => {
  const nodes = get(prismicData, 'nodes[0].data');
  return nodes.company_links.map(link => link);
};

export default prismicData => ({
  recursiveMenuItems: extractNavigationBarData(get(prismicData, 'allPrismicWipNavigation')),
  footerCompanyLinks: extractFooterLinks(get(prismicData, 'allPrismicWipNavigation')),
  headerLinks: get(prismicData, 'prismicWipNavigation.data.header_navigation', []),
  guestHeaderLinks: get(prismicData, 'prismicWipNavigation.data.guest_header_navigation', []),
  footerSocialLinks: get(prismicData, 'prismicWipNavigation.data.guest_footer_navigation', []),
  disableGuestNavBar: get(prismicData, 'prismicWipNavigation.data.disable_guest_nav_bar', false),
  showAbout: get(prismicData, 'prismicWipNavigation.data.show_about', false)
});
