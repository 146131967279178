import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import Parsha from '~src/templates/parsha';
import Playlist from '~src/templates/playlist';
import Holiday from '~src/templates/holiday';
import About from '~src/pages/about';
import FAQ from '~src/pages/faq';
import Guides from '~src/pages/guides';
import Holidays from '~src/pages/holidays';
import Home from '~src/pages/index';
import Privacy from '~src/pages/privacy';
import ProducersCircle from '~src/pages/patrons-circle';
import Search from '~src/pages/search';
import Team from '~src/pages/team';
import Terms from '~src/pages/terms';
import TishaBavHome from '~src/pages/tisha-bav-home';
import WeeklyTorahPortion from '~src/pages/weekly-torah-portion';
import Advanced from '~src/templates/advanced';
import Article from '~src/templates/article';
import BlogPost from '~src/templates/blog_post';
import Book from '~src/templates/book';
import Curation from '~src/templates/curation';
import GenericPage from '~src/templates/generic_page';
import Job from '~src/templates/job_post';
import Topic from '~src/templates/topic';
import PodcastPlaylist from '~src/templates/podcasts';
import PodcastEpisode from '~src/templates/podcast_episode_description';
import Course from '~src/templates/course';

const componentResolver = componentResolverFromMap({
  parshat: Parsha,
  playlist: Playlist,
  holiday_redesign: Holiday,
  about: About,
  faq: FAQ,
  guides: Guides,
  index: Holidays,
  home: Home,
  privacy: Privacy,
  'producers-circle': ProducersCircle,
  search: Search,
  team: Team,
  terms: Terms,
  'tisha-bav-home': TishaBavHome,
  'weekly-torah-portion': WeeklyTorahPortion,
  advanced: Advanced,
  article: Article,
  blog_post: BlogPost,
  book: Book,
  curation: Curation,
  generic_page: GenericPage,
  job_page: Job,
  topic: Topic,
  podcast_playlist: PodcastPlaylist,
  podcast_episode: PodcastEpisode,
  course: Course
});

export default componentResolver;
