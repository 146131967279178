/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Link from '~components/Link';
import Author from '~components/playlist/author';
import Excerpt from '~components/Excerpt';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: '100%',
    display: 'flex',
    borderRadius: '10px'
  },
  imageLink: {
    '&:hover': {
      opacity: '0.8',
      cursor: 'pointer'
    }
  },
  titleLink: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  caption: {
    '& > * ': {
      marginBlockStart: '0 !important'
    },
    '& > * > * ': {
      marginBlockStart: '0 !important'
    }
  }
}));

const Image = ({ url, ...rest }) => {
  const classes = useStyles();
  if (url) {
    return (
      <Link to={url}>
        {/* eslint-disable-next-line */}
        <img {...rest} className={classnames(classes.image, classes.imageLink)} />
      </Link>
    );
  }

  return <img {...rest} className={classes.image} />; // eslint-disable-line
};

const Title = ({ url, title, ...rest }) => {
  const classes = useStyles();
  if (url) {
    return (
      <Link to={url} className={classes.titleLink}>
        <RichText html={title.html} verticalSpacing={0} {...rest} />
      </Link>
    );
  }

  return <RichText html={title.html} verticalSpacing={0} {...rest} />;
};

const BlogPost = ({
  isFeatured,
  url,
  title,
  overline,
  last_publication_date,
  author,
  content,
  seo_image
}) => {
  const classes = useStyles();
  const imageSize = isFeatured ? 12 : 6;
  const dateVariant = isFeatured ? 'subtitle1' : 'caption';

  return (
    <Box my={2}>
      {/*  */}
      <Grid
        container
        direction={isFeatured ? 'column' : 'row'}
        justify={isFeatured ? 'flex-start' : 'space-between'}
        alignItems={isFeatured ? 'flex-start' : 'center'}
        spacing={2}
      >
        <Grid item xs={12} sm={imageSize}>
          {/* Image */}
          <Box my={2}>
            <Image url={url} src={seo_image.url} alt={seo_image.alt} />
          </Box>
        </Grid>

        <Grid item xs={12} sm>
          {/* Overline */}
          <Box color="#438dce" mb={2} className={classes.caption}>
            <RichText
              html={overline.html}
              verticalSpacing={0}
              mode={!isFeatured && 'nonFeaturedPostOverline'}
              textAlign="left"
            />
          </Box>

          {/* Date */}
          <Typography variant={dateVariant} component="p">
            {last_publication_date}
          </Typography>

          {/* Title */}
          <Box my={2}>
            <Title url={url} title={title} mode={!isFeatured && 'nonFeaturedPostHeading'} />
          </Box>

          {/* Author */}
          {isFeatured ? (
            <Author {...author} />
          ) : (
            <Typography variant="caption" component="p" gutterBottom>
              By {author.name}
            </Typography>
          )}

          {/* Description */}
          {isFeatured && content ? <Excerpt description={content.text} buttons={false} /> : null}

          {/* Read more */}
          {content && (
            <Link to={url} variant="subtitle1">
              Read More
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

BlogPost.propTypes = {
  isFeatured: PropTypes.bool,
  url: PropTypes.string,
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  overline: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  last_publication_date: PropTypes.string.isRequired,
  author: PropTypes.shape(Author.propTypes).isRequired,
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }),
  seo_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired
};

BlogPost.defaultProps = {
  isFeatured: false,
  url: null,
  content: null
};

export default BlogPost;
