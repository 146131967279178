import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fab from '@material-ui/core/Fab';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

const getVariantStyles = (variant, disabled) => {
  if (disabled) {
    return {
      color: variant === 'dark' ? '#3E3E3E' : '#E3E3E3',
      border: variant === 'dark' ? '1px solid #3E3E3E' : '1px solid #E3E3E3',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    };
  }
  return {
    color: variant === 'dark' ? '#FFFFFF' : '#000000',
    border: variant === 'dark' ? '1px solid #EBEBEB' : '1px solid #B9B9B8',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      filter:
        variant === 'dark'
          ? 'drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25))'
          : 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))'
    },
    '&:active': {
      backgroundColor: 'transparent',
      border: variant === 'dark' ? '1px solid #FFFFFF' : '1px solid #000000',
      filter:
        variant === 'dark'
          ? 'drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25))'
          : 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))',
      boxShadow: 'none'
    }
  };
};

const useStyles = makeStyles({
  fab: props => ({
    boxShadow: 'none',
    width: 35,
    height: 35,
    ...getVariantStyles(props.variant, props.disabled)
  }),
  arrow: {
    fontSize: 18
  }
});

const SlideNavButton = ({ onClick, direction, disabled, variant }) => {
  const classes = useStyles({ disabled, variant });
  return (
    <Fab
      onClick={() => !disabled && onClick()}
      size="small"
      className={classes.fab}
      aria-label="navigate back"
      disableRipple
    >
      {direction === 'prev' ? (
        <ArrowBack className={classes.arrow} />
      ) : (
        <ArrowForward className={classes.arrow} />
      )}
    </Fab>
  );
};

SlideNavButton.propTypes = {
  direction: PropTypes.oneOf(['prev', 'next']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['light', 'dark'])
};

SlideNavButton.defaultProps = {
  direction: 'next',
  disabled: false,
  onClick: () => {},
  variant: 'light'
};

export default SlideNavButton;
