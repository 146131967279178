import React, { useState, useEffect } from 'react';
import posed from 'react-pose';
import makeStyles from '@material-ui/core/styles/makeStyles';

import PrimaryPlayButtonIcon from '~images/animatedPlayButton/play-button-primary-big.png';

const useStyles = makeStyles(theme => ({
  circle: {
    width: '117px',
    height: '117px',
    background: '#438dce',
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      height: '80px'
    }
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    position: 'absolute',
    top: '0px',
    left: '0px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80px'
    }
  },
  animationPosition: {
    position: 'absolute',
    left: '46%',
    top: '36%',
    borderRadius: '50%',
    border: '8px solid #ffffff',
    zIndex: '10',

    [theme.breakpoints.down('sm')]: {
      left: 'calc(50% - 40px)',
      bottom: '-35px',
      top: 'unset'
    }
  }
}));

const CircleWrapper = posed.div({
  hidden: {
    transform: 'scale(1.06)',
    transition: {
      duration: 1000
    }
  },
  visible: {
    transform: 'scale(1)',
    transition: {
      duration: 400
    }
  }
});

const Circle = posed.div({
  hidden: {
    transform: 'scale(0.6)',
    opacity: 1,
    transition: {
      duration: 0
    }
  },
  visible: {
    transform: 'scale(1.4)',
    opacity: 0,
    transition: {
      duration: 1000
    }
  }
});

const PrimaryColorAnimatedButton = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fadeOut = setInterval(() => {
      setIsVisible(prevState => !prevState);
    }, 1000);

    return () => clearInterval(fadeOut);
  }, []);

  return (
    <div className={classes.animationPosition}>
      <CircleWrapper className={classes.circle} pose={isVisible ? 'visible' : 'hidden'}>
        <Circle className={classes.circle} pose={isVisible ? 'visible' : 'hidden'} />
        <img src={PrimaryPlayButtonIcon} alt="play button icon" className={classes.media} />
      </CircleWrapper>
    </div>
  );
};

export default PrimaryColorAnimatedButton;
