import { get } from '../utils/get';

export default prismicData => ({
  prismicId: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].prismicId', ''),
  requiredSubscriptionPlan: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.podcast_playlist.document.data.required_subscription_plan',
    ''
  ),
  audioUrl: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.audio_url.url', ''),
  spotifyUrl: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.spotify_url.url', ''),
  playerEmbedCode: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.player_embed_code', ''),
  isSubscriberEpisode: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.is_subscriber_episode',
    false
  ),
  color: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.color', ''),
  playButtonColor: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.play_button_color', ''),
  playButtonTextColor: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.play_button_text_color',
    '#FFFFFF'
  ),
  thumbnail: {
    url: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.cover_image.url', ''),
    alt: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.cover_image.alt', '')
  },
  createdAt:
    new Date(get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.created_at', '')) || null,
  description: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.description.html', ''),
  detailedDescription: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.detailed_description.html',
    ''
  ),
  episode: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.episode', null),
  duration: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.length_in_seconds', ''),
  part: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.part', null),
  podcastTitle: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.podcast_playlist.document.data.podcast_title.text',
    ''
  ),
  previousSeasons: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.podcast_playlist.document.data.previous_seasons',
    []
  ).map(season => ({
    ctaText: get(season, 'cta_text', ''),
    url: get(season, 'previous_playlist.document.url', ''),
    uid: get(season, 'previous_playlist.document.uid', ''),
    slug: get(season, 'previous_playlist.slug', '')
  })),
  providers: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.body', [])
    .find(slice => slice.slice_type === 'providers_slice')
    ?.items.map(provider => ({
      id: get(provider, 'provider_id', ''),
      url: get(provider, 'url.url', '')
    })),
  hosts: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.body', [])
    .find(slice => slice.slice_type === 'host')
    ?.items.map(({ host }) => ({
      name: get(host, 'document.data.name', ''),
      role: get(host, 'document.data.role', ''),
      avatar: get(host, 'document.data.avatar', {})
    })),
  podcastPrismicId: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.podcast_playlist.id',
    ''
  ),
  season: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.season', null),
  title: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.title', ''),
  transcript: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.transcript.html', ''),
  uid: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].uid', ''),
  podcastUid: get(prismicData, 'allPrismicPodcastEpisode.nodes[0].data.podcast_playlist.uid', ''),
  hostNames: get(
    prismicData,
    'allPrismicPodcastEpisode.nodes[0].data.podcast_playlist.document.data.hosts',
    []
  )[0].items.reduce((acc, curr) => {
    const name = get(curr, 'host.document.data.name');
    return `${acc} ${name}`;
  }, ''),
  seasonUid: get(
    get(
      prismicData,
      'allPrismicPodcastEpisode.nodes[0].data.podcast_playlist.document.data.episodes',
      []
    ).find(season =>
      season.items.find(
        ep =>
          ep.podcast_episode.uid === get(prismicData, 'allPrismicPodcastEpisode.nodes[0].uid', '')
      )
    ),
    'primary.series_uid',
    ''
  )
});
