import React from 'react';
import PropTypes from 'prop-types';

import BarBatMitzvahSalePopup from '~components/popups/BarBatMitzvahSalePopup';
import BarBatMitzvahOnboardingPopup from '~components/popups/BarBatMitzvahOnboardingPopup';
import GenericPopup from '~components/popups/GenericPopup';
import GiftContentPopup from '~components/popups/GiftContentPopup';
import GiftThankYouPopup from '~components/popups/GiftThankYouPopup';
import GenericAccessBlockingPopup from '~components/popups/GenericAccessBlockingPopup';
import GuestLoginPopup from '~components/popups/GuestLoginPopup';
import WatchTimeHalfwayPopup from '~components/popups/WatchTimeHalfwayPopup/WatchTimeHalfwayPopup';
import WatchTimeOverPopup from '~components/popups/WatchTimeOverPopup/WatchTimeOverPopup';
import GuestBlockerPopup from '~components/popups/GuestBlockerPopup/GuestBlockerPopup';
import {
  // GuestUserSoftSaleFirstPopup,
  // GuestUserSoftSaleSecondPopup,
  RegisteredUserSoftSaleSecondPopup
} from '~components/banners/';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';

import { POPUPS_DATA } from '~utils/popup-helpers';
import LargePDFPopup from '../components/popups/LargePDFPopup';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);

export const POPUPS_MAP = {
  FIRST_SOFT_SALE: 11,
  SECOND_SOFT_SALE: 12,
  GUEST_BLOCKER: 10,
  REGISTERED_PRESALE_ONE: 21,
  REGISTERED_BLOCKER: 20,
  REGISTERED_PRESALE_TWO: 13,
  PREMIUM_CONTENT_BLOCKER: 25,
  BARBATMITZVAH_SALE: 30,
  BARBATMITZVAH_ONBOARDING: 31,
  FAILED_CHARGES_BLOCKER: 70,
  THANK_YOU_FOR_NOT_CANCELLING: 71,
  PRODUCER_CIRCLE_CONTENT_BLOCKER: 80,
  SUBSCRIBER_GIFT_CONTENT_FORM: 100,
  UPGRADE_TO_PREMIUM_FOR_FEATURE: 102,
  GIFT_THANK_YOU: 103,
  TRANSCRIPTS_LIMIT: 104,
  GUEST_USER_LOGIN: 105,
  PDF_TOO_LARGE_FOR_DOWNLOAD: 106,
  IN_VIDEO_CONTENT_BLOCKER: 200
};

export const POPUP_CONFIGS = {
  [POPUPS_MAP.GUEST_USER_LOGIN]: {
    autoplayOnClose: true
  },
  redirectHomeOnClose: [
    POPUPS_MAP.GUEST_BLOCKER,
    POPUPS_MAP.REGISTERED_BLOCKER,
    POPUPS_MAP.PREMIUM_CONTENT_BLOCKER,
    POPUPS_MAP.PRODUCER_CIRCLE_CONTENT_BLOCKER,
    POPUPS_MAP.FAILED_CHARGES_BLOCKER
  ]
};

const Popups = ({
  which,
  gift,
  goToCheckout,
  handleClose,
  isPopupOpen,
  disableBackdropClick,
  disableEscapeKeyDown,
  handleSubmit,
  playlistId,
  playlistSlug,
  senderFullName,
  giftToken,
  ...rest
}) => {
  switch (which) {
    case POPUPS_MAP.GUEST_BLOCKER: {
      if (IS_BLACK_FRIDAY_OFFER_ENABLED) {
        return (
          <WatchTimeOverPopup
            id={POPUPS_DATA.GUEST_BLOCKER.id}
            isPopupOpen={isPopupOpen}
            handlePopupClose={handleClose}
            showTopCloseBtn
            {...rest}
          />
        );
      }
      return (
        <GuestBlockerPopup
          id={POPUPS_DATA.GUEST_BLOCKER.id}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );
    }

    case POPUPS_MAP.REGISTERED_BLOCKER:
      return (
        <WatchTimeOverPopup
          id={POPUPS_DATA.REGISTERED_BLOCKER.id}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );
    case POPUPS_MAP.REGISTERED_PRESALE_ONE:
      return (
        <WatchTimeHalfwayPopup
          id={POPUPS_DATA.REGISTERED_PRESALE_ONE.id}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          which={which}
          showTopCloseBtn
          {...rest}
        />
      );
    case POPUPS_MAP.FIRST_SOFT_SALE: {
      if (IS_BLACK_FRIDAY_OFFER_ENABLED) {
        return (
          <WatchTimeHalfwayPopup
            id={POPUPS_DATA.REGISTERED_PRESALE_ONE.id}
            isPopupOpen={isPopupOpen}
            handlePopupClose={handleClose}
            which={which}
            showTopCloseBtn
            {...rest}
          />
        );
      }
      return null;
    }
    case POPUPS_MAP.REGISTERED_PRESALE_TWO:
      return (
        <RegisteredUserSoftSaleSecondPopup
          id={POPUPS_DATA.REGISTERED_PRESALE_TWO.id}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          which={which}
          {...rest}
        />
      );

    case POPUPS_MAP.PREMIUM_CONTENT_BLOCKER:
      return (
        <GenericAccessBlockingPopup
          id={POPUPS_DATA.PREMIUM_CONTENT_BLOCKER.id}
          popupData={POPUPS_DATA.PREMIUM_CONTENT_BLOCKER}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );

    //  NEW
    case POPUPS_MAP.JOIN_OUR_COMMUNITY:
      return (
        <GenericAccessBlockingPopup
          id={POPUPS_DATA.JOIN_OUR_COMMUNITY.id}
          popupData={POPUPS_DATA.JOIN_OUR_COMMUNITY}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );

    case POPUPS_MAP.PDF_TOO_LARGE_FOR_DOWNLOAD:
      return (
        <LargePDFPopup
          id={POPUPS_DATA.PDF_TOO_LARGE_FOR_DOWNLOAD.id}
          popupData={POPUPS_DATA.PDF_TOO_LARGE_FOR_DOWNLOAD}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );

    //  NEW
    case POPUPS_MAP.UPGRADE_TO_PREMIUM_FOR_FEATURE:
      return (
        <GenericAccessBlockingPopup
          id={POPUPS_DATA.UPGRADE_TO_PREMIUM_FOR_FEATURE.id}
          popupData={POPUPS_DATA.UPGRADE_TO_PREMIUM_FOR_FEATURE}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );

    case POPUPS_MAP.TRANSCRIPTS_LIMIT:
      return (
        <GenericAccessBlockingPopup
          id={POPUPS_DATA.TRANSCRIPTS_LIMIT.id}
          popupData={POPUPS_DATA.TRANSCRIPTS_LIMIT}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );

    case POPUPS_MAP.FAILED_CHARGES_BLOCKER:
      return (
        <GenericAccessBlockingPopup
          id={POPUPS_DATA.FAILED_CHARGES_BLOCKER.id}
          popupData={POPUPS_DATA.FAILED_CHARGES_BLOCKER}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );

    case POPUPS_MAP.PRODUCER_CIRCLE_CONTENT_BLOCKER:
      return (
        <GenericAccessBlockingPopup
          id={POPUPS_DATA.PRODUCER_CIRCLE_CONTENT_BLOCKER.id}
          popupData={POPUPS_DATA.PRODUCER_CIRCLE_CONTENT_BLOCKER}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );

    case POPUPS_MAP.BARBATMITZVAH_SALE:
      return (
        <BarBatMitzvahSalePopup
          showTopCloseBtn
          maxWidth="md"
          background="transparent"
          gift={gift}
          goToCheckout={goToCheckout}
          handleClose={handleClose}
          backdrop="#438dceF0"
        />
      );
    case POPUPS_MAP.BARBATMITZVAH_ONBOARDING:
      return (
        <BarBatMitzvahOnboardingPopup
          maxWidth="md"
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      );
    case POPUPS_MAP.THANK_YOU_FOR_NOT_CANCELLING:
      return (
        <GenericPopup
          id={POPUPS_DATA.THANK_YOU_FOR_NOT_CANCELLING.id}
          popupData={POPUPS_DATA.THANK_YOU_FOR_NOT_CANCELLING}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          showTopCloseBtn
          {...rest}
        />
      );
    case POPUPS_MAP.SUBSCRIBER_GIFT_CONTENT_FORM:
      return (
        <GiftContentPopup
          id={POPUPS_MAP.SUBSCRIBER_GIFT_CONTENT_FORM}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          playlistId={playlistId}
          playlistSlug={playlistSlug}
          showTopCloseBtn
          {...rest}
        />
      );

    case POPUPS_MAP.GIFT_THANK_YOU:
      return (
        <GiftThankYouPopup
          id={POPUPS_MAP.GIFT_THANK_YOU}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          playlistId={playlistId}
          playlistSlug={playlistSlug}
          senderFullName={senderFullName}
          showTopCloseBtn
          giftToken={giftToken}
          {...rest}
        />
      );
    case POPUPS_MAP.GUEST_USER_LOGIN:
      return (
        <GuestLoginPopup
          id={POPUPS_MAP.GUEST_USER_LOGIN}
          isPopupOpen={isPopupOpen}
          handlePopupClose={handleClose}
          {...rest}
        />
      );

    default:
      return null;
  }
};

Popups.propTypes = {
  which: PropTypes.number,
  gift: PropTypes.bool,
  goToCheckout: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  playlistId: PropTypes.string,
  playlistSlug: PropTypes.string,
  senderFullName: PropTypes.string,
  giftToken: PropTypes.string,
  toggleVideoPlayState: PropTypes.func
};

Popups.defaultProps = {
  which: null,
  gift: false,
  goToCheckout: null,
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
  handleClose: null,
  handleSubmit: null,
  isPopupOpen: null,
  playlistId: null,
  playlistSlug: null,
  senderFullName: null,
  giftToken: null,
  toggleVideoPlayState: null
};

export default Popups;
