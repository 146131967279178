import React from 'react';
import { Element } from 'react-scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import ImpactLogo from './images/heart-logo-brown.svg';
import BottomShapeDividerSrc1 from './images/pc-page-shape-divider-2.svg';
import BecomePatronButton from '../shared/BecomePatronButton/BecomePatronButton';
import ImpactHeartIcon from './images/impact-heart.svg';
import ImpactGlobeIcon from './images/impact-globe.svg';
import ImpactMicIcon from './images/impact-mic.svg';
import ImpactPlayIcon from './images/impact-play.svg';
import ImpactClockIcon from './images/impact-clock.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#D4E9F7'
  },
  impactRoot: {
    backgroundColor: '#F7F6F4'
  },
  sectionHeading: {
    color: '#040057',
    fontSize: '50px',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      paddingBottom: theme.spacing(2),
      letterSpacing: '0px'
    }
  },
  paragraphStyle: {
    color: '#475C6C',
    fontSize: '20px',
    paddingBottom: theme.spacing(2),
    '& strong': {
      color: '#040057',
      fontSize: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '17px',
      '& strong': {
        fontSize: '22px'
      }
    }
  },
  logo: {
    display: 'block',
    margin: '77px auto 0px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '74px',
      margin: '40px auto 0px'
    }
  },
  container: {
    padding: '0px 17px 50px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 17px 48px'
    }
  },
  headerText: {
    color: '#222',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '52px',
    fontWeight: '600',
    lineHeight: '135%',
    marginTop: '18px',
    marginBottom: '32px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      marginTop: '16px',
      marginBottom: '24px',
      margin: 'auto',
      maxWidth: '330px'
    }
  },
  subtitleText: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '170%',
    marginTop: '0px',
    marginBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      marginBottom: '16px'
    }
  },
  bodyText: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '20px',
    lineHeight: '170%',
    marginTop: '0px',
    marginBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      marginBottom: '16px'
    }
  },
  impactHeaderText: {
    color: '#222',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '52px',
    fontWeight: '600',
    lineHeight: '135%',
    marginTop: '95px',
    marginBottom: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      marginTop: '48px',
      maxWidth: '330px',
      margin: 'auto'
    }
  },
  statsArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: '40px',
    rowGap: '48px',
    flexWrap: 'wrap',
    marginTop: '52px',
    marginBottom: '90px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
      columnGap: '16px',
      rowGap: '32px',
      marginBottom: '8px'
    }
  },
  statsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    maxWidth: '150px',
    '& h4': {
      marginTop: '0px',
      marginBottom: '0px',
      fontSize: '42px',
      fontWeight: '500px',
      lineHeight: '140%',
      textAlign: 'center'
    },
    '& p': {
      marginTop: '0px',
      marginBottom: '0px',
      fontSize: '16px',
      fontFamily: 'Inter',
      lineHeight: '135%',
      textAlign: 'center'
    }
  },
  textContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '24px'
    }
  }
}));

const IMPACT_STATISTICS = [
  { amount: '500', title: 'Hours of Torah Consumed Daily', icon: ImpactHeartIcon },
  { amount: '75', title: 'Countries Our Users Come From', icon: ImpactGlobeIcon },
  { amount: '10K', title: 'Weekly Podcast Listeners', icon: ImpactMicIcon },
  { amount: '1235', title: ' Videos in Our Library', icon: ImpactPlayIcon },
  { amount: '53M+', title: ' Total Minutes Watched', icon: ImpactClockIcon }
];

const ProducersCircleImpactSection = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Container size={50} maxWidth="md" center className={classes.container}>
          <Element name="PC Impact" className={classes.textContainer}>
            <img
              src={ImpactLogo}
              alt="Patrons Circle"
              title="Patrons Circle"
              className={classes.logo}
            />
            <h2 className={classes.headerText}>The difference YOU make</h2>
            <p className={classes.subtitleText}>
              We’re not funded by any multimillion dollar gifts from mega-philanthropists. Aleph
              Beta exists because of people like YOU!
            </p>
            <p className={classes.bodyText}>
              Being a Patron of Aleph Beta’s inner circle is about more than just access to
              exclusive perks. It’s about joining a community of passionate supporters who are
              investing in our mission! Your partnership fuels our creativity and helps free us of
              the need to fundraise, so we can focus on what we do best: developing high-quality
              Torah content.
            </p>
            <p className={classes.bodyText}>
              And here’s the real magic: We know life is busy. You might not always be able to dive
              into every course. And that’s okay. Your membership isn’t merely about the benefits
              you receive. It’s about the difference you make. Every Patron membership – whether
              used once a day or once a year – is helping to foster profound, Torah-rooted
              transformations for countless learners across the globe.
            </p>
          </Element>
          <BecomePatronButton variant="black" />
        </Container>
        <BottomShapeDivider src={BottomShapeDividerSrc1} bgColor="transparent" flex />
      </div>
      <div className={classes.impactRoot}>
        <Container size={50} maxWidth="lg" center className={classes.container}>
          <Element name="Partner With Us">
            <h2 className={classes.impactHeaderText}>The impact of Your Donation</h2>
            <p className={classes.bodyText}>
              84% of every dollar Aleph Beta receives goes toward- making and sharing Torah content.
            </p>
            <div className={classes.statsArea}>
              {IMPACT_STATISTICS.map(statistic => (
                <div key={statistic.title} className={classes.statsBox}>
                  <img src={statistic.icon} alt={statistic.title} title={statistic.title} />
                  <h4>{statistic.amount}</h4>
                  <p>{statistic.title}</p>
                </div>
              ))}
            </div>
          </Element>
        </Container>
      </div>
    </>
  );
};

export default ProducersCircleImpactSection;
