import { get } from 'lodash';
import { boolFlagFromString } from '~utils/playlist-helpers';

const extractHeroData = prismicData => {
  const heroSlice = get(prismicData, 'prismicCuration.data.body', []).find(
    slice => slice.slice_type === 'curation_hero'
  );

  return { ...heroSlice.primary } || {};
};

const extractCards = prismicData => {
  const prismicCards = get(prismicData, 'prismicCuration.data.grid', []);
  const cards = prismicCards.map(card => ({
    ...card.primary,
    playlists_search_title: card.items.map(item => get(item, 'playlists.document.data.title')),
    playlists_search_description: card.items.map(item =>
      get(item, 'playlists.document.data.description.text')
    ),
    playlists_search_author: card.items.map(item =>
      get(item, 'playlists.document.data.author.document.data.name')
    ),
    url: card.items && card.items[0].playlists.document.url,
    playlists: card.items.map(item => ({
      title: get(item, 'playlists.document.data.title', ''),
      author: get(item, 'playlists.document.data.author.document.data.name', ''),
      cover_image: get(item, 'playlists.document.data.cover_image', ''),
      description: get(item, 'playlists.document.data.description.text', ''),
      isAudio: boolFlagFromString(get(item, 'playlists.document.data.label_is_audio', 'false')),
      isNew: boolFlagFromString(get(item, 'playlists.document.data.label_is_new', 'false')),
      isPremium: boolFlagFromString(get(item, 'playlists.document.data.label_is_premium', 'false')),
      isProducer: boolFlagFromString(
        get(item, 'playlists.document.data.label_is_producer', 'false')
      ),
      color: card.primary.card_color,
      uid: get(item, 'playlists.document.uid', ''),
      url: get(item, 'playlists.document.url', ''),
      length_in_seconds: item.playlists.document.data.videos.reduce(
        (acc, video) => acc + video.video.document.data.length_in_seconds,
        0
      )
    }))
  }));

  return cards || {};
};

export default prismicData => ({
  hero: extractHeroData(prismicData),
  grid: {
    grid_title: get(prismicData, 'prismicCuration.data.grid_title.text'),
    cards: extractCards(prismicData)
  },
  seo: {
    seo_title: get(prismicData, 'prismicCuration.data.seo_title'),
    seo_description: get(prismicData, 'prismicCuration.data.seo_description'),
    seo_keywords: get(prismicData, 'prismicCuration.data.seo_keywords'),
    seo_image: get(prismicData, 'prismicCuration.data.seo_image.url')
  }
});
