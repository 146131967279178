import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: props => ({
    borderBottom: `1px solid ${props.lineColor}`,
    width: '100%'
  }),
  content: props => ({
    color: props.textColor,
    padding: '0 10px 0 10px'
  })
}));

const TextDivider = ({ children, lineColor, textColor }) => {
  const classes = useStyles({ lineColor, textColor });
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};

TextDivider.propTypes = {
  children: PropTypes.node.isRequired,
  lineColor: PropTypes.string,
  textColor: PropTypes.string
};

TextDivider.defaultProps = {
  lineColor: '#E2E2E2',
  textColor: '#000000'
};

export default TextDivider;
