import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const ButtonSubmit = React.forwardRef(({ children, ...rest }, ref) => (
  <Box py={1}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      ref={ref}
      {...rest}
    >
      {children}
    </Button>
  </Box>
));

ButtonSubmit.propTypes = {
  children: PropTypes.node.isRequired
};

export default ButtonSubmit;
