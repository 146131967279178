import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import AnimatedProgressBar from '~components/AnimatedProgressBar';
import ABLogo from '~images/ab-logo-blue.svg';
import { redirect } from '~utils/redirect-helper';

const useStyles = makeStyles(theme => ({
  body: {
    padding: '45px 52px 52px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
    [theme.breakpoints.down('xs')]: {
      padding: '45px 24px'
    }
  },
  logo: {
    maxWidth: '47px',
    margin: 'auto',
    marginBottom: '12px'
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    color: '#3A3937',
    fontSize: 26,
    fontFamily: 'Inter',
    fontWeight: '700',
    lineHeight: '120%'
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    color: '#3A3937',
    fontSize: 18,
    fontFamily: 'Inter'
  },
  dialogStyle: {
    '& > .MuiBackdrop-root': {
      backgroundColor: '#222222 !important',
      opacity: '0.9 !important'
    }
  }
}));

const RedirectWithNotice = ({ url, title, description, seconds }) => {
  const classes = useStyles();

  useEffect(() => {
    // let's redirect after given seconds
    const timeoutId = null;
    if (typeof window !== 'undefined') {
      window.setTimeout(() => {
        redirect(url);
      }, seconds * 1000);
    }
    return () => timeoutId && window.clearTimeout(timeoutId);
  }, [url, seconds]);

  return (
    <Dialog
      aria-labelledby="loading-members-page"
      open
      className={classes.dialogStyle}
      PaperProps={{
        style: { borderRadius: '8px' }
      }}
    >
      <div className={classes.body}>
        <img src={ABLogo} alt="Aleph Beta Loading" className={classes.logo} />
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.description}>{description}</p>
      </div>
      <AnimatedProgressBar seconds={seconds} />
    </Dialog>
  );
};

RedirectWithNotice.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  seconds: PropTypes.number
};

RedirectWithNotice.defaultProps = {
  title: 'Please wait a moment...',
  description: 'We are redirecting you to the respective page.',
  seconds: 5
};

export default RedirectWithNotice;
