import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  carousel: {
    width: '100%',
    '& .slick-list': {
      padding: '8px 0',
      position: 'unset',
      paddingTop: '40px',
      paddingBottom: '40px'
    },
    '& .slick-track': {
      display: 'flex !important',
      gap: 30,
      position: 'unset',
      marginLeft: 0,
      marginRight: 0
    },
    '& .slick-slider': {
      position: 'unset'
    },
    '& .slick-slide': {
      height: 'inherit !important',
      '& > :first-child': {
        height: '100%'
      }
    },
    '& .slick-dots': {
      '& > li': {
        margin: 0
      },
      '& > li button:before': {
        color: props => props.dotColor,
        fontSize: 10,
        opacity: 1
      },
      '& > li.slick-active button:before': {
        color: props => `${props.activeDotColor} !important`,
        opacity: 1
      }
    }
  }
});

const BaseSlider = React.forwardRef(({ children, dots, dotColor, activeDotColor }, ref) => {
  const classes = useStyles({ dotColor, activeDotColor });

  const carouselSettings = {
    className: classes.carousel,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: false,
    swipeToSlide: true,
    infinite: true,
    arrows: false,
    dots
  };

  return (
    <Slider ref={ref} {...carouselSettings}>
      {children}
    </Slider>
  );
});

BaseSlider.propTypes = {
  children: PropTypes.node.isRequired,
  dots: PropTypes.bool,
  dotColor: PropTypes.string,
  activeDotColor: PropTypes.string
};

BaseSlider.defaultProps = {
  dots: false,
  dotColor: '#BFE0FF',
  activeDotColor: '#397BB9'
};

export default BaseSlider;
