import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import SlideNavButton from '~components/buttons/SlideNavButton/SlideNavButton';
import Container from '~components/Container';
import BaseSlider from './BaseSlider';
import TestimonialCard from './TestimonialCard';

export const DEFAULT_VARIANTS = [
  { backgroundColor: '#397BB9', color: '#FFFFFF' },
  { backgroundColor: '#F8A39B', color: '#FFFFFF' },
  { backgroundColor: '#FFE4D6', color: '#000000' },
  { backgroundColor: '#B4DAFD', color: '#000000' }
];
const ROTATIONS = [-4, 8, -3.5, 6.3]; // amount of degress which cards should rotate

const TestimonialCarousel = ({ testimonials, variants, dotColor, activeDotColor }) => {
  const theme = useTheme();
  const sliderRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const colorVariants = variants?.length > 0 ? variants : DEFAULT_VARIANTS;

  const navigateNext = () => {
    sliderRef.current.slickNext();
  };

  const navigatePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <Grid container alignItems="stretch" gap={2}>
        <BaseSlider
          ref={sliderRef}
          dots={isMobile}
          dotColor={dotColor}
          activeDotColor={activeDotColor}
        >
          {testimonials.map((card, index) => (
            <TestimonialCard
              key={card?.author}
              {...card}
              variant={colorVariants[index % colorVariants.length]}
              rotation={ROTATIONS[index % ROTATIONS.length]}
            />
          ))}
        </BaseSlider>
      </Grid>
      {!isMobile && (
        <Container>
          <Box
            component="div"
            display="flex"
            justifyContent="flex-end"
            width="100%"
            marginTop="15px"
          >
            <Box component="div" display="flex" gridGap={14}>
              <SlideNavButton onClick={navigatePrev} direction="prev" />
              <SlideNavButton onClick={navigateNext} direction="next" />
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

TestimonialCarousel.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      testimonial: PropTypes.string,
      author: PropTypes.string,
      designation: PropTypes.string
    })
  ).isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string,
      color: PropTypes.string
    })
  ),
  dotColor: PropTypes.string,
  activeDotColor: PropTypes.string
};

TestimonialCarousel.defaultProps = {
  variants: null,
  dotColor: '#BFE0FF',
  activeDotColor: '#397BB9'
};

export default TestimonialCarousel;
