import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Typography } from '@material-ui/core';
import WhitePlayButton from '~images/buttons/play_btn_white.svg';
import { getPlaylistTypeIcon, getPlaylistType } from '~utils/playlist-helpers';

const useStyles = makeStyles(() => ({
  description: {
    position: 'absolute',
    width: '100%',
    bottom: 25,
    left: 0,
    zIndex: 20,
    paddingLeft: 25,
    paddingRight: 25,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 4
  },
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '16px',
    color: '#FFFFFF',
    maxWidth: '485px'
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#FFFFFF',
    maxWidth: '485px'
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, transparent, transparent 30%, rgba(0, 0, 0, 0.8))',
    borderRadius: 13,
    border: '1px solid #FFFFFF'
  },
  videoInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 20
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 20
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    marginBottom: '20px'
  },
  infoText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    color: '#C9C9C9',
    marginBottom: 0
  },
  author: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    color: '#C9C9C9',
    margin: 'auto',
    textAlign: 'left',
    marginTop: '4px'
  },
  resourceInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gridGap: '8px',
    justifyContent: 'center',
    marginTop: '16px'
  }
}));

const DesktopDescription = ({ title, description, author, videoCount, isAudio, duration }) => {
  const classes = useStyles();

  const resourceType = getPlaylistType(videoCount, isAudio);
  const part = videoCount > 1 ? `Part 1 of ${videoCount}` : '';
  const typeIcon = getPlaylistTypeIcon(resourceType);

  return (
    <>
      <div className={classes.description}>
        <Box display="flex" flexDirection="column" gridRowGap="4px">
          <Typography component="h3" className={classes.titleText}>
            {title}
          </Typography>
          <Typography component="p" className={classes.descriptionText}>
            {description}
          </Typography>
        </Box>
        <div className={classes.videoInfo}>
          <div className={classes.cardFooter}>
            <div>
              {(resourceType || part || duration) && (
                <span className={classes.resourceInfo}>
                  <img src={typeIcon} alt="icon" />
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    component="p"
                    className={classes.infoText}
                  >
                    {[resourceType, part, duration].filter(Boolean).join(' • ')}
                  </Typography>
                </span>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.author}
              >
                {author}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <img className={classes.playBtn} src={WhitePlayButton} alt="play button active" />
      <div className={classes.overlay} />
    </>
  );
};

DesktopDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  author: PropTypes.string,
  videoCount: PropTypes.number.isRequired,
  isAudio: PropTypes.bool,
  duration: PropTypes.string
};

DesktopDescription.defaultProps = {
  description: '',
  author: '',
  isAudio: false,
  duration: ''
};

export default DesktopDescription;
