import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useApi, { GET_CONTINUE_WATCHING, GET_PLAYLISTS_PROGRESS } from '~hooks/useApi';
import LoadingSpinner from '~components/LoadingSpinner';
import Playlists from './playlists';
import ModernPlaylists from './modernPlaylists';

const THEMES = {
  MODERN_LIGHT: 'Modern Light',
  DEFAULT: 'Default'
};

const ContinueWatchingSection = ({ theme, ...rest }) => {
  if (theme === THEMES.MODERN_LIGHT) {
    return <ModernPlaylists {...rest} section_title="<h2>Continue Watching</h2>" />;
  }
  return <Playlists {...rest} section_title="<h2> Continue Watching... </h2>" />;
};

ContinueWatchingSection.propTypes = {
  theme: PropTypes.oneOf(Object.values(THEMES)).isRequired
};

const useStyles = makeStyles({
  root: {
    backgroundColor: props => props.backgroundColor || 'transparent'
  }
});

const PlaylistContinueWatching = ({ theme, hasPromotionalCard }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [continueWatchingState, getContinueWatching] = useApi();
  const [continueWatchingProgressState, getContinueWatchingProgress] = useApi();

  const { data: continueWatchingPlaylists } = continueWatchingState;
  const { data: continueWatchingProgress, isLoading: isContinueWatchingProgressLoading } =
    continueWatchingProgressState;
  const backgroundColor = theme === THEMES.MODERN_LIGHT ? '#F6F6F6' : 'transparent';
  const classes = useStyles({ backgroundColor });

  useEffect(() => {
    if (isVisible) {
      if (!continueWatchingPlaylists) {
        const continueWatchingParams = GET_CONTINUE_WATCHING();
        getContinueWatching(continueWatchingParams);
      } else {
        const playlistIds = continueWatchingPlaylists.reduce(
          (acc, currentPlaylist) => [...acc, currentPlaylist.prismic_id],
          []
        );
        if (playlistIds.length > 0) {
          const continueWatchingProgressParams = GET_PLAYLISTS_PROGRESS({
            playlistIds
          });
          getContinueWatchingProgress(continueWatchingProgressParams);
        }
      }
    }
  }, [continueWatchingPlaylists, getContinueWatching, getContinueWatchingProgress, isVisible]);

  const onVisibilityChange = visibility => {
    if (visibility === true) {
      setIsVisible(visibility);
    }
  };

  const hasFinishedLoading =
    continueWatchingPlaylists && (continueWatchingProgress || !isContinueWatchingProgressLoading);

  const hasRequiredData = continueWatchingPlaylists && continueWatchingProgress;
  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <div className={classes.root}>
        {!hasFinishedLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {hasRequiredData && (
              <ContinueWatchingSection
                theme={theme}
                progress={continueWatchingProgress.progress}
                hasPromotionalCard={hasPromotionalCard}
                playlists={continueWatchingPlaylists}
              />
            )}
          </>
        )}
      </div>
    </VisibilitySensor>
  );
};

PlaylistContinueWatching.propTypes = {
  hasPromotionalCard: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(THEMES))
};

PlaylistContinueWatching.defaultProps = {
  hasPromotionalCard: false,
  theme: 'Default'
};

export default PlaylistContinueWatching;
