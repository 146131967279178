import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import { calculateVideoContentType } from '~utils/playlist-helpers';

const Popups = loadable(() => import('~layout/Popups'));
const MediaPlayer = loadable(() => import('~components/media-player'));
const Transcript = loadable(() => import('./transcript'));
const Container = loadable(() => import('~components/Container'));
const MaterialsExpansionPanel = loadable(() => import('./materials-expansion-panel'));
const PlaylistMenu = loadable(() => import('./playlist-menu'));
const Title = loadable(() => import('./title'));
const Author = loadable(() => import('./author'));
const HtmlExcerpt = loadable(() => import('~components/HtmlExcerpt'));
const Divider = loadable(() => import('~components/Divider'));
const SkipCtaButton = loadable(() => import('./skip-cta-button'));
const CommentRestrictionBox = loadable(() => import('./comment-restriction-box'));
const PodcastSubscriptionLinks = loadable(() =>
  import('~components/quarantine/PodcastSubscriptionsLinks')
);
const GiftAcknowledgeBanner = loadable(() => import('./gift-acknowledge-banner'));
const Comments = loadable(() => import('~components/comments'), {
  resolveComponent: components => components.Comments
});

const PlaylistLayout = ({
  isPlaying,
  seekToSecond,
  currentVideo,
  handlers,
  popup,
  isPopupOpen,
  title,
  hasPodcastSubscriptionLink,
  author,
  materials,
  currentVideoIndex,
  hasUserSufficientAccess,
  videos,
  nextPlaylist,
  prismicId,
  thumbnail,
  isSingleVideo,
  uid,
  isNotAllowedToComment,
  suggestedPlaylists,
  hasPromotionalVideo,
  promoVideoId,
  labels,
  senderFullName,
  isGiftPlaylist,
  giftToken,
  isGiftBannerOpen,
  isSkipPromotionalButtonOpen,
  playlistUid
}) => {
  const {
    onReady,
    onStart,
    onProgress,
    onEnded,
    handleBlockingPopupClose,
    showContentBlockingPopup,
    setCurrentVideo,
    onPromotionalProgress,
    onPromotionEnded,
    showGiftPopup,
    handleGiftThankYouClick,
    handleCloseGiftBanner,
    toggleVideoPlayState
  } = handlers;
  const hasMaterials = materials && materials.length > 0;

  const contentType = calculateVideoContentType(isSingleVideo, labels.isAudio);

  return (
    <Container center size={100}>
      <Box py={2}>
        <Grid container direction="row" justify="space-between" alignItems="stretch">
          <Grid item xs={12} sm={8} md={9}>
            {/* Video player and details */}
            <>
              {/* Container for the positioning of the "In Video Popups". This is the only way to position the soft-sale banner as a border of the video player on all desktop screens without media queries */}
              <Box position="relative" width="100%">
                {hasPromotionalVideo && (
                  <MediaPlayer
                    playing
                    url={promoVideoId}
                    isPromotionalVideo
                    onEnded={onPromotionEnded}
                    onProgress={onPromotionalProgress}
                  />
                )}
                {!hasPromotionalVideo && (
                  <MediaPlayer
                    playing={isPlaying}
                    seekToSecond={seekToSecond}
                    url={currentVideo.wistia_url.url}
                    onReady={onReady}
                    onStart={onStart}
                    onProgress={onProgress}
                    onEnded={onEnded}
                  />
                )}

                <Popups
                  which={popup}
                  isPopupOpen={isPopupOpen}
                  handleClose={handleBlockingPopupClose}
                  playlistId={prismicId}
                  playlistSlug={uid}
                  thumbnail={thumbnail}
                  contentType={contentType}
                  playlistTitle={title}
                  videoTitle={currentVideo.title}
                  author={author.name}
                  senderFullName={senderFullName}
                  giftToken={giftToken}
                  toggleVideoPlayState={toggleVideoPlayState}
                />
                {isSkipPromotionalButtonOpen && <SkipCtaButton handleClick={onPromotionEnded} />}
              </Box>

              <Title
                playlistTitle={title}
                videoTitle={currentVideo.title}
                labels={labels}
                contentType={contentType.toLowerCase()}
                showGiftPopup={showGiftPopup}
              />

              {hasPodcastSubscriptionLink && <PodcastSubscriptionLinks pt={3} />}
              <Divider />

              <Author {...author} />

              <HtmlExcerpt html={currentVideo.description.html} />

              <Divider />
              {hasMaterials && (
                <>
                  <MaterialsExpansionPanel materials={materials} title="Materials" />
                  <Divider />
                </>
              )}

              <Transcript
                transcript={currentVideo.transcript.html}
                uid={currentVideo.uid}
                hasUserSufficientAccess={hasUserSufficientAccess}
                handleInsufficientAccess={showContentBlockingPopup}
                title={title}
              />

              <CommentRestrictionBox />

              <Hidden smUp>
                <PlaylistMenu
                  playlistUid={playlistUid}
                  currentVideoIndex={currentVideoIndex}
                  setCurrentVideo={setCurrentVideo}
                  videos={videos}
                  nextPlaylist={nextPlaylist}
                  suggestedPlaylists={suggestedPlaylists}
                  labels={labels}
                />
              </Hidden>

              <Hidden xsDown>
                <Comments
                  playlistId={prismicId}
                  playlistUid={uid}
                  videoId={currentVideo.prismicId}
                  isNotAllowedToComment={isNotAllowedToComment}
                  hasUserSufficientAccess={hasUserSufficientAccess}
                  handleInsufficientAccess={showContentBlockingPopup}
                />
              </Hidden>
            </>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            {/* Playlist with next videos */}
            <>
              <Hidden smUp>
                <Comments
                  playlistId={prismicId}
                  playlistUid={uid}
                  videoId={currentVideo.prismicId}
                  isNotAllowedToComment={isNotAllowedToComment}
                />
              </Hidden>
              <Hidden xsDown>
                <PlaylistMenu
                  playlistUid={playlistUid}
                  currentVideoIndex={currentVideoIndex}
                  setCurrentVideo={setCurrentVideo}
                  videos={videos}
                  nextPlaylist={nextPlaylist}
                  suggestedPlaylists={suggestedPlaylists}
                  labels={labels}
                />
              </Hidden>
            </>
          </Grid>
        </Grid>
      </Box>

      <GiftAcknowledgeBanner
        handleGiftThankYouClick={handleGiftThankYouClick}
        handleCloseGiftBanner={handleCloseGiftBanner}
        senderFullName={senderFullName}
        isGiftPlaylist={isGiftPlaylist}
        isGiftBannerOpen={isGiftBannerOpen}
      />
    </Container>
  );
};

PlaylistLayout.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  seekToSecond: PropTypes.number,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    wistia_url: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  handlers: PropTypes.shape({
    onReady: PropTypes.func.isRequired,
    onStart: PropTypes.func.isRequired,
    onProgress: PropTypes.func.isRequired,
    onEnded: PropTypes.func.isRequired,
    handleBlockingPopupClose: PropTypes.func.isRequired,
    showContentBlockingPopup: PropTypes.func.isRequired,
    setCurrentVideo: PropTypes.func.isRequired,
    onPromotionalProgress: PropTypes.func.isRequired,
    onPromotionEnded: PropTypes.func.isRequired,
    showGiftPopup: PropTypes.func.isRequired,
    handleGiftThankYouClick: PropTypes.func,
    handleCloseGiftBanner: PropTypes.func.isRequired,
    toggleVideoPlayState: PropTypes.func.isRequired
  }).isRequired,
  popup: PropTypes.number,
  isPopupOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  hasPodcastSubscriptionLink: PropTypes.bool.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  currentVideoIndex: PropTypes.number.isRequired,
  hasUserSufficientAccess: PropTypes.bool.isRequired,
  videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nextPlaylist: PropTypes.shape({}),
  prismicId: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  isNotAllowedToComment: PropTypes.bool.isRequired,
  suggestedPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  hasPromotionalVideo: PropTypes.bool.isRequired,
  promoVideoId: PropTypes.string,
  thumbnail: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string }).isRequired,
  isSingleVideo: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  senderFullName: PropTypes.string,
  isGiftPlaylist: PropTypes.bool,
  giftToken: PropTypes.string,
  isGiftBannerOpen: PropTypes.bool.isRequired,
  isSkipPromotionalButtonOpen: PropTypes.bool.isRequired,
  playlistUid: PropTypes.string.isRequired
};
PlaylistLayout.defaultProps = {
  seekToSecond: undefined,
  nextPlaylist: null,
  suggestedPlaylists: [],
  materials: [],
  isPopupOpen: false,
  isGiftPlaylist: false,
  popup: undefined,
  promoVideoId: null,
  senderFullName: null,
  giftToken: null
};

export default PlaylistLayout;
