import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(() => ({
  backdrop: props => ({
    background: props.backdrop ? props.backdrop : 'rgba(115, 178, 232, 0.96)'
  }),
  paper: {
    overflowY: 'visible',
    background: props => props.background,
    boxShadow: 'none',
    borderRadius: '4px',
    width: '100%',
    margin: '65px 12px',
    padding: '0'
  }
}));

const Dialog = ({ children, background, backdrop, isOpen, ...rest }) => {
  const classes = useStyles({ background, backdrop });
  return (
    <MuiDialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      BackdropProps={{
        className: classes.backdrop
      }}
      PaperProps={{
        className: classes.paper
      }}
      scroll="body"
      {...rest}
    >
      {children}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  isOpen: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

Dialog.defaultProps = {
  background: '#ffffff',
  isOpen: false,
  backdrop: false
};

export default Dialog;
