import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import Link from '@material-ui/core/Link';

import { AppStore, GooglePlay } from '~components/social/MobileApps';

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(),
    borderRadius: 4
  },
  guide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pdfIcon: {
    marginRight: theme.spacing()
  }
}));

const Description = ({ title, date, verses, guides }) => {
  const classes = useStyles();
  return (
    <div>
      <Box textAlign="center" mt={4} mb={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
      </Box>

      <Box textAlign="center" mb={4}>
        <Typography variant="h6" color="primary" gutterBottom>
          Date
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {date}
        </Typography>
      </Box>
      <Box textAlign="center" mb={4}>
        <Typography variant="h6" color="primary" gutterBottom>
          Torah Verses
        </Typography>
        {verses && (
          <Typography variant="subtitle1" gutterBottom>
            {verses}
          </Typography>
        )}
      </Box>

      <Box textAlign="center" mb={4}>
        <Typography variant="h6" color="primary" gutterBottom>
          <Chip size="small" label="PREMIUM" color="primary" className={classes.chip} />
          Printable Guides
        </Typography>
        {guides &&
          guides.map(({ title: guideTitle, url }) => (
            <Link
              key={guideTitle}
              variant="subtitle1"
              component={GatsbyLink}
              to={url}
              className={classes.guide}
              gutterBottom
            >
              <PDFIcon className={classes.pdfIcon} />
              {guideTitle}
            </Link>
          ))}
      </Box>

      <Box textAlign="center" mb={4}>
        <Typography variant="h6" color="primary" gutterBottom>
          Listen to Torah On-The-Go
        </Typography>
        <Box display="inline-block" m={1}>
          <AppStore />
        </Box>
        <Box display="inline-block" m={1}>
          <GooglePlay />
        </Box>
      </Box>
    </div>
  );
};

Description.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  verses: PropTypes.string,
  guides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired
    })
  ).isRequired
};
Description.defaultProps = {
  verses: null
};

export default Description;
