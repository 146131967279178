import React from 'react';
import cn from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import BottomShapeDividerSrc from './images/pc-page-shape-divider-1.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E9E9FB',
    position: 'relative'
  },
  leftImage: {
    position: 'absolute',
    top: 30,
    left: 0
  },
  rightImage: {
    position: 'absolute',
    top: 30,
    right: 0
  },
  container: {
    alignItems: 'center !important',
    paddingTop: '24px',
    paddingBottom: '48px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '40px',
      paddingBottom: '40px'
    }
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '772px',
    gap: '0px',
    textAlign: 'center',
    marginBottom: '26px'
  },
  sectionHeading: {
    fontSize: '44px',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    color: '#1D1927',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2)
    }
  },
  heroTextGap: {
    marginBottom: '18px'
  },
  heroTextParagraph: {
    color: '#4C5766',
    fontSize: '18px',
    fontFamily: 'Inter',
    lineHeight: '170%',
    letterSpacing: '0px'
  },
  heroTextBold: {
    color: '#030053',
    fontSize: '18px',
    fontFamily: 'Inter',
    fontWeight: '700',
    lineHeight: '170%',
    letterSpacing: '0px',
    [theme.breakpoints.down(1438)]: {
      maxWidth: '700px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset'
    }
  }
}));

const ProducersCircleIntro = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.textArea}>
          <Typography variant="h3" component="h1" className={classes.sectionHeading}>
            Why Join The Circle?
          </Typography>

          <Typography
            variant="subtitle1"
            className={cn(classes.heroTextParagraph, classes.heroTextGap)}
            gutterBottom
          >
            Your curiosity, your thirst for wisdom, and your love for Torah could help shape the
            future of Biblical learning. Aleph Beta’s mission is to enable people to fall in love
            with the Torah’s beauty, meaning, and relevance.
          </Typography>

          <Typography variant="subtitle1" className={classes.heroTextParagraph}>
            A patron is our highest membership tier at Aleph Beta.
          </Typography>
          <Typography variant="subtitle1" className={classes.heroTextBold}>
            As a Patron of Aleph Beta, you’ll do more than just support our mission - you’ll become
            an integral part of it.
          </Typography>
        </div>
      </Container>
      <BottomShapeDivider src={BottomShapeDividerSrc} bgColor="transparent" flex />
    </div>
  );
};

ProducersCircleIntro.propTypes = {};

export default ProducersCircleIntro;
