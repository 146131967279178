import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Link from '~components/Link';
import { pushDataLayerEvent } from '~utils/data-layer';
import CardBackgroundImage from '~images/black-friday/black-friday-background.svg';
import BagsImage from '~images/black-friday/black-friday-bags-with-text.svg';
import { BLACK_FRIDAY_ANALYTICS_EVENT, BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH } from '../constants';
import useTargetAudience from '../useTargetAudience';

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
    height: '222px',
    backgroundImage: `url(${CardBackgroundImage})`,
    backgroundPositionX: 'center',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20
  },
  image: {
    maxHeight: '117px'
  },
  ctaButton: {
    padding: '8px 25px',
    textTransform: 'none',
    letterSpacing: 0,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '146%',
    textAlign: 'center',
    borderRadius: '30px',
    color: '#FFFFFF',
    backgroundColor: '#1775FB',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#1775FB',
      textDecoration: 'none'
    }
  }
});

const BlackFridayRedeemCard = () => {
  const classes = useStyles();
  const isBlackFridayTargetAudience = useTargetAudience();

  const onCardClick = () => {
    // adding analytics with ga4 custom event since utm parameters are not working with same site navigation
    pushDataLayerEvent(BLACK_FRIDAY_ANALYTICS_EVENT, { source: 'playlist-page-card' });
  };

  if (!isBlackFridayTargetAudience) {
    return false;
  }

  return (
    <Link to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH} underline="none" onClick={onCardClick}>
      <div className={classes.root}>
        <img className={classes.thumb} src={BagsImage} alt="black friday offer" />
        <Button
          component={Link}
          className={classes.ctaButton}
          to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH}
        >
          Redeem Offer
        </Button>
      </div>
    </Link>
  );
};

BlackFridayRedeemCard.propTypes = {};

BlackFridayRedeemCard.defaultProps = {};

export default BlackFridayRedeemCard;
