import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';
import Link from '~components/Link';

const Item = ({ image, title, description }) => (
  <>
    <img src={image.src} alt={image.alt} title={title} />
    <Box mt={2} mb={2}>
      <Typography variant="h4">{title}</Typography>
    </Box>
    <Typography variant="subtitle1">{description}</Typography>
  </>
);

Item.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const About = ({ title, items, cta_text, cta_link }) => (
  <Container center size={100} background="rgb(247, 249, 251)">
    <Box mt={4} mb={8} textAlign="center">
      <Typography variant="h3">{title}</Typography>
    </Box>

    <Box
      component={Grid}
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
      textAlign="center"
      spacing={2}
    >
      {items &&
        items.map(item => (
          <Grid item xs={12} lg={4} key={item.title}>
            <Item {...item} />
          </Grid>
        ))}
    </Box>

    <Box mt={8} mb={4}>
      <Link variant="h6" to={cta_link}>
        {cta_text}
      </Link>
    </Box>
  </Container>
);

About.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(Item.propTypes)).isRequired,
  cta_text: PropTypes.string.isRequired,
  cta_link: PropTypes.string.isRequired
};

export default About;
