/* eslint-disable import/prefer-default-export */
import { get as lodashGet } from 'lodash';

/**
 * Wrapper around `lodash/get` which behaves in the same way except `null` values.
 * If the value found is `null` we still return `defaultValue`.
 * Original `lodash/get` returns `defaultValue` only if the value found is `undefined` exactly.
 *
 * @param {*} container Anything object-like which lodash/get can handle
 * @param {*} query Query string like `object.property.property` to dig into the `container`.
 * @param {*} defaultValue Default value to use if the value found is `undefined` OR `null` exactly. Other false-y values are returned as is.
 */
export const get = (container, query, defaultValue = {}) => {
  const maybeValue = lodashGet(container, query, defaultValue); // NOTE: `lodash.get` returns defaultValue ONLY if the found value is exactly `undefined`.
  if (maybeValue === null) {
    return defaultValue;
  }
  return maybeValue;
};
