import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { pushDataLayerEvent } from '~utils/data-layer';
import { VIEWS } from './constants';
import ViewLayout from './ViewLayout';

const TRANSITION_PERIOD_IN_MS = 400;

const useStyles = makeStyles(theme => ({
  contentContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    height: '82vh',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#FFF9F5',
      height: '92vh',
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  contetBackground: {
    height: '100vh',
    width: '150%',
    position: 'absolute',
    top: '0px',
    left: '-25%',
    borderRadius: '50%',
    backgroundColor: '#FFF9F5',
    zIndex: '-1',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  animateIn: {
    animation: `$slideIn ${TRANSITION_PERIOD_IN_MS}ms`,
    animationFillMode: 'forwards'
  },
  animateOut: {
    animation: `$slideOut ${TRANSITION_PERIOD_IN_MS}ms`,
    animationFillMode: 'forwards'
  },
  '@keyframes slideIn': {
    '0%': {
      bottom: '-75vh'
    },
    '100%': {
      bottom: 0
    }
  },
  '@keyframes slideOut': {
    '0%': {
      bottom: 0
    },
    '100%': {
      bottom: '-75vh'
    }
  }
}));

const GuestLoginPopup = ({ handlePopupClose }) => {
  const classes = useStyles();
  const [isClosing, setIsClosing] = useState(false);
  const [view, setView] = useState(VIEWS.INITIAL_VIEW);
  const [username, setUsername] = useState('');

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    pushDataLayerEvent('popup_viewed', { popup: 'guest_login_flow' });
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (isClosing) {
      timeoutId = window.setTimeout(() => {
        handlePopupClose();
      }, TRANSITION_PERIOD_IN_MS);
    }
    return () => timeoutId && window.clearTimeout(timeoutId);
  }, [isClosing, handlePopupClose]);

  const handleClose = () => {
    setIsClosing(true);
  };

  return (
    <div
      className={`${classes.contentContainer} ${
        isClosing ? classes.animateOut : classes.animateIn
      }`}
    >
      <div className={classes.contetBackground} />
      <Box component="div" marginTop="50px">
        <ViewLayout
          view={view}
          setView={setView}
          username={username}
          setUsername={setUsername}
          handleClose={handleClose}
        />
      </Box>
    </div>
  );
};

GuestLoginPopup.propTypes = {
  handlePopupClose: PropTypes.func.isRequired
};

export default GuestLoginPopup;
