import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import localStorage from 'store2';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import BlackFridayRedeemCard from '~components/black-friday/BlackFridayRedeemCard';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';

import Link from '~components/Link';
import Next from './Next';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);

const LOCAL_STORAGE_KEY = 'autoplay';

const useStyles = makeStyles(theme => ({
  headBox: {
    background: theme.palette.primary.main,
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textBold: {
    fontWeight: 'bold',
    borderBottom: '1px solid grey'
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  },
  imgStyles: {
    display: 'flex',
    maxWidth: '100%'
  },
  iconPosition: {
    marginTop: '-2px'
  },
  tooltip: {
    fontSize: '11px',
    padding: '6px'
  },
  switchBase: {
    color: '#dcdcdc',
    '&$checked': {
      color: '#fff'
    },
    '&$checked + $track': {
      backgroundColor: '#ffffffa6'
    }
  },
  checked: {},
  track: {}
}));

const pluralize = (value, word, suffix = 's') => {
  if (value === 1) {
    return `${value} ${word}`;
  }
  return `${value} ${word}${suffix}`;
};

const PlaylistMenu = ({
  playlistUid,
  currentVideoIndex,
  videos,
  nextPlaylist,
  suggestedPlaylists,
  labels
}) => {
  const classes = useStyles();
  const [isAutoplayChecked, setIsAutoplayChecked] = useState(() =>
    localStorage.get(LOCAL_STORAGE_KEY) === null ? true : !!localStorage.get(LOCAL_STORAGE_KEY)
  );

  const autoplayStateDisplayName = isAutoplayChecked ? 'enabled' : 'disabled';

  useEffect(() => {
    // We autoplay should be enabled by default.
    if (localStorage.get(LOCAL_STORAGE_KEY) === null) {
      localStorage.set(LOCAL_STORAGE_KEY, true);
    }
  }, []);

  const handleAutoplayChange = event => {
    setIsAutoplayChecked(event.target.checked);
    localStorage.set(LOCAL_STORAGE_KEY, event.target.checked);
  };

  const contentTypeAsString = labels.isAudio ? 'episode' : 'video';
  const contentCollectionAsString = labels.isAudio ? 'Podcast' : 'Series';

  return (
    <Box>
      <Box p={2} className={classes.headBox}>
        <Typography variant="subtitle1">
          Up Next ({pluralize(videos.length, contentTypeAsString)} in {contentCollectionAsString})
        </Typography>

        <Tooltip
          title={`Autoplay for the next video or playlist is ${autoplayStateDisplayName}`}
          arrow
          classes={{ tooltip: classes.tooltip }}
        >
          <Switch
            checked={isAutoplayChecked}
            onChange={handleAutoplayChange}
            checkedIcon={<PlayCircleFilledIcon className={classes.iconPosition} />}
            icon={<PauseCircleFilledIcon className={classes.iconPosition} />}
            inputProps={{
              'aria-label': 'Toggle Autoplay'
            }}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track
            }}
          />
        </Tooltip>
      </Box>

      {/* Videos of the current playlist */}
      {videos &&
        videos.map((video, index) => (
          <Link
            key={video.title}
            className={classes.link}
            to={`/video/${playlistUid}/${videos[index]?.uid}`}
          >
            <Next index={index + 1} isCurrent={index === currentVideoIndex} {...video} />
          </Link>
        ))}

      {/* Next playlist which will autoplay after current ends */}
      {nextPlaylist && nextPlaylist.uid && (
        <Link to={`/playlist/${nextPlaylist.uid}`} className={classes.link}>
          <Next {...nextPlaylist} />
        </Link>
      )}

      {/* Playlist suggestions */}
      {!!suggestedPlaylists.length && (
        <Box p={1}>
          <Typography variant="h6" className={classes.textBold}>
            Suggested For You
          </Typography>
        </Box>
      )}

      {IS_BLACK_FRIDAY_OFFER_ENABLED && <BlackFridayRedeemCard />}

      {suggestedPlaylists &&
        suggestedPlaylists.map(playlist => (
          <Link
            key={playlist.title}
            to={`/playlist/${playlist.uid}`}
            variant="body1"
            className={classes.link}
          >
            <Next {...playlist} />
          </Link>
        ))}
    </Box>
  );
};

PlaylistMenu.propTypes = {
  currentVideoIndex: PropTypes.number.isRequired,
  videos: PropTypes.arrayOf(PropTypes.shape(Next.propTypes)),
  nextPlaylist: PropTypes.shape(Next.propTypes),
  suggestedPlaylists: PropTypes.arrayOf(PropTypes.shape(Next.propTypes)),
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired
  }).isRequired,
  playlistUid: PropTypes.string.isRequired
};

PlaylistMenu.defaultProps = {
  videos: [],
  nextPlaylist: null,
  suggestedPlaylists: []
};

export default PlaylistMenu;
