/**
 * @deprecated In Prismic plugin v5 this have been obsoleted by the "route resolver", see https://prismic.io/docs/route-resolver and https://github.com/prismicio/prismic-gatsby/blob/main/docs/technical-reference-gatsby-source-prismic-v5.md
 *
 * NOTE EXTREMELY IMPORTANT: every time you change anything in this code, you MUST copy this file to `members/src/utils/marketing-site-link-resolver.js`!
 *
 * @param doc Result of querying the Gatsby GraphQL data API in presence of the `gatsby-source-prismic` plugin. It MUST contain the `type` and `uid` fields at least, ensure that in your query for these objects.
 * @return {string} Path part of the URL to this document (e.g. `/podcast/into-the-verse`), MUST correspond to how the `gatsby-node.js` actually creates the pages!!!
 */
module.exports = doc => {
  const { type, uid } = doc;

  switch (type) {
    case 'book': {
      return `/torah/${uid}`;
    }
    case 'holiday_redesign': {
      return `/${uid}`;
    }
    case 'playlist': {
      return `/playlist/${uid}`;
    }
    case 'parshat': {
      return `/weekly-torah-portion/${uid}`;
    }
    case 'advanced': {
      return `/${uid}`;
    }
    case 'blog_post': {
      return `/blog/${uid}`;
    }
    case 'course': {
      return `/course/${uid}`;
    }
    case 'article': {
      const parentUid = doc.data?.parent_page?.uid;
      return `/${parentUid}/${uid}`;
    }
    case 'topic': {
      return `/${uid}`;
    }
    case 'curation': {
      return `/${uid}`;
    }
    case 'page': {
      const prefix = doc.data?.url_prefix || doc.data?.custom_url_prefix || '';
      const prefixWithoutTrailingSlashes = prefix.replace(/[/\\]+$/, '');

      return `${prefixWithoutTrailingSlashes}/${uid}`; // NOTE NO leading slash!!! Put it into the prefix!
    }
    case 'job_page': {
      return `/jobs/${uid}`;
    }
    case 'podcast_playlist': {
      return `/podcasts/${uid}`;
    }
    case 'podcast_episode': {
      const podcastId = doc.data?.podcast_playlist?.uid;
      return `/${podcastId}/${uid}`;
    }

    case 'video': {
      const { playlist_uid } = doc;
      return `/video/${playlist_uid}/${uid}`;
    }
    case 'material':
      return `/guides/${uid}`;
    case 'about': {
      return '/about';
    }
    case 'privacy': {
      return '/privacy';
    }
    case 'faq_page': {
      return '/faq';
    }
    case 'terms': {
      return '/terms';
    }
    case 'guides': {
      return '/guides';
    }
    case 'holidays': {
      return '/holidays';
    }
    case 'home': {
      return '/';
    }
    case 'producers_circle': {
      return '/patrons-circle';
    }
    case 'team_page': {
      return '/team';
    }
    case 'tisha_bav_home': {
      return '/';
    }
    case 'weekly_torah_portion': {
      return '/weekly-torah-portion';
    }

    default:
      return '/';
  }
};
