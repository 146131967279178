import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isIOS } from 'react-device-detect';
import ABLogo from '~images/popups/ab_mobile_blocker_logo.svg';
import ABAppIcon from '~images/ab-icon-with-bg.svg';
import Link from '~components/Link';
import BrowserIcon from '~images/popups/browser-icon.svg';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import clsx from '~styles/clsx';

export const REDIRECT_DATA = {
  iOSLink: 'https://apps.apple.com/app/apple-store/id598939260?pt=118164377&ct=PopupRedirects&mt=8',
  androidLink:
    'https://play.google.com/store/apps/details?id=org.alephbeta.android&referrer=utm_source%3DPopupRedirects%26utm_medium%3Dlink%26utm_campaign%3DPopupRedirects'
};

const TRANSITION_PERIOD_IN_MS = 400;

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    bottom: '0%',
    left: '0%',
    width: '100%',
    zIndex: 1450
  },
  root: {
    borderRadius: '48px 48px 0px 0px',
    backgroundColor: '#FFFFFF',
    padding: '18px 20px 23px',
    marginBottom: '-5px',
    overflow: 'hidden'
  },
  blockerBackground: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    bottom: '0%',
    left: '0%',
    backgroundColor: '#000000',
    opacity: 0.3,
    zIndex: 1440,
    overflow: 'hidden'
  },
  logo: {
    display: 'flex',
    height: '70px'
  },
  titleText: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 700,
    fontFamily: 'Inter',
    textAlign: 'center'
  },
  descriptionText: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    fontFamily: 'Inter',
    textAlign: 'center'
  },
  appText: {
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: 'Inter',
    whiteSpace: 'nowrap'
  },
  openAppBtn: {
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: 'Inter',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#278AE7',
    borderRadius: 76,
    width: '107px',
    padding: '8px 0px',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  continueAppBtn: {
    fontSize: '14px',
    lineHeight: '17px',
    fontFamily: 'Inter',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    borderRadius: 76,
    border: '1px solid #000000',
    width: '107px',
    padding: '8px 0px',
    textTransform: 'none'
  },
  animateIn: {
    animation: `$slideIn ${TRANSITION_PERIOD_IN_MS}ms`,
    animationFillMode: 'forwards'
  },
  animateOut: {
    animation: `$slideOut ${TRANSITION_PERIOD_IN_MS}ms`,
    animationFillMode: 'forwards'
  },
  '@keyframes slideIn': {
    '0%': {
      bottom: '-75vh'
    },
    '100%': {
      bottom: 0
    }
  },
  '@keyframes slideOut': {
    '0%': {
      bottom: 0
    },
    '100%': {
      bottom: '-75vh'
    }
  }
});

const MobileAppBlockerPopup = () => {
  const classes = useStyles();
  const { intercomInitialized } = useGlobalStore().state;
  const [continueBrowse, setContinueBrowse] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // let's open the popup after 2 seconds
    const timeoutId = window.setTimeout(() => {
      setIsOpen(true);
    }, 2000);
    return () => timeoutId && window.clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isOpen]);

  useEffect(() => {
    if (!continueBrowse && intercomInitialized) {
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: true
        });
      }
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: false
        });
      }
    };
  }, [continueBrowse, intercomInitialized]);

  const url = isIOS ? REDIRECT_DATA.iOSLink : REDIRECT_DATA.androidLink;

  const onContinueBrowser = () => {
    setContinueBrowse(true);
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className={classes.blockerBackground} />
      <div
        className={clsx(
          classes.container,
          !continueBrowse && classes.animateIn,
          continueBrowse && classes.animateOut
        )}
      >
        <div className={classes.root}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gridGap="24px"
            marginBottom="28px"
          >
            <Box width="fit-content">
              <img src={ABLogo} alt="Aleph Beta Mobile Revamped" className={classes.logo} />
            </Box>
            <Box display="flex" flexDirection="column" textAlign="center" gridGap="8px">
              <Typography component="h3" className={classes.titleText}>
                AlephBeta app has been revamped!
              </Typography>
              <Typography component="h3" className={classes.descriptionText}>
                The best experience is on the app
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gridGap="18px">
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" alignItems="center" gridColumnGap="13px">
                <img src={ABAppIcon} alt="Aleph Beta Mobile Logo" />
                <Typography component="h3" className={classes.appText}>
                  AlephBeta App
                </Typography>
              </Box>
              <Link id="app-blocker-open-app-btn" to={url} className={classes.openAppBtn}>
                Open App
              </Link>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" alignItems="center" gridColumnGap="13px">
                <img src={BrowserIcon} alt="Aleph Beta Mobile Logo" />
                <Typography component="h3" className={classes.appText}>
                  Browser
                </Typography>
              </Box>
              <ButtonBase
                id="app-blocker-continue-btn"
                onClick={onContinueBrowser}
                className={classes.continueAppBtn}
              >
                Continue
              </ButtonBase>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default MobileAppBlockerPopup;
