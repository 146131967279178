import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Container from '~components/Container';
import RichText from '~components/RichText';
import Link from '~components/Link';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: props => props.section_background_color || 'inherit'
  },
  contentArea: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '72px 0px 65px',
    padding: '40px 60px',
    borderRadius: '20px',
    backgroundColor: props => props.background_color || 'inherit',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '40px 0px'
    }
  },
  heroImage: {
    display: 'flex',
    maxWidth: '600px',
    maxHeight: '450px',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      maxWidth: '460px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px'
    }
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '645px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  topLabel: props => ({
    backgroundColor: props.top_label_background_color,
    color: props.top_label_text_color,
    position: 'absolute',
    top: '-20px',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: '700',
    padding: '12px 31px',
    fontFamily: 'Inter',
    width: 'fit-content',
    borderRadius: '5px',
    textAlign: 'center',
    margin: 0
  }),
  title: {
    margin: '20px 0px 10px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '317px'
    },
    '& > *': {
      color: props => props.text_color,
      fontSize: '40px',
      fontWeight: '600',
      fontFamily: 'Inter',
      lineHeight: '115%',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '35px'
      }
    }
  },
  description: {
    marginBottom: '26px',
    maxWidth: '509px',
    '& > *': {
      fontSize: '16px',
      lineHeight: '158%',
      fontFamily: 'Inter',
      fontWeight: '500',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: props => props.text_color
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '317px'
    }
  },
  cta: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '170%',
    textDecoration: 'underline'
  }
}));

const FeaturedCourse = ({
  featured_course_title,
  text_color,
  top_label,
  top_label_background_color,
  top_label_text_color,
  section_background_color,
  background_color,
  cta_text,
  cta_url,
  featured_course_description,
  featured_course_image,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    section_background_color,
    background_color,
    top_label_background_color,
    top_label_text_color,
    text_color
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  return (
    <div className={classes.root}>
      <Container center>
        <Link to={cta_url} className={classes.contentArea} underline="none">
          {top_label && <p className={classes.topLabel}>{top_label}</p>}
          <div className={classes.textArea}>
            {isMobile && (
              <img
                src={featured_course_image?.url}
                alt={featured_course_image?.alt || featured_course_title}
                className={classes.heroImage}
              />
            )}
            <RichText
              html={featured_course_title?.html}
              verticalSpacing={0}
              externalClassName={classes.title}
            />
            <RichText
              html={featured_course_description?.html}
              verticalSpacing={0}
              externalClassName={classes.description}
            />
            <Link to={cta_url} className={classes.cta}>
              {cta_text}
            </Link>
          </div>
          {!isMobile && (
            <img
              src={featured_course_image?.url}
              alt={featured_course_image?.alt || featured_course_title}
              className={classes.heroImage}
            />
          )}
        </Link>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

FeaturedCourse.propTypes = {
  featured_course_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  featured_course_description: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  section_background_color: PropTypes.string,
  background_color: PropTypes.string.isRequired,
  text_color: PropTypes.string.isRequired,
  top_label: PropTypes.string,
  top_label_background_color: PropTypes.string,
  top_label_text_color: PropTypes.string,
  cta_text: PropTypes.string.isRequired,
  cta_url: PropTypes.string.isRequired,
  featured_course_image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

FeaturedCourse.defaultProps = {
  section_background_color: '',
  top_label: '',
  top_label_background_color: '#FFFFFF',
  top_label_text_color: '#000000',
  bottom_shape_divider: null
};

export default FeaturedCourse;
