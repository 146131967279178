import { useEffect, useState } from 'react';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import {
  moveAuthTokenFromLocalStorageToCookieStorage,
  moveAuthTokenFromCookieStorageToLocalStorage
} from '~utils/request';

const IS_MARKETING_SITE = isFeatureEnabled(FEATURE_FLAGS.MARKETING_SITE);

const useConversionFramework = () => {
  const [isMarketingSite] = useState(IS_MARKETING_SITE);

  useEffect(() => {
    if (isMarketingSite) {
      // let's remove the local storage token if exists and set the token in cookie storage
      moveAuthTokenFromLocalStorageToCookieStorage();
    } else {
      // let's remove the cookie storage token if exists and set the token in local storage
      moveAuthTokenFromCookieStorageToLocalStorage();
    }
  }, [isMarketingSite]);

  return { isMarketingSite };
};

export default useConversionFramework;
