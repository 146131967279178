import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import useApi, { GET_PLAYLISTS_PROGRESS } from '~hooks/useApi';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import LoadingSpinner from '~components/LoadingSpinner';

const SliceToComponent = loadable(() => import('~components/slices/slices'), {
  fallback: (
    <div>
      <LoadingSpinner />
    </div>
  )
});
const Page = loadable(() => import('./Page'));

const collectPagePlaylistIds = (unfurlData = {}) => {
  const { slices } = unfurlData;
  if (!slices) {
    return [];
  }
  return slices
    .filter(slice => ['playlists', 'video_list'].includes(slice.slice_type))
    .map(slice => slice.items || [])
    .map(items =>
      items.reduce((ids, item) => (item.playlist ? [...ids, item.playlist.id] : ids), [])
    )
    .reduce((accumulator, element) => [...accumulator, ...element], []);
};

const PageWithSlices = ({
  slices,
  seo,
  listOfParshas,
  currentParsha,
  backgroundColor,
  globalProps,
  redirectLoggedInUsersToMembers,
  children
}) => {
  const { session } = useUserContext();
  const { isAdmin, isSubscriber, isLoggedIn, isUserStateLoading } = session;

  const [progressState, getProgress] = useApi();
  const { isLoading: isProgressLoading, data: progressData } = progressState;
  const playlistIds = collectPagePlaylistIds({ slices });

  useEffect(() => {
    if (!isLoggedIn || isUserStateLoading || isProgressLoading) {
      return;
    }

    if (!progressData && playlistIds && playlistIds.length > 0) {
      const progressParams = GET_PLAYLISTS_PROGRESS({ playlistIds });
      getProgress(progressParams);
    }
  }, [isLoggedIn, isProgressLoading, isUserStateLoading, playlistIds, progressData, getProgress]);

  const progress = (progressData && progressData.progress) || {};

  return (
    <Page
      seo={seo}
      redirectLoggedInUsersToMembers={redirectLoggedInUsersToMembers}
      backgroundColor={backgroundColor}
    >
      {children}
      <SliceToComponent
        slices={slices}
        listOfParshas={listOfParshas}
        currentParsha={currentParsha}
        progress={isLoggedIn ? progress : {}}
        isAdmin={isAdmin}
        isUserStateLoading={isUserStateLoading}
        isSubscriber={isSubscriber}
        isLoggedIn={isLoggedIn}
        globalProps={globalProps}
      />
    </Page>
  );
};

PageWithSlices.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  seo: PropTypes.shape({ seo_title: PropTypes.string, seo_description: PropTypes.string }),
  listOfParshas: PropTypes.arrayOf(PropTypes.shape({})),
  currentParsha: PropTypes.string,
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  globalProps: PropTypes.shape({}),
  redirectLoggedInUsersToMembers: PropTypes.bool
};

PageWithSlices.defaultProps = {
  seo: undefined,
  listOfParshas: null,
  currentParsha: null,
  children: null,
  backgroundColor: '',
  globalProps: null,
  redirectLoggedInUsersToMembers: false
};

export default PageWithSlices;
