import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { LabelPremium, LabelOverlay } from '~components/playlist-card/PlaylistLabels';
import Excerpt from '~components/Excerpt';
import Link from '~components/Link';
import { planIdFromString } from '~utils/playlist-helpers';
import ResourceIcon, { getResourceName } from '~components/resource-icon/ResourceIcon';
import authorizeUser from '~utils/authorization-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(theme => ({
  overlineLink: {
    textDecoration: 'none !important',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '& span': {
        textDecoration: 'underline'
      }
    }
  },
  overlineIcon: {
    marginRight: theme.spacing()
  },
  overlay: {
    position: 'absolute',
    bottom: 0
  }
}));

const ResourceItem = ({
  title,
  title_text,
  description,
  material_type,
  required_subscription_plan,
  description_text,
  url,
  typeName,
  file,
  slices
}) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isMarketingSite } = useConversionFramework();

  const materialDescription = description && description;
  const articleDescription = description_text && description_text.text;
  const holidayDescription = slices && slices[0]?.primary?.description;

  const descriptionToShow = materialDescription || articleDescription || holidayDescription;

  const requiredSubscriptionLevel = planIdFromString(required_subscription_plan);

  const { hasAccess, redirectPath, redirectProps } = authorizeUser(
    requiredSubscriptionLevel,
    session,
    false,
    isMarketingSite
  );

  const isMaterialPremium = required_subscription_plan === 'Premium';

  const redirectState = !hasAccess && { ...redirectProps };

  const href = file?.url;

  return (
    <Box>
      <Box mt={2} mb={2}>
        <Link
          variant="h6"
          color="primary"
          to={hasAccess ? href || url : redirectPath}
          target={href ? '_blank' : '_self'}
          className={classes.overlineLink}
          {...redirectState}
        >
          <ResourceIcon
            material_type={material_type || typeName}
            className={classes.overlineIcon}
          />
          <span>{getResourceName(material_type || typeName)}</span>
        </Link>
      </Box>

      <Box mt={2} mb={2}>
        <Link
          variant="h5"
          to={hasAccess ? href || url : redirectPath}
          target={href ? '_blank' : '_self'}
          color="textPrimary"
          {...redirectState}
        >
          {/* title?.text is solely added to support prismic preview. As prismic previews doesn't read written graphql queries.
          What it does is returning all the data relevant to the document.
          Graphql written for this specific slice has an alternative name for the title as title_text (for blogpost type references) which doesn't really known to prismic previews */}
          {title?.text || title || title_text.text}
        </Link>
      </Box>

      <Box mt={2} mb={2}>
        <Excerpt description={descriptionToShow?.text || descriptionToShow || ''} />
        <Box position="relative" pt={3}>
          {isMaterialPremium && (
            <LabelOverlay className={classes.overlay}>
              {isMaterialPremium && <LabelPremium />}
            </LabelOverlay>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ResourceItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  material_type: PropTypes.string,
  url: PropTypes.string.isRequired,
  typeName: PropTypes.string,
  description_text: PropTypes.shape(),
  title_text: PropTypes.shape(),
  slices: PropTypes.arrayOf(PropTypes.shape()),
  required_subscription_plan: PropTypes.string,
  file: PropTypes.shape({
    url: PropTypes.string
  })
};

ResourceItem.defaultProps = {
  title: null,
  required_subscription_plan: null,
  description: null,
  material_type: null,
  description_text: null,
  title_text: null,
  slices: null,
  typeName: null,
  file: {}
};

export default ResourceItem;
