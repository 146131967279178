import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const PopupCloseButton = loadable(() =>
  import('~modules/account/components/forms/shared/PopupCloseButton')
);

const GiftAcknowledgeBanner = ({
  handleGiftThankYouClick,
  handleCloseGiftBanner,
  senderFullName,
  isGiftPlaylist,
  isGiftBannerOpen
}) => {
  if (isGiftPlaylist && senderFullName && isGiftBannerOpen) {
    return (
      <Box position="fixed" bottom={0} p={4} width="100%" bgcolor="#fff" border="1px solid #848484">
        <>
          <Typography style={{ fontSize: '20px', textAlign: 'center' }}>
            Shared with you from <span style={{ fontWeight: 700 }}>{senderFullName}</span>, an Aleph
            Beta Subscriber.{' '}
            <Box
              display="inline-block"
              onClick={handleGiftThankYouClick}
              style={{
                color: '#438DCE',
                textDecoration: 'underline',
                fontWeight: 600,
                cursor: 'pointer'
              }}
            >
              Click here
            </Box>{' '}
            to say thank you!
          </Typography>
          <span style={{ flex: 1 }} />
          <PopupCloseButton
            handleClose={handleCloseGiftBanner}
            style={{ top: '22px', right: '72px' }}
          />
        </>
      </Box>
    );
  }

  return null;
};

GiftAcknowledgeBanner.propTypes = {
  handleGiftThankYouClick: PropTypes.func.isRequired,
  handleCloseGiftBanner: PropTypes.func.isRequired,
  senderFullName: PropTypes.string,
  isGiftPlaylist: PropTypes.bool,
  isGiftBannerOpen: PropTypes.bool.isRequired
};
GiftAcknowledgeBanner.defaultProps = {
  isGiftPlaylist: false,
  senderFullName: null
};

export default GiftAcknowledgeBanner;
