/* eslint-disable no-console */
import axios from 'axios';
import localStorage from 'store2';
import Cookies from 'js-cookie';
import { set, get } from 'lodash';

// marketing site specific auth token key to set in the cookie
const AUTH_COOKIE_KEY = 'auth-jwt';
const AUTH_DOMAIN = process.env.GATSBY_AUTH_DOMAIN;

// non marketing site specific auth token local storage key
const LOCAL_STORAGE_KEY = 'jwt';

// Key can be exist in local storage (old site) or cookie storage (new site)
const jwtToken = Cookies.get(AUTH_COOKIE_KEY) || localStorage.get(LOCAL_STORAGE_KEY);

export const isUserAuthTokenAvailable = () =>
  !!Cookies.get(AUTH_COOKIE_KEY) || !!localStorage.get(LOCAL_STORAGE_KEY);

export const moveAuthTokenFromLocalStorageToCookieStorage = () => {
  if (localStorage.has(LOCAL_STORAGE_KEY)) {
    const token = localStorage.get(LOCAL_STORAGE_KEY);
    Cookies.set(AUTH_COOKIE_KEY, token, { expires: 365, domain: AUTH_DOMAIN });
    localStorage.remove(LOCAL_STORAGE_KEY);
  }
};

export const moveAuthTokenFromCookieStorageToLocalStorage = () => {
  const tokenInCookie = Cookies.get(AUTH_COOKIE_KEY);
  if (tokenInCookie) {
    localStorage.set(LOCAL_STORAGE_KEY, tokenInCookie);
    Cookies.remove(AUTH_COOKIE_KEY);
  }
};

const API_URL = process.env.GATSBY_API_URL;

axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;

if (jwtToken) {
  axios.defaults.headers.Authorization = `Bearer ${jwtToken}`;
}

export const logError = error => {
  console.error(error);
  console.debug('axios request to API `error.response`', error.response);
  console.debug('axios request to API `error.request`', error.request);
  console.debug('axios request to API `error.config`', error.config);
};

export const setAuthHeader = (token, isMarketingSite = false) => {
  if (!token) {
    delete axios.defaults.headers.Authorization;
  } else {
    if (isMarketingSite) {
      // for marketing site, token is stored in the cookie storage
      Cookies.set(AUTH_COOKIE_KEY, token, { expires: 365, domain: AUTH_DOMAIN });
    } else {
      // for non marketing site, token is stored in the local storage
      localStorage.set(LOCAL_STORAGE_KEY, token);
    }

    set(axios, 'defaults.headers.Authorization', `Bearer ${token}`);
  }
};

export const clearAuthHeader = () => {
  setAuthHeader(undefined);
};

export const clearAuthenticationTokenStorage = () => {
  Cookies.remove(AUTH_COOKIE_KEY, { domain: AUTH_DOMAIN });
  localStorage.remove(LOCAL_STORAGE_KEY);
};

export const setCustomRequestHeader = (name, value) => {
  const existingCustomHeaders = get(axios, 'defaults.customHeaders', {});
  set(axios, 'defaults.customHeaders', { ...existingCustomHeaders, [name]: value });
};

/**
 * This function adds the special header which our backend uses to track URLs for analytics purposes
 *
 * @param params
 * @return {AxiosPromise<any>}
 */
const axiosWrapperFunction = async params => {
  const enrichedParams = {
    ...params,
    headers: {
      ...params.headers,
      ...get(axios, 'defaults.customHeaders', {}),
      'X-AB-Origin-Frontend-URL': (window && window.location && window.location.href) || ''
    }
  };

  return axios(enrichedParams);
};

export default axiosWrapperFunction;
