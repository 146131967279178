import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import MobileHeroImage from '~images/producers-circle/partner-with-us.png';
import LeftImage from './images/pc-hero-left.svg';
import RightImage from './images/pc-hero-right.svg';
import BecomePatronButton from '../shared/BecomePatronButton/BecomePatronButton';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E9E9FB',
    position: 'relative'
  },
  leftImage: {
    position: 'absolute',
    top: 30,
    left: 0
  },
  rightImage: {
    position: 'absolute',
    top: 30,
    right: 0
  },
  container: {
    alignItems: 'center !important',
    paddingTop: '105px',
    paddingBottom: '240px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '40px',
      paddingBottom: '40px'
    }
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '782px',
    gap: '0px',
    textAlign: 'center',
    marginBottom: '26px'
  },
  sectionHeading: {
    fontSize: '52px',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    color: '#1D1928',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2)
    }
  },
  heroTextParagraph: {
    color: '#4C5766',
    fontSize: '18px',
    fontFamily: 'Inter',
    lineHeight: '170%',
    letterSpacing: '0px'
  },
  mobileImageMedia: {
    width: '100%',
    maxWidth: '450px',
    display: 'flex',
    margin: '0 auto'
  }
}));

const ProducersCircleHero = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <img
          src={LeftImage}
          alt="Patrons Circle"
          title="Patrons Circle"
          className={classes.leftImage}
        />
        <img
          src={RightImage}
          alt="Patrons Circle"
          title="Patrons Circle"
          className={classes.rightImage}
        />
      </Hidden>

      <Container className={classes.container}>
        {isSmallerScreen && (
          <img
            src={MobileHeroImage}
            alt="Patrons Circle"
            title="Patrons Circle"
            className={classes.mobileImageMedia}
          />
        )}
        <div className={classes.textArea}>
          <Typography variant="h3" component="h1" className={classes.sectionHeading}>
            Join Our Inner Circle.
            <br />
            Become a Patron of Aleph Beta
          </Typography>

          <Typography variant="subtitle1" className={classes.heroTextParagraph} gutterBottom>
            Want to take your involvement with us to the next level? Become a Patron of Aleph Beta.
          </Typography>
        </div>
        <BecomePatronButton
          variant="purple"
          marginTop={isSmallerScreen ? '0px' : '24px'}
          marginBottom="24px"
        />
      </Container>
    </div>
  );
};

ProducersCircleHero.propTypes = {};

export default ProducersCircleHero;
