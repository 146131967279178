/* eslint-disable no-console */
import MediaPlayerBg from '~images/playlist/playlist_media_player_background.gif';

const enableLogging = true; // set to false to stop default console logs
const isDevelopment = process.env.NODE_ENV === 'development' && enableLogging;

// Supported options https://wistia.com/support/developers/embed-options#options_list
export default {
  width: '100%',
  height: '100%',
  style: {
    background: `url(${MediaPlayerBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  config: {
    wistia: {
      options: {
        seo: false,
        videoFoam: true,
        playlistLinks: 'auto',
        playerColor: '#3688FF',
        playbar: false,
        playbackRateControl: false,
        fullscreenButton: false,
        volumeControl: false,
        settingsControl: false,
        resumable: false
      }
    }
  },
  onReady: () => isDevelopment && console.log('onReady'),
  onPlay: () => isDevelopment && console.log('onPlay'),
  onProgress: () => isDevelopment && console.log('onProgress'),
  onEnded: () => isDevelopment && console.log('onEnded')
};
