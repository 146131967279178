import { extractKnownSlices, extractSeoData } from '~src/utils/unfurl-helpers';
import { get } from '../utils/get';

export default prismicData => ({
  title: get(prismicData, 'prismicBook.data.title'),
  description: get(prismicData, 'prismicBook.data.description'),
  background_color: get(prismicData, 'prismicBook.data.background_color'),
  chapters: get(prismicData, 'prismicBook.data.chapters', []).map(item => ({
    title: get(item, 'chapter.document.data.title', '!!!!!NO TITLE!!!'),
    url: item.chapter.url
  })),
  seo: extractSeoData(prismicData, 'prismicBook'),
  slices: extractKnownSlices(prismicData, 'prismicBook.data.body')
});
