import React, { useMemo } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#438dce'
    },
    secondary: pink,
    error: {
      main: '#f7565b'
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    fontSize: 14
  }
});

export default function Theme(props) {
  const cachedTheme = useMemo(() => theme, []);

  return <ThemeProvider theme={cachedTheme} {...props} />;
}
