import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import Link from '~components/Link';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BottomShapeDividerSrc1 from './images/pc-page-shape-divider-4.svg';
import BottomShapeDividerSrc2 from './images/pc-page-shape-divider-5.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#83D2F0',
    zIndex: 1,
    position: 'relative',
    marginTop: '-210px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '220px 60px 105px',
    [theme.breakpoints.down('xs')]: {
      padding: '100px 10px 80px'
    },
    '& > h3': {
      color: '#222222',
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: '52px',
      textAlign: 'center',
      marginTop: 0,
      marginBottom: 32,
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px'
      }
    }
  },
  phoneText: {
    color: '#3C3284',
    fontFamily: 'Inter',
    fontSize: '40px',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: -8,
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  },
  emailText: {
    color: '#3C3284',
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 500,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    }
  },
  bottomDivider: {
    marginTop: -2
  }
}));

const ProducersCircleCta = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <>
      <div className={classes.root}>
        {!isXs && <BottomShapeDivider src={BottomShapeDividerSrc1} bgColor="transparent" flex />}
        <div className={classes.container}>
          <h3>Still not sure? Give us a call!</h3>
          <Link
            to="tel:(516)253-5691"
            variant="subtitle1"
            align="center"
            className={classes.phoneText}
            underline="none"
          >
            (516) 253-5691
          </Link>
          <Link
            to="mailto:patrons@alephbeta.org"
            variant="subtitle1"
            align="center"
            className={classes.emailText}
            underline="none"
          >
            patrons@alephbeta.org
          </Link>
        </div>
      </div>
      <BottomShapeDivider
        src={BottomShapeDividerSrc2}
        bgColor="transparent"
        flex
        className={classes.bottomDivider}
      />
    </>
  );
};

export default ProducersCircleCta;
