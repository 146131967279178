import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ProducersCircleButton } from '~modules/producers-circle/components/shared';

const useStyles = makeStyles(theme => ({
  floatingButton: isVisible => ({
    position: 'fixed',
    maxWidth: '100vw',
    WebkitTransition: 'all .3s ease-in-out',
    MozTransition: 'all .3s ease-in-out',
    OTransition: 'all .3s ease-in-out',
    transition: 'all .3s ease-in-out',
    top: '20px',
    bottom: 'unset',
    right: '45px',
    zIndex: 3,
    WebkitTransform: isVisible ? 'translateY(50px)' : 'translateY(-100%)',
    MsTransform: isVisible ? 'translateY(50px)' : 'translateY(-100%)',
    transform: isVisible ? 'translateY(50px)' : 'translateY(-100%)',
    [theme.breakpoints.down('sm')]: {
      right: '10px',
      top: 0
    }
  })
}));

const ProducersCircleFloatingButton = ({ isVisible }) => {
  const classes = useStyles(isVisible);
  return (
    <div className={classes.floatingButton}>
      <ProducersCircleButton title="Join the Circle" />
    </div>
  );
};

ProducersCircleFloatingButton.propTypes = {
  isVisible: PropTypes.bool.isRequired
};
export default ProducersCircleFloatingButton;
