import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTestId } from '~utils/set-testid';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #e2e2e1',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #b5b5b5i'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  focused: {}
}));

const EmailInput = ({ form, ...rest }) => {
  const classes = useStyles();
  const testid = useTestId(`${form}-email-input`);
  return (
    <TextField
      label="Email"
      type="email"
      name="email"
      autoComplete="email"
      fullWidth
      required
      inputProps={{ ...testid }}
      margin="normal"
      variant="filled"
      // eslint-disable-next-line
      InputProps={{ classes, disableUnderline: true }}
      {...rest}
    />
  );
};

EmailInput.propTypes = {
  form: PropTypes.string.isRequired
};

export default EmailInput;
