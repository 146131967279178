/* eslint-disable no-underscore-dangle, consistent-return */
import { useEffect } from 'react';
import { navigate } from 'gatsby';
import localStorage from 'store2';
import { nanoid } from 'nanoid';
import { get } from 'lodash';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import * as ACTIONS from '~context/UserContext/actions/actions';

import {
  pushDataLayerEvent,
  setUser as setDataLayerUser,
  clearUser as clearDataLayerUser
} from '~utils/data-layer';

import useApi, {
  SYNC,
  LOGIN,
  REGISTER,
  REGISTER_CTA,
  REGISTER_UNEMPLOYED,
  REGISTER_SUPPORT_IN_NEED,
  MAGIC_LINK_GET,
  MAGIC_LINK_USE,
  LOGOUT,
  LOGIN_FB,
  VERIFY_EMAIL,
  CHECK_USERNAME_EXISTS
} from '~hooks/useApi';

import { clearAuthHeader, setAuthHeader, clearAuthenticationTokenStorage } from '~utils/request';
import { gaRegistrationEvent } from '~utils/google-analytics';
import { fbRegistrationEvent } from '~utils/facebook-analytics';
import { irRegistrationEvent } from '~utils/invite-referrals';

import { restartIntercom, updateIntercomUser } from '~utils/intercom';
import useConversionFramework from '~hooks/useConversionFramework';
import { setPushToAppExperiment } from '~utils/experiment-helper';
import AnalyticsTracking from '../../utils/analytics-tracking';

export const useSyncUser = () => {
  const [state] = useApi(SYNC());
  const { dispatch, session } = useUserContext();
  const { isLoading, isError, data: syncedUser, error } = state;

  /**
   * Init the session based on the "marketingUser" we define on the custom user context
   * for the marketing website, keep in mind that disabling the MARKETING_SITE feature flag
   * will break this.
   */
  useEffect(() => {
    if (!session.isUserStateLoading) {
      AnalyticsTracking.initUser(session.marketingUser);
    }
  }, [session]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      clearDataLayerUser();
      // NOTE: this call doubles our `user_synced` events in GA4 in case people error out while syncing
      pushDataLayerEvent('user_synced');
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (syncedUser) {
      setDataLayerUser(syncedUser);
      pushDataLayerEvent('user_synced');
      updateIntercomUser(syncedUser);
      setPushToAppExperiment(syncedUser.access_level > 1);
      dispatch({ type: ACTIONS.USER_READY, payload: syncedUser });
    }
  }, [isLoading, isError, syncedUser, error, dispatch]);
};

export const useLogin = () => {
  const { dispatch } = useUserContext();
  const { isMarketingSite } = useConversionFramework();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  useEffect(() => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }

    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      pushDataLayerEvent('login_failed', { loginMethod: 'local', loginFailureReason: error });
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      setDataLayerUser(user);
      pushDataLayerEvent('login', { loginMethod: 'local' });
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [isLoading, isError, error, data, isMarketingSite]); // eslint-disable-line react-hooks/exhaustive-deps

  const login = ({ username, password }) => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }
    const loginParams = LOGIN({
      username,
      password
    });
    makeRequest(loginParams);
  };

  return [state, login];
};

export const useFacebookLogin = () => {
  const { dispatch } = useUserContext();
  const { isMarketingSite } = useConversionFramework();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  useEffect(() => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }
    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      clearDataLayerUser();
      pushDataLayerEvent('login_failed', { loginMethod: 'local', loginFailureReason: error });
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      setDataLayerUser(user);
      pushDataLayerEvent('login', { loginMethod: 'facebook' });
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [data, error, isError, isLoading, isMarketingSite]); // eslint-disable-line react-hooks/exhaustive-deps

  const loginWithFacebook = ({ accessToken }) => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }
    const loginParams = LOGIN_FB({
      accessToken
    });
    makeRequest(loginParams);
  };

  return [state, loginWithFacebook];
};

export const useRegistration = () => {
  const { dispatch } = useUserContext();
  const { isMarketingSite } = useConversionFramework();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error, requestConfig } = state;

  useEffect(() => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }
    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      clearDataLayerUser();
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      // Facebook event ID which must be unique and identical for both frontend and backend
      // @see https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
      // It is being passed to the `fbRegistrationEvent` method
      // as well as corresponding call to our own API.
      const fbEventId = get(requestConfig, 'headers["X-Facebook-Event-ID"]');

      gaRegistrationEvent();

      fbRegistrationEvent({ fbEventId });
      setDataLayerUser(user);
      irRegistrationEvent({ email: user.username, fname: user.meta_first_name });
      pushDataLayerEvent('sign_up', { signUpMethod: 'local' });
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [isLoading, isError, error, data, requestConfig, isMarketingSite]); // eslint-disable-line react-hooks/exhaustive-deps

  const register = ({ username, password, meta_first_name, meta_last_name, ...rest }) => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }
    const fbEventId = nanoid();

    const registerParams = REGISTER({
      fbEventId,
      username,
      password,
      meta_first_name,
      meta_last_name,
      ...rest
    });
    makeRequest(registerParams);
  };

  return [state, register];
};

export const useCtaRegistration = () => {
  const { dispatch } = useUserContext();
  const { isMarketingSite } = useConversionFramework();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error, requestConfig } = state;

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      clearDataLayerUser();
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      // Facebook event ID which must be unique and identical for both frontend and backend
      // @see https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
      // It is being passed to the `fbRegistrationEvent` method
      // as well as corresponding call to our own API.
      const fbEventId = get(requestConfig, 'headers["X-Facebook-Event-ID"]');

      gaRegistrationEvent();
      fbRegistrationEvent({ fbEventId });
      irRegistrationEvent({ email: user.username, fname: user.meta_first_name });
      setDataLayerUser(user);
      pushDataLayerEvent('sign_up', { signUpMethod: 'call_to_action' });
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [isLoading, isError, error, data, requestConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const registerCta = ({ username, ...rest }) => {
    const fbEventId = nanoid();
    const registerParams = REGISTER_CTA({ fbEventId, username, ...rest });
    makeRequest(registerParams);
  };
  return [state, registerCta];
};

export const useLogout = () => {
  const { dispatch } = useUserContext();
  const [state, makeRequest] = useApi();
  const { isLoading, data, error } = state;

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (data || error) {
      clearAuthHeader();
      localStorage.clearAll();
      clearAuthenticationTokenStorage();
      clearDataLayerUser();
      pushDataLayerEvent('logout');
      restartIntercom();
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
    }
  }, [data, error, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const logout = () => {
    const logoutParams = LOGOUT();
    makeRequest(logoutParams);
  };

  return [state, logout];
};

export const useGetMagicLink = (redirectTo, redirectState) => {
  const { session } = useUserContext();
  const { isLoggedIn } = session;

  const [state, makeRequest] = useApi();

  if (isLoggedIn) {
    navigate(redirectTo || window.previousPath, { state: redirectState });
  }

  const getMagicLink = ({ username }) => {
    const magicLinkParams = MAGIC_LINK_GET({
      username
    });
    makeRequest(magicLinkParams);
    pushDataLayerEvent('get_magic_link');
  };

  return [state, getMagicLink];
};

export const useLoginWithMagicLink = (username, magicToken) => {
  const { dispatch } = useUserContext();
  const { isMarketingSite } = useConversionFramework();
  const apiParams = isMarketingSite ? null : MAGIC_LINK_USE({ username, token: magicToken });
  const [state] = useApi(apiParams);
  const { isLoading, isError, data, error } = state;

  useEffect(() => {
    if (isLoading || isMarketingSite || isMarketingSite === null) {
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      clearDataLayerUser();
      pushDataLayerEvent('login_failed', { loginMethod: 'magic_link', loginFailureReason: error });
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      setDataLayerUser(user);
      pushDataLayerEvent('login', { loginMethod: 'magic_link' });
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [data, error, isError, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state];
};

export const useVerifyEmail = (username, verifyToken) => {
  const { isMarketingSite } = useConversionFramework();
  const { session, dispatch } = useUserContext();
  const { isLoggedIn } = session;
  const apiParams = VERIFY_EMAIL({ username, token: verifyToken });
  const [state] = useApi(apiParams);
  const { isLoading, isError, data, error } = state;

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (isError && error) {
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      if (isLoggedIn) {
        return;
      }

      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      setDataLayerUser(user);
      pushDataLayerEvent('account_verified');
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [isLoading, isError, error, data, isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state];
};

/**
 * Temporary handler for the free unemployed accounts!!!
 */
export const useUnemployedRegistration = () => {
  const { dispatch } = useUserContext();
  const { isMarketingSite } = useConversionFramework();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error, requestConfig } = state;

  useEffect(() => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }
    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      clearDataLayerUser();
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      // Facebook event ID which must be unique and identical for both frontend and backend
      // @see https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
      // It is being passed to the `fbRegistrationEvent` method
      // as well as corresponding call to our own API.
      const fbEventId = get(requestConfig, 'headers["X-Facebook-Event-ID"]');

      gaRegistrationEvent();
      fbRegistrationEvent({ fbEventId });
      irRegistrationEvent({ email: user.username, fname: user.meta_first_name });
      setDataLayerUser(user);
      pushDataLayerEvent('sign_up', { signUpMethod: 'unemployed_promotion' });
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [isLoading, isError, error, data, requestConfig, isMarketingSite]); // eslint-disable-line react-hooks/exhaustive-deps

  const registerUnemployed = ({
    username,
    meta_first_name,
    meta_last_name,
    meta_zip,
    most_recent_employer,
    last_date_of_employment,
    is_currently_unemployed
  }) => {
    if (isMarketingSite || isMarketingSite === null) {
      return;
    }
    const fbEventId = nanoid();

    const registerParams = REGISTER_UNEMPLOYED({
      fbEventId,
      username,
      meta_first_name,
      meta_last_name,
      meta_zip,
      most_recent_employer,
      last_date_of_employment,
      is_currently_unemployed
    });
    makeRequest(registerParams);
  };
  return [state, registerUnemployed];
};

export const useSupportInNeedRegistration = () => {
  const { isMarketingSite } = useConversionFramework();
  const { dispatch } = useUserContext();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error, requestConfig } = state;

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: ACTIONS.USER_LOADING });
      return;
    }

    if (isError && error) {
      clearAuthHeader();
      clearDataLayerUser();
      dispatch({ type: ACTIONS.USER_READY, payload: {} });
      return;
    }

    if (data) {
      const { user, token } = data;
      if (token) {
        setAuthHeader(token, isMarketingSite);
      }
      // Facebook event ID which must be unique and identical for both frontend and backend
      // @see https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
      // It is being passed to the `fbRegistrationEvent` method
      // as well as corresponding call to our own API.
      const fbEventId = get(requestConfig, 'headers["X-Facebook-Event-ID"]');

      gaRegistrationEvent();
      fbRegistrationEvent({ fbEventId });
      irRegistrationEvent({ email: user.username, fname: user.meta_first_name });
      setDataLayerUser(user);
      pushDataLayerEvent('sign_up', { signUpMethod: 'support_in_need_promotion' });
      updateIntercomUser(user);
      dispatch({ type: ACTIONS.USER_READY, payload: user });
    }
  }, [isLoading, isError, error, data, requestConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const registerSupportInNeed = formPayload => {
    const fbEventId = nanoid();

    const registerParams = REGISTER_SUPPORT_IN_NEED({
      fbEventId,
      formPayload
    });
    makeRequest(registerParams);
  };
  return [state, registerSupportInNeed];
};

export const useCheckUsernameExists = () => {
  const [state, makeRequest] = useApi();

  const checkUsernameExists = ({ username }) => {
    const checkUsernameExistsParams = CHECK_USERNAME_EXISTS({ username });
    makeRequest(checkUsernameExistsParams);
  };

  return [state, checkUsernameExists];
};
