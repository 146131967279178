import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';
import { Typography, Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px dotted black',
    minHeight: '100px',
    paddingLeft: '35px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0px'
    }
  },
  info: {
    fontSize: '16px'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    border: '1px solid black',
    borderRadius: '7px',
    height: '40px',
    width: '100px',
    margin: '0px 7px',
    fontSize: '16px',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: '15px 5px',
      width: '90px'
    }
  },
  linkStyle: {
    color: '#000000',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  summary: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px dotted #222222',
    minHeight: '100px',
    padding: '30px 220px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px'
    }
  }
}));

const WeeklyTorahPortionEventItem = ({ title, date, verses, description, url }) => {
  const classes = useStyles();

  const [summaryShowing, setSummaryShowing] = useState(false);

  const toggleSummary = () => {
    setSummaryShowing(!summaryShowing);
  };

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid item xs={12} md={2}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        {verses && (
          <Grid item xs={6} sm={3} md={3}>
            <Typography className={classes.info}>{verses}</Typography>
          </Grid>
        )}
        {date && (
          <Grid item xs={6} sm={3} md={2}>
            <Typography className={classes.info}>{date}</Typography>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={5} className={classes.buttonsContainer}>
          <Button onClick={() => toggleSummary()} className={classes.button}>
            Summary
          </Button>
          <Button className={classes.button}>
            <Link to={url} className={classes.linkStyle}>
              Videos
            </Link>
          </Button>
          <Button className={classes.button}>
            <Link className={classes.linkStyle} to={`${url}/#AdditionalResources`}>
              Guides
            </Link>
          </Button>
        </Grid>
      </Grid>
      {summaryShowing && (
        <Grid container alignItems="center" className={classes.summary}>
          <Typography>{description}</Typography>
        </Grid>
      )}
    </>
  );
};

WeeklyTorahPortionEventItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  verses: PropTypes.string,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

WeeklyTorahPortionEventItem.defaultProps = {
  date: null,
  verses: null
};

export default WeeklyTorahPortionEventItem;
