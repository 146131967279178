/**
 * README
 * We're using `gatsby-plugin-google-analytics` to initialize GA and track page views automatically.
 * This module's solely purpose is to easy the tracking of specific actions and it uses the pre-loaded `ga` global variable.
 */
export const gaRegistrationEvent = () => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('send', 'event', 'Registration', 'Register', 'Normal', 1);
  }
};

export const gaSubscriptionEvent = ({ planName = 'Normal', value = 9 }) => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('send', 'event', 'Subscriptions', 'Subscribed', planName, value);
  }
};

export const gaRecurringDonationEvent = ({ planName = 'Normal', value = 9 }) => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('send', 'event', 'Donations', 'RecurringDonation', planName, value);
  }
};

export const gaDonationEvent = ({ value = 3 }) => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('send', 'event', 'Donations', 'Donated', 'Normal', value);
  }
};

export const gaBarMitzvahEvent = () => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('send', 'event', 'Bar Mitzvah', 'Purchased Access', 'Normal', 3);
  }
};

export const gaSearchEvent = term => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('send', 'event', 'Search', 'Search', term);
  }
};
export const gaSetExperiment = (experimentId, variant) => {
  if (typeof window !== 'undefined' && window.ga) {
    window.ga('set', 'exp', `${experimentId}.${variant}`);
    window.ga('send', 'pageview');
  }
};
