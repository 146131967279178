import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Box, Grid } from '@material-ui/core';

import PodcastListenButton from './PodcastListenButton/PodcastListenButton';

const PodcastListenButtons = ({ applyBorder, providers }) => {
  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      component={Grid}
      container
      spacing={1}
      paddingTop={4}
      paddingBottom={8}
      flexDirection={isUpMD ? 'row' : 'column'}
      alignItems="center"
      justifyContent="center"
    >
      {providers &&
        providers.map(({ id, url }) => (
          <Grid item key={url}>
            {url && <PodcastListenButton text={id} url={url} applyBorder={applyBorder} />}
          </Grid>
        ))}
    </Box>
  );
};

PodcastListenButtons.propTypes = {
  applyBorder: PropTypes.bool,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

PodcastListenButtons.defaultProps = {
  applyBorder: false
};

export default PodcastListenButtons;
