import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Container from '~components/Container';
import RichText from '~components/RichText';

const TeamPageHero = ({ hero_background_image, hero_subtitle, hero_title }) => (
  <Container background={`url(${hero_background_image && hero_background_image.url})`}>
    <Box mt={10}>
      <RichText html={hero_title && hero_title.html} verticalSpacing={0} mode="teamHeroTitle" />
    </Box>
    <Box mt={3} mb={15}>
      <RichText
        html={hero_subtitle && hero_subtitle.html}
        verticalSpacing={0}
        mode="teamHeroSubtitle"
      />
    </Box>
  </Container>
);
TeamPageHero.propTypes = {
  hero_subtitle: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired
};

export default TeamPageHero;
