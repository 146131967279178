import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    minHeight: '140px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  contractedText: {
    fontSize: '34px',
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px'
    }
  },
  expandedTitle: {
    fontSize: '25px',
    fontWeight: '500',
    paddingBottom: '20px',
    maxWidth: '570px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  expandedSubtitle: {
    fontSize: '22px',
    fontWeight: '500',
    paddingTop: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px'
    }
  },
  rssLink: {
    width: '500px',
    height: '50px',
    border: '1px solid #D1D9DD',
    borderRadius: '6px 0px 0px 6px',
    color: '#D1D9DD',
    fontSize: '22px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '440px',
      fontSize: '20px',
      paddingLeft: '15px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px',
      textAlign: 'center',
      fontSize: '15px',
      borderRadius: '6px'
    }
  },
  copyButton: {
    backgroundColor: '#278AE7',
    width: '220px',
    height: '50px',
    color: '#FFFFFF',
    borderRadius: '0px 6px 6px 0px',
    textTransform: 'none',
    fontSize: '19px',
    '&:hover': {
      backgroundColor: '#D3D3D3'
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      borderRadius: '6px',
      marginTop: '10px',
      height: '40px'
    }
  },
  clickHereLink: {
    cursor: 'pointer'
  }
}));

const RSSFeed = ({ rssLink, rssFeedInstructions }) => {
  const classes = useStyles();

  const [sliceExpanded, setSliceExpanded] = useState(false);

  const copyUrlToClipboard = () => {
    window.navigator.clipboard.writeText(rssLink);
  };

  return (
    <Container className={classes.root}>
      {!sliceExpanded && (
        <Typography
          className={classes.contractedText}
          onClick={() => setSliceExpanded(!sliceExpanded)}
        >
          Subscribe to the Podcast in your Podcast Player {'>'}
        </Typography>
      )}
      {sliceExpanded && (
        <Grid container direction="column" alignItems="center">
          <Typography className={classes.expandedTitle}>
            Prefer to listen on your favorite podcast app? Here is a direct link to your personal
            RSS feed:
          </Typography>
          <Grid item container direction="row" justifyContent="center" alignItems="center">
            <Typography className={classes.rssLink}>{rssLink}</Typography>
            <Button className={classes.copyButton} onClick={copyUrlToClipboard}>
              Copy RSS Link
            </Button>
          </Grid>
          <Typography className={classes.expandedSubtitle}>
            Still need help?{' '}
            <Link to={rssFeedInstructions} className={classes.clickHereLink}>
              click here
            </Link>
          </Typography>
        </Grid>
      )}
    </Container>
  );
};

RSSFeed.propTypes = {
  rssLink: PropTypes.string.isRequired,
  rssFeedInstructions: PropTypes.string.isRequired
};

export default RSSFeed;
