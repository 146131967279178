import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fab from '@material-ui/core/Fab';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import School from '@material-ui/icons/School';
import LinkIcon from '@material-ui/icons/Link';
import AudioTrack from '@material-ui/icons/Audiotrack';
import FileCopy from '@material-ui/icons/FileCopy';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

const useStyles = makeStyles({
  fab: {
    color: '#438dce',
    backgroundColor: '#fff',
    boxShadow: 'unset',
    border: '2px solid #438dce',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#438dce'
    }
  }
});

export const getResourceName = material_name => {
  switch (material_name) {
    case 'PrismicArticle':
    case 'PrismicMaterial':
      return '101 Guide';
    case 'PrismicBlogPost':
      return 'Blog Post';
    case 'PrismicHoliday':
    case 'PrismicHolidayRedesign':
      return 'Holiday Guide';
    case 'PrismicParshat':
      return 'Parshat';
    case 'PrismicAdvanced':
      return 'Advanced';
    case 'Teacher Guide':
      return 'Teacher Guide';
    case 'Outline':
      return 'Outline';
    case 'Podcast':
      return 'Podcast';
    case 'Holiday Guide':
      return 'Printable Guide';
    case 'Parsha Guide':
      return 'Printable Guide';
    case 'Transcript':
      return 'Transcript';
    default:
      return 'Guide';
  }
};

const getIcon = materialType => {
  switch (materialType) {
    case 'podcast':
      return <AudioTrack />;
    case 'teacher guide':
      return <School />;
    case 'outline':
    case 'parsha guide':
    case 'printable guide':
    case 'transcript':
      return <PDFIcon />;
    case '101 guide':
      return <ImportContactsIcon />;
    case 'blog post':
      return <LibraryBooksIcon />;
    case 'holiday guide':
    case 'parshat':
    case 'advanced':
      return <FileCopy />;
    default:
      return <LinkIcon />;
  }
};

const ResourceIcon = ({ material_type, ...rest }) => {
  const resourceName = getResourceName(material_type);
  const lowercaseName = resourceName.toLowerCase();
  const classes = useStyles();

  return (
    <Fab size="medium" aria-label="resource material" classes={{ root: classes.fab }} {...rest}>
      {getIcon(lowercaseName)}
    </Fab>
  );
};

ResourceIcon.propTypes = {
  material_type: PropTypes.string
};
ResourceIcon.defaultProps = {
  material_type: null
};

export default ResourceIcon;
