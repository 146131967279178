import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useApi, { SOFT_SALE_CLOSE } from '~hooks/useApi';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import useBlackFridayTargetAudience from '~components/black-friday/useTargetAudience';

import PopupVariant2 from './PopupVariant2';
import PopupBlackFriday from './PopupBlackFriday';

const IS_BLACK_FRIDAY_OFFER_ENABLED = isFeatureEnabled(FEATURE_FLAGS.BLACK_FRIDAY_OFFER);

const WatchTimeHalfwayPopup = ({
  handlePopupClose,
  which,
  setPopupId,
  isPopupOpen,
  toggleVideoPlayState,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [, makeRequest] = useApi();
  const { isLoggedIn } = useUserContext().session;
  const isBlackFridayTargetAudience = useBlackFridayTargetAudience();

  useEffect(() => {
    if (isOpen && toggleVideoPlayState) {
      toggleVideoPlayState(false);
    }
  }, [isOpen, toggleVideoPlayState]);

  const handleClose = async () => {
    if (which) {
      const params = SOFT_SALE_CLOSE({ which });
      await makeRequest(params);
    }

    if (toggleVideoPlayState) {
      toggleVideoPlayState(true);
    }

    if (handlePopupClose) {
      handlePopupClose();
      return;
    }
    if (setPopupId) {
      setPopupId(0);
    }

    setIsOpen(false);
  };

  if (IS_BLACK_FRIDAY_OFFER_ENABLED && isBlackFridayTargetAudience) {
    return (
      <PopupBlackFriday
        {...rest}
        handleClose={handleClose}
        isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
        isLoggedIn={isLoggedIn}
      />
    );
  }

  return (
    <PopupVariant2
      {...rest}
      handleClose={handleClose}
      isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
      isLoggedIn={isLoggedIn}
    />
  );
};

WatchTimeHalfwayPopup.propTypes = {
  showTopCloseBtn: PropTypes.bool,
  setPopupId: PropTypes.func,
  id: PropTypes.string,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  which: PropTypes.number,
  toggleVideoPlayState: PropTypes.func
};

WatchTimeHalfwayPopup.defaultProps = {
  showTopCloseBtn: false,
  setPopupId: null,
  id: undefined,
  isPopupOpen: null,
  handlePopupClose: null,
  which: null,
  toggleVideoPlayState: null
};

export default WatchTimeHalfwayPopup;
