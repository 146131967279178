import makeStyles from '@material-ui/core/styles/makeStyles';

export const useSharedStyles = makeStyles(theme => ({
  root: {
    width: '455px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  title: {
    fontWeight: '800',
    fontSize: '25px',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: '20px',
    textAlign: 'center'
  },
  joinPremiumDescription: {
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center'
  },
  errorText: {
    fontSize: '14px',
    textAlign: 'left',
    color: theme.palette.error.main
  },
  submitButton: {
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#000000',
    paddingTop: 9,
    paddingBottom: 9,
    borderRadius: 7,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#363636'
    }
  },
  secondaryTextLink: {
    fontSize: '14px',
    color: '#3C3B3C',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  gapXs: {
    marginBottom: theme.spacing(1)
  },
  gapSm: {
    marginBottom: theme.spacing(2)
  },
  gapMd: {
    marginBottom: theme.spacing(3)
  },
  gapLg: {
    marginBottom: theme.spacing(4)
  },
  gapXl: {
    marginBottom: theme.spacing(5)
  },
  gapXXl: {
    marginBottom: theme.spacing(6)
  },
  inputAdornment: {
    marginRight: 19,
    fontWeight: '700',
    cursor: 'pointer !important'
  }
}));
