import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import Container from '~components/Container';
import { HeroTitle, HeroSubtitle } from '~components/Typography';

const HeroText = ({ title, subtitle, background_image }) => (
  <Container center size={33} background={`url(${background_image.url})`}>
    <Box py={2}>
      <HeroTitle align="center">{title}</HeroTitle>
      <HeroSubtitle align="center" variant="h6">
        {subtitle}
      </HeroSubtitle>
    </Box>
  </Container>
);

HeroText.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  background_image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default HeroText;
