import useApi, { GET_TOP_100_SEARCHES } from '~hooks/useApi';

const useTopSearches = () => {
  const [state, makeRequest] = useApi();

  const getTopSearches = () => {
    const topSearchesParams = GET_TOP_100_SEARCHES();
    makeRequest(topSearchesParams);
  };

  return [state, getTopSearches];
};
export default useTopSearches;
