import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton';
import { getSubscribeButtonProps } from '~utils/buttontext';
import useConversionFramework from '~hooks/useConversionFramework';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import AboutVideoThumbnailImg from './about-box-video-thumb.jpg';

const useStyles = makeStyles(theme => ({
  container: {
    scrollMarginTop: '100px',
    width: '100%',
    marginTop: '90px',
    marginBottom: '90px',
    color: '#FFFFFF',
    backgroundColor: '#282828',
    padding: '102px 62px',
    borderRadius: '13px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '78px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '50px 30px',
      gap: '32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '26px 30px',
      marginTop: '64px',
      marginBottom: '64px'
    }
  },
  title: {
    fontSize: '46px',
    fontWeight: 600,
    fontFamily: 'Inter',
    marginTop: 0,
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px'
    }
  },
  description: {
    color: '#CCCCCC',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '150%',
    maxWidth: '515px',
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      maxWidth: '450px'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      maxWidth: '307px',
      margin: 'auto'
    }
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    minWidth: '520px',
    [theme.breakpoints.down('md')]: {
      minWidth: '440px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      order: 2
    }
  },
  imageArea: {
    [theme.breakpoints.down('sm')]: {
      order: 1
    }
  },
  ctaOutlineButton: {
    cursor: 'pointer',
    color: '#FFFFFF',
    backgroundColor: '#282828',
    padding: '19px 32px',
    fontSize: 18,
    lineHeight: '18px',
    fontWeight: 700,
    borderRadius: 45,
    border: '2px solid #FFFFFF',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#282828'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: '20px',
      borderRadius: 32,
      padding: '15px 20px',
      backgroundColor: '#02070F',
      '&:hover': {
        backgroundColor: '#02070F'
      }
    }
  },
  ctaButtonText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    fontSize: 18,
    lineHeight: '18px',
    textTransform: 'none',
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: '14px'
    }
  },
  thumbnail: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: 2
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    marginTop: '40px'
  }
}));

const AboutBoxSlice = () => {
  const classes = useStyles();

  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;

  const {
    text: subscribeBtnText,
    url: subscribeBtnUrl,
    shouldTrackAnalytics: shouldCtaButtonTrackAnalytics,
    analyticsEvent: ctaAnalyticsEvent
  } = getSubscribeButtonProps(jwtAvailable, isMarketingSite, marketingUser);

  const gotoAboutVideo = () => {
    navigate('/who-we-are', {
      state: { noRedirect: true, playIntroVideo: true }
    });
  };

  return (
    <div className={classes.container} id="about">
      <div className={classes.contentArea}>
        <h2 className={classes.title}>What is Aleph Beta?</h2>
        <p className={classes.description}>
          Aleph Beta is a unique kind of Torah library. Led by our founder, Rabbi David Fohrman, we
          are dedicated to high-level, textual Torah learning for adults that is intellectually and
          spiritually sophisticated, that enlivens your Jewish practice and helps you forge a deeper
          connection to God. Whether you’ve been learning in yeshiva for years or you’re just
          beginning your Torah journey, you’re sure to find something meaningful and surprising
          waiting for you here.
        </p>
        <p className={classes.description}>
          Browse our library of over 1,000 beautifully produced animated videos, podcasts, deep dive
          courses, and printable guides. Topics include the weekly parsha, Jewish holidays & fast
          days, laws & mitzvot, prayers, relationships, big philosophical ideas and more. Have
          something to say at the Shabbos table that will amaze your family and guests and bring
          deep meaning into their lives.
        </p>
        <div className={classes.buttonArea}>
          <RoundedLinkButton
            text={subscribeBtnText}
            to={subscribeBtnUrl}
            shouldTrackAnalytics={shouldCtaButtonTrackAnalytics}
            analyticsEvent={ctaAnalyticsEvent}
            analyticsTitle="What is Aleph Beta?"
            analyticsSection="About Us Slice"
            size="small"
            variant="blue"
          />
          <Link to="/who-we-are" className={classes.ctaOutlineButton}>
            About us
          </Link>
        </div>
      </div>
      <div className={classes.imageArea}>
        <Button onClick={gotoAboutVideo} component={Link}>
          <img src={AboutVideoThumbnailImg} className={classes.thumbnail} alt="About" />
        </Button>
      </div>
    </div>
  );
};

export default AboutBoxSlice;
