import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fab from '@material-ui/core/Fab';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  carousel: {
    width: '100%',
    '& .slick-list': {
      padding: '8px 0',
      position: 'unset',
      [theme.breakpoints.down('xs')]: {
        marginTop: props => (props.mobileDotsOnTop ? '40px' : '0px')
      }
    },
    '& .slick-track': {
      display: 'flex !important',
      gap: 15,
      position: 'unset',
      marginLeft: 0,
      marginRight: 0
    },
    '& .slick-slider': {
      position: 'unset'
    },
    '& .slick-slide': {
      height: 'inherit !important',
      '& > :first-child': {
        height: '100%'
      }
    },
    '& .slick-dots': {
      [theme.breakpoints.down('xs')]: {
        top: props => (props.mobileDotsOnTop ? '0px' : 'unset'),
        bottom: props => (props.mobileDotsOnTop ? 'unset' : '-25px')
      }
    },
    '& .slick-dots > li': {
      margin: 0
    }
  },
  carouselBtn: {
    position: 'absolute',
    top: '40%',
    zIndex: 1,
    boxShadow: 'none',
    color: '#D9D9D9',
    border: '2px solid #D9D9D9',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#9d9d9d',
      border: '2px solid #9d9d9d'
    },
    '&:active': {
      backgroundColor: '#FFFFFF',
      color: '#9d9d9d',
      border: '2px solid #9d9d9d',
      boxShadow: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      left: props => (props.isNext ? null : 24),
      right: props => (props.isNext ? 24 : null)
    },
    [theme.breakpoints.up('md')]: {
      left: props => (props.isNext ? null : 40),
      right: props => (props.isNext ? 40 : null)
    },
    [theme.breakpoints.up('lg')]: {
      left: props => (props.isNext ? null : -50),
      right: props => (props.isNext ? -50 : null)
    },
    '@media only screen and (min-width: 1279px) and (max-width: 1300px)': {
      left: props => (props.isNext ? null : 8),
      right: props => (props.isNext ? 8 : null)
    }
  }
}));

const CarouselArrow = ({ onClick, isNext }) => {
  const classes = useStyles({ isNext });
  return (
    <Fab
      onClick={onClick}
      size="medium"
      className={classes.carouselBtn}
      aria-label={isNext ? 'navigate next' : 'navigate previous'}
      disableRipple
    >
      {isNext ? <NavigateNext /> : <NavigateBefore />}
    </Fab>
  );
};

CarouselArrow.propTypes = {
  onClick: PropTypes.func,
  isNext: PropTypes.bool
};

CarouselArrow.defaultProps = {
  onClick: () => {},
  isNext: false
};

const ModernCarousel = ({ children, mobileDotsOnTop, ...rest }) => {
  const classes = useStyles({ mobileDotsOnTop });
  const ensureArrayChildren = React.Children.toArray(children);

  const carouselSettings = {
    className: classes.carousel,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    swipeToSlide: true,
    infinite: ensureArrayChildren.length > 3,
    prevArrow: <CarouselArrow />,
    nextArrow: <CarouselArrow isNext />,
    responsive: [
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: ensureArrayChildren.length > 1,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: ensureArrayChildren.length > 2,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: ensureArrayChildren.length > 3,
          arrows: false,
          dots: true
        }
      }
    ]
  };

  return (
    <>
      <Container {...rest}>
        <Slider {...carouselSettings}>{ensureArrayChildren}</Slider>
      </Container>
    </>
  );
};

ModernCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  mobileDotsOnTop: PropTypes.bool
};

ModernCarousel.defaultProps = {
  mobileDotsOnTop: false
};

export default ModernCarousel;
