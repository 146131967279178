import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import RichText from '~components/RichText';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import { setMarqueeBannerHidden } from '~context/GlobalContext/actions';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    boxShadow: '4px 0px 3px 2px rgba(0, 0, 0, 0.1)'
  },
  root: {
    backgroundColor: '#EEF5FE',
    padding: '20px 65px 20px 0px',
    marginBottom: '-5px',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 35px 20px 0px'
    }
  },
  contentArea: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '20px'
  },
  divider: {
    display: 'inline-block',
    borderLeft: '1px solid rgba(0, 0, 0, 0.22)',
    height: '20px',
    verticalAlign: 'middle'
  },
  marquee: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'absolute',
    width: props => props.contentWidth
  },
  marqueeAnimation: {
    animation: `$marqueeIn`,
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    animationDuration: props => `${props.animationSpeed}s`
  },
  marqueeElement: {
    whiteSpace: 'nowrap',
    float: 'left'
  },
  '@keyframes marqueeIn': {
    '0%': {
      transform: 'translateX(0)'
    },
    '100%': {
      transform: 'translateX(-100%)'
    }
  },
  text: {
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 10,
    verticalAlign: 'middle',
    '& > *': {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Inter',
      fontWeight: 500,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      whiteSpace: 'nowrap',
      color: '#000000'
    }
  },
  closeBtnArea: props => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    top: -5,
    right: props.close_button_offset || '18px',
    [theme.breakpoints.down('sm')]: {
      right: props.close_button_offset || '2px'
    }
  }),
  closeBtn: {
    color: '#000000',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  closeIcon: {
    fontSize: '18px'
  }
}));

const ANIMATION_START_DELAY = 2000;

const MarqueeBanner = ({ marquee_texts, marquee_animation_speed, close_button_offset }) => {
  const {
    state: { isMarqueeBannerHidden },
    dispatch
  } = useGlobalStore();
  const ref = useRef(null);
  const [animationSpeed, setAnimationSpeed] = useState(5);
  const [contentWidth, setContentWidth] = useState(null);
  const classes = useStyles({ animationSpeed, contentWidth, close_button_offset });

  const handleBannerClose = () => {
    dispatch(setMarqueeBannerHidden(true));
  };

  useEffect(() => {
    const handleResize = () => {
      // wait until animation delay to start the animation
      setTimeout(() => {
        if (ref.current) {
          /*
          speed = 100 pixels per second
          therefore animation time can be derived using below,
            time = distance / speed
                 = width of the section / 100
        */
          const time = ref.current.offsetWidth / marquee_animation_speed;
          setAnimationSpeed(Math.round(time));
          setContentWidth(ref.current.offsetWidth);
        }
      }, ANIMATION_START_DELAY);
    };

    handleResize();

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, [ref, marquee_animation_speed, marquee_texts]);

  // Generate a text and divider together in pairs,
  // then flatten the array of pairs one level down,
  // getting a list of texts interspersed with dividers
  const renderTexts = () =>
    marquee_texts.flatMap(record => [
      <RichText
        key={record.marquee_text.html}
        externalClassName={classes.text}
        html={record.marquee_text.html}
        verticalSpacing={0}
      />,
      <div key={`DIVIDER-${record.marquee_text.html}`} className={classes.divider} />
    ]);

  const texts = useMemo(renderTexts, [marquee_texts, classes]);

  if (marquee_texts?.length === 0 || isMarqueeBannerHidden) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <div className={classes.contentArea}>
          <div className={cn(classes.marquee, contentWidth !== null && classes.marqueeAnimation)}>
            <div className={classes.marqueeElement} ref={ref}>
              {texts}
            </div>
            <div className={classes.marqueeElement}>
              <div key="__divider__" className={classes.divider} />
              {texts}
            </div>
          </div>
        </div>

        <div className={classes.closeBtnArea}>
          <IconButton
            aria-label="close"
            onClick={handleBannerClose}
            classes={{
              root: classes.closeBtn
            }}
            disableRipple
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

MarqueeBanner.propTypes = {
  marquee_texts: PropTypes.arrayOf(
    PropTypes.shape({
      marquee_text: PropTypes.shape({
        html: PropTypes.string
      })
    })
  ),
  marquee_animation_speed: PropTypes.number,
  close_button_offset: PropTypes.string
};

MarqueeBanner.defaultProps = {
  marquee_texts: [],
  marquee_animation_speed: 100,
  close_button_offset: null
};

export default MarqueeBanner;
