import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Drawer from '@material-ui/core/Drawer';

import DrawerContent from './components/DrawerContent';

const CurationPageDrawer = ({ drawerData, handleClose }) => {
  const theme = useTheme();
  const isXsScreenSize = useMediaQuery(theme.breakpoints.down('sm'));

  if (isXsScreenSize) {
    return (
      <Drawer open onClose={handleClose} anchor="right" variant="permanent">
        <DrawerContent drawerData={drawerData} handleClose={handleClose} />
      </Drawer>
    );
  }

  return <DrawerContent drawerData={drawerData} handleClose={handleClose} />;
};
CurationPageDrawer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  drawerData: PropTypes.shape({
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    playlists: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired
      })
    )
  })
};

CurationPageDrawer.defaultProps = {
  drawerData: {}
};
export default CurationPageDrawer;
