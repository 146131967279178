import { getNavigationPageLink } from '~utils/common-site-helper';

export const ACCESS_LEVELS = {
  LOGGED_IN: -1, // user needs to be logged in to access this resource
  FREE_USER: 0, // need only to be registered, no required subscription level
  BASIC_PLAN: 1, // must be at least a basic member
  PREMIUM_PLAN: 2, // must be at least premium
  EDUCATOR_PLAN: 3, // educators and producers only
  PRODUCER_PLAN: 4, // producer circle only
  BARBATMITZVAH: 5, // users with `has_bar_mitzvah` flag or who have premium and higher subscription levels
  ADMIN: 6, // admins, users with `is_admin` flag
  UNRESTRICTED: 99 // no restriction whatsoever, and time limits are ignored for these resources
};

// This method converts Prismic required subscription level to our internal access level code.
export const getRequiredPlanId = planString => {
  if (!planString) {
    return ACCESS_LEVELS.FREE_USER;
  }
  switch (planString) {
    case 'Free':
      return ACCESS_LEVELS.FREE_USER;
    case 'Member':
      return ACCESS_LEVELS.BASIC_PLAN;
    case 'Premium':
      return ACCESS_LEVELS.PREMIUM_PLAN;
    case 'Educator':
      return ACCESS_LEVELS.EDUCATOR_PLAN;
    case 'Producer Circle':
      return ACCESS_LEVELS.PRODUCER_PLAN;
    case 'Unrestricted':
      return ACCESS_LEVELS.UNRESTRICTED;

    default:
      throw new Error(
        `getRequiredPlanId: Unknown \`planString\` inside of required-subscription-plan! Received: '${planString}'`
      );
  }
};

export const getRequiredPlanName = id => {
  if (id === undefined || id === null) {
    return 'Free';
  }
  switch (id) {
    case ACCESS_LEVELS.FREE_USER:
      return 'Free';
    case ACCESS_LEVELS.BASIC_PLAN:
      return 'Member';
    case ACCESS_LEVELS.PREMIUM_PLAN:
      return 'Premium';
    case ACCESS_LEVELS.EDUCATOR_PLAN:
      return 'Educator';
    case ACCESS_LEVELS.PRODUCER_PLAN:
      return 'Patrons Circle';
    default:
      throw new Error(
        'getRequiredPlanName: Unknown `planString` inside of required-subscription-plan!'
      );
  }
};

export const getInsufficientPlanRedirectProps = minAccessLevel => {
  const planName = getRequiredPlanName(minAccessLevel);

  return {
    state: {
      alert: {
        show: true,
        plan: planName,
        type: 'warning',
        title: `${planName} Content`,
        text: `${`You must be a ${planName} paid user to access the content of this page.`}`,
        button: {
          title: `Already a ${planName} paid user? Login Here`,
          to: getNavigationPageLink('/login')
        }
      }
    },
    replace: true
  };
};

export const getGuestTranscriptRedirectProps = () => ({
  state: {
    alert: {
      show: true,
      plan: 'Registered',
      type: 'warning',
      title: `Join our community`,
      text: `${`Please register to enjoy this content. If you believe in what we're doing, please consider subscribing. It would mean the world to have your support. `}`,
      button: {
        title: `Already a registered user? Login Here`,
        to: getNavigationPageLink('/login')
      }
    }
  }
});

export const getInsufficientTranscriptAccessRedirectProps = () => ({
  state: {
    alert: {
      show: true,
      plan: 'Registered',
      type: 'warning',
      title: `Join our community`,
      text: `${`Please subscribe to enjoy this content. If you believe in what we're doing, please consider donating or joining our Patrons Circle. It would mean the world to have your support.`}`,
      button: {
        title: `Already a paid user? Login Here`,
        to: getNavigationPageLink('/login')
      }
    }
  }
});

export const getFailedChargesRedirectProps = () => ({
  state: {
    alert: {
      show: true,
      type: 'warning',
      title: `Billing Notice`,
      text: `We’re sorry, it looks like this content is not available to you due to a temporary hold on your account. Please make sure your subscription is up-to-date.`,
      button: {
        title: 'Need help? Email info@alephbeta.org.',
        to: 'mailto:info@alephbeta.org'
      }
    }
  },
  replace: true
});

export const getFreeUserCommentRedirectProps = () => ({
  state: {
    alert: {
      show: true,
      plan: 'Free',
      type: 'warning',
      title: `Join our community`,
      text: `Please subscribe to enjoy this content. If you believe in what we're doing, please consider donating or joining our Patrons Circle. It would mean the world to have your support.`,
      button: {
        title: `Already a paid user? Login Here`,
        to: getNavigationPageLink('/login')
      }
    }
  }
});
