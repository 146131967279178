import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography as MuiTypography } from '@material-ui/core';

const useStyles = makeStyles({
  textColor: {
    color: '#fff'
  }
});

export const HeroTitle = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiTypography className={classes.textColor} variant="h3" component="h1" gutterBottom {...rest}>
      {children}
    </MuiTypography>
  );
};

HeroTitle.propTypes = { children: PropTypes.node.isRequired };

export const HeroSubtitle = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiTypography className={classes.textColor} variant="h5" component="h2" gutterBottom {...rest}>
      {children}
    </MuiTypography>
  );
};

HeroSubtitle.propTypes = { children: PropTypes.node.isRequired };

export const Title = ({ componentElementName, children, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiTypography
      className={classes.textColor}
      variant="h4"
      component={componentElementName}
      gutterBottom
      {...rest}
    >
      {children}
    </MuiTypography>
  );
};

Title.propTypes = {
  componentElementName: PropTypes.string,
  children: PropTypes.node.isRequired
};
Title.defaultProps = {
  componentElementName: 'h3'
};

export const Overline = ({ children, ...rest }) => (
  <MuiTypography variant="overline" color="secondary" gutterBottom {...rest}>
    {children}
  </MuiTypography>
);

Overline.propTypes = { children: PropTypes.node.isRequired };

export const Text = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiTypography
      className={classes.textColor}
      variant="subtitle1"
      component="p"
      gutterBottom
      {...rest}
    >
      {children}
    </MuiTypography>
  );
};

Text.propTypes = { children: PropTypes.node.isRequired };
