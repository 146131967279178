import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(() => ({
  checkboxStyle: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eeebe5'
    }
  }
}));

const OnboardingRadio = ({ title, ...rest }) => {
  const classes = useStyles();

  return (
    <Box
      component={FormControlLabel}
      label={title}
      value={title}
      bgcolor="#FFF"
      width="100%"
      p={2}
      control={<Radio color="primary" />}
      className={classes.checkboxStyle}
      {...rest}
    />
  );
};

OnboardingRadio.propTypes = {
  title: PropTypes.string.isRequired
};

export default OnboardingRadio;
