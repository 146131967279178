import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  successBg: {
    background: theme.palette.primary.main
  },
  successText: {
    color: '#fff'
  },
  errorBg: {
    background: theme.palette.error.main
  },
  errorText: {
    color: '#fff'
  }
}));

const MessageBox = ({ text, isError, ...rest }) => {
  const classes = useStyles();
  return (
    <Box
      mt={2}
      mb={1}
      p={2}
      borderRadius={4}
      className={isError ? classes.errorBg : classes.successBg}
    >
      <Typography
        variant="subtitle1"
        className={isError ? classes.errorText : classes.successText}
        {...rest}
      >
        {text}
      </Typography>
    </Box>
  );
};

MessageBox.propTypes = {
  text: PropTypes.string.isRequired,
  isError: PropTypes.bool
};

MessageBox.defaultProps = {
  isError: false
};

export default MessageBox;
