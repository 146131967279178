import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import { Input } from '~components/form-inputs-v2';
import ClickableText from '~components/buttons/ClickableText/ClickableText';
import { useRegistration } from '~hooks/useAuth';
import Link from '~components/Link';
import { VIEWS } from '../constants';
import { useSharedStyles } from '../styles';

const useStyles = makeStyles(() => ({
  termsText: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#3C3B3C'
  },
  termsLink: {
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'inherit'
  }
}));

const CreateAccountView = ({ changeView, username }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [regState, registerUser] = useRegistration();
  const { register, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const isLoading = regState?.isLoading;

  useEffect(() => {
    if (regState?.data?.user) {
      changeView(VIEWS.CREATE_ACCOUNT_SUCCESS_VIEW);
    }
  }, [changeView, regState]);

  const onSubmit = formData => {
    registerUser({ ...formData, username: formData?.username || username });
  };

  const handleEditEmail = () => {
    changeView(VIEWS.INITIAL_VIEW);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(isVisible => !isVisible);
  };

  return (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid className={sharedClasses.root}>
            <Grid item xs={12} className={sharedClasses.gapLg}>
              <Typography component="h5" className={sharedClasses.title}>
                Create your free account
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Input
                label="Email Address"
                id="email-address"
                type="email"
                name="username"
                endAdornment={
                  <InputAdornment position="end">
                    <ClickableText
                      className={sharedClasses.inputAdornment}
                      onClick={handleEditEmail}
                    >
                      Edit
                    </ClickableText>
                  </InputAdornment>
                }
                value={username}
                inputRef={register}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Input
                label="Password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                inputProps={{
                  minLength: 6
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <ClickableText
                      className={sharedClasses.inputAdornment}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </ClickableText>
                  </InputAdornment>
                }
                inputRef={register}
                required
              />
              {regState?.error && (
                <Typography component="h6" className={sharedClasses.errorText}>
                  {String(regState?.error)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Typography component="h6" className={classes.termsText}>
                By creating an account you agree to the updated{' '}
                <a href="/terms" target="_blank" className={classes.termsLink}>
                  Terms of Sale, Terms of Service
                </a>{' '}
                and{' '}
                <a href="/privacy" target="_blank" className={classes.termsLink}>
                  Privacy Policy
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapLg}>
              <ButtonSubmit
                id="guest-login-popup-create-account-btn"
                className={sharedClasses.submitButton}
                disableElevation
                disabled={isLoading}
              >
                Create free account
              </ButtonSubmit>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h6" className={sharedClasses.joinPremiumDescription}>
                Unlimited Access to over 1,000 Torah podcasts and videos.
              </Typography>
              <Box
                component="h6"
                fontSize="16px"
                textAlign="center"
                marginTop="6px"
                marginBottom="0px"
              >
                <Link to="/subscribe">Join Premium</Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

CreateAccountView.propTypes = {
  username: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired
};

export default CreateAccountView;
