/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { PayPalButton } from 'react-paypal-button-v2';

const IS_PAYPAL_SANDBOX = process.env.IS_PAYPAL_SANDBOX; // eslint-disable-line
const PAYPAL_APP_ID = process.env.PAYPAL_APP_ID; // eslint-disable-line

const isSandbox = IS_PAYPAL_SANDBOX === 'true';

// NOTE: Documentation for 'react-paypal-button-v2' says to put the `'sb'` string token instead of the client ID for sandbox queries.
// I'm not sure how the PayPal will distinguish the sandbox transactions without the application ID in this way
//   but the library does not provide any other way to specify that it's the sandbox requests.
// Maybe the PayPal application used will be determined by the PayPal user account used to "pay" in the checkout?
const clientId = isSandbox ? 'sb' : PAYPAL_APP_ID;

const options = {
  clientId,
  debug: isSandbox,
  'disable-funding': ['card'],
  currency: 'USD'
};

const style = {
  color: 'white',
  shape: 'rect',
  label: 'checkout'
};

const PaypalExpressCheckoutButton = ({ amount, onCancel, onSuccess, ...rest }) => (
  <PayPalButton
    amount={amount}
    style={style}
    options={options}
    onSuccess={onSuccess}
    onCancel={onCancel}
    {...rest}
  />
);

PaypalExpressCheckoutButton.propTypes = {
  amount: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

PaypalExpressCheckoutButton.defaultProps = {
  onCancel: () => console.warn('PayPal Express Checkout Cancelled.')
};

export default PaypalExpressCheckoutButton;
