import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  box: {
    '&:hover': {
      background: '#f8f8f8'
    }
  },
  imgStyles: {
    display: 'flex',
    maxWidth: '90%',
    minHeight: '80px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100%',
      minHeight: 'unset'
    }
  }
}));

const Next = ({ index, isCurrent, title, cover_image }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box p={1} display="block" className={classes.box}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
        <Grid item xs={12} md={12} lg={6}>
          <img
            src={cover_image?.url}
            alt={title || ''}
            style={{ display: 'flex', maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography
            variant="body2"
            component="h4"
            style={{ color: isCurrent ? theme.palette.primary.main : theme.palette.text.primary }}
          >
            {index ? `${index}. ${title}` : title}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

Next.propTypes = {
  index: PropTypes.number,
  isCurrent: PropTypes.bool,
  title: PropTypes.string,
  cover_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired
};

Next.defaultProps = {
  index: null,
  isCurrent: false,
  title: ''
};

export default Next;
