import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Link from '~components/Link';
import GuidesMaterialExpansionPanel from './GuidesMaterialsExpansionPanel';

const GuidesHolidayGuides = ({ materials }) => (
  <Box width="100%" py={3}>
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="h4" component="h4" gutterBottom>
          Holiday Guides
        </Typography>
      </Grid>
      <Grid item>
        <Box bgcolor="#474c5a" color="#ffffff" borderRadius={4} px={1} mb={1}>
          <Typography variant="overline" color="inherit">
            Free
          </Typography>
        </Box>
      </Grid>
    </Grid>
    <Typography variant="subtitle2" gutterBottom>
      If you are interested in sponsoring one of our Holiday guides, please email{' '}
      <Link to="mailto:info@alephbeta.org?Subject=Holiday%20Guide%20Sponsoring">
        info@alephbeta.org.
      </Link>
      Our holiday guides are available for free.
    </Typography>
    <Box py={3}>
      <GuidesMaterialExpansionPanel materials={materials} />
    </Box>
  </Box>
);

GuidesHolidayGuides.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      panel_title: PropTypes.string.isRequired,
      materials: GuidesMaterialExpansionPanel.PropTypes
    })
  ).isRequired
};

export default GuidesHolidayGuides;
