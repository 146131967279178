import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import LoadingSpinner from '~components/LoadingSpinner';
import Container from '~components/Container';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import useApi, { SEARCH_BY_TERM } from '~hooks/useApi';
import SearchInputForm from '~components/search/SearchInputForm';
import SearchResults from '~components/search/SearchResults';
import SearchStartingPage from '~components/search/SearchStartingPage';
import useTopSearches from '~hooks/useTopSearches';
import { pushDataLayerEvent } from '~utils/data-layer';
import { gaSearchEvent } from '~utils/google-analytics';
import useRecentSearches from '~hooks/useRecentSearches';
import parseSearchTermQueryString from '~utils/parse-query-string';

const SearchUI = ({ location, recommendedSearchTerms }) => {
  const { register, handleSubmit } = useForm();
  const { session } = useUserContext();
  const { isAdmin } = session;

  const [state, makeRequest] = useApi();
  const [recentSearchesState, setRecentSearchesState] = useRecentSearches();

  const [topSearchesState, getTopSearches] = useTopSearches();
  const { data: topSearchesData } = topSearchesState;

  const { searchTerms } = recentSearchesState;
  const { isLoading, isError, data, error } = state;

  const { search } = location; // query string from the url

  const decodedUrlSearchParam = search && parseSearchTermQueryString(search);

  const hasSearchResults = data && data.result && data.result.length > 0;
  const hasNotFoundSearchResults = data && data.result && data.result.length === 0;

  const modifiedSearchTerms = [...searchTerms];

  const onInputSubmit = params => {
    const { term } = params;

    // Keeping the length of the recent searches to ten
    if (searchTerms.length >= 10) {
      modifiedSearchTerms.shift();
    }

    modifiedSearchTerms.push(term);

    setRecentSearchesState({
      searchTerms: modifiedSearchTerms
    });

    const encodedUrlSearchParam = encodeURIComponent(term);

    navigate(`/search?t=${encodedUrlSearchParam}`);
  };

  useEffect(() => {
    getTopSearches();
    if (search) {
      const requestParams = SEARCH_BY_TERM({ term: decodedUrlSearchParam });
      makeRequest(requestParams);
      gaSearchEvent(decodedUrlSearchParam);
      pushDataLayerEvent('search', { searchTerm: decodedUrlSearchParam });
    }
  }, [search]);

  return (
    <Container>
      <SearchInputForm
        onSubmit={handleSubmit(onInputSubmit)}
        isError={isError}
        error={error}
        register={register}
        isLoading={isLoading}
        searchTerm={decodedUrlSearchParam}
      />
      {isLoading && <LoadingSpinner style={{ margin: '0 auto', paddingTop: '40px' }} />}

      {hasSearchResults ? (
        <SearchResults
          data={data}
          searchTerm={decodedUrlSearchParam}
          isLoading={isLoading}
          isAdmin={isAdmin}
          topSearchesData={topSearchesData}
        />
      ) : (
        <SearchStartingPage
          recommendedSearchTerms={recommendedSearchTerms}
          searchTerms={searchTerms}
          setRecentSearchesState={setRecentSearchesState}
          hasNotFoundSearchResults={hasNotFoundSearchResults}
          searchTerm={decodedUrlSearchParam}
          isLoading={isLoading}
          isAdmin={isAdmin}
          topSearchesData={topSearchesData}
        />
      )}
    </Container>
  );
};

SearchUI.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  recommendedSearchTerms: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SearchUI;
