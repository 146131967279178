import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import HtmlExcerpt from '~components/HtmlExcerpt';

import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Link from '~components/Link';

const useStyles = makeStyles(() => ({
  media: {
    display: 'flex',
    maxWidth: '100%'
  }
}));

const FeaturedVideo = ({ cover_image, parsha_name, title, description, url }) => {
  const classes = useStyles();
  return (
    <Container background="#f6f6f6">
      <Box py={3}>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} md={5}>
            <Link to={url}>
              <img
                src={cover_image.url}
                alt={cover_image.alt}
                className={`${classes.media} lazyload`}
              />
            </Link>
          </Grid>

          <Grid item xs={12} md={5}>
            <Typography variant="overline" color="primary">
              Featured {parsha_name} Video
            </Typography>

            <Typography variant="h5" component="h4">
              {title}
            </Typography>

            <HtmlExcerpt html={description.text} />

            <ButtonSubmit component={GatsbyLink} to={url} type="button" fullWidth={false}>
              Watch Video
            </ButtonSubmit>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

FeaturedVideo.propTypes = {
  parsha_name: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  url: PropTypes.string.isRequired,
  cover_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired
};

FeaturedVideo.defaultProps = {
  parsha_name: null
};

export default FeaturedVideo;
