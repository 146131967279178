import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import unfurl from '~prismic/unfurl/podcasts';
import Container from '~components/Container';
import Page from '~layout/Page';
import PodcastHero from '~src/modules/podcasts/components/PodcastHero';
import HostContainer from '~src/modules/podcasts/components/HostContainer';
import PodcastDescription from '~src/modules/podcasts/components/PodcastDescription';
import PodcastEpisodes from '~src/modules/podcasts/components/PodcastEpisodes';
import SpotifyPlayer from '~src/modules/podcasts/components/SpotifyPlayer';
import BuzzsproutPlayer from '~src/modules/podcasts/components/BuzzsproutPlayer';
import PodcastPreviousSeasons from '~src/modules/podcasts/components/PodcastPreviousSeasons';
import PodcastUneditedContent from '~src/modules/podcasts/components/PodcastUneditedContent';
import Divider from '~components/Divider';
import useApi, {
  PODCAST_START_EPISODE,
  PODCAST_START_LISTENING,
  PODCAST_CLOSE_PLAYER
} from '~hooks/useApi';
import { makeAnalyticsPayloadForPodcastPageEpisode } from '~utils/analytics';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import Popups, { POPUPS_MAP } from '~layout/Popups';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import { useLocation } from '@reach/router';

const IS_BUZZSPROUT_PODCAST_PLAYER_ENABLED = isFeatureEnabled(
  FEATURE_FLAGS.BUZZSPROUT_PODCAST_PLAYER
);

const PodcastsPage = ({ data }) => {
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [, makeRequest] = useApi();
  const { session } = useUserContext();
  const { isSubscriber } = session;

  const podcasts = unfurl(data);

  const {
    uid,
    hosts,
    // prismicId,
    providers,
    coverColor,
    playButtonColor,
    playButtonTextColor,
    podcastTitle,
    podcastDescription,
    podcastSponsorship,
    podcastSubtitle,
    podcastSeries,
    podcastThumbnail,
    previousSeasons,
    uneditedContent,
    seo,
    rssFeedInstructions
    // requiredSubscriptionPlan
  } = podcasts;

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const seasonParam = search.get('season');

  const selectedSeason = useMemo(
    () => podcastSeries.find(series => series.uid === seasonParam) || podcastSeries[0],
    [seasonParam, podcastSeries]
  );

  const handlePlay = (title, seasonUid) => {
    const season = podcastSeries.find(series => series.uid === seasonUid) || podcastSeries[0];
    if (!season) {
      return; // when there are no seasons added to a podcast. Just not to break the page.
    }
    const episode = season.episodes.find(ep => ep.title === title);

    if (episode) {
      setSelectedEpisode(episode);
      makeRequest(
        PODCAST_START_EPISODE({ episode: makeAnalyticsPayloadForPodcastPageEpisode(episode) })
      );
    }
  };

  const handleStartListening = selectedSeasonUid => {
    const season =
      podcastSeries.find(series => series.uid === selectedSeasonUid) || podcastSeries[0];
    if (!season || !season.episodes) {
      return; // when there are no seasons added to a podcast. Just not to break the page.
    }
    const episode = season.episodes[season.episodes.length - 1];
    setSelectedEpisode(episode);
    makeRequest(
      PODCAST_START_LISTENING({ episode: makeAnalyticsPayloadForPodcastPageEpisode(episode) })
    );
  };

  const handleClose = () => {
    if (selectedEpisode) {
      makeRequest(
        PODCAST_CLOSE_PLAYER({
          episode: makeAnalyticsPayloadForPodcastPageEpisode(selectedEpisode)
        })
      );
    }
    setSelectedEpisode(null);
  };

  const handleClosePopup = () => {
    setSelectedEpisode(null);
  };

  const showSubscribePopup = selectedEpisode?.isSubscriberEpisode && !isSubscriber;

  const showSpotifyPlayer = !IS_BUZZSPROUT_PODCAST_PLAYER_ENABLED
    ? selectedEpisode?.spotifyUrl // when Buzzsprout Player is not enabled, just check spotify url is set
    : !selectedEpisode?.playerEmbedCode && selectedEpisode?.spotifyUrl; // when Buzzsprout Player is enabled, when there's no embed code, let's show the spotify player

  const showBuzzsproutPlayer =
    IS_BUZZSPROUT_PODCAST_PLAYER_ENABLED && selectedEpisode?.playerEmbedCode;

  return (
    <Page seo={seo} hideNewsletterSignup redirectLoggedInUsersToMembers>
      <PodcastHero
        selectedSeason={selectedSeason}
        coverColor={coverColor}
        playButtonColor={playButtonColor}
        playButtonTextColor={playButtonTextColor}
        podcastCover={podcastThumbnail}
        play={handleStartListening}
        providers={providers}
        description={podcastDescription}
        title={podcastTitle}
        uid={uid}
      />
      <Container background="#F1F1F1">
        <PodcastDescription podcastSubtitle={podcastSubtitle} sponsorship={podcastSponsorship} />

        <Divider spacing={8} />

        <PodcastPreviousSeasons previousSeasons={previousSeasons} />

        <HostContainer hosts={hosts} />
      </Container>
      {uneditedContent?.playlistUrl && <PodcastUneditedContent {...uneditedContent} />}

      <Container background="#F1F1F1">
        <PodcastEpisodes
          seasons={podcastSeries}
          rssFeedInstructions={rssFeedInstructions}
          handlePlayEpisode={handlePlay}
          uid={uid}
        />
      </Container>

      {!showSubscribePopup && showSpotifyPlayer && (
        <SpotifyPlayer
          key={selectedEpisode.spotifyUrl}
          url={selectedEpisode.spotifyUrl}
          title={podcastTitle}
          handleClose={handleClose}
        />
      )}
      {!showSubscribePopup && showBuzzsproutPlayer && (
        <BuzzsproutPlayer embedCode={selectedEpisode.playerEmbedCode} handleClose={handleClose} />
      )}
      {showSubscribePopup && (
        <Popups
          which={POPUPS_MAP.UPGRADE_TO_PREMIUM_FOR_FEATURE}
          isPopupOpen
          handleClose={handleClosePopup}
        />
      )}
    </Page>
  );
};

PodcastsPage.propTypes = {
  data: PropTypes.shape().isRequired
};

export default withPrismicPreview(PodcastsPage);

export const query = graphql`
  query ($uid: String!) {
    prismicPodcastPlaylist(uid: { eq: $uid }) {
      _previewable
      uid
      prismicId
      type
      data {
        required_subscription_plan
        cover_color
        play_button_color
        play_button_text_color
        podcast_thumbnail {
          url
          alt
        }
        podcast_title {
          text
        }
        podcast_description {
          text
          html
        }
        podcast_sponsorship {
          text
          html
        }
        podcast_subtitle {
          text
          html
        }
        rss_feed_instruction_video
        providers {
          ... on PrismicPodcastPlaylistDataProvidersProvidersSlice {
            id
            items {
              provider_id
              url {
                url
              }
            }
          }
        }
        hosts {
          ... on PrismicPodcastPlaylistDataHostsHost {
            id
            items {
              host {
                document {
                  ... on PrismicAuthor {
                    id
                    data {
                      name
                      role
                      avatar {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
        }
        episodes {
          ... on PrismicPodcastPlaylistDataEpisodesEpisode {
            id
            primary {
              series_uid
              series_title {
                text
                html
              }
              hero_background {
                url
                alt
              }
              episode_play_button_color
              episode_play_button_text_color
              spotify_listen_url
              apple_listen_url
              google_listen_url
              rss_feed_url
              subscriber_rss_feed_url
            }
            items {
              podcast_episode {
                slug
                uid
                document {
                  ... on PrismicPodcastEpisode {
                    id
                    data {
                      color
                      title
                      created_at
                      episode
                      season
                      part
                      length_in_seconds
                      cover_image {
                        alt
                        url
                      }
                      wistia_stream_url {
                        url
                      }
                      spotify_url {
                        url
                      }
                      is_subscriber_episode
                      player_embed_code
                      audio_url {
                        url
                      }
                      description {
                        text
                      }
                      transcript {
                        text
                      }
                    }
                    prismicId
                  }
                }
              }
            }
          }
        }
        previous_seasons {
          cta_text
          previous_playlist {
            slug
            uid
            document {
              ... on PrismicPlaylist {
                uid
                url
              }
            }
          }
        }
        unedited_content_title
        unedited_content_description {
          html
        }
        unedited_content_playlist {
          url
        }
        unedited_content_cta_text
        unedited_content_background_color
        unedited_content_text_color
        unedited_content_thumbnail {
          url
          alt
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
      }
    }
  }
`;
