import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { LightenColor } from '~utils/playlist-helpers';

const useStyles = makeStyles(theme => ({
  playlistCard: {
    cursor: 'pointer',
    position: 'relative',
    paddingTop: '16px',
    height: '130px',
    overflow: 'hidden',
    fontSize: '16px',
    lineHeight: '1.1em',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '8px',
      paddingRight: '42px',
      display: 'flex',
      alignItems: 'flex-start'
    },
    [theme.breakpoints.up('lg')]: {
      height: '190px'
    }
  },
  selectedCard: {
    cursor: 'pointer',
    position: 'relative',
    paddingTop: '16px',
    fontSize: '16px',
    height: '130px',
    '&::before': {
      border: '3px solid #2591EE',
      borderRadius: '8px',
      content: `''`,
      padding: '5px',
      width: `calc(100% + 10px)`,
      position: 'absolute',
      height: 'calc(100% + 10px)',
      top: '-5px',
      left: '-5px'
    },
    [theme.breakpoints.only('xs')]: {
      height: '75px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '190px'
    }
  },
  // NOTE: the icon is placed in the bottom-right corner and so *extends upwards and leftwards*
  iconPosition: {
    position: 'absolute',
    display: 'flex',
    maxWidth: '100%',
    right: 0,
    bottom: 0
  },
  playlistCardTitle: {
    fontSize: '1em',
    fontWeight: '500',
    color: '#fff',
    [theme.breakpoints.only('xs')]: {
      fontSize: 'clamp(12px, 0.8em, 16px)'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.3em'
    }
  },
  inactiveCard: {
    filter: 'grayscale(100%)',
    cursor: 'not-allowed'
  }
}));

const CurationPageStoryCard = ({
  card_title,
  card_color,
  card_icon,
  handleClick,
  card_alt_title,
  inactive,
  selectedPlaylist
}) => {
  const classes = useStyles();
  const [currentCardTitle, setCardTitle] = useState(card_title);

  const handleHoverEnter = () => {
    if (inactive) {
      setCardTitle('Coming Soon');
      return;
    }
    if (card_alt_title) {
      setCardTitle(card_alt_title);
    }
  };
  const handleHoverLeave = () => {
    if (currentCardTitle !== card_title) {
      setCardTitle(card_title);
    }
  };

  const typographyClasses = classNames({
    [classes.selectedCard]: selectedPlaylist === card_title,
    [classes.playlistCard]: !(selectedPlaylist === card_title),
    [classes.inactiveCard]: inactive
  });
  return (
    <Box
      disabled
      id={card_title}
      onClick={handleClick}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      px={2}
      bgcolor={selectedPlaylist === card_title ? `#${LightenColor(card_color, -30)}` : card_color}
      borderRadius={6}
      width="100%"
      className={typographyClasses}
    >
      <Typography className={classes.playlistCardTitle} gutterBottom>
        {currentCardTitle}
      </Typography>

      <Box
        component="img"
        src={card_icon.url}
        display="flex"
        maxWidth="100%"
        className={classes.iconPosition}
      />
    </Box>
  );
};

CurationPageStoryCard.propTypes = {
  card_title: PropTypes.string.isRequired,
  card_color: PropTypes.string.isRequired,
  card_icon: PropTypes.shape({ url: PropTypes.string }).isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedPlaylist: PropTypes.string,
  card_alt_title: PropTypes.string,
  inactive: PropTypes.bool.isRequired
};

CurationPageStoryCard.defaultProps = {
  selectedPlaylist: null,
  card_alt_title: null
};

export default CurationPageStoryCard;
