import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography, Box } from '@material-ui/core';
import Link from '~components/Link';
import { getResourceName } from '~components/resource-icon/ResourceIcon';
import useConversionFramework from '~hooks/useConversionFramework';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { planIdFromString } from '~utils/playlist-helpers';
import authorizeUser from '~utils/authorization-helper';

const useStyles = makeStyles({
  body: {
    width: '365px',
    height: props => props.cardHeight,
    padding: '10px',
    border: '1px solid #DDDDDE',
    borderRadius: '8.5px',
    boxShadow: '0px 2px 0px 0px #d3d3d3',
    position: 'relative',
    backgroundColor: '#FFFFFF'
  },
  resourceInfo: {
    margin: '15px'
  },
  title: {
    fontSize: '18px',
    fontWeight: '600'
  },
  subtitle: {
    fontSize: '14px',
    marginTop: '5px',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical'
  },
  resourceType: {
    fontSize: '12px',
    color: '#B8B8B8',
    marginTop: '5px'
  },
  button: {
    backgroundColor: '#009EFF',
    fontWeight: '700',
    borderRadius: '8.5px',
    padding: '8px 35px 8px 35px',
    fontSize: '14px',
    position: 'absolute',
    bottom: '30px',
    marginLeft: '15px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#0087ff'
    }
  },
  imageContainer: {
    top: '16px',
    height: '182px'
  },

  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '12px'
  }
});

const getResourceUrl = (resource, url) => {
  if (resource?.file?.url) {
    return { href: resource?.file?.url, target: '_blank' };
  }
  if (resource?.url) {
    return { href: resource?.url, target: '_self' };
  }
  return { href: url, target: '_self' };
};

const ResourceCard = ({ resource, url }) => {
  const imagePreview = resource.seo_image?.url || resource.preview_image?.url;

  const cardHeight = imagePreview ? '490px' : '300px';

  const classes = useStyles({ cardHeight });
  const { session } = useUserContext();
  const { isMarketingSite } = useConversionFramework();

  const materialDescription = resource.description && resource.description;
  const articleDescription = resource.description_text && resource.description_text.text;
  const holidayDescription = resource.slices && resource.slices[0]?.primary?.description;
  const contentText = resource.content?.text && `${resource.content?.text.substring(0, 122)}...`;

  const descriptionToShow =
    materialDescription || articleDescription || holidayDescription || contentText;

  const materialType = resource.material_type || resource.typeName;

  const requiredSubscriptionLevel = planIdFromString(resource.required_subscription_plan);

  const { hasAccess, redirectPath, redirectProps } = authorizeUser(
    requiredSubscriptionLevel,
    session,
    false,
    isMarketingSite
  );

  const redirectState = !hasAccess && { ...redirectProps };
  const { href, target } = getResourceUrl(resource, url);

  const viewResourceButtonText = {
    PrismicArticle: 'View',
    PrismicMaterial: 'View Guide',
    PrismicBlogPost: 'View Blog Post',
    'Holiday Guide': 'Print Now'
  };

  return (
    <Grid
      container
      className={classes.body}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
      xs={12}
    >
      {imagePreview && (
        <Grid item>
          <Box className={classes.imageContainer}>
            <img className={classes.image} src={imagePreview} alt="resource" />
          </Box>
        </Grid>
      )}
      <Grid item className={classes.resourceInfo}>
        <Typography className={classes.title}>
          {resource.title || resource.title_text?.text}
        </Typography>
        <Typography className={classes.resourceType}>{getResourceName(materialType)}</Typography>
        <Typography className={classes.subtitle}>
          {descriptionToShow?.text || descriptionToShow || ''}
        </Typography>
      </Grid>
      <Link
        underline="none"
        className={classes.button}
        to={hasAccess ? href : redirectPath}
        target={hasAccess ? target : '_self'}
        {...redirectState}
      >
        {viewResourceButtonText[materialType] || 'View'}
      </Link>
    </Grid>
  );
};

ResourceCard.propTypes = {
  url: PropTypes.string,
  resource: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    material_type: PropTypes.string,
    typeName: PropTypes.string,
    description_text: PropTypes.shape(),
    title_text: PropTypes.shape(),
    content: PropTypes.shape(),
    slices: PropTypes.arrayOf(PropTypes.shape()),
    url: PropTypes.string,
    file: PropTypes.shape(),
    seo_image: PropTypes.shape(),
    preview_image: PropTypes.shape(),
    required_subscription_plan: PropTypes.string
  })
};

ResourceCard.defaultProps = {
  url: '',
  resource: {}
};

export default ResourceCard;
