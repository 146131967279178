import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import VideoActionBar from '~components/video-action-bar/VideoActionBar';
import {
  LabelNew,
  LabelPremium,
  LabelAudio,
  LabelProducer
} from '~components/playlist-card/PlaylistLabels';

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: 'bold'
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  labelsSpacing: {
    margin: '12px 0px',
    '& > *': {
      marginRight: theme.spacing() / 2
    }
  },
  container: {
    marginTop: '16px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    }
  }
}));

const VideoHeader = ({ videoTitle, playlistTitle, labels, contentType, showGiftPopup }) => {
  const classes = useStyles();

  const { isNew, isAudio, isPremium, isProducer } = labels;
  return (
    <div className={classes.container}>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={12} sm="auto">
          <Typography variant="h5" component="h1" className={classes.boldText} gutterBottom>
            {videoTitle}
          </Typography>
          <Typography variant="h6" component="h2" gutterBottom>
            {playlistTitle}
          </Typography>
          <Box className={classes.labelsSpacing}>
            {isNew && <LabelNew />}
            {isAudio && <LabelAudio />}
            {isPremium && <LabelPremium />}
            {isProducer && <LabelProducer />}
          </Box>
        </Grid>
        <Grid item xs={12} sm="auto">
          <VideoActionBar
            title={playlistTitle}
            videoTitle={videoTitle}
            contentType={contentType}
            showGiftPopup={showGiftPopup}
          />
        </Grid>
      </Grid>
    </div>
  );
};

VideoHeader.propTypes = {
  videoTitle: PropTypes.string.isRequired,
  playlistTitle: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  showGiftPopup: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired
};

export default VideoHeader;
