import createPersistedState from 'use-persisted-state';

const localStorageUserKey = 'checkout';
const useCheckoutState = createPersistedState(localStorageUserKey);

export default (
  product = null,
  billingCycle = false,
  coupon = false,
  isGift = false,
  donationNotes = {}
) => {
  const [checkoutState, setCheckoutState] = useCheckoutState({
    product,
    billingCycle,
    coupon,
    isGift,
    donationNotes
  });

  return [checkoutState, setCheckoutState];
};
