import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppleSignInButtonImage from '~images/other/apple-button.png';

const PrimaryVariantButton = ({ signInUrl }) => (
  <Box
    component="a"
    textAlign="center"
    maxWidth="100%"
    bgcolor="#000000"
    display="flex"
    justifyContent="center"
    alignItems="center"
    borderRadius="5px"
    style={{ cursor: 'pointer' }}
    href={signInUrl}
  >
    <img src={AppleSignInButtonImage} alt="Apple Sign In" style={{ maxWidth: '100%' }} />
  </Box>
);

PrimaryVariantButton.propTypes = {
  signInUrl: PropTypes.string.isRequired
};

export default PrimaryVariantButton;
