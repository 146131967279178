import products from '~utils/products';

export function makeSubscriptionPlanName(user = {}) {
  const { subscription_plan_id } = user;
  if (!subscription_plan_id) {
    return '';
  }

  const product = products[user.subscription_plan_id];
  return product ? product.name : '';
}

export function clearUser() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    userId: undefined,
    userSubscriptionPlanName: undefined,
    userSubscriptionPlanPeriod: undefined
  });
}

export function setUser(user) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    userId: user.id || undefined,
    userSubscriptionPlanName: makeSubscriptionPlanName(user) || undefined,
    userSubscriptionPlanPeriod: user.subscription_plan_period || undefined
  });
}

export function pushDataLayerEvent(eventName, properties = {}) {
  window.dataLayer = window.dataLayer || [];
  const event = {
    event: eventName,
    ...properties
  };
  window.dataLayer.push(event);
}

export function pushDataLayerEcommerceEvent(eventName, properties = {}) {
  window.dataLayer = window.dataLayer || [];
  /**
   * It's recommended to clear ecommerce object before pushing a new ecommerce event
   * Clearing the object will prevent multiple ecommerce events on a page from affecting each other.
   * Resource - https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
   */
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

  pushDataLayerEvent(eventName, properties);
}
