import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PlaylistIcon from '@material-ui/icons/PlaylistPlay';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import Divider from '~components/Divider';
import Link from '~components/Link';
import HtmlExcerpt from '~components/HtmlExcerpt';
import HighlightExpansionPanel from './SearchHighlightExpansionPanel';

const useStyles = makeStyles({
  episodeTitle: {
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  episodePlaylistSubtitle: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '15px'
  },
  media: {
    display: 'flex',
    maxWidth: '100%'
  },
  linkStyle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    marginRight: '20px'
  },
  iconStyle: {
    fontSize: '20px',
    marginRight: '6px'
  }
});

const PodcastEpisodeCard = ({
  searchTerm,
  listPosition,
  episode: { url, title, description, playlist_title, cover_image_url, relevance, highlight, id },
  classes
}) => {
  const { session } = useUserContext();
  const { isAdmin, isUserStateLoading } = session;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item md={3}>
          <Link
            to={url}
            underline="hover"
            color="textPrimary"
            style={{ width: '100%' }}
            state={{
              searchTerm,
              searchResultsListPosition: listPosition,
              clickLocation: 'thumbnail',
              searchResultType: 'podcast_episode'
            }}
          >
            <img src={cover_image_url} alt="" className={classes.media} />
          </Link>
        </Grid>
        <Grid item md={8}>
          <Typography title={`Relevance score: ${relevance}`} className={classes.episodeTitle}>
            <Link
              to={url}
              underline="hover"
              color="textPrimary"
              state={{
                searchTerm,
                searchResultsListPosition: listPosition,
                clickLocation: 'title',
                searchResultType: 'podcast_episode'
              }}
            >
              {title}
            </Link>
          </Typography>
          <Typography className={classes.episodePlaylistSubtitle}>
            ↳{' '}
            {playlist_title
              ? `from the playlist «${playlist_title}»`
              : `not assigned to a playlist. Tell us about it!`}
          </Typography>
          {description && <HtmlExcerpt html={description} />}
          {isAdmin && !isUserStateLoading && (
            <>
              <Grid item container direction="row">
                <Grid item>
                  <Link
                    to={`https://aleph-beta.prismic.io/documents~k=podcast_episode&b=working&c=published&l=en-us/${id}/`}
                    className={classes.linkStyle}
                    target="_blank"
                  >
                    <PlaylistIcon className={classes.iconStyle} /> Edit Podcast Episode document
                  </Link>
                </Grid>
              </Grid>
              {highlight && (
                <Grid item>
                  <HighlightExpansionPanel highlights={highlight} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
const PodcastEpisodePropTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  cover_image_url: PropTypes.string,
  description: PropTypes.string,
  playlist_uid: PropTypes.string,
  playlist_title: PropTypes.string,
  relevance: PropTypes.number,
  highlight: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
};
PodcastEpisodeCard.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  listPosition: PropTypes.number.isRequired,
  episode: PropTypes.shape(PodcastEpisodePropTypes),
  classes: PropTypes.shape({
    media: PropTypes.string,
    episodeTitle: PropTypes.string,
    episodePlaylistSubtitle: PropTypes.string,
    linkStyle: PropTypes.string,
    iconStyle: PropTypes.string
  })
};
PodcastEpisodeCard.defaultProps = {
  episode: {},
  classes: {}
};

const PodcastEpisodes = ({ searchTerm, episodes }) => {
  const classes = useStyles();

  return (
    <>
      {episodes.map((episode, index) => (
        <PodcastEpisodeCard
          key={`${episode.id} ${episode.title}`}
          episode={episode}
          classes={classes}
          searchTerm={searchTerm}
          listPosition={index + 1}
        />
      ))}
    </>
  );
};

PodcastEpisodes.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  episodes: PropTypes.arrayOf(PropTypes.shape(PodcastEpisodePropTypes))
};
PodcastEpisodes.defaultProps = {
  episodes: []
};

export default PodcastEpisodes;
