import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useApi, { SOFT_SALE_CLOSE } from '~hooks/useApi';
import PopupContent from './PopupContent';

const GuestBlockerPopup = ({ handlePopupClose, which, setPopupId, isPopupOpen, ...rest }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [, makeRequest] = useApi();

  const handleClose = async () => {
    if (which) {
      const params = SOFT_SALE_CLOSE({ which });
      await makeRequest(params);
    }

    if (handlePopupClose) {
      handlePopupClose();
      return;
    }
    if (setPopupId) {
      setPopupId(0);
    }

    setIsOpen(false);
  };

  return (
    <PopupContent
      {...rest}
      handleClose={handleClose}
      isOpen={isPopupOpen !== null ? isPopupOpen : isOpen}
    />
  );
};

GuestBlockerPopup.propTypes = {
  showTopCloseBtn: PropTypes.bool,
  setPopupId: PropTypes.func,
  id: PropTypes.string,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  which: PropTypes.number
};

GuestBlockerPopup.defaultProps = {
  showTopCloseBtn: false,
  setPopupId: null,
  id: undefined,
  isPopupOpen: null,
  handlePopupClose: null,
  which: null
};

export default GuestBlockerPopup;
