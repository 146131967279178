import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Dialog from '~components/Dialog';
import AbWhiteLogo from '~images/aleph-beta-logo-white.svg';
import LeftImage from '~images/popups/watch-time/devices-set-1.png';
import RightImage from '~images/popups/watch-time/devices-set-2.png';
import MobileImage from '~images/popups/watch-time/devices-set-3.png';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import Link from '~components/Link';
import { useTestId } from '~utils/set-testid';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import ButtonClosePopup from '../components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  scrollBody: {
    '&:after': {
      height: 'unset !important'
    }
  },
  paper: {
    margin: 0,
    color: '#FFFFFF',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      height: 'unset'
    }
  },
  logoImage: {
    marginLeft: '40px',
    marginTop: '18px'
  },
  bodyContainer: {
    position: 'absolute',
    left: '50%',
    top: '53%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
      left: 'unset',
      top: 'unset',
      transform: 'unset',
      padding: '0px 20px'
    }
  },
  topLabel: {
    textAlign: 'center',
    maxWidth: '363px',
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '140%',
    backgroundColor: '#FFE560',
    borderRadius: '6px',
    padding: '15px 30px',
    marginTop: 0,
    marginBottom: '50px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      marginBottom: '30px',
      padding: '10px 20px',
      maxWidth: '256px'
    }
  },
  headingText: {
    fontFamily: 'Inter',
    fontSize: '52px',
    lineHeight: '114%',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '482px',
    marginBottom: '39px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '117%',
      marginBottom: '12px'
    }
  },
  bodyText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '158%',
    fontWeight: 500,
    textAlign: 'center',
    maxWidth: '484px',
    marginBottom: '33px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '150%',
      fontWeight: 400,
      marginBottom: '29px'
    }
  },
  bottomContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '8px'
    }
  },
  bottomLightText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '142%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  continueWatchingTextBtn: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '142%',
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  bottomBoldTextLink: {
    '& > *': {
      color: '#FFFFFF',
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '142%',
      fontWeight: 600,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    '& > a:hover': {
      textDecoration: 'none'
    }
  },
  leftImage: {
    position: 'absolute',
    left: 0,
    top: '55%',
    transform: 'translate(0%, -50%)',
    maxWidth: '489px',
    [theme.breakpoints.down(1450)]: {
      width: '370px'
    },
    [theme.breakpoints.down(1250)]: {
      width: '280px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  rightImage: {
    position: 'absolute',
    right: 0,
    top: '55%',
    maxWidth: '489px',
    transform: 'translate(0%, -50%)',
    [theme.breakpoints.down(1450)]: {
      width: '370px'
    },
    [theme.breakpoints.down(1250)]: {
      width: '280px'
    }
  },
  mobileImage: {
    margin: 'auto',
    marginBottom: '8px',
    maxWidth: '323px'
  },
  subscribeBtn: {
    textAlign: 'center',
    padding: '22px 102px',
    fontWeight: 600
  },
  subscribeMobileBtn: {
    fontWeight: 600,
    padding: '9px 22px'
  }
}));

const PopupContent = ({ showTopCloseBtn, id = undefined, handleClose, isOpen }) => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const testid = useTestId(`access-blocking-popup-${id}`);

  return (
    <Dialog
      isOpen={isOpen}
      id={id}
      backdrop="#006CC3"
      background="transparent"
      maxWidth="100vw"
      classes={{ paper: classes.paper, scrollBody: classes.scrollBody }}
      disableScrollLock
      {...testid}
    >
      {!isMobileDevice && (
        <>
          <img src={AbWhiteLogo} alt="Aleph Beta logo" className={classes.logoImage} />
          <img src={LeftImage} alt="Aleph Beta on web and mobile" className={classes.leftImage} />
          <img src={RightImage} alt="Aleph Beta on web and mobile" className={classes.rightImage} />
        </>
      )}
      <div className={classes.bodyContainer}>
        <p className={classes.topLabel}>Register for a free account in order to keep watching</p>
        {isMobileDevice && (
          <img
            src={MobileImage}
            alt="Aleph Beta on web and mobile"
            className={classes.mobileImage}
          />
        )}
        <Typography className={classes.headingText}>
          Imagine if you had unlimited access to Torah like this, every day.
        </Typography>
        <Typography className={classes.bodyText}>
          You’ve come to the right place. Aleph Beta’s library of over a thousand videos and
          podcasts will help you fall in love with Torah – for the first time, or all over again.
        </Typography>
        <RoundedLinkButton
          text="Create Free Account"
          to={getNavigationPageLink('/checkout/register', isMarketingSite)}
          size={isMobileDevice ? 'mini' : 'large'}
          variant="white"
          externalClassName={isMobileDevice ? classes.subscribeMobileBtn : classes.subscribeBtn}
        />
        <div className={classes.bottomContainer}>
          <Typography className={classes.bottomLightText}>
            Already have an account?{' '}
            <span className={classes.bottomBoldTextLink}>
              <Link to={getNavigationPageLink('/login', isMarketingSite)}>Log in here</Link>
            </span>
          </Typography>
          <Typography className={classes.bottomLightText}>
            Want to be part of this?{' '}
            <span className={classes.bottomBoldTextLink}>
              <Link to="/subscribe">Support Us</Link>
            </span>
          </Typography>
        </div>
      </div>
      {showTopCloseBtn && (
        <ButtonClosePopup
          color="#FFFFFF"
          iconSize={isMobileDevice ? '20px' : '2rem'}
          top={isMobileDevice ? '10px' : '20px'}
          right={isMobileDevice ? '10px' : '20px'}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};

PopupContent.propTypes = {
  showTopCloseBtn: PropTypes.bool,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

PopupContent.defaultProps = {
  showTopCloseBtn: false,
  id: undefined,
  isOpen: null
};

export default PopupContent;
