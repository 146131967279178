import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import Link from '~components/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import RichText from '~components/RichText';
import DateIcon from '~images/icons/date-icon.svg';
import TimeIcon from '~images/icons/time-icon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E9E9FB',
    marginTop: '-150px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingTop: '60px'
    }
  },
  container: {
    padding: '0px 17px 50px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 17px 24px'
    }
  },
  contentArea: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '48px 84px 27px',
    borderRadius: '20px 20px 0px 0px',
    backgroundColor: '#3A3380',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '36px 25px 25px'
    },
    [theme.breakpoints.down('xs')]: {
      overflow: 'inherit'
    }
  },
  bottomArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '47px 36px 47px 84px',
    borderRadius: '0px 0px 20px 20px',
    backgroundColor: '#FBFBFB',
    boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0.10)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '25px'
    }
  },
  heroImage: {
    display: 'flex',
    maxWidth: '360px',
    maxHeight: '240px',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '193px',
      marginTop: '30px'
    }
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '615px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  topLabel: {
    backgroundColor: '#83d2f1',
    color: '#3f2d93',
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '700',
    padding: '10px 24px',
    fontFamily: 'Inter',
    width: 'fit-content',
    borderRadius: '10px',
    textAlign: 'center',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: '26px'
    },
    [theme.breakpoints.down('xs')]: {
      color: '#453563',
      fontSize: '14px',
      lineHeight: '14px',
      backgroundColor: '#ECE5FE',
      borderRadius: '8px'
    }
  },
  title: {
    color: '#ffffff',
    maxWidth: '600px',
    margin: '24px 0px 16px',
    '& > *': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      fontSize: '44px',
      fontWeight: '600',
      fontFamily: 'Inter',
      lineHeight: '115%',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      color: '#EAE4FD',
      maxWidth: '317px',
      margin: '16px 0px 8px'
    }
  },
  description: {
    color: '#ffffff',
    textTransform: 'uppercase',
    opacity: 0.8,
    marginTop: 0,
    marginBottom: 0,
    '& > *': {
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: '600',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        lineHeight: '158%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      opacity: 1,
      color: '#D8D6E6'
    }
  },
  quote: {
    marginTop: '13px',
    marginBottom: 0,
    color: '#ffffff',
    opacity: 0.7,
    textTransform: 'capitalize',
    '& > *': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      fontSize: '20px',
      fontFamily: 'Inter',
      fontStyle: 'italic',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        lineHeight: '158%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '317px',
      marginTop: '8px'
    }
  },
  bottomText: {
    '& > *': {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: '500',
      color: '#222',
      lineHeight: '170%',
      marginTop: '0px',
      marginBottom: '0px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: '400',
        lineHeight: '146%',
        textAlign: 'center',
        color: '#3C3C3C'
      }
    }
  },
  bottomTextArea: {
    maxWidth: '592px'
  },
  dateTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '50px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '16px'
    }
  },
  dateTime: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    '& > p': {
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '170%',
      color: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      '& > p': {
        fontSize: '12px',
        fontFamily: 'Inter',
        lineHeight: '170%',
        marginTop: '0px',
        marginBottom: '0px'
      },
      '& > img': {
        display: 'none'
      }
    }
  },
  button: {
    color: '#FFFFFF',
    backgroundColor: '#3A3380',
    borderRadius: '40px',
    padding: '20px 70px',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      padding: '16px 32px',
      marginTop: '8px'
    }
  },
  statusLabel: {
    position: 'absolute',
    transform: 'rotate(42.5deg)',
    width: 'fit-content',
    top: props => `${props.statusLabelDesktopOffset}px`,
    right: props => `-${props.statusLabelDesktopOffset}px`,
    backgroundColor: '#FDD247',
    color: '#000',
    fontSize: '18px',
    lineHeight: '100%',
    fontWeight: '700',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    padding: '14px 60px',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      transform: 'none',
      fontSize: '16px',
      padding: '8px 12px',
      right: '0px !important',
      left: 0,
      top: '-16px !important',
      margin: 'auto'
    }
  }
}));

const getStatusLabelDesktopOffset = labelLength => {
  if (labelLength < 20) {
    return 64;
  }
  // for each extra letter after 20th letter, 2px should be added
  return 64 + (labelLength - 20) * 2;
};

const ProducersCircleCourse = ({
  label,
  title,
  time_period,
  date_period,
  registration_label,
  cta_url,
  cta_text,
  description,
  thumbnail,
  subtitle,
  quote
}) => {
  const statusLabelDesktopOffset = getStatusLabelDesktopOffset(registration_label?.length);
  const classes = useStyles({ statusLabelDesktopOffset });
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <div className={classes.root}>
      <Container center className={classes.container}>
        <div className={classes.contentArea}>
          <div className={classes.textArea}>
            {isMobile && thumbnail?.url && (
              <img src={thumbnail?.url} alt={thumbnail?.alt} className={classes.heroImage} />
            )}
            <p className={classes.topLabel}>{label}</p>
            <RichText html={title?.html} externalClassName={classes.title} />
            <RichText html={subtitle?.html} externalClassName={classes.description} />
            <RichText html={quote?.html} externalClassName={classes.quote} />
          </div>
          {!isMobile && thumbnail?.url && (
            <img src={thumbnail?.url} alt={thumbnail?.alt} className={classes.heroImage} />
          )}
          {registration_label && <div className={classes.statusLabel}>{registration_label}</div>}
        </div>
        <div className={classes.bottomArea}>
          <div className={classes.bottomTextArea}>
            {description?.html && (
              <RichText html={description?.html} externalClassName={classes.bottomText} />
            )}

            <div className={classes.dateTimeContainer}>
              {date_period && (
                <div className={classes.dateTime}>
                  <img src={DateIcon} alt="date icon" />
                  <p>{date_period}</p>
                </div>
              )}
              {time_period && (
                <div className={classes.dateTime}>
                  <img src={TimeIcon} alt="date icon" />
                  <p>{time_period}</p>
                </div>
              )}
            </div>
          </div>
          {cta_url && (
            <Link to={cta_url} underline="none" className={classes.button}>
              {cta_text}
            </Link>
          )}
        </div>
      </Container>
    </div>
  );
};

ProducersCircleCourse.propTypes = {
  label: PropTypes.string,
  title: PropTypes.shape({
    html: PropTypes.string
  }).isRequired,
  time_period: PropTypes.string,
  date_period: PropTypes.string,
  registration_label: PropTypes.string,
  cta_url: PropTypes.string,
  cta_text: PropTypes.string,
  description: PropTypes.shape({
    html: PropTypes.string
  }),
  thumbnail: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  subtitle: PropTypes.shape({
    html: PropTypes.string
  }),
  quote: PropTypes.shape({
    html: PropTypes.string
  })
};

ProducersCircleCourse.defaultProps = {
  label: '',
  time_period: '',
  date_period: '',
  registration_label: '',
  cta_text: '',
  cta_url: '',
  description: null,
  thumbnail: null,
  subtitle: null,
  quote: null
};

export default ProducersCircleCourse;
