import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 150,
    height: 150,
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block'
    }
  }
}));

const AuthorTestimonials = ({ avatar, name, role, testimonial }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container center background="#f6f6f6">
      <Box py={9} width="100%" textAlign={isSmallerThanMd ? 'center' : ''}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} md={2}>
            <Avatar src={avatar.url} alt={avatar.alt || name} className={classes.avatar} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" color="primary" gutterBottom>
              {name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {role}
            </Typography>
            <Typography variant="h6">{testimonial}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

AuthorTestimonials.propTypes = {
  testimonial: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired
};

export default AuthorTestimonials;
