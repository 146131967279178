import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import Link from '~components/Link';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const CommentRestrictionBox = () => {
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isLoggedIn, isSubscriber } = session;

  if (isSubscriber) {
    return null;
  }

  return (
    <Box bgcolor="#F7F4F0" p={2}>
      <Typography variant="subtitle1" component="p">
        <Link to="/subscribe">Subscribe</Link> today to join the conversation.
        {!isLoggedIn && (
          <>
            <br />
            Already a subscriber?&nbsp;
            <Link to={getNavigationPageLink('/login', isMarketingSite)}>Log in here!</Link>
          </>
        )}
      </Typography>
    </Box>
  );
};
export default CommentRestrictionBox;
