import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles(theme => ({
  supportText: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
    }
  },
  heartIcon: {
    color: '#FFFFFF',
    fontSize: '1.95rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem'
    }
  }
}));

const SupportHeartText = () => {
  const classes = useStyles();
  return (
    <Typography variant="subtitle1" align="center" component="h3" className={classes.supportText}>
      <FavoriteIcon color="secondary" fontSize="small" className={classes.heartIcon} />
      Support Us
    </Typography>
  );
};

export default SupportHeartText;
