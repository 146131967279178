import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import useCardSearch from '~hooks/useCardSearch';
import { trackCurationSortingMethod, trackCurationCardSelect } from '~utils/analytics';
import { pushDataLayerEvent } from '~utils/data-layer';
import CurationHero from '~src/modules/curation-page/components/CurationHero';
import CurationPageStoriesGrid from '~src/modules/curation-page/components/CurationPageStoriesGrid';
import CurationPageDrawer from '~src/modules/curation-page/components/CurationPageDrawer';

const useStyles = makeStyles(theme => ({
  gridSpacing: {
    marginBottom: props => (props.isDrawerOpen ? 'unset' : '200px'),
    [theme.breakpoints.down('sm')]: {
      marginBottom: '190px'
    }
  }
}));

const CurationPageLayoutContainer = ({ hero, grid, isDrawerOpen, setIsDrawerOpen }) => {
  const classes = useStyles({ isDrawerOpen });
  const theme = useTheme();
  const isBellowMd = useMediaQuery(theme.breakpoints.down('md'));

  const [drawerData, setDrawerData] = useState();

  const [sortType, setSortType] = useState();
  const [query, setQuery] = useState('');
  const [selectedPlaylist, setSelectedPlaylist] = useState();

  const { cards } = grid;
  const cardsSearchResult = useCardSearch(cards, query, sortType);

  const handleSortClick = useCallback(event => {
    const sortTypeSelected = event.currentTarget.id;
    trackCurationSortingMethod(sortTypeSelected);
    pushDataLayerEvent('curation_page_sort_type_selected', { sort_type: sortTypeSelected });
    setSortType(sortTypeSelected);
  }, []);

  const handleClick = useCallback(
    event => {
      const selectedPlaylistId = event.currentTarget.id;

      const selectedDrawerPlaylist =
        cards && cards.find(card => card.card_title === selectedPlaylistId); // FIXME: SM: original code needs refactoring

      const { open_first_playlist_on_click, url, inactive } = selectedDrawerPlaylist;

      if (inactive) {
        return;
      }

      if (open_first_playlist_on_click) {
        console.log(open_first_playlist_on_click, url);
        trackCurationCardSelect(url);
        pushDataLayerEvent('curation_page_select_card', { card: url });
        navigate(url);

        return;
      }
      const { playlists, card_color, card_title } = selectedDrawerPlaylist;
      setSelectedPlaylist(selectedPlaylistId);
      trackCurationCardSelect(url);
      pushDataLayerEvent('curation_page_select_card', { card: url });
      setDrawerData({ title: card_title, color: card_color, playlists });
      setIsDrawerOpen(true);
    },
    // FIXME: SM: original [playlist] needs refactoring
    [] // eslint-disable-line
  );

  const handleClose = useCallback(() => {
    setIsDrawerOpen(false);
    setDrawerData();
    setSelectedPlaylist();
  }, []);

  useEffect(() => {
    if (isDrawerOpen === null) return;

    scroller.scrollTo('storyGridElement', {
      duration: 0,
      delay: 0,
      smooth: false,
      offset: isBellowMd ? -90 : -140
    });
  }, [isDrawerOpen]); // eslint-disable-line

  return (
    <Grid container direction="row" justify="space-around" alignItems="stretch">
      <Grid item xs={12} md={isDrawerOpen ? 6 : 12} className={classes.gridSpacing}>
        <CurationHero isDrawerOpen={isDrawerOpen} {...hero} />

        <CurationPageStoriesGrid
          handleClick={handleClick}
          title={grid.grid_title}
          cards={cardsSearchResult}
          isDrawerOpen={isDrawerOpen}
          selectedPlaylist={selectedPlaylist}
          setSearchQuery={setQuery}
          searchQuery={query}
          handleSortClick={handleSortClick}
        />
      </Grid>

      {/* FIXME: SM: needs refactoring */}
      {isDrawerOpen && (
        <Grid item xs={12} md={6}>
          <CurationPageDrawer drawerData={drawerData} handleClose={handleClose} />
        </Grid>
      )}
    </Grid>
  );
};

CurationPageLayoutContainer.propTypes = {
  hero: PropTypes.shape({
    hero_popup_video_link: PropTypes.shape({ url: PropTypes.string }),
    description: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
    title: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
    popup_button_text: PropTypes.string,
    text_color: PropTypes.string,
    hero_background_color: PropTypes.string,
    hero_background_image: PropTypes.shape({ url: PropTypes.string })
  }),
  grid: PropTypes.shape(CurationPageStoriesGrid.propTypes.cards),
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func.isRequired
};

CurationPageLayoutContainer.defaultProps = {
  hero: {},
  grid: [],
  isDrawerOpen: false
};

export default CurationPageLayoutContainer;
