import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import { Input } from '~components/form-inputs-v2';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import ClickableText from '~components/buttons/ClickableText/ClickableText';
import { useLogin } from '~hooks/useAuth';
import { VIEWS } from '../constants';
import { useSharedStyles } from '../styles';

const LoginView = ({ changeView, username, onSuccess }) => {
  const sharedClasses = useSharedStyles();
  const [loginState, login] = useLogin();
  const { register, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const { session } = useUserContext();
  const { isUserStateLoading, isLoggedIn } = session;

  const isLoading = loginState?.isLoading || isUserStateLoading;

  useEffect(() => {
    if (isLoggedIn) {
      onSuccess();
    }
  }, [isLoggedIn, onSuccess]);

  const onSubmit = formData => {
    login({ ...formData, username: formData?.username || username });
  };

  const handleForgetPassword = () => {
    changeView(VIEWS.RESET_PASSWORD_VIEW);
  };

  const handleEditEmail = () => {
    changeView(VIEWS.INITIAL_VIEW);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(isVisible => !isVisible);
  };

  return (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid className={sharedClasses.root}>
            <Grid item xs={12} className={sharedClasses.gapLg}>
              <Typography component="h5" className={sharedClasses.title}>
                Log in to continue learning
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Input
                label="Email Address"
                id="email-address"
                type="email"
                name="username"
                endAdornment={
                  <InputAdornment position="end">
                    <ClickableText
                      className={sharedClasses.inputAdornment}
                      onClick={handleEditEmail}
                    >
                      Edit
                    </ClickableText>
                  </InputAdornment>
                }
                value={username}
                inputRef={register}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <Input
                label="Password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <ClickableText
                      className={sharedClasses.inputAdornment}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </ClickableText>
                  </InputAdornment>
                }
                inputRef={register}
                required
              />
              {loginState?.error && (
                <Typography component="h6" className={sharedClasses.errorText}>
                  {String(loginState?.error)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapSm}>
              <ClickableText
                className={sharedClasses.secondaryTextLink}
                onClick={handleForgetPassword}
              >
                Forgot your password?
              </ClickableText>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapMd}>
              <ButtonSubmit
                id="guest-login-popup-login-btn"
                className={sharedClasses.submitButton}
                disableElevation
                disabled={isLoading}
              >
                Log in
              </ButtonSubmit>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

LoginView.propTypes = {
  username: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default LoginView;
