import React from 'react';
import { navigate } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getPreviousLocationData } from '~utils/previous-path-cookie';

const useStyles = makeStyles({
  previousPageLinkStyle: {
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    textDecoration: 'underline',
    color: '#438dce'
  },

  pointer: {
    cursor: 'pointer'
  }
});
const SearchPreviousPageLink = () => {
  const classes = useStyles();

  const handlePreviousPageClick = () => {
    const { pathname } = getPreviousLocationData();
    navigate(pathname);
  };

  return (
    <Typography className={classes.previousPageLinkStyle}>
      <Box component="span" className={classes.pointer} onClick={handlePreviousPageClick}>
        Back to previous page
      </Box>
    </Typography>
  );
};

export default SearchPreviousPageLink;
