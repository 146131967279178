import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import unfurl from '~prismic/unfurl/blog_post';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import Container from '~components/Container';
import BlogHead from '~components/blog/';
import RichText from '~components/RichText';

const BlogPost = ({ data }) => {
  const { head, html, custom_html_slices, seo } = unfurl(data);
  return (
    <Page>
      <SEO {...seo} />

      <Container maxWidth="md">
        <BlogHead {...head} isFeatured />
        <RichText html={html} />
        {custom_html_slices &&
          custom_html_slices.map(htmlSection => (
            <RichText key={head.title} html={htmlSection.html} />
          ))}
      </Container>
    </Page>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape().isRequired
};

export const query = graphql`
  query ($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      _previewable
      uid
      last_publication_date(formatString: "MMMM D, YYYY")
      type
      data {
        title {
          text
          html
        }
        overline {
          text
          html
        }
        content {
          html
          text
        }
        body {
          ... on PrismicBlogPostDataBodyCustomHtml {
            slice_type
            primary {
              custom_html {
                html
                text
              }
            }
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              data {
                name
                role
                avatar {
                  url
                  alt
                }
              }
            }
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
          alt
        }
      }
    }
  }
`;

export default withPrismicPreview(BlogPost);
