import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CoursePerks from '~components/slices/course-perks/CoursePerks';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import IconReading from './images/icon-perk-reading.svg';
import IconDisplay from './images/icon-perk-display.svg';
import IconHandshake from './images/icon-perk-handshake.svg';
import IconBooks from './images/icon-perk-books.svg';
import IconHandStar from './images/icon-perk-hand-star.svg';
import BottomShapeDividerSrc1 from './images/pc-page-shape-divider-1.svg';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '60px',
    backgroundColor: '#F7F6F4',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px'
    }
  },
  subtitle: {
    '& > a': {
      color: '#1D1927'
    }
  },
  title: {
    fontWeight: '600',
    fontSize: '44px',
    maxWidth: '700px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px'
    }
  }
}));

const PERKS = [
  {
    perk_title: 'Interactive Live Courses',
    perk_description: {
      html: `<p>Dive deep into topics that intrigue, enlighten, and challenge your perception of life, Torah, and everything in between. From Aleph Beta methodology to explorations of parenting, recovering from trauma, or even the meaning of life, you'll have a front-row, participatory seat to some of the most profound learning experiences you can imagine.</p>`
    },
    perk_icon: {
      alt: 'Interactive Live Courses',
      url: IconReading
    },
    background_color: '#FFFFFF',
    text_color: '#000000'
  },
  {
    perk_title: 'Shiny New Things Webinars',
    perk_description: {
      html: `<p>As a Patron, you get to join Rabbi Fohrman live as he develops his latest Torah research while he's still in the process of refining it. You're not just learning a "shiny new thing," you're participating in its creation!</p>`
    },
    perk_icon: {
      alt: 'Shiny New Things Webinars',
      url: IconDisplay
    },
    background_color: '#FFFFFF',
    text_color: '#000000'
  },
  {
    perk_title: 'In-Person Gatherings & Events',
    perk_description: {
      html: `<p>Ever wanted to shmooze with Rabbi Fohrman in the flesh? Meet up for dinner in Israel or for drinks in New York? Want to outsmart Rabbi Fohrman in a trivia game night? Or learn with him live, in person? Opportunities like these can pop up throughout the year, and as a patron you'll always be on the invitation list.</p>`
    },
    perk_icon: {
      alt: 'In-Person Gatherings & Events',
      url: IconHandshake
    },
    background_color: '#FFFFFF',
    text_color: '#000000'
  },
  {
    perk_title: 'Biblical Hebrew Course',
    perk_description: {
      html: `<p>Unlock the secrets of the Torah in its original language with our semester-long beginner and intermediate Biblical Hebrew courses.</p>`
    },
    perk_icon: {
      alt: 'Biblical Hebrew Course',
      url: IconBooks
    },
    background_color: '#FFFFFF',
    text_color: '#000000'
  },
  {
    perk_title: 'Concierge Service',
    perk_description: {
      html: `<p>A dedicated customer-service agent will field your calls and help you with any Aleph Beta-related issue. Also, have your burning Torah questions answered by our Scholars or even Rabbi Fohrman himself!</p>`
    },
    perk_icon: {
      alt: 'Concierge Service',
      url: IconHandStar
    },
    background_color: '#FFFFFF',
    text_color: '#000000'
  }
];

const ProducersCircleBenefits = () => {
  const classes = useStyles();
  const subtitle = (
    <span className={classes.subtitle}>
      Our Patron tier includes all{' '}
      <a href="/subscribe#premium-benefits" target="_blank">
        Premium benefits
      </a>
      , plus:
    </span>
  );
  const title = <div className={classes.title}>Unique perks and opportunities just for you</div>;
  return (
    <div className={classes.root}>
      <CoursePerks
        title={title}
        text_color="#1D1927"
        background_color="#F7F6F4"
        subtitle={subtitle}
        perks={PERKS}
      />
      <BottomShapeDivider src={BottomShapeDividerSrc1} bgColor="transparent" flex />
    </div>
  );
};

export default ProducersCircleBenefits;
