/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RichText from '~components/RichText';

const useStyles = makeStyles({
  expansion: {
    '& > em': { background: 'yellow', fontWeight: '500', padding: '0px 3px' }
  }
});

const HighlightExpansionPanel = ({ highlights }) => {
  const classes = useStyles();

  return (
    <Accordion style={{ boxShadow: 'unset' }}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ padding: '5px 0px' }}
      >
        <Button color="primary" variant="contained">
          Why this result
        </Button>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'unset' }}>
        {Object.entries(highlights).map(([fieldName, fieldHighlights]) => (
          <React.Fragment key={fieldName}>
            <h3>
              <code>{fieldName}</code> matches
            </h3>
            {fieldHighlights.map(highlight => (
              <RichText
                key={`${fieldName}-${highlight}`}
                html={highlight}
                externalClassName={classes.expansion}
              />
            ))}
          </React.Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

HighlightExpansionPanel.propTypes = {
  highlights: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired
};

export default HighlightExpansionPanel;
