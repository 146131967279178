import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import RichText from '~components/RichText';
import Box from '@material-ui/core/Box';
import Container from '~components/Container';
import DescriptionDialog from '~components/TeamMembers/DescriptionDialog';
import ClickableText from '~components/buttons/ClickableText/ClickableText';

const useStyles = makeStyles(() => ({
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0 auto'
  },
  teamPageMemberName: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBlockStart: '16px',
    marginBlockEnd: '0px',
    opacity: '0.87'
  },
  teamPageMemberPosition: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '25.2px',
    marginBlock: '5px 25px'
  },
  teamBioContent: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '140%',
    textDecoration: 'underline',
    color: '#317DC9',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  teamBioContainer: {
    textAlign: 'center'
  }
}));

const TeamSection = ({ section_title, members }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [selectedMember, setSelectedMember] = useState(null);
  const onReadMoreClick = member => {
    setSelectedMember(member);
  };
  const handleCloseDescriptionDialog = () => setSelectedMember(null);
  return (
    <>
      {selectedMember && (
        <DescriptionDialog show onClose={handleCloseDescriptionDialog} {...selectedMember} />
      )}
      <Container style={{ marginBottom: '100px' }}>
        <RichText
          html={section_title && section_title.html}
          mode="teamPageSectionTitle"
          verticalSpacing={0}
        />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={isXs ? 2 : 6}
        >
          {members &&
            members.map(member => (
              <Grid item xs={12} sm={6} md={3} key={member.name}>
                <img
                  src={member.avatar && member.avatar.url}
                  alt={member.avatar && member.avatar.alt}
                  className={`${classes.media} lazyload`}
                />
                <p className={classes.teamPageMemberName}>{member.name}</p>
                <p className={classes.teamPageMemberPosition}>{member.position}</p>
                {member.description.html && (
                  <Box className={classes.teamBioContainer}>
                    <ClickableText
                      className={classes.teamBioContent}
                      onClick={() => onReadMoreClick(member)}
                    >
                      Read More
                    </ClickableText>
                  </Box>
                )}
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
};

TeamSection.propTypes = {
  section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      position: PropTypes.string,
      avatar: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }).isRequired,
      description: PropTypes.shape({
        html: PropTypes.string
      }).isRequired
    }).isRequired
  ).isRequired
};
export default TeamSection;
