import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Dialog from '~components/Dialog';
import AbBlackLogo from '~images/alephbeta-black-logo.svg';
import DealOfTheYearBanner from '~images/black-friday/black-friday-deal-of-the-year-banner.svg';
import BFOfferBags from '~images/black-friday/black-friday-bags-with-text.svg';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import Link from '~components/Link';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import ButtonClosePopup from '../components/ButtonClosePopup';
import { BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH } from '../../black-friday/constants';

const useStyles = makeStyles(theme => ({
  scrollBody: {
    '&:after': {
      height: 'unset !important'
    }
  },
  paper: {
    margin: 0,
    color: '#000000',
    height: '100%',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
      minHeight: '100vh'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      backgroundPosition: 'bottom center'
    }
  },
  logoImage: {
    marginLeft: '40px',
    marginTop: '18px',
    maxWidth: '200px'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '44px 83px',
    gap: 15,
    [theme.breakpoints.down('sm')]: {
      padding: '54px 36px 36px'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1310px'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 10
    }
  },
  bodyHighlightedText: {
    fontFamily: 'Inter',
    fontSize: '24px',
    lineHeight: '140%',
    fontWeight: 700,
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  bodyText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '158%',
    fontWeight: 500,
    maxWidth: '484px',
    marginBottom: '32px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '150%',
      fontWeight: 400,
      marginBottom: '29px',
      order: 2
    }
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      gap: '8px',
      order: 3
    }
  },
  bottomLightText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '142%',
    textAlign: 'center',
    marginTop: '4px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      marginTop: '0px'
    }
  },
  continueWatchingTextBtn: {
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '142%',
    fontWeight: 700,
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px'
    }
  },
  bottomBoldTextLink: {
    '& > *': {
      color: '#000000',
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '142%',
      fontWeight: 700,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px'
      }
    },
    '& > a:hover': {
      textDecoration: 'none'
    }
  },
  subscribeBtn: {
    textAlign: 'center',
    padding: '22px 82px',
    fontWeight: 600,
    marginBottom: '16px'
  },
  subscribeMobileBtn: {
    fontWeight: 600,
    padding: '9px 34px'
  },
  bannerImage: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  offerImage: {
    minWidth: '640px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '540px'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '440px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '340px'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '240px'
    }
  }
}));

const PopupBlackFriday = ({ showTopCloseBtn, id = undefined, handleClose, isOpen, isLoggedIn }) => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      isOpen={isOpen}
      id={id}
      backdrop="#A4D0EE"
      background="#FFFFFF"
      maxWidth="100vw"
      classes={{ paper: classes.paper, scrollBody: classes.scrollBody }}
      disableScrollLock
    >
      {!isMobileDevice && (
        <img src={AbBlackLogo} alt="Aleph Beta logo" className={classes.logoImage} />
      )}
      <div className={classes.bodyContainer}>
        <img src={DealOfTheYearBanner} alt="Deal of the Year" className={classes.bannerImage} />
        <img src={BFOfferBags} alt="Black Friday Offer" className={classes.offerImage} />
        <div className={classes.contentArea}>
          <div>
            <Typography className={classes.bodyHighlightedText}>
              You’ve watched all of your free 18 free minutes this month.
            </Typography>
            <Typography className={classes.bodyText}>
              You’ve come to the right place. Aleph Beta’s library of over a thousand videos and
              podcasts will help you fall in love with Torah – for the first time, or all over
              again.
            </Typography>
          </div>

          <div className={classes.bottomContainer}>
            <RoundedLinkButton
              text="Redeem Offer"
              to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH}
              size={isMobileDevice ? 'mini' : 'large'}
              variant="black"
              externalClassName={isMobileDevice ? classes.subscribeMobileBtn : classes.subscribeBtn}
            />
            {!isLoggedIn && (
              <Typography className={classes.bottomLightText}>
                Already have an account?{' '}
                <span className={classes.bottomBoldTextLink}>
                  <Link to={getNavigationPageLink('/login', isMarketingSite)}>Log in here</Link>
                </span>
              </Typography>
            )}
          </div>
        </div>
      </div>
      {showTopCloseBtn && (
        <ButtonClosePopup
          color="#000000"
          iconSize={isMobileDevice ? '20px' : '2rem'}
          top={isMobileDevice ? '10px' : '20px'}
          right={isMobileDevice ? '10px' : '20px'}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};

PopupBlackFriday.propTypes = {
  showTopCloseBtn: PropTypes.bool,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool
};

PopupBlackFriday.defaultProps = {
  showTopCloseBtn: false,
  id: undefined,
  isOpen: null,
  isLoggedIn: false
};

export default PopupBlackFriday;
