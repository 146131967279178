import { get } from 'lodash';

const getDepartmentOrder = department => {
  switch (department) {
    case 'Product':
      return 1;
    case 'Finance':
      return 2;
    case 'Marketing':
      return 3;
    default:
      return 666;
  }
};

const compareJobGroups = (left, right) => {
  const leftDepartment = left[0];
  const rightDepartment = right[0];

  return getDepartmentOrder(leftDepartment) - getDepartmentOrder(rightDepartment);
};

export const jobsPage = allPrismicJobPage => {
  const raw = get(allPrismicJobPage, 'allPrismicJobPage.edges')
    .map(el => el.node)
    .reduce((accumulator, element) => {
      const department = element?.data?.department;
      if (!accumulator[department]) {
        accumulator[department] = [];
      }
      accumulator[department].push({
        department,
        uid: element.uid,
        type: element.data.type,
        location: element.data.location,
        position: element.data.job_position
      });
      return accumulator;
    }, {});

  const groupsArray = Object.entries(raw);
  groupsArray.sort(compareJobGroups);

  return groupsArray;
};

export default prismicJob => {
  const job = get(prismicJob, 'prismicJobPage.data');
  return {
    sections: job.body,
    department: job.department,
    jobPosition: job.job_position,
    location: job.location,
    type: job.type
  };
};
