import { getRequiredPlanId } from '~utils/required-subscription-plan';
import { boolFlagFromString } from '~utils/playlist-helpers';
import { get } from '../utils/get';

const extractFirstVideo = prismicData => {
  const firstVideo = get(prismicData, 'prismicPlaylist.data.videos[0].video', null);

  if (firstVideo) {
    return {
      prismicId: get(firstVideo, 'document.prismicId'),
      uid: get(firstVideo, 'document.uid'),
      first_publication_date: get(firstVideo, 'document.first_publication_date'),
      ...get(firstVideo, 'document.data')
    };
  }
  return null;
};

const extractNextPlaylist = prismicData => {
  const nextPlaylist = get(prismicData, 'prismicPlaylist.data.next_playlist', {});
  if (!nextPlaylist || !nextPlaylist.document) {
    return null;
  }
  return {
    url: get(nextPlaylist, 'url', ''),
    uid: get(nextPlaylist, 'document.uid', ''),
    ...get(nextPlaylist, 'document.data')
  };
};

const extractPlaylists = (prismicData, dataPath) => {
  const playlistsFromPrismic = get(prismicData, dataPath, []);
  const nonEmptyPlaylists = playlistsFromPrismic.filter(
    ({ playlist }) => !!playlist && !!playlist.document
  );
  const mappedPlaylists = nonEmptyPlaylists.map(playlist => ({
    url: get(playlist, 'playlist.url', ''),
    uid: get(playlist, 'playlist.document.uid', ''),
    ...get(playlist, 'playlist.document.data')
  }));

  return mappedPlaylists;
};

const extractMaterials = prismicData => {
  const materialsArrayFromPrismic = get(prismicData, 'prismicPlaylist.data.materials', []);
  const nonEmptyMaterialsArray = materialsArrayFromPrismic.filter(
    ({ material }) => !!material && !!material.document
  );
  const mappedMaterialsArray = nonEmptyMaterialsArray.map(material => ({
    url: get(material, 'material.url', ''),
    uid: get(material, 'material.uid', ''),
    ...get(material, 'material.document.data')
  }));

  return mappedMaterialsArray;
};

const extractPromotionalVideos = prismicData => {
  const { data } = prismicData.prismicPromotionalCtaVideos || {};
  const { body } = data || {};

  const promotionalVideos =
    body &&
    body.length > 0 &&
    body.map(promoVideoData => ({
      promoVideoUrl: get(promoVideoData, 'primary.wistia_video_url'),
      accessLevel: get(promoVideoData, 'primary.video_access_level'),
      isFirst: get(promoVideoData, 'primary.promotional_video_position') === 'First',
      isLast: get(promoVideoData, 'primary.promotional_video_position') === 'Last'
    }));

  return promotionalVideos;
};

const extractPlaylistLabels = prismicData => {
  const label_is_audio = get(prismicData, 'prismicPlaylist.data.label_is_audio');
  const label_is_new = get(prismicData, 'prismicPlaylist.data.label_is_new');
  const label_is_premium = get(prismicData, 'prismicPlaylist.data.label_is_premium');
  const label_is_producer = get(prismicData, 'prismicPlaylist.data.label_is_producer');

  return {
    isAudio: boolFlagFromString(label_is_audio),
    isNew: boolFlagFromString(label_is_new),
    isPremium: boolFlagFromString(label_is_premium),
    isProducer: boolFlagFromString(label_is_producer)
  };
};

export default prismicData => ({
  prismicId: get(prismicData, 'prismicPlaylist.prismicId', null),
  uid: get(prismicData, 'prismicPlaylist.uid', null),
  title: get(prismicData, 'prismicPlaylist.data.title', null),
  required_subscription_plan: getRequiredPlanId(
    get(prismicData, 'prismicPlaylist.data.required_subscription_plan', '')
  ),
  firstVideo: extractFirstVideo(prismicData),
  videos: get(prismicData, 'prismicPlaylist.data.videos', []).map(video => ({
    uid: get(video, 'video.document.uid', null),
    prismicId: get(video, 'video.document.prismicId', null),
    first_publication_date: get(video, 'video.document.first_publication_date'),
    ...get(video, 'video.document.data')
  })),
  nextPlaylist: extractNextPlaylist(prismicData),
  suggestedPlaylists: extractPlaylists(prismicData, 'prismicPlaylist.data.suggested_playlists'),
  trendingPlaylists: extractPlaylists(
    prismicData,
    'prismicPopularTrendingPlaylists.data.trending_list'
  ),
  popularPlaylists: extractPlaylists(
    prismicData,
    'prismicPopularTrendingPlaylists.data.popular_list'
  ),
  materials: extractMaterials(prismicData),
  author: { ...get(prismicData, 'prismicPlaylist.data.author.document.data') },
  labels: extractPlaylistLabels(prismicData),
  seo: {
    seo_title: get(prismicData, 'prismicPlaylist.data.seo_title', ''),
    seo_description: get(prismicData, 'prismicPlaylist.data.seo_description', ''),
    seo_keywords: get(prismicData, 'prismicPlaylist.data.seo_keywords', ''),
    seo_image:
      get(prismicData, 'prismicPlaylist.data.seo_image.url', '') ||
      get(prismicData, 'prismicPlaylist.data.cover_image.url', '')
  },
  promotionalVideos: extractPromotionalVideos(prismicData),
  redirectUrl: get(prismicData, 'prismicPlaylist.data.redirect_url.url', ''),
  allowFullWatch: get(prismicData, 'prismicPlaylist.data.allow_full_watch', false),
  allowedSeconds: get(prismicData, 'prismicPlaylist.data.allowed_seconds', 90),
  membersSitePath: get(prismicData, 'prismicPlaylist.data.members_site_playlist_path', ''),
  heroCoverImage: get(prismicData, 'prismicPlaylist.data.marketing_playlist_page_cover', null),
  pageLayout: get(prismicData, 'prismicPlaylist.data.page_layout', '')
});
