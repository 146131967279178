import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import RichText from '~components/RichText';

import { trackCurationIntroButton } from '~utils/analytics';
import { pushDataLayerEvent } from '~utils/data-layer';

import { CurationHeroDialog, CurationDialogButton } from './components';

const useStyles = makeStyles(theme => ({
  heroImage: props => ({
    backgroundImage: `url(${props.hero_background_image.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }),
  gridMinHeight: {
    minHeight: '550px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '420px'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '330px'
    }
  },
  mobileHero: props => ({
    padding: '64px 0px',
    zIndex: '2',
    [theme.breakpoints.down('sm')]: {
      padding: '32px',
      backgroundImage: `url(${props.hero_background_image.url})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      '&::after': {
        background: 'linear-gradient(180deg, rgba(62, 88, 126, 0) 0%, #3E587E 100%)',
        content: `''`,
        width: `100%`,
        position: 'absolute',
        height: '100%',
        top: '0',
        left: '0',
        zIndex: '0'
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: '32px 32px 18px 32px'
    }
  })
}));

const CurationHero = ({
  title,
  description,
  hero_background_color,
  hero_background_image,
  hero_popup_video_link,
  popup_button_text,
  text_color,
  isDrawerOpen
}) => {
  const classes = useStyles({ hero_background_image });

  const browser = typeof window !== 'undefined' && window;
  const { pathname: slug } = browser && browser.location;

  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => {
    trackCurationIntroButton();
    pushDataLayerEvent('curation_page_intro_video_button_clicked', { slug });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Grid
      container
      direction="row-reverse"
      justify="center"
      alignItems="stretch"
      className={classes.gridMinHeight}
    >
      <Hidden smDown>
        <Grid item xs={12} md={isDrawerOpen ? 12 : 6}>
          <Box
            width="100%"
            height="100%"
            className={classes.heroImage}
            style={{ paddingTop: isDrawerOpen ? '55%' : 'unset' }}
          />
        </Grid>
      </Hidden>
      <Box
        component={Grid}
        item
        container
        direction="row"
        alignItems={isSmallerThanMd ? 'flex-end' : 'center'}
        justify="center"
        xs={12}
        md={isDrawerOpen ? 12 : 6}
        bgcolor={hero_background_color}
        color={text_color}
        className={classes.mobileHero}
      >
        <Grid item xs={12} sm={10} md={5} style={{ zIndex: '2' }}>
          <RichText html={title.html} mode="curationHeroTitle" verticalSpacing={0} />

          <RichText html={description.html} mode="curationHeroSubtitle" verticalSpacing={0} />

          <CurationDialogButton onClick={handleOpen} buttonText={popup_button_text} />
        </Grid>
      </Box>

      <CurationHeroDialog
        isOpen={isOpen}
        handleClose={handleClose}
        videoUrl={hero_popup_video_link.url}
      />
    </Grid>
  );
};

CurationHero.propTypes = {
  hero_popup_video_link: PropTypes.shape({ url: PropTypes.string }),
  description: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
  title: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
  popup_button_text: PropTypes.string,
  text_color: PropTypes.string,
  hero_background_color: PropTypes.string,
  hero_background_image: PropTypes.shape({ url: PropTypes.string }),
  isDrawerOpen: PropTypes.bool
};

CurationHero.defaultProps = {
  hero_popup_video_link: null,
  description: null,
  title: null,
  popup_button_text: null,
  text_color: '#ffffff',
  hero_background_color: '#3787FB',
  hero_background_image: null,
  isDrawerOpen: null
};

export default CurationHero;
