import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import StarIconImage from './images/star.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F7F6F4'
  },
  sectionHeading: {
    fontWeight: '600',
    color: '#1D1927',
    fontSize: '44px',
    fontFamily: 'Inter',
    marginBottom: '70px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: '40px',
      fontSize: '36px',
      textAlign: 'center'
    }
  },
  imageMedia: {
    maxWidth: '100%',
    display: 'flex'
  },
  iconPosition: {
    display: 'flex',
    alignItems: 'center'
  },
  containerSpacing: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(8)
    }
  },
  buttonStyle: {
    borderRadius: '40px',
    padding: '10px 52px',
    marginTop: '32px',
    fontSize: '18px',
    textTransform: 'none',
    boxShadow: 'none',
    color: '#FFFFFF',
    backgroundColor: '#222222',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#222222'
    },
    '&:active': {
      textDecoration: 'none',
      backgroundColor: '#222222'
    }
  },
  buttonContainer: {
    width: '100%',
    padding: '0 2em',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const ProducersCircleMeetTheProducersSection = ({ producersCircleMembers }) => {
  const classes = useStyles();
  const [isListExpanded, setIsListExpanded] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const visibleMembersList =
    isXs && !isListExpanded ? producersCircleMembers.slice(0, 39) : producersCircleMembers;

  return (
    <div className={classes.root}>
      <Container center className={classes.containerSpacing}>
        <Typography variant="h3" component="h1" className={classes.sectionHeading} gutterBottom>
          Meet our Patrons
        </Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
          {visibleMembersList &&
            visibleMembersList.map(producer => (
              <Grid key={producer.member_name} item xs={12} sm={6} md={4}>
                <Typography variant="subtitle1" className={classes.iconPosition}>
                  <img
                    src={StarIconImage}
                    title="Producer"
                    alt="star"
                    className={classes.imageMedia}
                    style={{ marginRight: '12px' }}
                  />
                  {producer.member_name}
                </Typography>
              </Grid>
            ))}
        </Grid>
        {isXs && !isListExpanded && (
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.buttonStyle}
              onClick={() => setIsListExpanded(prevState => !prevState)}
            >
              Extend
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
};

ProducersCircleMeetTheProducersSection.propTypes = {
  producersCircleMembers: PropTypes.arrayOf(
    PropTypes.shape({
      member_name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ProducersCircleMeetTheProducersSection;
