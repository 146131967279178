import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AuthorAvatar from './author-avatar';

const Author = ({ avatar, name, role }) => (
  <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
    <Grid item>
      <AuthorAvatar {...avatar} />
    </Grid>
    <Grid item>
      <Typography variant="h6" component="h4">
        {name}
      </Typography>
      <Typography variant="body1" component="p">
        {role}
      </Typography>
    </Grid>
  </Grid>
);

Author.propTypes = {
  avatar: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};

export default Author;
