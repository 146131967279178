/**
 * At first render we boot the Intercom app.
 *
 * @see https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 * @see https://developers.intercom.com/installing-intercom/web/installation/#installation
 *
 * @param _ First parameter is a padding for the plugin options to be the second argument
 * @param {{appId: string}} pluginOptions Plugin options, the same as inside `gatsby-ssr`. An object containing a single string property `appId`, which is an ID of the Intercom App. Must be filled.
 */
export const onInitialClientRender = (_, { appId }) => {
  if (!appId) {
    return;
  }
  /*
   * We have a guarantee that if the Intercom loader script is loaded at all,
   *  then `window.Intercom` is actually a function which we can call as if it's actually an Intercom API,
   *  and Gatsby guarantees that `onInitialClientRender` is being called after all the scripts in the `head` element in the document.
   * So the only check we need is for `window.Intercom` to be a nonempty and be a function.
   *
   * We boot the Intercom app without any parameters because it's a truth: we don't know whether the current visitor is a user or not (at this moment).
   * When we'll know whether the user is logged in or not we'll call `window.Intercom('update')`.
   */
  if (window && window.Intercom && typeof window.Intercom === 'function') {
    window.Intercom('boot', {
      app_id: appId
    });
  }
};

let isFirstRouteUpdate = true;

/**
 * According to Gatsby documentation, `onRouteUpdate` is being called on EVERY page render, INCLUDING the very first one.
 * We want to call `window.Intercom('update')` only on the subsequent updates, so we have no other choice than to use a global flag.
 */
export const onRouteUpdate = () => {
  if (!isFirstRouteUpdate) {
    /*
     * Intercom documentation contradicts between pages:
     * https://developers.intercom.com/installing-intercom/web/methods/#intercomupdate
     * and
     * https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
     *
     * But actual experiments have shown that it's enough to just call `'update'` and user ID and email will automatically be picked up.
     */
    if (window && window.Intercom && typeof window.Intercom === 'function') {
      window.Intercom('update');
    }
  }
  isFirstRouteUpdate = false;
};
