import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Link from '~components/Link';
import CelebrationEffect from '~components/effects/CelebrationEffect';
import { useSharedStyles } from '../styles';

const AccountCreatedView = ({ onClose }) => {
  const sharedClasses = useSharedStyles();
  const { handleSubmit } = useForm();

  return (
    <Grid container justifyContent="center" direction="column" alignItems="center">
      <Grid item xs={12}>
        <CelebrationEffect />
        <form onSubmit={handleSubmit(onClose)}>
          <Grid className={sharedClasses.root}>
            <Grid item xs={12} className={sharedClasses.gapMd}>
              <Typography component="h5" className={sharedClasses.title}>
                Great, you created a new account!
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapLg}>
              <Typography component="h6" className={sharedClasses.subtitle}>
                Enjoy access to 30 minutes of Torah videos and 2 PDF downloads per month!
              </Typography>
            </Grid>
            <Grid item xs={12} className={sharedClasses.gapXXl}>
              <ButtonSubmit
                id="guest-login-popup-start-btn"
                className={sharedClasses.submitButton}
                disableElevation
              >
                Start learning
              </ButtonSubmit>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h6" className={sharedClasses.joinPremiumDescription}>
                Unlimited Access to over 1,000 Torah podcasts and videos.
              </Typography>
              <Box
                component="h6"
                fontSize="16px"
                textAlign="center"
                marginTop="6px"
                marginBottom="0px"
              >
                <Link to="/subscribe">Join Premium</Link>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

AccountCreatedView.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AccountCreatedView;
