import React, { useRef, useState } from 'react';
import { Element } from 'react-scroll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Toggle from '~components/form-inputs/Toggle';
import { PRODUCT_IDS } from '~utils/products';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import BottomShapeDividerSrc1 from './images/pc-page-shape-divider-3.svg';
import BottomShapeDividerSrc2 from './images/pc-page-shape-divider-3-1.svg';
import PricingCard from './PricingCard';
import BaseSlider from './BaseSlider';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F7F6F4'
  },
  container: {
    padding: '80px 17px',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      padding: '48px 17px',
      borderRadius: '0px'
    }
  },
  headerText: {
    color: '#1D1927',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '44px',
    fontWeight: '600',
    lineHeight: '120%',
    marginTop: '0px',
    marginBottom: '52px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      marginBottom: '24px'
    }
  },
  toggleArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > p': {
      margin: '4px 0px 0px',
      color: '#6C4BFF',
      textAlign: 'center',
      fontSize: '14px',
      display: props => (props.isYearly ? 'none' : 'block')
    }
  },
  cardsArea: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: '24px',
    marginTop: '60px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left'
    }
  },
  bottomText: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '170%',
    marginTop: '40px',
    marginBottom: '0px',
    maxWidth: '640px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  bottomRoot: {
    backgroundColor: '#3C3284',
    zIndex: 1,
    position: 'relative',
    marginTop: '-210px'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > p': {
      padding: '220px 60px 105px',
      color: '#FFFFFF',
      textAlign: 'center',
      fontSize: '44px',
      fontWeight: 600,
      fontFamily: 'Inter',
      marginTop: 0,
      marginBottom: 0,
      maxWidth: '880px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
        padding: '260px 10px 80px'
      }
    }
  }
}));

const TIERED_PACKAGES = [
  {
    productId: PRODUCT_IDS.PatronsBronze,
    packageName: 'Bronze',
    isFeatured: false,
    bgColor: '#F9F5F0',
    perks: ['1 Patron account with unlimited access', '1 ticket to all in-person Patron events']
  },
  {
    productId: PRODUCT_IDS.PatronsSilver,
    packageName: 'Silver',
    isFeatured: true,
    featuredText: 'MOST POPULAR',
    bgColor: '#F9F9F9',
    borderColor: '#3C3284',
    perks: [
      '2 Patron accounts with unlimited access',
      '2 tickets to all in-person Patron events',
      '2 complimentary Premium accounts to gift to friends'
    ]
  },
  {
    productId: PRODUCT_IDS.PatronsGold,
    packageName: 'Gold',
    isFeatured: false,
    bgColor: '#FFFDEF',
    perks: [
      '4 Patron accounts with unlimited access for you and your family members',
      '4 tickets to all in-person Patron events',
      '3 complimentary Premium accounts to gift to friends',
      'Your contribution funds 2 full Patron scholarship accounts*'
    ]
  }
];

const ProducersCircleTiers = () => {
  const theme = useTheme();
  const isSmallerDevices = useMediaQuery(theme.breakpoints.down('sm'));
  const sliderRef = useRef();
  const [isYearly, setIsYearly] = useState(true);
  const classes = useStyles({ isYearly });

  return (
    <>
      <div className={classes.root}>
        <Element name="Patron Tiers Section">
          <Container size={50} maxWidth="lg" center className={classes.container}>
            <h2 className={classes.headerText}>Choose your tier:</h2>
            <div className={classes.toggleArea}>
              <Toggle
                name="plan_selector"
                checkedText="Yearly Pledge"
                uncheckedText="Monthly Pledge"
                isChecked={isYearly}
                onChange={setIsYearly}
              />
              <p>Save up to 17% with yearly billing</p>
            </div>
            {!isSmallerDevices ? (
              <div className={classes.cardsArea}>
                {TIERED_PACKAGES.map(tier => (
                  <PricingCard key={`tier_${tier.productId}`} {...tier} isYearly={isYearly} />
                ))}
              </div>
            ) : (
              <div className={classes.cardsArea}>
                <BaseSlider ref={sliderRef}>
                  {TIERED_PACKAGES.map(tier => (
                    <PricingCard key={`tier_${tier.productId}`} {...tier} isYearly={isYearly} />
                  ))}
                </BaseSlider>
              </div>
            )}

            <p className={classes.bottomText}>
              * At Aleph Beta we never turn anyone away due to finances. It is because of the
              generosity of people like you that we are able to provide free scholarship Premium
              accounts to those in need. Thank you for giving the gift of Torah to others.
            </p>
          </Container>
        </Element>
      </div>
      <div className={classes.bottomRoot}>
        <BottomShapeDivider src={BottomShapeDividerSrc1} bgColor="transparent" flex />
        <div className={classes.bottomContainer}>
          <p>So, come along friend, become a Patron and enjoy the impact of the Inner Circle.</p>
        </div>
        <BottomShapeDivider src={BottomShapeDividerSrc2} bgColor="transparent" flex />
      </div>
    </>
  );
};

export default ProducersCircleTiers;
