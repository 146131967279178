import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import unfurl from '~prismic/unfurl/holidays';

import HeroText from '~components/hero/HeroText';
import { Summary, Description } from '~components/wtp-holidays/summary';
import PlaylistCard from '~components/playlist-card/PlaylistCard';
import More from '~components/wtp-holidays/More';
import { Events, EventItem } from '~components/wtp-holidays/list-of-events';
import Resources from '~components/holidays/Resources';
import About from '~components/wtp-holidays/About';

import holidayHeroBg from '~images/holidays/holidays-hero-bg.jpg';
import wtpJewishIcon from '~images/holidays/jewish-icon.jpg';
import wtpTorahIcon from '~images/holidays/torah-icon.jpg';
import wtpNonProfitIcon from '~images/holidays/non-profit-icon.jpg';
import PageWithSlices from '~src/layout/PageWithSlices';

const Holidays = ({ data }) => {
  const { seo, slices, current, listOfEvents } = unfurl(data);
  const { playlists } = current;

  const allCategories = [];
  const defaultCategory = 'All';

  const jewishPlayerEntry = Object.entries(listOfEvents).find(
    ([, event]) => event.title === 'Jewish Prayer'
  );

  if (jewishPlayerEntry) {
    listOfEvents.splice(jewishPlayerEntry[0], 1);
    listOfEvents.push(jewishPlayerEntry[1]);
  }

  return (
    <PageWithSlices seo={seo} slices={slices}>
      <HeroText
        title="Jewish Holidays"
        subtitle="Jewish holidays give us a chance to remember the significant history that has contributed to the survival and shaping of our Jewish identities today, and allow us to tap into the spiritual potential these times hold. These videos take a deep dive into the Jewish holidays, allowing you to uncover how these age-old celebrations and festivals are still relevant to our lives today."
        background_image={{
          url: `${holidayHeroBg}`
        }}
      />

      <Summary>
        {playlists && playlists[0] && <PlaylistCard {...playlists[0]} />}

        <Description {...current} />

        {playlists && playlists[1] && <PlaylistCard {...playlists[1]} />}
      </Summary>

      {playlists && playlists.length > 2 && (
        <More title={current.title}>
          {playlists.map(
            (playlist, index) => index > 1 && <PlaylistCard key={playlist.title} {...playlist} />
          )}
        </More>
      )}

      <Events
        title="List of Holidays"
        allCategories={allCategories}
        defaultCategory={defaultCategory}
      >
        {listOfEvents && listOfEvents.map(item => <EventItem key={item.title} {...item} />)}
      </Events>

      <Resources />

      <About
        title="About Aleph Beta"
        items={[
          {
            title: 'Our Mission',
            description:
              'Aleph Beta’s mission is to create relevant Jewish learning, to help people achieve answers that are meaningful and satisfying for their everyday life. Our Torah videos and guides help you look at some of life’s biggest questions, by taking you on a deep journey through the text of the Torah. We believe Torah study should be evidence-based, intellectually stimulating, and emotionally gripping.',
            image: {
              src: `${wtpJewishIcon}`,
              alt: 'our mission'
            }
          },
          {
            title: 'We Bring Torah to Life',
            description:
              'Through animated Torah Videos and a multisensory experience, Aleph Beta helps bring Torah to life. The introspective content appeals to sophisticated audiences, but the animated style works to engage even young or beginner audiences. Our students should decide for themselves what they believe is compelling, and in this way, we strive to keep our Torah study honest and compelling.',
            image: {
              src: `${wtpTorahIcon}`,
              alt: 'scroll'
            }
          },
          {
            title: "We're Nonprofit",
            description:
              'Aleph Beta is a nonprofit organization, funded with the help of dedicated supporters. For a membership fee of $9 a month, more than 7,000 members help us bring the Torah to life. Together with our world-renowned educators, Aleph Beta is building an online library to make the amazing messages in the Torah available to students all over the world.',
            image: {
              src: `${wtpNonProfitIcon}`,
              alt: 'nonprofit'
            }
          }
        ]}
        cta_text="Join our mission today!"
        cta_link="/subscribe"
      />
    </PageWithSlices>
  );
};

export default withPrismicPreview(Holidays);

Holidays.propTypes = {
  data: PropTypes.shape().isRequired
};

export const query = graphql`
  {
    prismicHolidays {
      _previewable
      data {
        current {
          document {
            ... on PrismicHolidayRedesign {
              data {
                title
                start_date(formatString: "MMMM D, YYYY")
                end_date(formatString: "MMMM D, YYYY")
                verses
                body {
                  ... on PrismicHolidayRedesignDataBodyVideoList {
                    slice_type
                    items {
                      playlist {
                        url
                        document {
                          ... on PrismicPlaylist {
                            uid
                            data {
                              title
                              description {
                                text
                              }
                              cover_image {
                                url
                                alt
                              }
                              author {
                                document {
                                  ... on PrismicAuthor {
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                              videos {
                                video {
                                  document {
                                    ... on PrismicVideo {
                                      data {
                                        length_in_seconds
                                      }
                                    }
                                  }
                                }
                              }
                              label_is_new
                              label_is_audio
                              label_is_premium
                              label_is_producer
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicHolidayRedesignDataBodyPlaylists {
                    slice_type
                    items {
                      playlist {
                        url
                        document {
                          ... on PrismicPlaylist {
                            uid
                            data {
                              title
                              description {
                                text
                              }
                              cover_image {
                                url
                                alt
                              }
                              author {
                                document {
                                  ... on PrismicAuthor {
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                              videos {
                                video {
                                  document {
                                    ... on PrismicVideo {
                                      data {
                                        length_in_seconds
                                      }
                                    }
                                  }
                                }
                              }
                              label_is_new
                              label_is_audio
                              label_is_premium
                              label_is_producer
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicHolidayRedesignDataBodyResources {
                    slice_type
                    items {
                      resource {
                        uid
                        url
                        document {
                          __typename
                          ... on PrismicMaterial {
                            data {
                              title
                              file {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicHolidaysDataBodyProducersCircleMembers {
            slice_type
            items {
              member_name
            }
          }
          ... on PrismicHolidaysDataBodyExpansionPanel {
            slice_type
            primary {
              panel_title
              category
            }
            items {
              panel_materials {
                url
                document {
                  ... on PrismicMaterial {
                    data {
                      title
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidaysDataBodyTeamSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              team_member_avatar {
                url
              }
              team_member_name {
                html
              }
              team_member_position {
                html
              }
            }
          }
          ... on PrismicHolidaysDataBodyJobSection {
            slice_type
            primary {
              job_section_title {
                html
                text
              }
              job_section_content {
                html
              }
              job_section_background
              job_section_button_title
              job_section_button_color
              job_section_button_link {
                url
              }
              job_section_image {
                url
                alt
              }
            }
          }
          ... on PrismicHolidaysDataBodyFaqSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicHolidaysDataBodyAudioFileSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            audio_files: items {
              audio_file_link {
                url
              }
              title
              description {
                html
              }
              transcript {
                html
              }
            }
          }
          ... on PrismicHolidaysDataBodyPromotionalBox {
            slice_type
            primary {
              promotional_box_link
              promotional_box_text
              text_color
              promotional_box_background_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicHolidaysDataBodyResources {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              resource {
                uid
                url
                document {
                  __typename
                  ... on PrismicMaterial {
                    data {
                      title
                      description
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                  ... on PrismicParshat {
                    data {
                      title
                      slices {
                        ... on PrismicParshatDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicHolidayRedesign {
                    data {
                      title
                      body {
                        ... on PrismicHolidayRedesignDataBodyHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAdvanced {
                    data {
                      title
                      slices {
                        ... on PrismicAdvancedDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }

                  ... on PrismicBlogPost {
                    data {
                      title_text: title {
                        text
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidaysDataBodyAuthorTestimonials {
            slice_type
            primary {
              author {
                document {
                  ... on PrismicAuthor {
                    data {
                      name
                      role
                      avatar {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              testimonial
            }
          }
          ... on PrismicHolidaysDataBodyAboutPageAboutUs {
            slice_type
            primary {
              section_title {
                html
                text
              }
              about_us_bg_color
            }
            sections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
              topic_image {
                url
                alt
              }
            }
          }
          ... on PrismicHolidaysDataBodyAboutTestimonials {
            slice_type
            primary {
              testimonials_background_color
            }
            testimonials: items {
              avatar {
                alt
                url
              }
              title
              subtitle
              name
              subscription_plan
            }
          }
          ... on PrismicHolidaysDataBodyAboutVideosPath {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              section_footnote {
                html
              }
              slider_background_color
            }
            slides: items {
              title {
                html
              }
              subtitle {
                html
              }
              first_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              second_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              third_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidaysDataBodyAboutTeam {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            members: items {
              avatar {
                alt
                url
              }
              name {
                html
              }
            }
          }
          ... on PrismicHolidaysDataBodyAboutFaq {
            slice_type
            primary {
              section_title {
                html
                text
              }
              faq_bg_color
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            faqSections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicHolidaysDataBodyAboutCta {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_content {
                html
              }
              section_image {
                alt
                url
              }
            }
            links: items {
              title {
                html
              }
              subtitle {
                html
              }
              to {
                url
              }
            }
          }
          ... on PrismicHolidaysDataBodyFeaturedPlaylist {
            slice_type
            primary {
              featured_playlist {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidaysDataBodyVideoList {
            slice_type
            primary {
              title_video_list {
                html
                text
              }
              event_date {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidaysDataBodyOneLineEmailAcquisition {
            slice_type
            primary {
              cta_text
              one_line_email_background_color
              cta_text_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicHolidaysDataBodyPlaylists {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      cover_image {
                        url
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidaysDataBodyTeamPageFounder {
            slice_type
            primary {
              founder_section_title {
                html
                text
              }
              founder_name {
                html
              }
              founder_position {
                html
              }
              founder_text {
                html
              }
              founder_image {
                url
              }
              founder_content_title {
                html
              }
            }
            items {
              founder_publication_title
              founder_publication {
                url
              }
            }
          }
          ... on PrismicHolidaysDataBodyCustomHtml {
            slice_type
            primary {
              custom_html {
                html
                text
              }
            }
          }
          ... on PrismicHolidaysDataBodyFaqHero {
            slice_type
            primary {
              hero_title {
                html
              }
              hero_subtitle {
                html
              }
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicHolidaysDataBodyHolidayHeroSplitScreen {
            slice_type
            primary {
              overline_text {
                html
                text
              }
              hero_title {
                html
                text
              }
              hero_image_split_direction
              hero_subtitle {
                html
                text
              }
              hero_background_image {
                url
                alt
              }
              label_text {
                html
                text
              }
              label_text_color
              label_text_background_color
              subscription_level
              hero_featured_video {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHolidaysDataBodyHeroTextImage {
            slice_type
            primary {
              title
              subtitle
              link {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
              link_title
              text_color
              background_color
              foreground_image {
                alt
                url
              }
              background_image {
                alt
                url
              }
            }
          }
          ... on PrismicHolidaysDataBodyHeroTextToc {
            slice_type
            primary {
              title
              overline_rich_text {
                html
                text
              }
              underline_rich_text {
                html
                text
              }
              description
              navigation_menu_title
              text_color
              hero_background_color
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicHolidaysDataBodyTextBlock {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content {
                html
              }
            }
          }
          ... on PrismicHolidaysDataBodyTextBlockCols {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content_left {
                html
              }
              text_block_content_right {
                html
              }
            }
          }
          ... on PrismicHolidaysDataBodyAboutHero {
            slice_type
            primary {
              hero_text {
                html
              }
              underline_text {
                html
              }
              hero_background_image {
                url
                alt
              }
              hero_head_image {
                url
                alt
              }
              hero_featured_video {
                url
              }
            }
          }
        }
        seo_title
        seo_description
        seo_image {
          url
        }
        seo_keywords
      }
    }
    allPrismicHolidayRedesign {
      nodes {
        uid
        data {
          title
          start_date(formatString: "MMMM D, YYYY")
          end_date(formatString: "MMMM D, YYYY")
          verses
          body {
            ... on PrismicHolidayRedesignDataBodyHolidayHeroSplitScreen {
              slice_type
              primary {
                hero_subtitle {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
