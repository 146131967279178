import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { FacebookIcon } from 'react-share';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(() => ({
  buttonBackground: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #000000',
    borderRadius: 7,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#F5F5F5'
    }
  },
  avatar: {
    backgroundColor: '#3b5998',
    width: '22px',
    height: '22px',
    marginTop: '-2px'
  }
}));

const SecondaryVariantButton = ({ onClick, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} size="large" fullWidth className={classes.buttonBackground} {...rest}>
      <Box component="span" pr={1}>
        <Avatar className={classes.avatar}>
          <FacebookIcon />
        </Avatar>
      </Box>
      {children}
    </Button>
  );
};

SecondaryVariantButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default SecondaryVariantButton;
