import React, { memo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';

const ModernPlaylistCard = loadable(() => import('~components/playlist-card/ModernPlaylistCard'));

const RichText = loadable(() => import('~src/components/RichText'));
const ModernCarousel = loadable(() => import('~components/ModernCarousel'));

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '32px',
    paddingBottom: '16px',
    minHeight: '370px'
  },
  title: {
    '& > *': {
      fontSize: '48px',
      fontFamily: 'Inter',
      fontWeight: 600,
      lineHeight: '113%',
      color: '#000000',
      textAlign: 'center',
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px'
      }
    }
  }
}));

const ModernPlaylists = memo(({ section_title, playlists, progress, hasPromotionalCard }) => {
  const classes = useStyles();
  const modifiedPlaylists = [...playlists];

  if (!playlists.length) {
    return null;
  }

  if (hasPromotionalCard) {
    modifiedPlaylists.splice(3, 0, {});
  }

  return (
    <div className={classes.root}>
      {section_title && (
        <Container center>
          <RichText html={section_title} externalClassName={classes.title} />
        </Container>
      )}
      <ModernCarousel>
        {playlists &&
          modifiedPlaylists.map((playlist, index) => (
            <ModernPlaylistCard
              key={playlist.id || `promotional-pc-card-${index}`}
              {...playlist}
              progress={progress[playlist.id]}
            />
          ))}
      </ModernCarousel>
    </div>
  );
});

ModernPlaylists.propTypes = {
  section_title: PropTypes.string,
  playlists: PropTypes.arrayOf(PropTypes.shape(ModernPlaylistCard.propTypes)).isRequired,
  progress: PropTypes.shape({}),
  hasPromotionalCard: PropTypes.bool
};

ModernPlaylists.defaultProps = {
  section_title: null,
  progress: {},
  hasPromotionalCard: false
};

export default ModernPlaylists;
