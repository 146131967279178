import { extractKnownSlices, extractSeoData } from '~src/utils/unfurl-helpers';
import { get } from '../utils/get';

const formatDateRange = (start, end) => (start && end ? `${start} - ${end}` : start);

const extractPlaylistData = slices => {
  const playlistsSlices = slices?.filter(slice =>
    ['playlists', 'video_list'].includes(slice.slice_type)
  );
  const playlists = get(playlistsSlices, '[0].items', [])
    .filter(({ playlist }) => !!playlist.document)
    .map(({ playlist }) => ({
      ...playlist.document.data,
      url: playlist.url,
      uid: playlist.uid,
      videos: get(playlist, 'document.data.videos', []).map(video =>
        get(video, 'video.document.data')
      ),
      author: get(playlist, 'document.data.author.document.data.name')
    }));
  return playlists;
};

const extractGuidesData = slices => {
  const resourcesSlices = slices?.filter(slice => slice.slice_type === 'resources');
  const guides = get(resourcesSlices, '[0].items', [])
    .filter(resource => !!resource.document)
    .map(({ resource }) => ({
      uid: resource.uid,
      url: resource.url,
      ...get(resource, 'document.data', {})
    }));
  return guides;
};

const extractCurrentData = prismicData => {
  const data = get(prismicData, 'prismicHolidays.data.current.document.data');
  const { start_date, end_date, body: slices } = data;

  const extracted = {
    title: data.title,
    date: formatDateRange(start_date, end_date),
    verses: data.verses,
    guides: extractGuidesData(slices),
    playlists: extractPlaylistData(slices)
  };

  return extracted;
};

const extractListOfEvents = prismicData => {
  const data = get(prismicData, 'allPrismicHolidayRedesign.nodes');
  const events = [];
  data.forEach(({ uid, data: holidayData }) => {
    const { start_date, end_date } = holidayData;
    const holidaySlices = get(holidayData, 'body', []).filter(({ slice_type }) => !!slice_type);
    const heroSlice = holidaySlices.find(
      ({ slice_type }) => slice_type === 'holiday_hero_split_screen'
    );
    const descriptionFromHero = get(heroSlice, 'primary.hero_subtitle.text', null);

    events.push({
      ...holidayData,
      url: `/${uid}`,
      uid,
      description: descriptionFromHero,
      date: formatDateRange(start_date, end_date)
    });
  });

  return events;
};

export default prismicData => ({
  current: extractCurrentData(prismicData),
  listOfEvents: extractListOfEvents(prismicData),
  seo: extractSeoData(prismicData, 'prismicHolidays'),
  slices: extractKnownSlices(prismicData, 'prismicHolidays.data.body')
});
