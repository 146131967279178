import { useEffect } from 'react';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Cookies from 'js-cookie';

const IDENTIFIED_USER_TYPE_KEY = 'identified_user_type';
const IDENTIFIED_USER_PLAN_KEY = 'identified_user_plan';

export default () => {
  const { session } = useUserContext();
  const { isLoggedIn, isSubscriber, isProducer, isEducator, isUserStateLoading } = session;

  useEffect(() => {
    if (isUserStateLoading) {
      return;
    }

    // set identified user type
    if (isSubscriber) {
      Cookies.set(IDENTIFIED_USER_TYPE_KEY, 'Subscribed User');
    } else if (isLoggedIn) {
      Cookies.set(IDENTIFIED_USER_TYPE_KEY, 'Free User');
    } else {
      Cookies.set(IDENTIFIED_USER_TYPE_KEY, 'Guest User');
    }

    // set user plan
    if (isEducator) {
      Cookies.set(IDENTIFIED_USER_PLAN_KEY, 'Educator');
    } else if (isProducer) {
      Cookies.set(IDENTIFIED_USER_PLAN_KEY, 'Producer');
    } else if (isSubscriber) {
      Cookies.set(IDENTIFIED_USER_PLAN_KEY, 'Premium');
    }
  }, [isLoggedIn, isSubscriber, isProducer, isEducator, isUserStateLoading]);
};
