import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import RichText from '~components/RichText';
import Container from '~components/Container';
import ResourceCard from './ResourceCard';
import FeaturedResourceCard from './FeaturedResourceCard';

const Resources = ({ title, children }) => (
  <Box component={Container} center size={50} pt={4} pb={4} id="AdditionalResources">
    {children &&
      children
        .filter(child => child.props.is_featured)
        .map((child, index) => (
          <Grid
            container
            key={`resource-key-${title}-${index}` || `resource-key-Additional Resources-${index}`}
          >
            <FeaturedResourceCard resource={child.props} />
          </Grid>
        ))}

    {title && <RichText html={title} mode="resourcesSectionTitleBold" />}

    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
      {children &&
        children
          .filter(child => !child.props.is_featured)
          .map((child, index) => (
            <Grid
              item
              key={`resource-key-${title}-${index}` || `resource-key-Additional Resources-${index}`}
            >
              <ResourceCard resource={child.props} />
            </Grid>
          ))}
    </Grid>
  </Box>
);

Resources.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};
Resources.defaultProps = {
  title: 'Additional Resources'
};

export default Resources;
