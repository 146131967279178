import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import MessageBox from '~components/forms/MessageBox';
import SearchIconBetaImg from '~images/navbar/search-icon.png';
import SearchPreviousPageLink from './SearchPreviousPageLink';
import autocompleteTerms from './autocompleteTerms';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    marginTop: '5px'
  },
  submitButton: {
    height: '100%',
    boxShadow: 'unset',
    textTransform: 'none',
    fontSize: '18px',
    lineHeight: '21px'
  },
  searchInputWrapper: {
    position: 'relative',
    background: 'white',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchInput: {
    padding: '14px 1em 13px 16px',
    width: '100%',
    fontSize: '24px',
    lineHeight: '28px',
    border: '0 none',
    '&:focus': {
      border: '0 none',
      outline: '0 none',
      background: 'white !important'
    }
  },

  searchRibbonImage: {
    height: '35px',
    marginLeft: '17px',
    maxWidth: '100%',
    display: 'flex'
  },
  popper: {
    width: '100%',
    backgroundColor: 'white',
    zIndex: '100',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    maxHeight: '500px',
    overflowY: 'auto',
    scrollbarColor: '#C4C4C4 transparent',
    scrollbarWidth: 'thin',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      overflowY: 'auto'
    },
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '15px',
      backgroundColor: '#C4C4C4'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '10px'
    }
  },

  popperField: {
    padding: '6px 12px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F9F2E8'
    }
  }
}));

const CustomSearchInput = ({ inputRef, disabled, classes, searchTerm }) => {
  const searchWrapperRef = useRef(null);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [inputValue, setInputValue] = useState(searchTerm);

  const filterData = query => {
    setFilteredData(
      autocompleteTerms.filter(term => term.toLowerCase().startsWith(query.toLowerCase()))
    );
  };

  const handleChange = event => {
    const query = event.target.value;

    setInputValue(query);
    if (query) {
      filterData(query);
      setPopperAnchorEl(searchWrapperRef.current);
    }
  };

  const hidePopper = () => {
    setPopperAnchorEl(null);
  };

  const handleSuggestionSubmit = event => {
    const value = event.target.id;

    setInputValue(value);
    hidePopper();
    navigate(`/search?t=${value}`);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        hidePopper();
      }}
    >
      <div ref={searchWrapperRef} className={classes.searchInputWrapper}>
        <img
          src={SearchIconBetaImg}
          alt="Aleph Beta Search Beta"
          className={classes.searchRibbonImage}
        />

        <input
          className={classes.searchInput}
          name="term"
          defaultValue={searchTerm}
          ref={inputRef}
          disabled={disabled}
          placeholder="Search by topic, character, etc..."
          onChange={handleChange}
          value={inputValue}
          autoComplete="off"
          required
        />

        <Popper
          open={!!popperAnchorEl && inputValue}
          anchorEl={popperAnchorEl}
          className={classes.popper}
          placement="bottom-start"
          disablePortal
          onClose={hidePopper}
        >
          {filteredData.map(field => (
            <Box
              id={field}
              key={field}
              onClick={handleSuggestionSubmit}
              className={classes.popperField}
            >
              {field}
            </Box>
          ))}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
CustomSearchInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    searchInputWrapper: PropTypes.string,
    searchInput: PropTypes.string,
    searchRibbonImage: PropTypes.string,
    popper: PropTypes.string,
    popperField: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  searchTerm: PropTypes.string
};
CustomSearchInput.defaultProps = {
  disabled: false,
  searchTerm: null
};

const SearchInputForm = ({
  onSubmit,
  isLoading,
  isError,
  error,
  register,
  submitTitle = 'Search',
  searchTerm
}) => {
  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      {isError && <MessageBox text={error} isError />}

      <Grid container direction="row" justify="space-evenly" spacing={3}>
        <Grid item xs={12} sm={10}>
          <CustomSearchInput
            classes={classes}
            inputRef={register}
            disabled={isLoading}
            searchTerm={searchTerm}
            onSubmit={onSubmit}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            className={classes.submitButton}
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {submitTitle}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <SearchPreviousPageLink />
        </Grid>
      </Grid>
    </form>
  );
};
SearchInputForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  register: PropTypes.func.isRequired,
  submitTitle: PropTypes.string,
  searchTerm: PropTypes.string
};
SearchInputForm.defaultProps = {
  isError: false,
  error: undefined,
  isLoading: false,
  submitTitle: 'Search',
  searchTerm: null
};

export default SearchInputForm;
