import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  buttonStyle: props => ({
    position: 'fixed',
    right: props.right || '20px',
    top: props.top || '20px',
    borderRadius: '50%'
  }),
  iconStyle: {
    fontSize: props => props.iconSize || '2rem',
    color: props => props.color || '#0000004D'
  }
});

const ButtonClosePopup = ({ handleClose, color, iconSize, right, top, ...rest }) => {
  const classes = useStyles({ color, iconSize, right, top });
  return (
    <IconButton aria-label="close" className={classes.buttonStyle} onClick={handleClose} {...rest}>
      <CloseIcon className={classes.iconStyle} />
    </IconButton>
  );
};

ButtonClosePopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  color: PropTypes.string,
  iconSize: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string
};
ButtonClosePopup.defaultProps = {
  color: null,
  iconSize: '2rem',
  right: '20px',
  top: '20px'
};

export default ButtonClosePopup;
