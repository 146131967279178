import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookie } from '@use-hook/use-cookie';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import RichText from '~components/RichText';
import { useGlobalStore } from '~context/GlobalContext/GlobalContextProvider';
import { setBottomBannerHidden } from '~context/GlobalContext/actions';
import { navigate } from 'gatsby';
import AnalyticsTracking, { ANALYTIC_EVENT } from '~utils/analytics-tracking';
import RedirectWithNotice from '~layout/RedirectWithNotice';
import MarqueeBanner from './MarqueeBanner';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: '0%',
    left: '0%',
    width: '100%',
    zIndex: 450,
    boxShadow: '4px 0px 3px 2px rgba(0, 0, 0, 0.1)'
  },
  root: {
    backgroundColor: props =>
      props.banner_background_color ? `${props.banner_background_color}` : '#FFFFFF',
    padding: '40px 85px',
    marginBottom: '-5px',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('xl')]: {
      padding: '40px 185px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 15px 28px'
    }
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 20,
    [theme.breakpoints.down('sm')]: {
      rowGap: 28,
      flexDirection: 'column'
    }
  },
  descriptionArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 60,
    [theme.breakpoints.down('sm')]: {
      gap: 20
    }
  },
  image: {
    maxHeight: '114px',
    borderRadius: '7px',
    [theme.breakpoints.down('xs')]: {
      height: '84px',
      width: '104px',
      objectFit: 'cover'
    }
  },
  titleText: {
    '& > *': {
      fontSize: '32px',
      lineHeight: '38px',
      fontFamily: 'Inter',
      maxWidth: props => (props.has_image ? '600px' : '900px'),
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: props => props.banner_text_color || '#000000',
      [theme.breakpoints.down('sm')]: {
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '23px'
      }
    }
  },
  actionArea: {
    width: '220px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  secondaryText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '& > *': {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'Inter',
      fontWeight: 500,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: props => props.banner_secondary_text_color || '#666666'
    }
  },
  ctaLink: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    color: props => props.button_text_color || '#FFFFFF',
    backgroundColor: props => props.button_background_color || '#000000'
  },
  ctaButton: {
    display: 'flex',
    flexDirection: 'row',
    gridColumnGap: '10px',
    paddingTop: '16px',
    paddingBottom: '15px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '23px',
      paddingBottom: '20px'
    }
  },
  arrow: {
    fontSize: 20
  },
  btnText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Inter',
    fontWeight: 600,
    paddingRight: '20px'
  },
  closeBtnArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: '18px',
    [theme.breakpoints.down('sm')]: {
      right: '2px'
    }
  },
  closeBtn: {
    color: props => props.close_icon_color || '#000000',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  closeIcon: {
    fontSize: '18px'
  }
}));

const Banner = ({
  uid,
  banner_background_color,
  banner_text,
  banner_text_color,
  button_background_color,
  button_text_color,
  banner_is_announcement,
  button_title,
  close_icon_color,
  banner_link,
  banner_secondary_text,
  banner_secondary_text_color,
  banner_image,
  appear_always,
  show_marquee_banner,
  marquee_animation_speed,
  marquee_texts,
  daysToExpire
}) => {
  const classes = useStyles({
    banner_background_color,
    banner_text_color,
    button_background_color,
    button_text_color,
    close_icon_color,
    banner_secondary_text_color,
    has_image: !!banner_image?.url
  });
  const [isBannerHidden, setIsBannerHidden] = useCookie(`hide-banner-${uid}`, false);
  const {
    state: { isSpotifyPlayerOpen, isBottomBannerHidden },
    dispatch
  } = useGlobalStore();
  const [redirection, setRedirection] = useState(null);

  const handleBannerClose = () => {
    if (!appear_always) {
      setIsBannerHidden(true, { expires: daysToExpire });
    }
    dispatch(setBottomBannerHidden(true));
  };

  const handleCtaClick = () => {
    if (!appear_always) {
      setIsBannerHidden(true, { expires: daysToExpire });
    }
    dispatch(setBottomBannerHidden(true));

    /*
      Note: Navigate to the link after analytics success
      This is how mixpanel suggests to track events between page loads (in SPA case, cross site navigation)
      callback is always getting called (even if the requests fails) after 300ms
    */
    AnalyticsTracking.trackEventWithCallback(
      ANALYTIC_EVENT.BANNER_CLICK,
      {
        Banner: 'Bottom Banner',
        UID: uid,
        'Page Path': typeof window !== 'undefined' && window.location.pathname,
        'Navigate Path': banner_link
      },
      () => {
        if (banner_link && banner_link.startsWith('http')) {
          // for external links, let's show a notice before redirecting
          setRedirection({
            url: banner_link,
            description: 'We are redirecting you to the applicable page.',
            seconds: 2
          });
        } else {
          navigate(banner_link, { state: { banner_uid: uid } });
        }
      }
    );
  };

  const isMainBannerHidden = isBannerHidden || !uid || isBottomBannerHidden;

  // navigate with a message
  if (redirection?.url) {
    return <RedirectWithNotice {...redirection} />;
  }

  // when spotify player is on, don't show any banners
  if (isSpotifyPlayerOpen) {
    return null;
  }

  // if only marquee banner is set to visible, shown only the marquee banner
  if (show_marquee_banner && isMainBannerHidden) {
    return (
      <div className={classes.container}>
        <MarqueeBanner
          marquee_texts={marquee_texts}
          marquee_animation_speed={marquee_animation_speed}
          close_button_offset="70px"
        />
      </div>
    );
  }

  // if main banner is hidden, show nothing
  if (isMainBannerHidden) {
    return null;
  }

  return (
    <div className={classes.container}>
      {show_marquee_banner && (
        <MarqueeBanner
          marquee_texts={marquee_texts}
          marquee_animation_speed={marquee_animation_speed}
        />
      )}
      <div className={classes.root}>
        <div className={classes.contentArea}>
          <div className={classes.descriptionArea}>
            {banner_image?.url && (
              <img className={classes.image} src={banner_image?.url} alt={banner_image?.alt} />
            )}
            <RichText
              externalClassName={classes.titleText}
              html={banner_text.html}
              verticalSpacing={0}
            />
          </div>

          <div className={classes.actionArea}>
            <RichText
              externalClassName={classes.secondaryText}
              html={banner_secondary_text.html}
              verticalSpacing={0}
            />
            {banner_is_announcement !== 'Yes' && (
              <div
                tabIndex={0}
                onClick={handleCtaClick}
                role="button"
                onKeyDown={event => {
                  if (
                    ['keydown', 'keypress'].includes(event.type) &&
                    ['Enter', ' '].includes(event.key)
                  ) {
                    handleCtaClick();
                  }
                }}
                className={classes.ctaLink}
              >
                <div className={classes.ctaButton}>
                  <ArrowForward className={classes.arrow} />
                  <Typography component="p" className={classes.btnText}>
                    {button_title}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.closeBtnArea}>
          <IconButton
            aria-label="close"
            onClick={handleBannerClose}
            classes={{
              root: classes.closeBtn
            }}
            disableRipple
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  uid: PropTypes.string, // can be undefined if `show_marquee_banner = true`.
  banner_background_color: PropTypes.string,
  banner_text: PropTypes.string,
  banner_text_color: PropTypes.string,
  button_background_color: PropTypes.string,
  button_text_color: PropTypes.string,
  button_title: PropTypes.string,
  banner_link: PropTypes.string,
  close_icon_color: PropTypes.string,
  banner_is_announcement: PropTypes.string,
  daysToExpire: PropTypes.number,
  banner_secondary_text: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  }),
  banner_secondary_text_color: PropTypes.string,
  banner_image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  appear_always: PropTypes.bool,
  show_marquee_banner: PropTypes.bool,
  marquee_animation_speed: PropTypes.number,
  marquee_texts: PropTypes.arrayOf(
    PropTypes.shape({
      marquee_text: PropTypes.shape({
        html: PropTypes.string
      })
    })
  )
};

Banner.defaultProps = {
  uid: '',
  daysToExpire: 14,
  banner_link: '',
  button_title: '',
  button_text_color: '',
  button_background_color: '',
  banner_text: '',
  banner_text_color: '',
  banner_background_color: '',
  banner_secondary_text: null,
  banner_secondary_text_color: '',
  banner_image: null,
  appear_always: false,
  show_marquee_banner: false,
  marquee_animation_speed: 100,
  marquee_texts: [],
  close_icon_color: '',
  banner_is_announcement: ''
};

export default Banner;
