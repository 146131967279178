import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PlayArrow as PlayArrowIcon } from '@material-ui/icons';
import { Box, useMediaQuery } from '@material-ui/core';
import Container from '~components/Container';
import { HeroSubtitle } from '~components/Typography';
import BookLikeNoOtherBg from '~images/book-like-no-other-bg.png';
import BookLikeNoOther from '~images/book-like-no-other.png';
import BookLikeNoOtherUneditedBg from '~images/bg-blno-unedited.png';
import BookLikeNoOtherUnedited from '~images/blno-unedited.png';
import cn from 'classnames';
import RichText from '~components/RichText';
import PodcastListenButtons from '../PodcastListenButtons';
import PodcastButton from '../PodcastButton';

const useStyles = makeStyles({
  listenButton: {
    margin: '32px',
    marginBottom: '8px',
    transition: 'all .2s ease-in-out',
    '& span': {
      gap: '8px'
    }
  },
  listenButtonContainer: {
    minHeight: '100px'
  },
  description: {
    color: '#fff',
    '& > *': {
      fontSize: '24px',
      letterSpacing: '0em',
      lineHeight: 1.334,
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  bookLikeNoOther: {
    maxWidth: '400px'
  },
  podcastCover: {
    height: 'auto',
    width: 'auto',
    maxHeight: '200px',
    margin: 'auto'
  },
  podcastCoverMobile: {
    width: '100%'
  }
});

const PodcastHero = ({
  podcastCover,
  play,
  coverColor,
  playButtonColor,
  playButtonTextColor,
  providers,
  description,
  title,
  uid,
  selectedSeason
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('sm'));
  const podcastCoverStyles = cn({
    [classes.podcastCover]: true,
    [classes.podcastCoverMobile]: !isUpMD
  });

  const { isBook, bg, bookImage } = useMemo(() => {
    switch (uid) {
      case 'book-like-no-other': {
        const bgBlno = selectedSeason?.heroBackground?.url || BookLikeNoOtherBg;
        return { isBook: true, bg: `url(${bgBlno})`, bookImage: BookLikeNoOther };
      }
      case 'behind-the-scenes-a-book-like-no-other': {
        const bgBlnoBehindScenes = selectedSeason?.heroBackground?.url || BookLikeNoOtherUneditedBg;
        return {
          isBook: true,
          bg: `url(${bgBlnoBehindScenes})`,
          bookImage: BookLikeNoOtherUnedited
        };
      }
      default:
        return {
          isBook: false,
          bg: `linear-gradient(${coverColor} 0 60%, #000000)`,
          bookImage: null
        };
    }
  }, [uid, coverColor, selectedSeason]);

  return (
    <Container
      center="horizontal"
      background={bg}
      background_position={isBook ? 'bottom' : 'center'}
    >
      <div>
        <Box
          height="100%"
          display={isBook ? 'grid' : 'flex'}
          gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
          flexDirection={isBook ? 'row' : 'column'}
          justifyContent="center"
          alignItems="center"
          margin={isUpMD ? '50px 0' : '30px 0 0'}
          width="100%"
        >
          {isBook && (
            <Box gridColumn="span 5" display={isUpMD ? 'grid' : 'none'} justifyContent="end">
              <img
                src={bookImage}
                width={isUpMD ? '400px' : '100%'}
                alt={title}
                className={`${classes.bookLikeNoOther} lazyload`}
              />
            </Box>
          )}
          <Box
            display={isBook ? 'grid' : 'flex'}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gridColumn={isUpMD ? 'span 6' : 'span 12'}
            textAlign="center"
            width="auto"
          >
            {podcastCover && (
              <img
                src={podcastCover.url}
                className={podcastCoverStyles}
                alt={podcastCover.alt || 'podcast cover'}
                height="auto"
                width="auto"
              />
            )}

            {description && (
              <HeroSubtitle>
                <RichText
                  externalClassName={classes.description}
                  html={description}
                  verticalSpacing={0}
                />
              </HeroSubtitle>
            )}

            <PodcastButton
              btnVariant="colored"
              color={selectedSeason?.playButtonTextColor || playButtonTextColor}
              backgroundColor={selectedSeason?.playButtonColor || playButtonColor}
              onClick={() => play(selectedSeason?.uid)}
              className={classes.listenButton}
              style={isBook ? { width: '50%', margin: '32px auto 8px' } : {}}
            >
              <PlayArrowIcon htmlColor={playButtonTextColor} height={14} width={14} />
              START LISTENING
            </PodcastButton>
          </Box>

          <Box
            gridColumn="span 12"
            display="grid"
            justifyContent="center"
            className={classes.listenButtonContainer}
          >
            {!isBook && <PodcastListenButtons applyBorder providers={providers} />}
          </Box>
        </Box>
      </div>
    </Container>
  );
};

PodcastHero.propTypes = {
  play: PropTypes.func.isRequired,
  podcastCover: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  coverColor: PropTypes.string,
  playButtonColor: PropTypes.string,
  playButtonTextColor: PropTypes.string,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      provider_id: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  uid: PropTypes.string,
  selectedSeason: PropTypes.shape({
    uid: PropTypes.string,
    heroBackground: PropTypes.shape({
      url: PropTypes.string
    }),
    playButtonColor: PropTypes.string,
    playButtonTextColor: PropTypes.string,
    listenOnSpotify: PropTypes.shape({
      url: PropTypes.string,
      id: PropTypes.string
    }),
    listenOnApple: PropTypes.shape({
      url: PropTypes.string,
      id: PropTypes.string
    }),
    listenOnGoogle: PropTypes.shape({
      url: PropTypes.string,
      id: PropTypes.string
    }),
    episodes: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.instanceOf(Date),
        description: PropTypes.string,
        duration: PropTypes.number,
        episode: PropTypes.number,
        part: PropTypes.number,
        season: PropTypes.number,
        title: PropTypes.string,
        wistiaStreamUrl: PropTypes.string
      })
    )
  })
};

PodcastHero.defaultProps = {
  podcastCover: '',
  coverColor: '#001242',
  playButtonTextColor: '#FFFFFF',
  playButtonColor: '',
  description: '',
  title: '',
  uid: '',
  selectedSeason: null
};

export default PodcastHero;
