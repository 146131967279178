import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import Excerpt from '~components/Excerpt';

const useStyles = makeStyles({
  chipStyle: props => ({
    maxWidth: '100%',
    color: '#fff',
    backgroundColor: props.color,
    '& > .MuiChip-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block'
    }
  }),
  cardContentPadding: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  }
});

const EventItem = ({ title, date, verses, description, url, color }) => {
  const classes = useStyles({ color });

  return (
    <Box component={Card} padding={3} boxShadow={0} border="1px solid #E8E5E1">
      <CardContent className={classes.cardContentPadding}>
        <Box minHeight="20px">
          {date && (
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              {date}
            </Typography>
          )}
        </Box>
        <Box minHeight="80px">
          <Typography variant="h4" component="h3" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Box mb={2} minHeight="24px">
          {verses && <Chip label={verses} size="small" className={classes.chipStyle} />}
        </Box>
        <Box minHeight="128px">
          <Excerpt description={description || ''} />
        </Box>
        <Box mt={2}>
          <Divider />
        </Box>

        <CardActions>
          <Button component={GatsbyLink} to={`${url}/#AdditionalResources`} color="primary">
            Guides
          </Button>

          <Button component={GatsbyLink} to={url} color="primary">
            Videos
          </Button>
        </CardActions>
      </CardContent>
    </Box>
  );
};

EventItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  verses: PropTypes.string,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string
};

EventItem.defaultProps = {
  date: null,
  verses: null,
  color: '#438dce'
};

export default EventItem;
