const isBrowser = typeof window !== 'undefined';

export const localeDate = (
  date,
  options = { year: 'numeric', month: 'numeric', day: 'numeric' },
  locale = isBrowser ? window.navigator.language : 'en-US'
) => {
  if (!date || date < 0) {
    return 'Invalid Date';
  }
  return new Date(date).toLocaleDateString(locale, options);
};

export const localeTime = (
  date,
  options = { hour: 'numeric', minute: 'numeric' },
  locale = isBrowser ? window.navigator.language : 'en-US'
) => {
  if (!date || date < 0) {
    return 'Invalid Date';
  }
  return new Date(date).toLocaleTimeString(locale, options);
};

export const localeDateTime = (
  date,
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  },
  locale = isBrowser ? window.navigator.language : 'en-US'
) => {
  if (!date || date < 0) {
    return 'Invalid Date';
  }
  return new Date(date).toLocaleString(locale, options);
};

export const extractMinutes = seconds => {
  if (typeof seconds !== 'number' || !seconds || seconds < 0) {
    return 0;
  }
  return Math.floor(seconds / 60);
};

export const extractHoursAndMinutes = seconds => {
  if (typeof seconds !== 'number' || !seconds || seconds < 0) {
    return '00:00';
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsRemainder = seconds % 60;
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  const secondsStr = secondsRemainder.toString().padStart(2, '0');
  if (hours > 0) {
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }
  return `${minutesStr}:${secondsStr}`;
};

/**
 * Converts a duration in seconds to an ISO 8601 formatted duration string.
 *
 * @param {number} seconds - The duration in seconds.
 * @returns {string} - The ISO 8601 formatted duration string.
 *
 * @example
 * makeIso8601DurationFromSeconds(3661);
 * // returns "PT1H1M1S"
 */
export const makeIso8601DurationFromSeconds = seconds => {
  if (typeof seconds !== 'number' || seconds < 0 || Number.isNaN(seconds)) {
    return 'Invalid';
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secondsLeft = seconds - hours * 3600 - minutes * 60;
  return `PT${hours}H${minutes}M${secondsLeft}S`;
};

export const formatDateYYYYMMDD = dateString => {
  if (!dateString || dateString < 0) {
    return 'Invalid Date';
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
