import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import RichText from '~components/RichText';
import Link from '~components/Link';
import RoundedLinkIconButton from '~components/buttons/RoundedLinkIconButton';

import MaskImage from '~images/mask-image.png';
import LgTest from '~images/lg-mask-image.png';
import MdTest from '~images/md-mask-test.png';
import MobileMask from '~images/mobile-mask-image.png';
import MaskDarkImage from '~images/mask-image-dark.png';
import LgDarkTest from '~images/lg-mask-image-dark.png';
import MdDarkTest from '~images/md-mask-test-dark.png';
import MobileDarkMask from '~images/mobile-mask-image-dark.png';

import Container from '~components/Container';

import HolidayHeroContainer from '~modules/holiday/components/HolidayHeroContainer';
import PlayButton from './PlayButton';
import ModernHolidayHeroMobile from './ModernHolidayHeroMobile';

const useStyles = makeStyles(theme => ({
  body: props => ({
    backgroundColor: props.themeColor,
    color: props.themeSecondaryColor
  }),
  gridStyle: {
    zIndex: 2,
    marginBlockEnd: '40px'
  },
  maskImage: props => ({
    display: 'flex',
    width: '54vw',
    position: 'absolute',
    left: '-4vw',
    top: 0,
    marginLeft: '-1px',
    height: props.maskHeight && props.maskHeight > 650 ? `${props.maskHeight}px` : '650px',
    [theme.breakpoints.up('lg')]: {
      width: '57vw'
    }
  }),
  eventName: props => ({
    marginBlockStart: '180px',
    marginBlockEnd: '30px',
    color: props.themeSecondaryColor,
    opacity: 0.6,
    fontSize: '16px',
    '& > *': {
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Inter',
      color: props.themeSecondaryColor,
      margin: 0
    },
    [theme.breakpoints.only('xs')]: {
      marginBlockStart: '75px'
    }
  }),
  heroTitle: props => ({
    marginBlockStart: 0,
    marginBlockEnd: 11,
    '& > *': {
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: '56px',
      lineHeight: '140%',
      color: props.themeSecondaryColor,
      margin: 0,
      textAlign: 'left'
    }
  }),
  heroSubtitle: props => ({
    marginBlockStart: 0,
    marginBlockEnd: 39,
    '& > *': {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 500,
      color: props.themeSecondaryColor,
      margin: 0,
      paddingRight: '66px',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        paddingRight: 'unset',
        marginBottom: '35px'
      }
    }
  })
}));

const StyledLink = styled(Link)`
  width: 100%;
`;

const StyledMobileHeroImage = styled.img`
  display: flex;
  width: 100vw;
  margin-left: -24px;
  margin-bottom: -8px;
  @media (max-width: 600px) {
    margin-left: -16px;
  }
`;

const StyledDiv = styled.div`
  position: relative;
`;

const StyledGrid = styled(Grid)`
  min-height: 650px;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 959px) {
    min-height: unset;
  }
`;

const THEME_OPTION = {
  MODERN_LIGHT: 'Modern Light',
  MODERN_DARK: 'Modern Dark'
};

const THEME_PRIMARY_COLOR = {
  [THEME_OPTION.MODERN_LIGHT]: '#FFFFFF',
  [THEME_OPTION.MODERN_DARK]: '#020928'
};

const THEME_SECONDARY_COLOR = {
  [THEME_OPTION.MODERN_LIGHT]: '#000000',
  [THEME_OPTION.MODERN_DARK]: '#FFFFFF'
};

const ModernHolidayHero = props => {
  const {
    theme: themeOption,
    hero_background_image,
    overline_text,
    hero_title,
    hero_subtitle,
    hero_featured_video
  } = props;
  const [maskHeight, setMaskHeight] = useState();
  const classes = useStyles({
    maskHeight,
    themeColor: THEME_PRIMARY_COLOR[themeOption],
    themeSecondaryColor: THEME_SECONDARY_COLOR[themeOption]
  });
  const { dispatch, session } = useUserContext();
  const { isUserStateLoading } = session;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const { url } = hero_featured_video;

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setMaskHeight(node.getBoundingClientRect().height);
      // eslint-disable-next-line no-undef
      if (typeof ResizeObserver !== 'undefined') {
        // eslint-disable-next-line no-undef
        const resizeObserver = new ResizeObserver(() => {
          setMaskHeight(node.getBoundingClientRect().height);
        });
        resizeObserver.observe(node);
      }
    }
  }, []);

  const calcHeroImage = () => {
    if (isSmallerThanMd) {
      return null;
    }
    if (isMd) {
      return themeOption === THEME_OPTION.MODERN_DARK ? MdDarkTest : MdTest;
    }

    if (isXl) {
      return themeOption === THEME_OPTION.MODERN_DARK ? MaskDarkImage : MaskImage;
    }
    return themeOption === THEME_OPTION.MODERN_DARK ? LgDarkTest : LgTest;
  };

  const isTishaBavHome = window && window.location.pathname === '/';
  useEffect(() => {
    if (isTishaBavHome && !isUserStateLoading) {
      dispatch({ type: 'USER_UPDATE', payload: { tishaBavHomeHeroFeaturedUrl: url } });
    }
  }, [dispatch, url, isUserStateLoading, isTishaBavHome]);

  const mobileMaskImage = themeOption === THEME_OPTION.MODERN_DARK ? MobileDarkMask : MobileMask;
  const buttonVariant = themeOption === THEME_OPTION.MODERN_DARK ? 'light' : 'dark';
  const playIconColor = themeOption === THEME_OPTION.MODERN_DARK ? '#000000' : '#FFFFFF';
  const playBgColor = themeOption === THEME_OPTION.MODERN_DARK ? '#FFFFFF' : '#000000';

  if (isMobile) {
    return <ModernHolidayHeroMobile {...props} />;
  }

  return (
    <div className={classes.body}>
      {isSmallerThanMd && (
        <Container background={`url("${hero_background_image.url}")`} zIndex={0}>
          <StyledDiv>
            <StyledMobileHeroImage src={mobileMaskImage} alt="Mask" />
            <GatsbyLink to={url}>
              <PlayButton backgroundColor={playBgColor} iconColor={playIconColor} />
            </GatsbyLink>
          </StyledDiv>
        </Container>
      )}
      <HolidayHeroContainer
        background={!isSmallerThanMd && `url("${hero_background_image.url}")`}
        zIndex={0}
      >
        {!isSmallerThanMd && (
          <StyledLink to={url}>
            <PlayButton backgroundColor={playBgColor} iconColor={playIconColor} />
          </StyledLink>
        )}

        <StyledGrid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          ref={measuredRef}
        >
          <Grid item xs={12} md={6} className={classes.gridStyle}>
            <RichText
              html={overline_text.html}
              verticalSpacing={0}
              externalClassName={classes.eventName}
            />

            <RichText html={hero_title.html} externalClassName={classes.heroTitle} />
            <RichText html={hero_subtitle.html} externalClassName={classes.heroSubtitle} />
            <RoundedLinkIconButton
              text="Watch Now"
              to={url}
              icon={
                <PlayArrowIcon fill={THEME_PRIMARY_COLOR[themeOption]} height={17} width={17} />
              }
              variant={buttonVariant}
            />
          </Grid>
          {!isSmallerThanMd && (
            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
              <img src={calcHeroImage()} alt="Mask" className={classes.maskImage} />
            </Grid>
          )}
        </StyledGrid>
      </HolidayHeroContainer>
    </div>
  );
};
ModernHolidayHero.propTypes = {
  hero_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }).isRequired,
  hero_mobile_background_image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
  }),
  overline_text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  }).isRequired,
  hero_featured_video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    document: PropTypes.shape({
      data: PropTypes.shape({
        label_is_new: PropTypes.string.isRequired,
        label_is_audio: PropTypes.string.isRequired,
        label_is_premium: PropTypes.string.isRequired,
        label_is_producer: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  theme: PropTypes.oneOf(Object.values(THEME_OPTION))
};

ModernHolidayHero.defaultProps = {
  theme: THEME_OPTION.MODERN_DARK,
  hero_mobile_background_image: ''
};

export default ModernHolidayHero;
