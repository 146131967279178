import genesisImage from './images/genesis.png';
import exodusImage from './images/exodus.png';
import leviticusImage from './images/leviticus.png';
import numbersImage from './images/numbers.png';
import deuteronomyImage from './images/deuteronomy.png';

export const SECTION_CONTENT = [
  {
    title: 'Genesis',
    description:
      'We begin the Book of Bereishit with the amazing creation of the world. Reading Genesis takes us on a journey through the stories of Abraham, Isaac, and Jacob, where we witness the birth of the future 12 tribes of Israel. We read about sibling rivalry, deceit, betrayal and love affairs – all provoking questions that apply to our lives today.',
    image: genesisImage
  },
  {
    title: 'Exodus',
    description:
      'In the Book of Shemot, the Israelites are enslaved by the Egyptians, but then they are led out of Egypt – through Moses, God’s plagues, and then the spitting of the sea. We follow the Israelites to freedom, until we reach Mount Sinai, where the Israelites are given the 10 commandments and more. We’re also taught our first Biblical laws, and given instructions about the Mishkan, the Tabernacle.',
    image: exodusImage
  },
  {
    title: 'Leviticus',
    description:
      'The Book of Vayikra is an important book for commandments and laws. When we read Leviticus, we study the intricate and various laws related to the important Jewish holidays, the Temple, kedusha, and other holy and spiritual matters.',
    image: leviticusImage
  },
  {
    title: 'Numbers',
    description:
      'Before entering the Promised Land, the Israelites spend 40 years wandering the desert. And that’s exactly what happens in the Book of Bamidbar. Through their experiences, we learn about the power of leadership and growth; we see what it takes for Moses to help the nation to spiritually prepare for the Land of Israel.',
    image: numbersImage
  },
  {
    title: 'Deuteronomy',
    description:
      'Before entering the Promised Land, the Israelites spend 40 years wandering the desert. And that’s exactly what happens in the Book of Bamidbar. Through their experiences, we learn about the power of leadership and growth; we see what it takes for Moses to help the nation to spiritually prepare for the Land of Israel.',
    image: deuteronomyImage
  }
];
