import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSyncUser } from '~hooks/useAuth';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import useVwoUserIdentification from '~hooks/useExperiment/useVwoUserIdentification';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import MobileAppBlocker from '~layout/MobileAppBlocker';
import loadable from '@loadable/component';
import 'lazysizes';

const IntercomWatcher = loadable(() => import('~containers/Intercom/IntercomWatcher'));

const MOBILE_POPUP_ENABLED = isFeatureEnabled(FEATURE_FLAGS.MOBILE_APP_REDIRECT_POPUP);

const App = ({ children }) => {
  useUserContext();
  useSyncUser();
  useVwoUserIdentification();

  return (
    <>
      <CssBaseline />
      {MOBILE_POPUP_ENABLED ? <MobileAppBlocker /> : null}
      {children}
      <IntercomWatcher />
    </>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired
};

export default App;
