/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from '~components/LoadingSpinner';
import HorizontalHolidayHero from '~modules/holiday/components/HorizontalHolidayHero';
import VerticalHolidayHero from '~modules/holiday/components/VerticalHolidayHero';
import ModernHolidayHero from '~modules/holiday/components/ModernHolidayHero';
import HanukkahHero from '~modules/holiday/components/HanukkahHero/';
import { isBotAgent } from '~utils/browser-helpers';

const HeroPlaceHolderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
`;

export const calculateHolidayHero = (isAdmin, isSubscriber, isUserStateLoading, heros) => {
  const adminHero = heros && heros.find(hero => hero.primary.subscription_level === 'Admins');
  const subscribersHero =
    heros && heros.find(hero => hero.primary.subscription_level === 'Subscribers');
  const nonSubscribedUsersHero =
    heros && heros.find(hero => hero.primary.subscription_level === 'Free Users/Guests');
  const everyoneHero = heros && heros.find(hero => hero.primary.subscription_level === 'Everyone');

  if (!adminHero && !subscribersHero && !nonSubscribedUsersHero && !everyoneHero) {
    return null;
  }

  const isBot = isBotAgent();
  if (isUserStateLoading && !isBot) {
    return (
      <HeroPlaceHolderDiv>
        <LoadingSpinner />
      </HeroPlaceHolderDiv>
    );
  }

  let applicableProps = null;
  if (isAdmin && adminHero) {
    applicableProps = adminHero;
  } else if (subscribersHero && isSubscriber) {
    applicableProps = subscribersHero;
  } else if (nonSubscribedUsersHero && !isSubscriber) {
    applicableProps = nonSubscribedUsersHero;
  } else if (everyoneHero) {
    applicableProps = everyoneHero;
  }

  if (!applicableProps) {
    return null;
  }

  // Modern Design
  if (
    applicableProps.primary.theme === 'Modern Light' ||
    applicableProps.primary.theme === 'Modern Dark'
  ) {
    return <ModernHolidayHero {...applicableProps.primary} />;
  }

  // NEW THEME
  if (applicableProps.primary.theme === 'Hanukkah') {
    return <HanukkahHero {...applicableProps.primary} />;
  }

  // Default Horizontal Design
  if (applicableProps.primary.hero_image_split_direction === 'Horizontal') {
    return <HorizontalHolidayHero {...applicableProps.primary} />;
  }
  // Default Vertical Design
  return <VerticalHolidayHero {...applicableProps.primary} />;
};
