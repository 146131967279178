import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import VideoPlayCard from '~components/VideoPlayCard';
import Container from '~components/Container';
import { formatDurationOfFirstVideoTishaBav } from '~utils/playlist-helpers';
import ResourceCard from '~components/slices/resources/ResourceCard';
import WeeklyTorahPortionOneLineSignUp from '../WeeklyTorahPortionOneLineSignUp/WeeklyTorahPortionOneLineSignUp';
import WeeklyTorahPortionMoreVideos from './WeeklyTorahPortionMoreVideos';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6F6F6',
    paddingBottom: '20px'
  },
  title: {
    fontSize: '48px',
    fontWeight: '700',
    lineHeight: '120%',
    paddingTop: '30px',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  videoContainer: {
    width: '75%',
    padding: '60px 0px'
  },
  subTitle: {
    fontSize: '40px',
    fontWeight: '600',
    lineHeight: '55px',
    textAlign: 'center'
  },
  parshaInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '11px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  parshaInfoBox: {
    padding: '14px 38px',
    borderRadius: '12px',
    border: '1px solid #DDDDDE',
    background: '#FFFFFF',
    boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.10)',
    '& > p': {
      color: '#000',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '1.68px',
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 0
    },
    '& > h5': {
      color: '#000',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '400',
      textAlign: 'center',
      marginTop: 0,
      marginBottom: 0
    }
  }
}));

const WeeklyTorahPortionHero = ({
  playlists,
  featuredPlaylist,
  current,
  isLoggedIn,
  isUserStateLoading
}) => {
  const classes = useStyles();
  const { title, date, verses, guides } = current;

  const actualFeaturedPlaylist = featuredPlaylist || (playlists && playlists[0]);

  return (
    <Container className={classes.root}>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <h1 className={classes.title}>Parsha of the week: Parshat {title}</h1>
        <div className={classes.parshaInfoContainer}>
          <div className={classes.parshaInfoBox}>
            <p>Parsha Date:</p>
            <h5>{date}</h5>
          </div>

          <div className={classes.parshaInfoBox}>
            <p>Torah Verses:</p>
            <h5>{verses}</h5>
          </div>
        </div>
        {actualFeaturedPlaylist && (
          <div className={classes.videoContainer}>
            <VideoPlayCard
              title={actualFeaturedPlaylist.title}
              url={actualFeaturedPlaylist.url}
              description={actualFeaturedPlaylist.description.text}
              image={actualFeaturedPlaylist.cover_image}
              author={actualFeaturedPlaylist.author?.document?.data?.name}
              videoCount={actualFeaturedPlaylist?.videos?.length}
              isAudio={actualFeaturedPlaylist?.is_audio}
              duration={formatDurationOfFirstVideoTishaBav(
                actualFeaturedPlaylist.videos[0].length_in_seconds
              )}
            />
          </div>
        )}
        {playlists && playlists.length > 1 && (
          <>
            <h2 className={classes.subTitle}>More Videos on Parshat {title}</h2>
            <WeeklyTorahPortionMoreVideos playlists={playlists} />
          </>
        )}
        <WeeklyTorahPortionOneLineSignUp
          isLoggedIn={isLoggedIn}
          isUserStateLoading={isUserStateLoading}
        />
        {guides && (
          <>
            <h3 className={classes.subTitle}>Printable Learning Guides</h3>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={4}
            >
              {guides.map(guide => (
                <Grid item key={guide.url}>
                  <ResourceCard resource={guide} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

WeeklyTorahPortionHero.propTypes = {
  current: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    verses: PropTypes.string,
    guides: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        uid: PropTypes.string,
        required_subscription_plan: PropTypes.string.isRequired,
        file: PropTypes.shape({
          url: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired
  }).isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }).isRequired,
      label_is_audio: PropTypes.string,
      label_is_new: PropTypes.string,
      label_is_premium: PropTypes.string,
      label_is_producer: PropTypes.string,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          length_in_seconds: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  featuredPlaylist: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.shape({}).isRequired,
    cover_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string
    }).isRequired,
    label_is_audio: PropTypes.string,
    label_is_new: PropTypes.string,
    label_is_premium: PropTypes.string,
    label_is_producer: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        length_in_seconds: PropTypes.number
      })
    ).isRequired
  }).isRequired,
  isLoggedIn: PropTypes.bool,
  isUserStateLoading: PropTypes.bool.isRequired
};

WeeklyTorahPortionHero.defaultProps = {
  isLoggedIn: false
};

export default WeeklyTorahPortionHero;
