import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';

import SiteLogo from '~images/site_logo_raw.png';

const useStyles = makeStyles({
  avatar: {
    width: 48,
    height: 48
  }
});
const AuthorAvatar = ({ url, alt }) => {
  const classes = useStyles();
  return <Avatar alt={alt} src={url} className={classes.avatar} />;
};

AuthorAvatar.propTypes = {
  alt: PropTypes.string,
  url: PropTypes.string
};

AuthorAvatar.defaultProps = {
  alt: 'Aleph Beta Author',
  url: SiteLogo
};

export default AuthorAvatar;
