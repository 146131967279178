import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles(() => ({
  circle: props => ({
    width: `${props.size}px`,
    height: `${props.size}px`,
    background: props.backgroundColor,
    borderRadius: '50%'
  }),
  animationPosition: props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: props.backgroundColor,
    borderRadius: '50px',
    width: `${props.size}px`,
    height: `${props.size}px`
  }),
  playSvg: props => ({
    fill: props.iconColor,
    height: Math.round(props.size * 0.7),
    width: Math.round(props.size * 0.7)
  }),
  iconWrapper: props => ({
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: `${props.size}px`,
    height: `${props.size}px`
  })
}));

const CircleWrapper = posed.div({
  hidden: {
    transform: 'scale(1.06)',
    transition: {
      duration: 1000
    }
  },
  visible: {
    transform: 'scale(1)',
    transition: {
      duration: 400
    }
  }
});

const Circle = posed.div({
  hidden: {
    transform: 'scale(0.6)',
    opacity: 1,
    transition: {
      duration: 0
    }
  },
  visible: {
    transform: 'scale(1.4)',
    opacity: 0,
    transition: {
      duration: 1000
    }
  }
});

const CustomAnimatedButton = ({ iconColor, backgroundColor, size }) => {
  const classes = useStyles({ iconColor, backgroundColor, size });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fadeOut = setInterval(() => {
      setIsVisible(prevState => !prevState);
    }, 1000);

    return () => clearInterval(fadeOut);
  }, []);

  return (
    <div className={classes.animationPosition}>
      <CircleWrapper className={classes.circle} pose={isVisible ? 'visible' : 'hidden'}>
        <Circle className={classes.circle} pose={isVisible ? 'visible' : 'hidden'} />
      </CircleWrapper>
      <div className={classes.iconWrapper}>
        <PlayArrowIcon className={classes.playSvg} />
      </div>
    </div>
  );
};

CustomAnimatedButton.propTypes = {
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  size: PropTypes.number
};

CustomAnimatedButton.defaultProps = {
  backgroundColor: '#FFFFFF',
  iconColor: '#000000',
  size: 82
};

export default CustomAnimatedButton;
